import { LayoutObject } from './layoutObject.model';

export class ContactLayout extends LayoutObject {
  transform: string;

  phone?: any[];
  email?: any[];
  fax?: any[];

  constructor(data) {
    super(data);

    this.transform = data.transform;
    this.phone = data.phone ? data.phone.split(',') : null;
    this.email = data.email ? data.email.split(',') : null;
    this.fax = data.fax ? data.fax.split(',') : null;
  }
}

<div class="search-box-layout container" [id]="elementID" style="margin-top: 0.5rem; border: 1px dotted #91A0AD;">
  <div *ngIf="canLoad" class="container-wrapper container-fluid" style="padding-right: 0;">
    <form [formGroup]="searchFb">
      <div class="row noStyleEventList">
        <div class="col-md-10 col-sm-10 col-xl-10 col-lg-10 col-xs-12" style="padding-left: 0;">
          <div class="row">
            <!-- ----------------------IsVenueSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsVenueSearch">
              <div class="input-group with-icon">
                <input type="text" class="form-control rounded" placeholder="Venue" matInput formControlName="venueName"
                  name="Venue Name" [matAutocomplete]="auto" (focusout)="resetOnFalseVenue()" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let v of filteredOptions | async " [value]="v.name"
                    (selectionChange)="selectedVenue = v" (click)="selectedVenue = v">
                    {{v.name}}
                  </mat-option>
                  <mat-option *ngIf="isNoResultFound">No results found</mat-option>
                </mat-autocomplete>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
            </div>

            <!-- ----------------------IsRegionSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsRegionSearch"
              (click)="setStyles('cdk-panel')">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label id="matLabelRegion">Regions</mat-label>
                <mat-select [formControl]="toppings" disableRipple multiple>
                  <mat-select-trigger>
                    {{toppings.value ? toppings.value[0] : ''}}
                    <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                      (+{{toppings.value.length - 1}} {{toppings.value?.length
                      === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
                  <mat-option (click)="displayRegion ? getValues() : '' ;" *ngFor="let topping of regionList"
                    [value]="topping.name">{{topping.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- ----------------------IsParkSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsParkSearch">
              <div class="input-group with-icon">
                <input type="text" class="form-control rounded" placeholder="Parks" matInput
                  formControlName="parkEvents" name="Park Events" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let r of sortedVenueList" [value]="r.name"
                    (selectionChange)="selectedRegionEvent = r" (click)="selectedRegionEvent =r.name"
                    (click)="displayRegion ? getSelectedValue(r) : '' ;">
                    {{r.name}}
                  </mat-option>
                  <mat-option *ngIf="isNoResultFound">No results found</mat-option>
                </mat-autocomplete>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
            </div>

            <!-- ----------------------IsDate----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsDate">
              <div class="input-group with-icon">
                <!-- <input
                  class="form-control rounded"
                  [value]="selectedValue"
                  [ngModelOptions]="true"
                  [(ngModel)]="selected"
                  (click)="openDialog(template)"
                  (click)="setMaxWidth()"
                  autocomplete="false"
                  placeholder="Select Date"
                  autocomplete="off"
                /> -->
                <input class="form-control rounded" type="text" (input)="onInputDateRangeText($event)"
                  (focusout)="resetOnFalseDate($event)" [value]="selectedValue" ngxDaterangepickerMd
                  [locale]="datePickerLocale" [(ngModel)]="selected" [showCustomRangeLabel]="true"
                  [alwaysShowCalendars]="true" [ranges]="ranges" [autoApply]="false" [linkedCalendars]="true"
                  [isInvalidDate]="isInvalidDate" [showClearButton]="false" [customRangeDirection]="true"
                  [keepCalendarOpeningWithRange]="true" (datesUpdated)="choosedDateTime($event)"
                  placeholder="Select Date" autocomplete="off" id="dateRangePicker" />
                <i class="fa fa-calendar"></i>
              </div>
            </div>

            <!-- ----------------------IsDays----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsDays">
              <div class="input-group with-icon">
                <input class="form-control rounded" (change)="displayDateRange ? daysChange($event) : ''" type="number"
                  min="1" matInput placeholder="Days" formControlName="days" name="days" autocomplete="off" />
                <i class="fa fa-sort-numeric-desc"></i>
              </div>
            </div>

            <!-- ----------------------IsEventSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsEventSearch">
              <div class="input-group with-icon">
                <input class="form-control rounded" matInput formControlName="eventName" placeholder="Search Events"
                  name="Event Name" autocomplete="off" />
                <i class="fa fa-search"></i>
              </div>
            </div>


            <!-- ----------------------IsAccess----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAccess">
              <div class="input-group with-icon">
                <input type="text" class="form-control rounded" placeholder="Access Types" matInput
                  formControlName="access" name="access" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option value="Access Option 1">Conventional Vehicle</mat-option>
                  <mat-option value="4WD">4WD</mat-option>
                  <mat-option value="Walk-in">Walk-in</mat-option>
                  <mat-option value="Boat">Boat</mat-option>
                  <mat-option value="Kayak">Kayak</mat-option>
                </mat-autocomplete>
                <i class="fa fa-arrow-circle-down"></i>
              </div>
            </div>

            <!-- ----------------------IsAccommodation----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAccommodation">
              <div class="input-group with-icon">
                <input type="text" class="form-control rounded" placeholder="Accommodation Types" matInput
                  formControlName="accommodation" name="accommodation" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option value="Camper Trailer">Camper Trailer</mat-option>
                  <mat-option value="Campervan">Campervan</mat-option>
                  <mat-option value="Caravan">Caravan</mat-option>
                  <mat-option value="Motorhome">Motorhome</mat-option>
                  <mat-option value="Tent">Tent</mat-option>
                </mat-autocomplete>
                <i class="fa fa-arrow-circle-down"></i>
              </div>
            </div>
            <!-- ----------------------IsAvailability----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAvailability">
              <div class="input-group with-icon">
                <input class="form-control rounded" type="number" min="0" matInput placeholder="Availability"
                  formControlName="availability" name="Availability" autocomplete="off" />
                <i class="fa fa-sort-numeric-desc"></i>
              </div>
            </div>
            <!-- ----------------------IsAddOn----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAddOn">
              <div class="input-group with-icon">
                <input class="form-control rounded" matInput placeholder="Addon" formControlName="isAddon"
                  name="isAddon" autocomplete="off" />
                <i class="fa fa-plus-circle"></i>
              </div>
            </div>
            <!-- ----------------------IsAddOnSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAddOnSearch">
              <div class="input-group with-icon">
                <input class="form-control rounded" matInput placeholder="AddOnSearch" formControlName="addOnSearch"
                  name="addOnSearch" autocomplete="off" />
                <i class="fa fa-search-plus"></i>
              </div>
            </div>
            <!-- ----------------------IsEventGroup----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsEventGroup">
              <div class="input-group with-icon">
                <input class="form-control rounded" matInput formControlName="eventGroup" placeholder="Event Group"
                  name="Event Group" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let v of eventGropsFilteredOptions | async " [value]="v.name"
                    (selectionChange)="selectedEventGroup = v" (click)="selectedEventGroup = v">
                    {{v.name}}
                  </mat-option>
                  <mat-option *ngIf="isNoEventGropResultFound">No results found</mat-option>
                </mat-autocomplete>
                <i class="fa fa-search"></i>
              </div>
            </div>
            <!-- ----------------------IsPriceRange----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsPriceRange">
              <div class="input-group with-icon" style="background-color: white;"
                style="padding-left: 12px; padding-right: 12px; padding-bottom: 15px; border: 1px solid #c7c7c7; border-radius: 0.25rem!important;">
                <ngx-slider class="custom-slider-style" style="display: inline-block; margin-bottom: 10px;"
                  id="ngx-price-range-slider" formControlName="eventByPriceRange" [(value)]="minValue"
                  [(highValue)]="maxValue" [options]="options" (userChange)="valueChanges()">
                </ngx-slider>
              </div>
            </div>
            <!-- ----------------------IsAgeGroup----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsAgeGroup">
              <div class="input-group" style="background-color: white; border: 1px solid #c7c7c7; border-radius: 0.25rem!important;" (scroll)="scrollHandler()"
                (click)="dropdownOpenBasedOnSpace()">
                <ng-multiselect-dropdown class="custom-style" [placeholder]="'Select Age Group'"
                  [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)" formControlName="ageGroupId" [settings]="dropdownSettings"
                  [disabled]="disabled" (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="selectallItems($event)"
                  (onDeSelectAll)="unselectallItems($event)">
                </ng-multiselect-dropdown>
              </div>
            </div>

            <!-- ----------------------IsBusinessAreaSearch----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsBusinessArea">
              <div class="input-group with-icon">
                <input class="form-control rounded" formControlName="businessArea" placeholder="Business Area"
                  name="businessArea" autocomplete="off" matInput [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let v of filteredOptionsBusiArea | async " [value]="v.name"
                    (selectionChange)="selectedBusinessArea = v" (click)="selectedBusinessArea = v">
                    {{v.name}}
                  </mat-option>
                  <mat-option *ngIf="isNoResultFoundBusiArea">No results found</mat-option>
                </mat-autocomplete>
                <i class="fa fa-search"></i>
              </div>
            </div>

            <!-- ----------------------IsEventSalesCategory----------------------- -->
            <div [class]="classValue+' p-0'" *ngIf="layoutAttribute.searchPanelData.IsSalesCategory">
              <div class="input-group with-icon">
                <input class="form-control rounded" matInput formControlName="eventSalesCategory"
                  placeholder="Event Sales Category" name="Event Sales Category" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let v of eventSalesCategoryFilteredOptions | async " [value]="v.name"
                    (selectionChange)="selectedEventSalesCategory = v" (click)="selectedEventSalesCategory = v">
                    {{v.name}}
                  </mat-option>
                  <mat-option *ngIf="isNoEventSalesCategoryResultFound">No results found</mat-option>
                </mat-autocomplete>
                <i class="fa fa-search"></i>
              </div>
            </div>

            <!------------------------------------------------------------>
          </div>
        </div>

        <div class="col-md-2 col-sm-2 col-xl-2 col-xs-12 searchBoxBtn"
          style="position: absolute; bottom: 0px; right: 0; padding: 0;">
          <ng-container *ngIf="onNoDelBtnVisible(searchFb.value) else elseTemplate">
            <div class="row">
              <div class="col-12 colFullBtn">
                <button class="btn btn-block btn-primary" style="
                    min-width: 99%;
                    height: 36px !important;
                    border: none;
                    color: white;
                  " type="submit" (click)="onSubmit()">
                  Search
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="row">
              <div class="col-3 searchBoxClearCol">
                <button class="btn btn-block btn-primary p-0 searchBoxClear" style="
                    height: 36px !important;
                    min-width: 100%;
                    background-color: white !important;
                    border-color: #ab2020;
                    padding-left: 5px;
                    padding-right: 5px;
                  " type="button" (click)="clearFields()">
                  <mat-icon style="color: #ab2020; margin-bottom: 5px;">delete</mat-icon>
                </button>
              </div>
              <div class="col-9 ipadCol" style="padding-left: 0;">
                <button class="btn btn-block btn-primary" style="
                    min-width: 100%;
                    height: 36px !important;
                    border: none;
                    color: white;
                  " type="submit" (click)="onSubmit()">
                  Search
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Custom Date Picker -->
<ng-template #template style="overflow: scroll; display: flex; flex-direction: column;">
  <mat-card class="date-range-picker-card-template" style="width: max-content; padding: 17px 5px 0 5px;">
    <mat-card-content>
      <ngx-daterangepicker-material (datesUpdated)="choosedDateTime($event)" [(ngModel)]="selected"
        [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
        [showClearButton]="false" [customRangeDirection]="true" [keepCalendarOpeningWithRange]="true">
      </ngx-daterangepicker-material>
    </mat-card-content>
  </mat-card>
</ng-template>

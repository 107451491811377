<div class="container">
    <div class="mmc_payment mmc_card payment">
        <div class="mmc_flex--container flex-column">
            <div class="mmc_flex__item main-row">
                <div class="mmc_flex--container">
                    <div class="mmc_flex__item p75">
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Invoice Reference Number" (ngModelChange)="invoiceValueChange($event)"  [(ngModel)]="ValidateInvoiceId" value="Enter Invoice Reference Number/CRN" [readonly]="!allowToDoManualInvoiceSearch">
                        </mat-form-field>
                    </div>

                        <div class="mmc_flex__item--vcenter p25 val-sec" *ngIf="!IsValidInvoiceRef && allowToDoManualInvoiceSearch">
                            <div class="">
                                <button class="btn__validate font__large" (click)="ValidateInvoice()" (keydown.enter)="ValidateInvoice()" mat-button>Validate</button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="mmc_flex__item main-row">
                <mat-form-field   [floatLabel]="'always'"  class="example-full-width pay-amount">
                    <input matInput id="amountInput1" placeholder="Payment Amount ({{currency}})" type="text" [ngStyle]="{'text-align':'right','position':'inherit','right':'-'+currency.length*14+'px'}" value="{{OutStandingAmount | NumberFormat}}" [readonly]="IsDisable" *ngIf="IsDisable">
                    <mat-label>Payment Amount</mat-label>
                    <input matInput id="amountInput" placeholder="Payment Amount" [formControl]="PaymentControl"    type="number" [ngStyle]="{'text-align':'right','position':'inherit','right':'-'+currency.length*14+'px'}" [(ngModel)]="OutStandingAmount" [readonly]="IsDisable" *ngIf="!IsDisable"  [min]="0"  [max]="OutStandingAmountMax" >
                    <span matPrefix style="position: absolute; margin-left: 1px; top:-14px">{{currency}}</span>
               </mat-form-field>
               <!-- [value]="OutStandingAmount| number:'1.2-2'" [ngModel]="OutStandingAmount|number:'1.2-2'"-->
            </div>
            <div class="mcc_flex__item main-row btn-section" *ngIf="IsValidInvoice && IsValidInvoiceRef" >
              <button type="button" class="buttons-secondary btn_mobile__pay font__large font__regular font__upper" (click)="proceedToPay()" (keydown.enter)="proceedToPay()" [disabled]="isBillerCodeLoading">
                proceed to pay
                <mat-icon *ngIf="isBillerCodeLoading"><mat-spinner style="top: 5px;" customSpinner color="#ffffff" diameter="20"></mat-spinner></mat-icon>
              </button>
            </div>
        </div>
    </div>
    <div *ngIf="(showLoader)">
        <opt-mmc-loader></opt-mmc-loader>
    </div>
</div>

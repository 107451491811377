import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ILayout } from '../../models/interfaces';
import { HeaderLayout } from '../../models/headerLayout.model';
import * as d3 from 'd3';
import { Theme } from '../../models/globalTheme';
import { LocalImage } from '../../models/image.model';
import { HEIGHT_SIZE } from '../../models/enums';
import { ThemeService } from '../../../../../services/public-web/theme.service';
import { SliderImage } from '../../models/sliderImage.model';
import { DomSanitizer } from '@angular/platform-browser';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Observable } from 'rxjs';
import { style } from '@angular/animations';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})

export class HeaderLayoutComponent implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: HeaderLayout;
  @Input() unique_key: any;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() elementID = 'demo';
  @Input() canLoad = false;
  carouselBanner: NguCarouselConfig;
  tempData: any[];
  
  sliderImages: SliderImage[] = [];
  parentRef: any;
  theme: Theme;
  videoCode:any
  dataIntervel:number=3000
  constructor(
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {
    this.setSliderImageForView();
    setTimeout(() => {
      this.initializeAttributes();
    }, 0);  
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.carouselBanner = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, xl:1, all: 0 },
      slide: 1,
      interval: {
        timing: this.layoutAttribute.imageTransitionTime*1000
      },
      point: {
        visible: true
      },
      custom: 'banner',
      load: 2,
      loop: true,
      vertical:{
        enabled:false,
        height:this.layoutAttribute.height
      },
    };
   
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });
    let url="https://www.youtube.com/embed/"+this.layoutAttribute.content+"?autoplay=1&mute=1&loop=1&playlist="+this.layoutAttribute.content+"&showinfo=0&rel=0&modestbranding=1&autohide=1&showinfo=0"
    this.videoCode = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    this.layoutAttribute.imageTransitionTime ? this.dataIntervel=(this.layoutAttribute.imageTransitionTime * 1000 ) : '';
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      const mainContainer = contentElement.closest('.main-layout-container');
      if (mainContainer) {
        switch (this.layoutAttribute.sizeType) {
          case HEIGHT_SIZE.FitToContent:
            const containerWidth = (d3.select(mainContainer).select('.carousel-inner').node() as Element).getBoundingClientRect().width;
            let largeHeight = 0;
            this.sliderImages.forEach(i => {
              const hr = Math.round(i.image.height / i.image.width * containerWidth);
              if (largeHeight < hr) {
                largeHeight = hr;
              }
            });
            this.addGlobalTheme(mainContainer);
            this.setSliderHeight(mainContainer, largeHeight);
            break;
          case HEIGHT_SIZE.SpecifySize:
            this.addGlobalTheme(mainContainer);
            this.setSliderHeight(mainContainer, this.layoutAttribute.height);
            break;
        }
      }
    }
  }

  setSliderHeight(mainContainer, height) {
    d3.select(mainContainer)
      .style('height', height + 'px');
    d3.select(mainContainer).select('.carousel-inner')
      .style('height', height + 'px')
      .selectAll('.slider-image')
      .style('height', height + 'px');
    if (this.layoutAttribute.isFitImage || this.layoutAttribute.sizeType === HEIGHT_SIZE.FitToContent) {
      d3.select(mainContainer)
        .selectAll('.slider-image').style('object-fit', 'contain');
    }
    if (!this.layoutAttribute.isFitImage) {
      d3.select(mainContainer)
        .selectAll('.slider-image').style('object-fit', 'cover');
    }
  }

  addGlobalTheme(mainContainer) {
    d3.select(mainContainer)
      .style('background-color', this.theme.global.backgroundColor);
  }

  setSliderImageForView() {
    this.sliderImages = this.layoutAttribute.sliderImages.filter(x => x.isSelected);
  }
}


<div  class=" main-layout-container footer-content social-media-content "
      [style.transform]="layoutAttribute.transform"
      [style.height.px]="layoutAttribute.height"
      [style.width.px]="layoutAttribute.width"

>

  <div  class="footer-layout-content"   [id]="type + '_' + unique_key">
    <nav>
      <ul>
        <li *ngIf="layoutAttribute.facebookUrl !== null"><a href="" class="item" (click)=" goto(layoutAttribute.facebookUrl)"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
        <li *ngIf="layoutAttribute.youtubeUrl !== null"><a href="" class="item" (click)=" goto(layoutAttribute.youtubeUrl)"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
        <li *ngIf="layoutAttribute.twitterUrl !== null"><a href="" class="item" (click)=" goto(layoutAttribute.twitterUrl)"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
        <li *ngIf="layoutAttribute.instagramUrl !== null"><a href="" class="item" (click)=" goto(layoutAttribute.instagramUrl)"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
      </ul>
    </nav>
  </div>
</div>

<div class="image-layout" [id]="elementID" [style.height.px]="layoutAttribute.height"
  [ngClass]="layoutAttribute.componentClass">

  <img *ngIf="layoutAttribute.image" [src]="layoutAttribute.image.src" class="img-container" width="100%" style="cursor:pointer"
    (error)="this.src='https://cdn-5fcbf5c4c1ac1a221c18568d.closte.com/wp-content/themes/ryse/assets/images/no-image/No-Image-Found-400x264.png'"
    [style.height.px]="layoutAttribute.height" (click)="navigate()"  [ngClass]="layoutAttribute.componentClass"/>

  <img *ngIf="layoutAttribute.url" [src]="layoutAttribute.url"
    (error)="this.src='https://cdn-5fcbf5c4c1ac1a221c18568d.closte.com/wp-content/themes/ryse/assets/images/no-image/No-Image-Found-400x264.png'"
    class="img-container" width="100%" [style.height.px]="layoutAttribute.height" />

  <img *ngIf="!layoutAttribute.url && !layoutAttribute.image"
    src="https://cdn-5fcbf5c4c1ac1a221c18568d.closte.com/wp-content/themes/ryse/assets/images/no-image/No-Image-Found-400x264.png"
    class="img-container" width="100%" [style.height.px]="layoutAttribute.height" />

  <div *ngIf=" layoutAttribute.content && layoutAttribute.height" class="image-layout-content" style="border-radius: 0 0 30px 30px !important;"></div>
</div>

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Theme } from "../../models/globalTheme";
import { ILayout } from "../../models/interfaces";
import * as d3 from "d3";
import { CardLayout } from "../../models/cardLayout.model";
import { COLUMN, LAYOUT_TYPE, PUBLIC_PAGE_DATA_TYPE } from "../../models/enums";
import { ThemeService } from "../../../../../services/public-web/theme.service";
import { RowLayoutComponent } from "../row-layout/row-layout.component";
import { RowLayout } from "../../models/rowLayout.model";
import { ImageLayout } from "../../models/image.model";
import { HTMLLayout } from "../../models/htmlLayout.model";
import { ImageLayoutComponent } from "../image-layout/image-layout.component";
import { HtmlLayoutComponent } from "../html-layout/html-layout.component";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { BaseComponent } from "src/modules/shared/base.component";
import { Package } from "src/modules/models/public-web/Package";
import { AddPackage, SetPackageList, UpdatePackage } from "../../../../../store/public-web/public-web-actions";
import * as _ from "lodash";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { IPublicPackage } from "../../../../../store/public-web/public-web-reducers";

@Component({
  selector: "opt-card-layout",
  templateUrl: "./card-layout.component.html",
})
export class CardLayoutComponent
  extends BaseComponent
  implements OnInit, ILayout, AfterViewInit
{
  @ViewChild("cardViewContainer", { read: ViewContainerRef })
  VCR: ViewContainerRef;
  parentRef: any;
  theme: Theme;
  cardGridSize = 12;
  cardContainerTemp;
  @Input() isView: boolean;
  @Input() layoutAttribute: CardLayout;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID = "demo";
  @Input() canLoad = false;
  categoryCardRrs = [];
  publicCart$;
  publicPackage: IPublicPackage;
  private selectedLayout;
  private selectedType;
  constructor(
    private themeService: ThemeService,
    private CFR: ComponentFactoryResolver,
    private store: Store<any>
  ) {
    super();
    this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageCarts);
  }

  ngOnInit(): void {
    this.themeService.theme$.safeSubscribe(this, (theme) => {
      this.theme = theme;
    });
    this.publicCart$.safeSubscribe(this, (carts:IPublicPackage[]) => {
      const p = _.cloneDeep(carts);
      if (
        p &&
        this.layoutAttribute.cardDataType ===
          PUBLIC_PAGE_DATA_TYPE.PUBLIC_PACKAGE
      ) {
        this.publicPackage = p.find(
          (x) => x.id === this.layoutAttribute.referenceID
        );
      }
    });
  }

  setCardGridView(e) {
    this.cardGridSize =
      e.numberOfColumns === COLUMN.ONE_COLUMN
        ? 12
        : e.numberOfColumns === COLUMN.TWO_COLUMN
        ? 6
        : e.numberOfColumns === COLUMN.THREE_COLUMN
        ? 4
        : e.numberOfColumns === COLUMN.FOUR_COLUMN
        ? 3
        : 12;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
      if (this.isView) {
        this.layoutAttribute.childLayout.forEach((layout) => {
          this.selectedLayout = undefined;
          this.selectedType = undefined;
          this.selectComponentForCreate(layout);
          if (this.selectedType && this.selectedLayout) {
            this.createComponent(layout);
          }
        });
      }
    });
  }
  createComponent(layout) {
    const componentFactory = this.CFR.resolveComponentFactory(
      this.selectedLayout
    );
    const childComponentRef = this.VCR.createComponent(componentFactory);
    const childComponent: any = childComponentRef.instance;
    childComponent.unique_key = layout.componentKey;
    childComponent.isView = true;
    childComponent.type = this.selectedType;
    childComponent.layoutAttribute = layout;
    childComponent.elementID =
      "view_" + childComponent.type + "_" + childComponent.unique_key;
  }

  private selectComponentForCreate(layout: any) {
    if (layout instanceof RowLayout) {
      this.selectedLayout = RowLayoutComponent;
      this.selectedType = LAYOUT_TYPE.ROW;
    }
    if (layout instanceof ImageLayout) {
      this.selectedLayout = ImageLayoutComponent;
      this.selectedType = LAYOUT_TYPE.IMAGE;
    }
    if (layout instanceof HTMLLayout) {
      this.selectedLayout = HtmlLayoutComponent;
      this.selectedType = LAYOUT_TYPE.HTML;
    }
  }
  setDataIntoState() {
    if (
      this.layoutAttribute.cardDataType === PUBLIC_PAGE_DATA_TYPE.PUBLIC_PACKAGE
    ) {
      let packageData: IPublicPackage = {
        id: this.layoutAttribute.referenceID,
        quantity: 0,
        isHaveLinkPackage: this.layoutAttribute.isHaveLinkPackage,
        isNotMemberPackage: this.layoutAttribute.isNotMemberPackage,
        isLinkPackageExpand: false,
        linkPackages: [],
        isBooked: false,
        itemQuantity: 0,
        singleDayPackage: this.layoutAttribute.singleDayPackage,
        hasTicketing: this.layoutAttribute.hasTicketing,
        maximumAttendees: 10,
        clientCategories: this.layoutAttribute.clientCategories,
        durationRange: this.layoutAttribute.durationRange,
        packageSessions: this.layoutAttribute.packageSessions,
        availability: 0
      };
      this.store.dispatch(new SetPackageList([packageData]));
    }
  }

  initializeAttributes() {
    // this.setDataIntoState();
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      const mainContainer = contentElement.closest(".card-layout");
      this.cardContainerTemp = d3
        .select(mainContainer)
        .select(".c-card-body")
        .node();
      if (mainContainer) {
        const componentSelector = mainContainer.parentElement;
        d3.select(componentSelector).attr(
          "class",
          `col-md-${this.cardGridSize} col-lg-${this.cardGridSize} col-xl-${this.cardGridSize} p-0`
        );
        d3.select(mainContainer)
          .select(".c-card-container")
          .style("margin", "5px");
        // .select('.c-card')
        // .style('height', this.layoutAttribute.height + 'px');
      }
    }
  }
}

<!-- <div class="temples-container main-layout-container">
  <div *ngIf="canLoad" [id]="elementID" class="container-wrapper" >
    <div
      *ngIf="sliderImages && sliderImages.length !== 0 else defaultTemplate "
      [id]="'carousel'+ elementID" class="carousel slide carouselDataInterval" data-ride="carousel" >
      <div class="carousel-inner">
        <div class="carousel-item " *ngFor="let i of sliderImages; index as index; first as isFirst" [ngClass]="{'active': index === 0}">
         <img *ngIf="layoutAttribute.componentClass !='video'" class="d-block w-100 slider-image" [src]="i.image.src" alt="First slide">
        <iframe  *ngIf="layoutAttribute.componentClass=='video'" width="100%" [height]="layoutAttribute.height" [src]="videoCode" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen></iframe>
        </div >
      </div>
      <a *ngIf="!layoutAttribute.componentClass" class="carousel-control-prev" [href]="'#carousel'+ elementID"  role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a  *ngIf="!layoutAttribute.componentClass" class="carousel-control-next" [href]="'#carousel'+ elementID" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <ng-template #defaultTemplate>
      <div class="header-default-template">
        <h3>Header</h3>
      </div>
    </ng-template>
  </div>
</div> -->
<div class="temples-container main-layout-container">
  
  <div *ngIf="sliderImages && sliderImages.length !== 0 else defaultTemplate " [id]="'carousel'+ elementID" class="slide">
    <ngu-carousel [inputs]="carouselBanner" [dataSource]="sliderImages">
      <button NguCarouselPrev class='leftRs'*ngIf="sliderImages.length>1">&lt;</button>
      <ngu-item class="cover-image" *nguCarouselDef="let item; index as i; let ani = animate" [@slider]="ani" >
        <div class="bannerStyle">
          <img *ngIf="layoutAttribute.componentClass !='video'" style="object-fit: cover;" class="d-block w-100 slider-image" [src]="item.image.src" [alt]="item.image.src" [height]="layoutAttribute.height" >
          <iframe  *ngIf="layoutAttribute.componentClass=='video'" width="100%" [height]="layoutAttribute.height" [src]="videoCode" frameborder="0" allow="encrypted-media; picture-in-picture" allowfullscreen></iframe>
        </div>
      </ngu-item>
      <button NguCarouselNext class='rightRs' *ngIf="sliderImages.length>1">&gt;</button>
    </ngu-carousel>
  </div>

  <ng-template #defaultTemplate>
    <div class="header-default-template">
      <h3>Header</h3>
    </div>
  </ng-template>
</div>
<div class="mmc-modal-wrapper cancel-booking-modal">
  <div class="mmc_flex--container mmc-modal-header header-policy-terms" style="background: #00507c;height: 45px !important;justify-content: space-between;">
    <div class="mmc_flex__item--vcenter mmc_flex__item--inherit pw-txt-h2 title-policy-terms" style="color:white;font-size:20px;font-weight: bolder;padding-left: 30px;">
    {{type=='terms' ? ('CHECKOUTINFO.Terms & Conditions' | optimotranslate): type=='privacy' ? ('CHECKOUTINFO.Privacy Policy'  | optimotranslate): ''}}
    </div>
    <div class="close-btn policy-terms-close-btn" style="align-items:center;display:flex;padding-right: 32px;justify-content: flex-end;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;"(click)="dialogRef.close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body" style="padding-top:0 !important;">


    <!-- Privacy Policy -->
    <div *ngIf="type=='terms'" class="mmc_flex--container inner privacy-condition" style="overflow: auto;height: 400px;display: flex;flex-direction: column;text-align: justify;" >
      <p> 
        {{'CHECKOUTINFO.POLICY_INFO' | optimotranslate}}
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas commodo sapien vel ullamcorper pellentesque. Sed placerat facilisis felis et placerat. Proin sollicitudin eu justo vitae ullamcorper. In maximus ultricies porttitor. In quis libero eu neque convallis suscipit. Integer sed lacus in dolor sagittis posuere at nec dolor. Vestibulum vitae tincidunt metus. Duis rutrum aliquam tincidunt. Praesent magna purus, scelerisque quis pellentesque eget, ornare vel lectus. Sed porta tincidunt lectus, a fermentum arcu. Duis finibus semper sagittis. Mauris ornare rutrum nisi. Pellentesque eu magna hendrerit, pulvinar felis eu, euismod augue. Sed diam elit, iaculis et interdum fringilla, consectetur sed nisl. Nulla eu neque nisi.
        Aenean euismod libero eget nisl fringilla, varius tincidunt tellus pretium. Nulla orci lacus, euismod sodales nibh et, ornare sollicitudin est. Phasellus auctor magna non enim imperdiet venenatis. Suspendisse vel facilisis mi. Duis quis gravida tortor, et varius mauris. Maecenas vitae venenatis dolor. Pellentesque eget ornare est. Donec porttitor ullamcorper sem, quis lacinia nibh venenatis a.
        Morbi aliquam tortor quam, vitae venenatis orci malesuada tincidunt. Fusce ac mattis lacus, convallis commodo diam. Ut porttitor risus id posuere sodales. Aliquam magna justo, euismod a ante consequat, fringilla aliquet ligula. Mauris gravida sem vitae maximus mollis. Pellentesque in justo interdum, gravida sapien eget, cursus velit. Fusce finibus convallis elementum. Nullam ut eros risus. Curabitur ut metus magna. Phasellus pulvinar nunc fermentum, commodo sem ac, bibendum mi. Morbi non imperdiet erat, sed tincidunt elit. Ut efficitur orci neque, a tempus arcu venenatis sit amet. Nulla non sollicitudin ipsum. Praesent consectetur magna in congue viverra. Curabitur lacinia erat id arcu condimentum, nec vestibulum nulla mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Pellentesque massa erat, euismod ut neque sit amet, hendrerit bibendum dolor. Sed ligula eros, viverra non magna et, tempus laoreet erat. Duis augue sem, blandit quis volutpat non, tempor ut leo. Duis nec elit sit amet risus aliquam lacinia ut ac nulla. Integer elit dolor, vehicula nec mauris quis, maximus aliquam elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque dictum dignissim dolor, eu lobortis dolor viverra eu.
        Integer pharetra velit in nisi venenatis pulvinar. Duis rutrum rutrum neque, id pulvinar libero ultrices sed. Donec massa neque, scelerisque id lectus sit amet, cursus porta elit. Pellentesque dapibus erat non libero commodo, ac sodales leo dapibus. Vivamus arcu ante, consectetur non felis rutrum, lacinia maximus enim. Sed dictum vulputate diam auctor euismod. Quisque a pulvinar dolor. Pellentesque semper tortor augue, sed eleifend erat eleifend et. Morbi auctor dictum leo in sollicitudin. Aenean quis dui ut mi eleifend efficitur. Donec non lacus quis ligula euismod consequat id vitae nunc. Quisque eget aliquet ligula, sed finibus lorem. Integer vel bibendum est, quis luctus orci. Mauris id magna vitae tortor hendrerit posuere. Etiam placerat, ligula ac dapibus scelerisque, ligula dolor ullamcorper lacus, eget sollicitudin ligula eros nec elit. -->
      </p>
    </div>
    <!-- Terms & Conditions -->
    <div *ngIf="type=='privacy'" class="mmc_flex--container inner privacy-condition" style="overflow: auto;height: 400px;display: flex;flex-direction: column;text-align: justify;" >
      <p> 
        {{'CHECKOUTINFO.TERMS_INFO' | optimotranslate}}
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas commodo sapien vel ullamcorper pellentesque. Sed placerat facilisis felis et placerat. Proin sollicitudin eu justo vitae ullamcorper. In maximus ultricies porttitor. In quis libero eu neque convallis suscipit. Integer sed lacus in dolor sagittis posuere at nec dolor. Vestibulum vitae tincidunt metus. Duis rutrum aliquam tincidunt. Praesent magna purus, scelerisque quis pellentesque eget, ornare vel lectus. Sed porta tincidunt lectus, a fermentum arcu. Duis finibus semper sagittis. Mauris ornare rutrum nisi. Pellentesque eu magna hendrerit, pulvinar felis eu, euismod augue. Sed diam elit, iaculis et interdum fringilla, consectetur sed nisl. Nulla eu neque nisi.
        Aenean euismod libero eget nisl fringilla, varius tincidunt tellus pretium. Nulla orci lacus, euismod sodales nibh et, ornare sollicitudin est. Phasellus auctor magna non enim imperdiet venenatis. Suspendisse vel facilisis mi. Duis quis gravida tortor, et varius mauris. Maecenas vitae venenatis dolor. Pellentesque eget ornare est. Donec porttitor ullamcorper sem, quis lacinia nibh venenatis a.
        Morbi aliquam tortor quam, vitae venenatis orci malesuada tincidunt. Fusce ac mattis lacus, convallis commodo diam. Ut porttitor risus id posuere sodales. Aliquam magna justo, euismod a ante consequat, fringilla aliquet ligula. Mauris gravida sem vitae maximus mollis. Pellentesque in justo interdum, gravida sapien eget, cursus velit. Fusce finibus convallis elementum. Nullam ut eros risus. Curabitur ut metus magna. Phasellus pulvinar nunc fermentum, commodo sem ac, bibendum mi. Morbi non imperdiet erat, sed tincidunt elit. Ut efficitur orci neque, a tempus arcu venenatis sit amet. Nulla non sollicitudin ipsum. Praesent consectetur magna in congue viverra. Curabitur lacinia erat id arcu condimentum, nec vestibulum nulla mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Pellentesque massa erat, euismod ut neque sit amet, hendrerit bibendum dolor. Sed ligula eros, viverra non magna et, tempus laoreet erat. Duis augue sem, blandit quis volutpat non, tempor ut leo. Duis nec elit sit amet risus aliquam lacinia ut ac nulla. Integer elit dolor, vehicula nec mauris quis, maximus aliquam elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque dictum dignissim dolor, eu lobortis dolor viverra eu.
        Integer pharetra velit in nisi venenatis pulvinar. Duis rutrum rutrum neque, id pulvinar libero ultrices sed. Donec massa neque, scelerisque id lectus sit amet, cursus porta elit. Pellentesque dapibus erat non libero commodo, ac sodales leo dapibus. Vivamus arcu ante, consectetur non felis rutrum, lacinia maximus enim. Sed dictum vulputate diam auctor euismod. Quisque a pulvinar dolor. Pellentesque semper tortor augue, sed eleifend erat eleifend et. Morbi auctor dictum leo in sollicitudin. Aenean quis dui ut mi eleifend efficitur. Donec non lacus quis ligula euismod consequat id vitae nunc. Quisque eget aliquet ligula, sed finibus lorem. Integer vel bibendum est, quis luctus orci. Mauris id magna vitae tortor hendrerit posuere. Etiam placerat, ligula ac dapibus scelerisque, ligula dolor ullamcorper lacus, eget sollicitudin ligula eros nec elit. -->
      </p>
    </div>
  </div>
</div>

import { BaseResource } from "../base-resource";

export class EntityConfigurationProfile extends BaseResource {
  name: string;
  description: string;
  entityConfigurationDetails: EntityConfigurationDetail[];
}

export class EntityConfigurationDetail extends BaseResource {
  profileId: number | null;
  profileName: string;
  property: string;
  displayName: string;
  mandatory: boolean | null;
  visible: boolean | null;
  readOnly: boolean | null;
  validationMessage: string;
  applicationId: number | null;
  default: boolean | null;
  entityResourceId: number | null;
}

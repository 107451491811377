import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { id } from "date-fns/locale";
import {
  Package,
  PackageDurationRange,
} from "src/modules/models/public-web/Package";
import { BaseComponent } from "src/modules/shared/base.component";
import { Store } from "src/modules/store";
import {
  IDateAndSession,
  IPBReducer,
  IPublicPackage,
} from "../../../../store/public-web/public-web-reducers";
import { Utility } from "src/modules/utility";
import * as _ from "lodash";
import {
  AddPackage,
  SelectPackageSessions,
  SelectSession,
} from "../../../../store/public-web/public-web-actions";
import { MatAccordion } from "@angular/material/expansion";
import { MultiDaySessionComponent } from "../multi-day-session/multi-day-session";
import { PackageDate } from "src/modules/models/public-web/PackageSession";
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
@Component({
  selector: "opt-package-duration-modal",
  templateUrl: "./package-duration-modal.component.html",
})
export class PackageDurationModalComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  publicPackage: Package;
  packageQuantity: number;
  packageDurationRanges: PackageDurationRange[];
  PBReducer$: any;
  selectedSessions: IDateAndSession[];
  packageInterfaceModal: IPublicPackage;
  isExpand: boolean;
  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    public dialogRef: MatDialogRef<PackageDurationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    dialogRef.disableClose = true;
    this.publicPackage = data.publicPackage;
    this.packageQuantity = data.quantity;
    this.packageInterfaceModal = data.packageInterfaceModal;
    this.packageDurationRanges = this.publicPackage.packageDurationRanges;
    this.orderTheDuration();
    this.PBReducer$ = this.store.select(PBSelector.selectedSessions);
  }

  ngOnInit(): void {
    this.PBReducer$.safeSubscribe(this, (d: IDateAndSession[]) => {
      this.selectedSessions = _.cloneDeep(d);
    });
  }
  orderTheDuration() {
    this.packageDurationRanges = this.packageDurationRanges.sort((x, y) =>
      new Date(x.packageDates[0].eventDate.date) >
        new Date(y.packageDates[0].eventDate.date)
        ? 1
        : 0
    );
  }
  getPackageDateDuration(id) {
    const packageDates = this.publicPackage.packageDates.filter(
      (p) => p.packageDurationRange.id === id
    );
    if (packageDates && packageDates.length > 0) {
      return (
        Utility.convertDateToTime(
          new Date(packageDates[0].eventDate.startTime)
        ) +
        "-" +
        Utility.convertDateToTime(new Date(packageDates[0].eventDate.endTime))
      );
    }
  }
  getDurationDates(packageDates: PackageDate[]) {
    let startDate, endDate;
    const sorted = packageDates.sort((x, y) => {
      const sDate = new Date(x.eventDate.date).getTime();
      const eDate = new Date(y.eventDate.date).getTime();
      return sDate - eDate
    });
    if (sorted.length > 0) {
      startDate = sorted[0].eventDate.startTime;
      endDate = sorted[sorted.length - 1].eventDate.endTime;
    }
    return { startDate, endDate };
  }
  selectPackageDuration(packageDurationRange: PackageDurationRange) {
    const selectedPackageSession = this.publicPackage.packageSessions.find(
      (ps) => {
        let isInclude = false;
        if (ps.packageDate) {
          let pd = this.publicPackage.packageDates
            .sort((x, y) => (x.eventDate.date > y.eventDate.date ? 1 : 0))
            .find(
              (pd) =>
                pd.id == ps.packageDate.id &&
                pd.packageDurationRange &&
                pd.packageDurationRange.id == packageDurationRange.id
            );
          if (pd) {
            isInclude = true;
          }
        }
        return isInclude;
      }
    );
    //#region Session create and update
    const { startDate, endDate } = this.getDurationDates(
      this.publicPackage.packageDates.filter(
        (x) => x.packageDurationRange.id === packageDurationRange.id
      )
    );
    if (selectedPackageSession && startDate && endDate) {
      const sessionIndex = this.selectedSessions.findIndex(
        (s) =>
          s.id === selectedPackageSession.id &&
          s.durationRange &&
          s.durationRange.id === packageDurationRange.id.toString()
      );
      let session: IDateAndSession;
      if (sessionIndex !== -1) {
        session = this.selectedSessions[sessionIndex];
      } else {
        session = {
          packages: [],
          id: selectedPackageSession.id.toString(),
          startTime: startDate,
          endTime: endDate,
          durationRange: packageDurationRange,
          availability: selectedPackageSession.availability,
        };
      }
      const pk = _.cloneDeep(this.packageInterfaceModal);
      const pkIndex = session.packages.findIndex((p) => p.id === pk.id);
      if (pkIndex !== -1) {
        session.packages[pkIndex] = pk;
        if (session.packages[pkIndex].quantity > 0) {
          session.packages[pkIndex].isBooked = false;
        } else {
          session.packages[pkIndex].isBooked = true;
        }
      } else {
        if (pk.quantity > 0) {
          pk.isBooked = false;
        } else {
          pk.isBooked = true;
        }
        session.packages.push(_.cloneDeep(pk));
      }

      if (sessionIndex !== -1) {
        this.selectedSessions[sessionIndex] = session;
      } else {
        this.selectedSessions.push(session);
      }
      const filteredSession = [];
      this.selectedSessions.forEach((s) => {
        let canAdd = false;
        for (const p of s.packages) {
          if (p.isHaveLinkPackage && p.linkPackages.length > 0) {
            for (const lp of p.linkPackages) {
              if (lp.quantity > 0) {
                canAdd = true;
                lp.isBooked = false;
                p.isBooked = false;
              } else {
                lp.isBooked = true;
              }
            }
          } else if (p.quantity > 0) {
            p.isBooked = false;
            canAdd = true;
            break;
          } else {
            if (p.addOns && p.addOns.length > 0) {
              for (const a of p.addOns) {
                if (a.qty > 0) {
                  canAdd = true;
                  break;
                }
              }
            }
          }
        }
        if (canAdd) {
          filteredSession.push(s);
        }
      });
      // const session: IDateAndSession = {
      //   id:e.id.toString(),
      //   startTime:e.session.startTime,
      //   endTime:e.session.endTime,
      //   availability: 0
      // }
      this.store.dispatch(new SelectSession(session));
      this.store.dispatch(new SelectPackageSessions(filteredSession));
      // this.store.dispatch(new AddPageConfiguration([PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION]));
      this.store.dispatch(new AddPackage(this.publicPackage.id));
      this.dialogRef.close();
    } else {
      console.error(
        "session Undefine",
        packageDurationRange,
        this.publicPackage
      );
    }
    //#endregion
  }
  openDateAndSessionModal(packageDurationRange) {
    const selectedPackageSession = this.publicPackage.packageSessions.filter(
      (ps) => {
        let isInclude = false;
        if (ps.packageDate) {
          let pd = this.publicPackage.packageDates.find(
            (pd) =>
              pd.id == ps.packageDate.id &&
              pd.packageDurationRange &&
              pd.packageDurationRange.id == packageDurationRange.id
          );
          if (pd) {
            isInclude = true;
          }
        }
        return isInclude;
      }
    );
    this.dialog.open(MultiDaySessionComponent, {
      data: {
        publicPackageSessions: selectedPackageSession,
        publicPackage: this.publicPackage,
        packageDuration: packageDurationRange,
        isMultiDaySession: true,
      },
      panelClass: ["custom-dialog-container", "modal-width", "extrapop", "multiday-session"],
      width: "80%",
      minWidth: "60%",
      height: "fit-content",
      minHeight: "400px",
      maxHeight: "95%",
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { SocialMediaLayout } from "../../models/socialMediaLayout.model";
import { Theme } from '../../models/globalTheme';
import { ContactLayout } from '../../models/contactLayout.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-contact-layout',
  templateUrl: './contact-layout.component.html',
  styleUrls: ['./contact-layout.component.scss']
})
export class ContactLayoutComponent implements OnInit {
  @Input() layoutAttribute: ContactLayout;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID = 'demo';
  @Input() isView: boolean;

  parentRef: any;
  theme: Theme;

  constructor() { }

  ngOnInit() {
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Store } from 'src/modules/store';

@Component({
  selector: 'opt-link-package-list',
  templateUrl: './link-package-list.component.html'
})
export class LinkPackageListComponent extends BaseComponent implements OnInit {

  private mPackageID;
  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit() {
    if(this.data) {
      this.mPackageID = this.data.packageId
    }
  }

  close(){

  }

}

import { LayoutObject } from './layoutObject.model';

export class DescriptionLayout extends LayoutObject {
  height: number;
  content: string;

  constructor(data) {
    super(data);

    this.height = data.height ? data.height : null;
    this.content = data.content;
  }
}

<div class="mmc-modal-wrapper package-duration-modal">
  <div class="mmc-modal-header mmc_flex--container">
    <div class="mmc_flex__item mmc_flex__item--vcenter pl-3">
      <span class="section-title ">Select Dates</span>
    </div>
  </div>
  <div class="mmc-modal-body p-3">

    <mat-expansion-panel [expanded]="isExpand" *ngFor="let packageDurationRange of packageDurationRanges">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="pw-txt-s2 pw-txt-bold" bis_skin_checked="1">
            {{packageDurationRange.label}}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="card-body">

        <div class="row">
          <div class="col-6 col-md-6 col-sm-12 col-lg-6 text-left">
            <h6 class="card-subtitle mb-2 text-muted">{{publicPackage.timelineDescription}}</h6>
          </div>
          <div class="col-6 col-md-6 col-sm-12 col-lg-6 text-right card-link pr-2">
            <h6 class="card-subtitle mb-2 viewDate" (click)="openDateAndSessionModal(packageDurationRange)">View Dates
            </h6>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-left">
            <p class="card-text"> {{getPackageDateDuration(packageDurationRange.id)}}</p>
          </div>
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-right pr-0">
            <!-- <p class="card-text pr-2" >{{packageDurationRange.available}} Available</p> -->
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-left sessionCount">
            <p class="card-text">{{packageDurationRange.sessions}} Sessions - {{packageDurationRange.available}}
              Available</p>
          </div>
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-right selectBtn">
            <button class="btn btn-primary " (click)='selectPackageDuration(packageDurationRange)'>Select</button>
          </div>
        </div>
      </div>

    </mat-expansion-panel>
  </div>
  <div class="mmc-modal-footer p-3" style="padding:10px 15px 10px 15px !important">
    <div class="button-row">
      <div class="row m-0">
        <div class="col-sm-12">
          <div class="mmc_flex--container mmc_just-content-end ">
            <div class="mmc_flex__item--inherit">
              <button mat-button class="mmc-btn-primary btn btn-primary primaryBtn mr-2" (click)="dialogRef.close()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

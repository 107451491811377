import { BaseResource } from "../base-resource"

 export class ErrorLog extends BaseResource{
    public  reference : string;
    public  message :string;
    public  stackTrace :string;
    public  occurredAt?:Date;
    public  loggedInUser :string;
    public  token :string;
    public  machineName :string;
    public  source :string;
    public  errorCode :string;
    public  url :string;
    public  remoteAddress :string;
    public  application :string;
    public  salesChannelId?:number;
    public  errorType?:ErrorType;
    public  note :string;
}
export enum ErrorType
{
    Validation = 1,
    Runtime = 2
}
import { FONT_CASE } from './enums';

export class TextStyle {
    fontFamily?: string;
    fontSize?: number;
    fontCase?: FONT_CASE;
    fontColor?: string;
    isBold?: boolean;
    isItalic?: boolean;
    backgroundColour?: string;

    constructor(data: TextStyle = {} as TextStyle) {
        const {
            fontFamily = '',
            fontSize = 16,
            fontCase = FONT_CASE.NORMAL,
            fontColor = 'black',
            isBold = true,
            isItalic = false,
            backgroundColour = '#f2ea06'
        } = data;

        this.fontFamily = fontFamily;
        this.fontSize = fontSize;
        this.fontCase = fontCase;
        this.fontColor = fontColor;
        this.isBold = isBold;
        this.isItalic = isItalic;
        this.backgroundColour = backgroundColour;
    }
}

export class Global {
    baseColor: string;
    backgroundColor: string;
    buttonColor: string;
    margin: number;

    constructor() {
        this.baseColor = 'yellow';
        this.backgroundColor = 'lightgrey';
        this.buttonColor = 'yellow';
        this.margin = 10;
    }
}

export class Typography {
    heading: TextStyle;
    description: TextStyle;
    staticText: TextStyle;
    name: TextStyle;

    constructor() {
        this.heading = new TextStyle();
        this.description = new TextStyle();
        this.staticText = new TextStyle();
        this.name = new TextStyle();
    }
}

export class Card {
    buttonColor: string;
    margin: number;
    heading: TextStyle;
    description: TextStyle;
    staticText: TextStyle;

    constructor() {
        this.buttonColor = '#f2ea06';
        this.margin = 10;
        this.heading = new TextStyle();
        this.description = new TextStyle({ backgroundColour: '#F7F7F7', fontSize: 14, isBold: false });
        this.staticText = new TextStyle();
    }
}

export class Theme {
    global: Global;
    typography: Typography;
    card: Card;
    constructor() {
        this.global = new Global();
        this.typography = new Typography();
        this.card = new Card();
    }
}


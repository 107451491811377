import {  Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Package } from 'src/modules/models/booking/package';
import { BOOKING_CART, PAGE_TYPE, FILE_TYPE } from 'src/modules/models/public-web/enum';
import { PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'opt-seat-input',
  templateUrl: './seat-input.component.html',
  styleUrls: ['./seat-input.component.scss']
})
export class SeatInputComponent implements OnInit {
  @ViewChild('tooltip') tooltip: MatTooltip;

  totalSeats: number = 0;
  value;
  freezedValue;
  BOOKING_CART = BOOKING_CART;
  editBookingButtonConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).editBookingButtonConfiguration;

  @Input("value") set setValue(v) {
    if (v) {
      if (!this.value && !this.freezedValue)
        this.freezedValue = v;
    }
    this.value = v;
  };
  @Input() height: string = '100%'
  @Input() cartType: number = 1;
  @Input() isCart: boolean = false;
  @Input() fontSize: string = '16px';
  @Input() styleClass: any;
  @Output() valueChange = new EventEmitter();
  @Output() increase = new EventEmitter();
  @Output() decrease = new EventEmitter();
  @Input() canValueChange = true;
  @Input() disableReduceBtnD = false;
  @Input() disableIncreaseBtnD = false;
  @Input() reduceButtonTooltip = "";
  @Input() package: Package;
  @Input() disabled = false;
  @Input() disableMinus = true;
  @Input() disablePlus = false;
  @Input() min = 0;
  @Input() max = 0;  constructor() { }

  ngOnInit(): void {
  }

  onDecrease() {
    if (this.value > this.min) {
      if (!(this.disableReduceBtnD && this.freezedValue == this.value) && this.canValueChange) {
        this.value--;
      } else if(this.disableReduceBtnD && this.freezedValue == this.value){
        this.tooltip.show()
      }
      this.valueChange.emit(this.value)
      this.decrease.emit(this.value)
    }
  }
  inputChange(event) {
    this.value = event.target.value;
    this.valueChange.emit(this.value)
  }
  onValueChange() {
    this.valueChange.emit(this.value)
  }
  get disableTooltip() {
    return !this.disableReduceBtnD || (this.freezedValue != this.value)
  }
  get reduceBtnStyle() {
    return (this.disableReduceBtnD && this.freezedValue == this.value) || (this.disableMinus && this.value <= this.min)
  }
  get increaseBtnStyle() {
    return (this.value==9999)
  }
  onIncrease() {
    if (this.canValueChange) {
        this.value++;

    }
    this.valueChange.emit(this.value)
    this.increase.emit(this.value)
  }
  onKeyup(event){
    if(this.value==0 || parseInt(this.value) < this.freezedValue){
      this.value = this.freezedValue;
      this.valueChange.emit(this.value)
    }
  }

  validateInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "0");
  }

}

import { Timeslot } from "../../booking/timeslot";
import { EventSeatingConfiguration } from "./eventSeatingConfiguration";

export class PublicEventFacilityTimeslot extends Timeslot {
  eventConfiguration: EventSeatingConfiguration;
  constructor() {
    super();
    this.type = 'PublicEventFacilityTimeslot'
  }
}

import { CalendarEvent } from "calendar-utils";
import { addDays, addHours, endOfMonth, startOfDay, subDays } from "date-fns";
import { CalendarEventAction } from "src/modules/public-web/Calender-module";
import { LayoutObject } from "./layoutObject.model";

export interface EventListByDate {
  id?: string | number;
  date: Date;
  startTime: string;
  endTime: string;
  calendarEvents: any [];
}
export class CalenderLayout extends LayoutObject {
  calenderEvents: CalendarEvent[] = [];
  calenderActions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: "Edit",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //   this.handleEvent("Edited", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: "Delete",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //   this.events = this.events.filter((iEvent) => iEvent !== event);
        //   this.handleEvent("Deleted", event);
      },
    },
  ];
  eventsByDate: EventListByDate[] = [];
  eventName: string;
  constructor(d) {
    super(d);
    this.eventName = "Cityplace Public Event";
    this.calenderEvents.push(...d.calenderEvents.map(event => {
      event.start = addHours(startOfDay(new Date(event.start)), 2);
      event.end = addHours(startOfDay(new Date(event.end)), 2);
      event.actions = this.calenderActions;
      event.color = event.eventColor;
      event.resizable = event.eventresizable;
      return event;
    }));
    this.calenderEvents.forEach(cEvent => {
      let d = new Date(cEvent.start);
      let index = this.eventsByDate.findIndex((e) => e.date.getTime()  === d.getTime() );
      if (index != -1) {
        this.eventsByDate[index].calendarEvents.push(cEvent);
      } else {
        const event: EventListByDate = {
          date: d,
          calendarEvents: [cEvent],
          startTime: null,
          endTime: null,
        };
        this.eventsByDate.push(event);
      }
    });
    const sortedActivities = this.eventsByDate.sort((a, b) => a.date.getTime() - b.date.getTime())
    

  }
  private MapCalenderObject(calenderEvents: any[]) {
    calenderEvents.forEach((event) => {
      event.start = addHours(startOfDay(new Date(event.start)), 2);
      event.end = addHours(startOfDay(new Date(event.end)), 2);
      event.actions = this.calenderActions;
      event.color = event.eventColor;
      event.resizable = event.eventresizable;
      this.getDates(event.start, event.end, event);
    });
    return calenderEvents;
  }
  getDates(DateOne, DateTwo, calendarEvent: CalendarEvent) {
    var oneDay = 24 * 3600 * 1000;
    for (
      var d = [], ms = DateOne * 1, last = DateTwo * 1;
      ms < last;
      ms += oneDay
    ) {
      let d = new Date(ms);
      let index = this.eventsByDate.findIndex((e) => e.date === d);
      if (index != -1) {
        this.eventsByDate[index].calendarEvents.push(calendarEvent);
      } else {
        const event: EventListByDate = {
          date: d,
          calendarEvents: [calendarEvent],
          startTime: null,
          endTime: null,
        };
        this.eventsByDate.push(event);
      }
    }
  }
}
// {
//     start: subDays(startOfDay(new Date('1.1.2021')), 1),
//     end: addDays(new Date('4.4.2021'), 1),
//     title: "A 3 day event",
//     color: colors.red,
//     actions: this.calenderActions,
//     allDay: true,
//     resizable: {
//       beforeStart: true,
//       afterEnd: true,
//     },
//     draggable: true,
//   },
//   {
//     start: subDays(startOfDay(new Date('1.1.2021')), 1),
//     end: addDays(new Date('4.4.2021'), 1),
//     title: "A 3 day event",
//     color: colors.red,
//     actions: this.calenderActions,
//     allDay: true,
//     resizable: {
//       beforeStart: true,
//       afterEnd: true,
//     },
//     draggable: true,
//   },
//   {
//     start: startOfDay(new Date('1.1.2021')),
//     title: "An event with no end date",
//     color: colors.yellow,
//     actions: this.calenderActions,
//   },
//   {
//     start: subDays(endOfMonth(new Date('1.1.2021')), 3),
//     end: addDays(endOfMonth(new Date('4.5.2021')), 3),
//     title: "A long event that spans 2 months",
//     color: colors.blue,
//     allDay: true,
//   },
//   {
//     start: addHours(startOfDay(new Date()), 2),
//     end: addHours(new Date('1.2.2021'), 2),
//     title: "A draggable and resizable event",
//     color: colors.yellow,
//     actions: this.calenderActions,
//     resizable: {
//       beforeStart: true,
//       afterEnd: true,
//     },
//     draggable: true,
//   },

import { HEIGHT_SIZE, LAYOUT_TYPE } from './enums';
import { FooterLocationLayout } from './footerLocationLayout.model';
import { SocialMediaLayout } from './socialMediaLayout.model';
import { LogoLayout } from './logoLayout.model';
import { ContactLayout } from './contactLayout.model';
import { LocalImage } from './image.model';
import { LayoutObject } from './layoutObject.model';
import { SliderImage } from './sliderImage.model';

export class FooterLayout extends LayoutObject {
  height: any;
  bg_image: LocalImage;
  isFitImage: boolean;
  imageHieght?: any;
  imageWidth?: any;
  sizeType: HEIGHT_SIZE;
  footerTemplateLayouts: LayoutObject[] = [];

  constructor(data) {
    super(data);

    this.height = data.height;
    this.isFitImage = data.FitImage;
    this.bg_image = new LocalImage(data.image);
    this.sizeType = data.sizeType;
    this.imageWidth = data.image.width;
    this.imageHieght = data.image.height;
    this.footerTemplateLayouts = data.childLayout.map(c => this.mapLayout(c));
  }

  mapLayout(layout) {
    switch (layout.type) {
      case LAYOUT_TYPE.LOCATION:
        return new FooterLocationLayout(layout);
      case LAYOUT_TYPE.SOCIAL_MEDIA:
        return new SocialMediaLayout(layout);
      case LAYOUT_TYPE.LOGO:
        return new LogoLayout(layout);
      case LAYOUT_TYPE.CONTACT:
        return new ContactLayout(layout);
      default:
        break;
    }
  }

}


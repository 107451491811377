import { HEIGHT_SIZE } from './enums';
import { LayoutObject } from './layoutObject.model';

export class HTMLLayout extends LayoutObject {
  height: number;
  content: any;
  sizeType: HEIGHT_SIZE;
  gridColumn: number;
  isEmptyColumn: boolean;

  constructor(data) {
    super(data);
    this.height = data.height ? data.height : null;
    this.sizeType = data.sizeType;
    this.gridColumn = data.gridColumn;
    this.content=this.generateHtml(data.content);
    }

  generateHtml(element){
    if(element) {
      let ele = element.toString();
      let content = ele.replace(/\\"/g, '"');
      let html = content.replace(/\\n/g, '');
      return html.replaceAll ("\\\\/", '').toString();
    }
    return "";
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { Package } from 'src/modules/models/public-web/Package';
import { WarningMessageComponent } from "src/modules/public-web/booking-application/warning-message/warning-message.component";

@Component({
  selector: 'opt-button-number-input',
  templateUrl: './button-number-input.component.html',
  styleUrls: ['./button-number-input.component.scss']
})
export class ButtonNumberInputComponent {
  @Output() valueChange = new EventEmitter();
  @Input() isLinkedPack: boolean
  @Input() linkPackages: any
  @Input() publicPackage: any;
  seatTypeModal: boolean = false
  totalSeats: number = 0;
  adultCount: number = 0;
  constructor( public snackBar: MatSnackBar) { }

  get selectedSeatText() {
    let textArr = [];
    let text = "";

    if (this.isLinkedPack) {
      this.linkPackages.forEach(pack => {
        if (pack.quantity > 0) {
          textArr.push(`${pack.quantity} ${pack.linkedPackageType?.name}`)
        }
      })
      text = textArr.join(" · ")
    }

    return text;
  }

  onvalueChanges(e) {
    this.valueChange.emit(e)
  }

  increaseSeat(pack?: any) {
    if(this.publicPackage.maximumAttendees !== 0 && this.totalSeats >= this.publicPackage.maximumAttendees){
      return this.openSnackBarError([
        "Maximum allocation is " +
        this.publicPackage.maximumAttendees,
      ]);
    }
    if (this.isLinkedPack) {
      if (pack) {
        pack.quantity++
      } else {
        this.linkPackages[0].quantity++
        this.adultCount = this.linkPackages[0].quantity
      }
    }
    if (!this.validated(pack)) {
      this.adultCount++
      this.totalSeats++
      this.valueChange.emit(this.totalSeats)
    }
  }

  decreaseSeat(pack?: any) {
    if (this.totalSeats > 0) {
      if (this.isLinkedPack) {
        if (pack) {
          if (pack.quantity > 0) {
            pack.quantity--
          }
        } else {
          const reverseLinkedPack = this.linkPackages.reverse()
          for (let pack of reverseLinkedPack) {
            if (pack.quantity > 0) {
              pack.quantity--
              break;
            }
          }
          this.linkPackages = reverseLinkedPack.reverse()
          this.adultCount = this.linkPackages[0].quantity
        }
      }
      if (!this.validated(pack)) {
        this.totalSeats--
        this.valueChange.emit(this.totalSeats)
      }
    }

    if(this.adultCount === 0){
      this.totalSeats = 0;
      this.valueChange.emit(0);
      this.linkPackages.forEach(pack => {
        pack.quantity = 0
      })
    }
  }

  validated(pk: Package) {
    if(!pk) return
    const adultPackage = _.cloneDeep(
      this.publicPackage.linkPackages.find(
        (x) => x.linkedPackageType.id === "1"
      )
    );

    if (pk.linkedPackageType.id === "1") {
      if (pk.quantity == null) {
        pk.quantity = 0;
        //this.store.dispatch(new UpdatePackage(this.publicPackage));
      }
      this.adultCount = pk.quantity;
    }
    let maxChildCount = 0
    if (!!adultPackage) {
      // if (
      //   pk.linkedPackageType.id === "2" &&
      //   pk.linkedPackageType.maximumNumberOfChildren !== 0 &&
      //   pk.linkedPackageType.adultRequired
      // )
      if (pk.linkedPackageType.adultRequired
      ) {
        if ((pk.maxChildrenPerAdult == null || pk.maxChildrenPerAdult == undefined) && pk.linkedPackageType.maximumNumberOfChildren !== 0 && pk.linkedPackageType.adultRequired) {
          maxChildCount = pk.linkedPackageType.maximumNumberOfChildren;
        }
        else if (pk.maxChildrenPerAdult !== 0 && (pk.maxChildrenPerAdult != null || pk.maxChildrenPerAdult != undefined)) {
          maxChildCount = pk.maxChildrenPerAdult * adultPackage.quantity;
        }
        if (this.adultCount > 0) {
          if (pk.quantity == null) {
            pk.quantity = 0;
            //  this.store.dispatch(new UpdatePackage(this.publicPackage));
          }
          if ((pk.quantity > maxChildCount) && maxChildCount !== 0) {
            pk.quantity = maxChildCount;
            // this.store.dispatch(new UpdatePackage(this.publicPackage));
            this.openSnackBarError([
              "Child Package count should be less than or equal " +
              maxChildCount,
            ]);
            return true;
          }
        } else {
          if (pk.quantity == null || pk.quantity > 0) {
            pk.quantity = 0;
            // this.store.dispatch(new UpdatePackage(this.publicPackage));
            this.openSnackBarError(["Adult package should be select first"]);
            return true;
          }
        }
      }
    }
    return false;
  }

  inputChange(event, pack?: any) {
    const value = parseInt(event.target.value)
    if (value < 0 || Number.isNaN(value)) return

    if (this.isLinkedPack) {
      if (pack) {
        const prevQty = parseInt(pack.quantity)
        pack.quantity = value
        if (!this.validated(pack)) {
          this.totalSeats = this.totalSeats - prevQty
          this.totalSeats = this.totalSeats + value
          this.adultCount = this.linkPackages[0].quantity
        }
      } else {
        this.linkPackages[0].quantity = value
      }
    } else {
      this.totalSeats = value
    }
    if(this.adultCount === 0){
      this.totalSeats = 0;
      this.linkPackages.forEach(pack => {
        pack.quantity = 0
      })
    }
    if(this.publicPackage.maximumAttendees !== 0 && this.totalSeats > this.publicPackage.maximumAttendees){
      this.totalSeats = 0
      this.linkPackages.forEach(pack => {
        pack.quantity = 0
      })
      return this.openSnackBarError([
        "Maximum allocation is " +
        this.publicPackage.maximumAttendees,
      ]);
    }
    this.valueChange.emit(this.totalSeats)
  }

  validateInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, "0");
  }

  toggleModal() {
    this.seatTypeModal = !this.seatTypeModal
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent( WarningMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }

}

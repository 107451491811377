import { Component, Input, OnInit } from '@angular/core';
import { SocialMediaLayout } from "../../models/socialMediaLayout.model";
import { Theme } from "../../models/globalTheme";
import { LogoLayout } from "../../models/logoLayout.model";
import { HEIGHT_SIZE } from "../../models/enums";

@Component({
  selector: 'opt-logo-layout',
  templateUrl: './logo-layout.component.html',
  styleUrls: ['./logo-layout.component.scss']
})
export class LogoLayoutComponent implements OnInit {
  @Input() layoutAttribute: LogoLayout;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID = 'demo';
  @Input() isView: boolean;

  parentRef: any;
  theme: Theme;

  constructor() { }

  ngOnInit() {
  }

  get HEIGHT_SIZE() {
    return HEIGHT_SIZE;
  }
}

import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Booking, DeliveryMethod } from "src/modules/models/booking/booking";
import {
  Address,
  Client,
  CommunicationMethod,
} from "src/modules/models/client/client";
import { Contact } from "src/modules/models/client/contact";
import { Invoice } from "src/modules/models/payment/Invoice";
import { Country } from "src/modules/models/public-web/customer/country";
import { EntityConfigurationProfile } from "src/modules/models/public-web/EntityConfigurationProfile";
import {
  PAGE_TYPE,
  PAYMENT_METHOD,
  DELIVERY_METHOD,
  SESSION_CONFIRMATION_M_TYPE,
  BOOKING_CART,
} from "src/modules/models/public-web/enum";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { PaymentConfiguration } from "src/modules/models/settings/payment-portal/payment-configuration";
import {
  DeliveryMethodSetting,
  Field,
  PublicBookingSettings,
} from "src/modules/models/settings/public-booking/public-booking-setting";
import { MyErrorStateMatcher } from "src/modules/payment-portal/payment/payment.component";
import { BookingService } from "src/modules/services/booking.service";
import { CustomerService } from "src/modules/services/customer.service";
import { PackageService } from "src/modules/services/package.service";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { SystemService } from "src/modules/services/system.service";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { BaseComponent } from "src/modules/shared/base.component";
import { ICart, Redirect, SignOut } from "src/modules/store";
import {
  AddBooking,
  AddBookingSession,
  AddCurrentPageType,
  AddPaymentSession,
  CheckBookingHasClient,
  PauseBookingSession,
  PausePaymentSession,
  ResetPackages,
  ResetState,
  ResumeBookingSession,
  ResumePaymentSession,
  StartPaymentSession,
} from "../../../../store/public-web/public-web-actions";
import {
  IPackageDeliveryMethod,
  IPBReducer,
  IPublicPackage,
  IPublicWebSession,
} from "../../../../store/public-web/public-web-reducers";

import { Utility } from "src/modules/utility";
import { ConfirmationComponent } from "../../modals/confirmation/confirmation.component";
import {
  DeliveryMethodEditModalComponent,
  DeliveryMethodModelType,
} from "../../modals/delivery-method-edit-modal/delivery-method-edit-modal.component";
import * as _ from "lodash";
import { PrivacyTermsModalComponent } from "../../modals/privacy-terms/privacy-terms.component";
import { PBUtility } from "src/modules/public-web/pb-utility";
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { AppSetting } from "src/modules/models/settings/casual-portal/app-setting";
import { BookingContact } from "src/modules/models/booking/booking-contact";
import { PublicBookingSelectService } from "src/modules/services/public-web/public-booking-select.service";


@Component({
  selector: "opt-checkout",
  templateUrl: "./checkout.component.html",
})
@HostListener("window:resize", ["$event"])
export class CheckoutComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  private pageType = PAGE_TYPE.CHECKOUT;
  selectedPaymentMethod: PAYMENT_METHOD;
  publicWebSession$: Observable<IPublicWebSession>;
  paymentFormBuilder: FormBuilder;
  paymentSession: SystemOption;
  matcher = new MyErrorStateMatcher();
  IsValidInvoiceRef: boolean;
  OutStandingAmount: number;
  OutStandingAmountMax: number = 0;
  ValidateBookingId: string;
  IsValidInvoice: boolean;
  invoice: Invoice;
  paymentConfiguration: PaymentConfiguration;
  currency: string;
  bookingReference: string;
  IsDisable: boolean;
  allowToDoManualInvoiceSearch: boolean;
  bookingId: string;
  billerCode: string = "";
  showLoader: boolean = true;
  booking: Booking;
  bookingPackages: any[] = [];
  orderTotalPrice: string;
  cart$: Observable<ICart>;
  contact: Contact;
  isShipmentConfirm: boolean = null;
  skipDeliveryMethod: boolean;
  paymentSessionKey;
  bookingSession: SystemOption;
  PBReducer$: Observable<any>;
  packageDeliveryMethods: IPackageDeliveryMethod[];
  deliveryMethods: DeliveryMethod[];
  selectedDeliveryMethod: DeliveryMethod;
  deliveryMethodSetting: DeliveryMethodSetting;
  entityConfigurationProfile: EntityConfigurationProfile;
  shipDifferentAddress: boolean;
  defaultDeliveryAddress: Address;
  defaultDeliveryCommunicationMethods: CommunicationMethod[];
  countries: Country[];
  defaultCountry: Country;
  canEditDeliveryAddress = false;
  isPPandTC: boolean = false;
  bookingMode: BOOKING_CART;
  publicCarts: IPublicPackage[];
  publicCart$: Observable<IPublicPackage[]>;
  booking$: Observable<Booking[]>;
  PBReducer: IPBReducer;
  cart;
  patchPreviewId: string;
  TaxLabelToDisplayName: string = '';
  appSetting: AppSetting;
  isLoggedIn: boolean = false;
  checkoutPageConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).checkoutPageConfiguration;

  constructor(
    private store: Store<any>,
    private router: Router,
    public snackBar: MatSnackBar,
    private _packageService: PackageService,
    private _bookingService: BookingService,
    private _publicBookingService: PublicBookingService,
    private _systemService: SystemService,
    private _customerService: CustomerService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private sysOptions: SystemService,
    private _publicBookingSelectService: PublicBookingSelectService
  ) {
    super();
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    this.PBReducer$ = this.store.select("PBReducer");
    this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageCarts);
    this.cart$ = this.store.select("cart");
    this.paymentSession = new SystemOption();
    this.store.dispatch(new AddCurrentPageType(this.pageType));
    this.IsValidInvoiceRef = false;
    this.bookingReference = window["InvoiceRef"];
    this.OutStandingAmount = 0;
    this.ValidateBookingId = "";
    this.IsValidInvoice = false;
    this.invoice = new Invoice();
    this.paymentConfiguration = new PaymentConfiguration();
    this.currency = environment.Currency;
    this.skipDeliveryMethod = false;
    this.shipDifferentAddress = false;
    this.selectedDeliveryMethod = undefined;
    this.appSetting = environment.AppSetting as AppSetting;

    this.cart$.safeSubscribe(this, (state) => {
      this.contact = state.contact;
      this.cart = state;
      this.isLoggedIn = state.contact != null && !!state.contact.firstName && state.contact.firstName !== null && state.contact.firstName !== ''
        ? true
        : false;
    });
    // this.publicWebSession$.safeSubscribe(this, (config) => {
    //   this.paymentSession = config.paymentSession;
    //   this.bookingSession = config.bookingSession;
    //   if (
    //     this.paymentSession &&
    //     !this.paymentSession.isSessionTimerStart &&
    //     !this.paymentSessionKey && this.paymentSession.sessionEndTime == 0
    //   ) {
    //     this.store.dispatch(new StartPaymentSession(new Date()))
    //   }
    // });
  }
  //#region getters
  get PAYMENT_METHOD() {
    return PAYMENT_METHOD;
  }
  get DELIVERY_METHOD() {
    return DELIVERY_METHOD;
  }
  get formFields(): Field[] {
    if (this.selectedDeliveryMethod.email) {
      return this.deliveryMethodSetting.emailFields.field;
    }
    if (this.selectedDeliveryMethod.postal) {
      return this.deliveryMethodSetting.postalFields.field;
    }
    return [];
  }
  get disableDeliveryMethodDisable() {
    let valid = true;
    if (this.selectedDeliveryMethod) {
      if (this.selectedDeliveryMethod.email) {
        if (this.booking && this.booking.deliveryCommunicationMethods) {
          const dMail = this.booking.deliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6 || +x.communicationTypeID == 5
          );
          if (dMail && dMail.value && dMail.value.length > 0) {
            valid = false;
          }
        }
      } else if (this.selectedDeliveryMethod.postal) {
        if (
          this.booking &&
          !!this.booking.deliveryAddress &&
          !!this.booking.deliveryAddress.address1 &&
          !!this.booking.deliveryAddress.countryID
        ) {
          valid = false;
        }
      } else {
        valid = false;
      }
    }
    return valid;
  }
  //#endregion

  ngOnInit() {
    this.publicWebSession$.subscribe((config) => {
      if (config.bookingSession && config.paymentSession) {
        this.bookingSession = config.bookingSession;
        this.paymentSession = config.paymentSession;
        //get url of current page
        if (this.router.url.includes('booking-checkout')) {
          if (this.bookingSession.timerPaused && this.bookingSession.sessionEndDate && !this.paymentSession.timerPaused && this.paymentSession.isSessionTimerStart) {
            this.store.dispatch(new ResumeBookingSession());
            this.store.dispatch(new PausePaymentSession());
            this.paymentSession.minutes = "0";
            this.paymentSession.seconds = "0";
            this.paymentSession.isSessionExpired = true;
            this.paymentSession.sessionExpended = false;
            this.paymentSession.isSessionTimerStart = false;
            this.paymentSession.sessionKey = undefined;
            this.paymentSession.timerPauseTime = null;
            this.store.dispatch(new AddPaymentSession(this.paymentSession)); 

          }
        }
      }
    });
    this.store.select(PBSelector.selectTaxLabelName).safeSubscribe(this, (taxLabel) => {
      if(taxLabel) {
        this.TaxLabelToDisplayName = taxLabel;
      }
    });
    this.onResize(event);
    this.store.dispatch(new CheckBookingHasClient());
    this.allowToDoManualInvoiceSearch =
      environment.AllowToDoManualInvoiceSearch;
    this.deliveryMethodSetting = (
      environment.PublicBookingSetting as PublicBookingSettings
    ).deliveryMethodSetting;
    this.getStateData();
    if(this.booking == undefined || this.booking == null ){
      if (this.appSetting.AnyPortalSignOutRedirectToSignIn) {
        var ul = environment.ApiUrl + "#/client/login";
        window.open(ul, "_self");
      }
      else {
        if(environment.HomeBaseUrl.trim()) {
          window.location.href = environment.HomeBaseUrl;;
        } else {
          this.router.navigate(["/event-category"]);
        }
      }
    }
    this.IsDisable = environment.IsDisableInputsInPayment;
    this.getDeliveryMethods();
    // if no paypal => choose credit card automatically
    if(!this.checkoutPageConfiguration.paypalPaymentMethod?.visible) {
      this.selectedPaymentMethod = PAYMENT_METHOD.CREDIT_DEBIT
    }
  }
  ngAfterViewInit(): void {
    this.store.select(PBSelector.selectPatchPreviewId).safeSubscribe(this, (d) => {
      this.patchPreviewId = d;
    });

    setTimeout(() => {
      this.route.queryParams.safeSubscribe(this, (params) => {
        this.showLoader = false;
        if(params["reDirect"] && +params["reDirect"] == 1) {
          this.isShipmentConfirm = true;
        }
      });
    });
  }

  checkoutBackBtn() {
    if(!this.skipDeliveryMethod && this.deliveryMethods.length > 1 && this.isShipmentConfirm) {
      this.isShipmentConfirm = false
    } else {
      this.router.navigate(["/booking-cart"]);
    }
  }

  getStateData() {
    this.publicCart$.safeSubscribe(this, (state: IPublicPackage[]) => {
      this.publicCarts = state;
    });
    this._publicBookingSelectService.getValue().safeSubscribe(this, (bpacks) => {
      if(bpacks.length > 0) {
        this.bookingPackages = bpacks;
      }
    })
    this.PBReducer$.safeSubscribe(this, (config: IPBReducer) => {
      if (config.booking) {
        this.bookingMode = config.bookingMode;
        this.bookingId = config.booking.id;
        this.PBReducer = config;
        this.bookingReference = config.booking.bookingReference;
        if (this.bookingMode === BOOKING_CART.NEW_BOOKING) {
          this.booking = config.booking;
          this.bookingPackages = config.booking?.bookingPackages;
        } else if (this.bookingMode === BOOKING_CART.EDIT_BOOKING) {
          this.booking = config.booking;
          this.bookingPackages = config.booking?.bookingPackages;
        }

        if (this.isLoggedIn && (!config.booking.deliveryAddress || !config.booking.deliveryCommunicationMethods)) {
          let uBooking: Booking;
          let bookingItemTypes;
          switch (this.bookingMode) {
            case BOOKING_CART.NEW_BOOKING:
              uBooking = new Booking();
              uBooking.id = this.booking.id;
              break;
            case BOOKING_CART.EDIT_BOOKING:
              const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
              const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(sessions, this.PBReducer, this.cart);
              uBooking = newBooking;
              bookingItemTypes = bookingItemTypeNames;
              break;

            default:
              break;
          }
          uBooking.contact = new BookingContact();
          uBooking.contact.id = this.contact.id;
          this.bookingPatchCall(uBooking);
        }
      }
    });
    this._customerService.GetCountries().safeSubscribe(this, (c) => {
      if (c) {
        this.countries = c;
        if (this.countries) {
          if (this.defaultDeliveryAddress && this.defaultDeliveryAddress.countryID) {
            this.defaultCountry = this.countries.find((x) => x.id === this.defaultDeliveryAddress.countryID.toString());
          } else {
            this.defaultCountry = this.countries.find((x) => x.id === environment.DefaultCountryId);
          }
        }
      }
    });
  }
  onResize(event) {
    window.innerWidth < 768
      ? document.getElementById("mobileView").classList.add("order-first")
      : document.getElementById("mobileView").classList.remove("order-first");
  }

  //#region get component data
  getInvoiceDetails() {
    this.showLoader = true;
    // this.booking = undefined;
    if (this.booking) {
      // this.store.dispatch(new StartPaymentSession(new Date()));
      this.bookingReference = this.booking.bookingReference;
      this.defaultDeliveryAddress = this.booking.deliveryAddress;
      this.defaultDeliveryCommunicationMethods =
        this.booking.deliveryCommunicationMethods;
      if (this.countries) {
        if (
          this.defaultDeliveryAddress &&
          this.defaultDeliveryAddress.countryID
        ) {
          this.defaultCountry = this.countries.find(
            (x) => x.id === this.defaultDeliveryAddress.countryID.toString()
          );
        } else {
          this.defaultCountry = this.countries.find(
            (x) => x.id === environment.DefaultCountryId
          );
        }
      }
      this.ValidateBookingId = this.booking.id;
      if (this.booking == undefined) {
        this.openSnackBarError([
          "Cannot get data. Please check your Invoice.",
        ]);
        this.IsValidInvoice = false;
      } else {
        if (
          this.bookingPackages &&
          this.bookingPackages.length > 0 &&
          this.bookingPackages[0].package.businessAreaId != ""
        ) {
          this.IsValidInvoice = true;
          this.showLoader = true;
          this.OutStandingAmount = PBUtility.getBookingTotal(this.booking) - this.booking.paidAmount;
          this.OutStandingAmountMax = this.OutStandingAmount;
          let resultBusinessArea = this._packageService.packageBusinessAreaSearch();
          resultBusinessArea.subscribe(
            (businessAreas) => {
              if (
                businessAreas != undefined &&
                businessAreas instanceof Array &&
                businessAreas.length > 0
              ) {
                let businessArea = businessAreas.filter(
                  (businessArea) =>
                    +businessArea.id ==
                    this.bookingPackages[0].package.businessAreaId
                );
                if (businessArea[0].billerCode != "") {
                  this.billerCode = businessArea[0].billerCode;
                }
              }
            },
            (er) => {
              this.showLoader = false;
            },
            () => {
              this.showLoader = false;
            }
          );
        }
        this.setSelectedDeliveryMethod()
        this.showLoader = false;
      }
    } else {
      this._publicBookingService.selectBooking(this.bookingId).safeSubscribe(
        this,
        (d) => {
          if (d.isError) {
            this.showLoader = false;
          } else {
            this.booking = d.data as Booking;
            // this.store.dispatch(new AddBooking(this.booking));
            // this.store.dispatch(new StartPaymentSession(new Date()));
            this.bookingReference = this.booking.bookingReference;
            this.defaultDeliveryAddress = this.booking.deliveryAddress;
            this.defaultDeliveryCommunicationMethods =
              this.booking.deliveryCommunicationMethods;
            if (this.countries) {
              if (
                this.defaultDeliveryAddress &&
                this.defaultDeliveryAddress.countryID
              ) {
                this.defaultCountry = this.countries.find(
                  (x) => x.id === this.defaultDeliveryAddress.countryID.toString()
                );
              } else {
                this.defaultCountry = this.countries.find(
                  (x) => x.id === environment.DefaultCountryId
                );
              }
            }
            this.ValidateBookingId = this.booking.id;
            if (this.booking == undefined) {
              this.openSnackBarError([
                "Cannot get data. Please check your Invoice.",
              ]);
              this.IsValidInvoice = false;
            } else {
              if (
                this.booking.bookingPackages &&
                this.booking.bookingPackages.length > 0 &&
                this.booking.bookingPackages[0].package.businessAreaId != ""
              ) {
                this.IsValidInvoice = true;
                this.showLoader = true;
                // this.getTotalAmount();
                this.OutStandingAmount = PBUtility.getBookingTotal(this.booking);
                this.OutStandingAmountMax = this.OutStandingAmount;
                let resultBusinessArea =
                  this._packageService.packageBusinessAreaSearch();
                resultBusinessArea.subscribe(
                  (businessAreas) => {
                    if (
                      businessAreas != undefined &&
                      businessAreas instanceof Array &&
                      businessAreas.length > 0
                    ) {
                      let businessArea = businessAreas.filter(
                        (businessArea) =>
                          +businessArea.id ==
                          this.booking.bookingPackages[0].package.businessAreaId
                      );
                      if (businessArea[0].billerCode != "") {
                        this.billerCode = businessArea[0].billerCode;
                      }
                    }
                  },
                  (er) => {
                    this.showLoader = false;
                  },
                  () => {
                    this.showLoader = false;
                  }
                );
              }
              this.setSelectedDeliveryMethod()
              this.showLoader = false;
            }
          }
        },
        (er) => {
          this.showLoader = false;
        },
        () => {
          this.showLoader = false;
        }
      );
    }
  }
  setSelectedDeliveryMethod() {
    if (
      this.booking &&
      this.deliveryMethods &&
      this.deliveryMethods.length > 0 &&
      this.booking.deliveryMethod
    ) {
      // this.booking.deliveryMethod.id !== "1" &&
      if (!this.selectedDeliveryMethod) {
        if (this.deliveryMethods.findIndex((x) => x.id === this.booking.deliveryMethod.id) === -1) {
          this.selectedDeliveryMethod = this.deliveryMethods[0];
          this.onDeliveryMethodChange();
        }
        else {
          this.selectedDeliveryMethod = this.deliveryMethods.find(
            (x) => x.id === this.booking.deliveryMethod.id
          );
        }
      }
      this.checkTheDeliveryWithClientDelivery();
    }
  }
  getDeliveryMethods() {
    this.showLoader = true;
    this._bookingService
      .BookingEligibleDeliveryMethods(this.bookingId)
      .safeSubscribe(this, (value: DeliveryMethod[]) => {
        if (!value || value.length == 0) {
          // const packageCount = this.booking.bookingPackages.length;
          // if (packageCount == 1) {
          //   this.isShipmentConfirm = true;
          // } else {
          this.showLoader = false;
          const ref = this.dialog.open(ConfirmationComponent, {
            data: {
              type: SESSION_CONFIRMATION_M_TYPE.NonCommonDeliveryMethodMessage,
              NonCommonDeliveryMethodMessage:
                this.deliveryMethodSetting.nonCommonDeliveryMethodMessage,
            },
            height: "auto",
            width: "40%",
          });
          ref.afterClosed().safeSubscribe(this, (x) => {
            if (x) {
              this.store.dispatch(new ResetState());
              window.open(environment.PublicWebPortalUrl, "_self");
            }
          });
          // }
        } else {
          this.getInvoiceDetails();
          this.deliveryMethods = value.filter((x) => x.id !== "0");
          if (value.length == 1) {
            this.isShipmentConfirm = true;
          } else if (value.length > 1 && value.filter((x) => x.id !== "0").length == 1) {
            this.isShipmentConfirm = true;
          } else {
            this.isShipmentConfirm = false;
          }
          const defaultD = this.deliveryMethods.find(
            (x) => x.defaultDeliveryMethod
          );
          this.setSelectedDeliveryMethod()
          if (defaultD && !this.selectedDeliveryMethod) {
            this.selectedDeliveryMethod = defaultD;
          }
        }
      });
  }
  // getClientAddress() {
  //   this._customerService
  //     .getClientDetails(this.contact.client.id)
  //     .safeSubscribe(this, (p) => {
  //       this.client = p;
  //     });
  // }
  //#endregion

  //#region popup modals
  openDeliveryMethodEditModel(
    type: DeliveryMethodModelType,
    contact: Contact = this.contact,
    address?: Address,
    communicationMethod?: CommunicationMethod[]
  ) {
    const dialogRef = this.dialog.open(DeliveryMethodEditModalComponent, {
      data: {
        modelType: type,
        contact: contact,
        address: address,
        communicationMethod: communicationMethod,
        booking: this.booking,
        countries: this.countries,
        cart: this.cart,
        pbreducer: this.PBReducer,
        patchPreviewId: this.patchPreviewId
      },
      height: "auto",
      width: '40%',
      panelClass: "Item-Session-Dialog-Box",
    });
    dialogRef.afterClosed().safeSubscribe(this, (e) => {
      if (e.cancelDifferentShipment) {
        this.shipDifferentAddress = false;
        this.checkTheDeliveryWithClientDelivery();
      } else {
        this.canEditDeliveryAddress = true;
        this.getInvoiceDetails();
      }
    });
  }
  popupConfirmation() {
    return this.dialog.open(ConfirmationComponent, {
      data: {
        type: SESSION_CONFIRMATION_M_TYPE.SESSION_EXPIRATION_CONFIRMATION,
      },
      panelClass: [
        "custom-dialog-container",
        "w50modal",
        "modal-width",
        "extrapop",
      ],
      height: "auto",
      width: "50%",
    });
  }
  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }
  //#endregion

  //#region delivery method function
  checkTheDeliveryWithClientDelivery() {
    if (this.selectedDeliveryMethod) {
      this.canEditDeliveryAddress = false;
      if (this.selectedDeliveryMethod.email) {
        const dMail = this.booking.deliveryCommunicationMethods.find(
          (x) => +x.communicationTypeID == 6
        );
        const cMail = this.contact.communicationMethods.find(
          (x) => +x.communicationTypeID == 6
        );

        if (
          dMail &&
          cMail &&
          cMail.value &&
          dMail.value &&
          cMail.value.toLowerCase() !== dMail.value.toLowerCase()
        ) {
          this.shipDifferentAddress = true;
          this.canEditDeliveryAddress = true;
        }
      } else if (this.selectedDeliveryMethod.postal) {
        if (
          !!this.contact.deliveryAddress &&
          !!this.contact.deliveryAddress.address1 &&
          !!this.booking.deliveryAddress.address1
        ) {
          if (
            (this.contact.deliveryAddress.address1 &&
              this.booking.deliveryAddress.address1 &&
              this.contact.deliveryAddress.address1.toLocaleLowerCase() !=
              this.booking.deliveryAddress.address1.toLocaleLowerCase()) ||
            (this.contact.deliveryAddress.firstName &&
              this.booking.deliveryAddress.firstName &&
              this.contact.deliveryAddress.firstName.toLocaleLowerCase() !=
              this.booking.deliveryAddress.firstName.toLocaleLowerCase()) ||
            (this.contact.deliveryAddress.lastName &&
              this.booking.deliveryAddress.lastName &&
              this.contact.deliveryAddress.lastName.toLocaleLowerCase() !=
              this.booking.deliveryAddress.lastName.toLocaleLowerCase()) ||
            this.contact.deliveryAddress.countryID !=
            this.booking.deliveryAddress.countryID ||
            (this.contact.deliveryAddress.city &&
              this.booking.deliveryAddress.city &&
              this.contact.deliveryAddress.city.toLocaleLowerCase() !=
              this.booking.deliveryAddress.city.toLocaleLowerCase()) ||
            (this.contact.deliveryAddress.stateCode &&
              this.booking.deliveryAddress.stateCode &&
              this.contact.deliveryAddress.stateCode.toLocaleLowerCase() !=
              this.booking.deliveryAddress.stateCode.toLocaleLowerCase()) ||
            (this.contact.deliveryAddress.postCode &&
              this.booking.deliveryAddress.postCode &&
              this.contact.deliveryAddress.postCode.toLocaleLowerCase() !=
              this.booking.deliveryAddress.postCode.toLocaleLowerCase())
          ) {
            this.shipDifferentAddress = true;
            this.canEditDeliveryAddress = true;
          }
        }
      }
    }
  }
  previousOutstanding: number
  patchDeliveryMethod() {
    const nBooking = new Booking();
    nBooking.id = this.booking.id;
    const prevDelivery = this.deliveryMethods.find(x => x.id == this.booking.deliveryMethod.id);
    this.previousOutstanding = this.OutStandingAmount - (!!prevDelivery ? prevDelivery.price : 0);
    if (nBooking.id && this.selectedDeliveryMethod) {
      nBooking.deliveryMethod = new DeliveryMethod();
      nBooking.deliveryMethod.id = this.selectedDeliveryMethod.id;
      if (
        this.booking.deliveryMethod &&
        this.booking.deliveryMethod.id === this.selectedDeliveryMethod.id
      ) {
      } else {
        this.showLoader = true;
        this.bookingPatchCall(nBooking, true)
        // this.isShipmentConfirm = false;
      }
    }
  }
  bookingPatchCall(booking, isDeliveryPatch = false) {
    switch (this.bookingMode) {
      case BOOKING_CART.EDIT_BOOKING:
        this._publicBookingService
          .BookingPatchPreview(booking, this.patchPreviewId)
          .safeSubscribe(this, (b) => {
            if (b.isError) {
              this.openSnackBarError(b.error);
            }
            if (isDeliveryPatch) {
              this.booking.deliveryMethod = b.data.deliveryMethod;
              this.store.dispatch(new AddBooking(this.booking))
            } else {
              this.booking = b.data;
              this.store.dispatch(new AddBooking(b.data))
            }
            this.showLoader = false;
            if (isDeliveryPatch) {
              let deliveryMethod: DeliveryMethod = this.deliveryMethods.find(x => x.id == this.booking.deliveryMethod.id)
              this.OutStandingAmount = deliveryMethod.price + this.previousOutstanding
            } else {
              this.OutStandingAmount = PBUtility.getBookingTotal(this.booking) - this.booking.paidAmount;
              this.getInvoiceDetails();
            }

          });
        break;
      case BOOKING_CART.NEW_BOOKING:
        this._publicBookingService
          .BookingPatch(booking)
          .safeSubscribe(this, (b) => {
            if (b.isError) {
              this.openSnackBarError(b.error);
            } else {
              this.booking = b.data.booking;
              this.defaultDeliveryAddress = this.booking.deliveryAddress
              this.store.dispatch(new AddBooking(this.booking))
              if (isDeliveryPatch) {
                // this.isShipmentConfirm = true;
              }
            }
            this.showLoader = false;
            this.OutStandingAmount = PBUtility.getBookingTotal(this.booking) - this.booking.paidAmount;
            this.getInvoiceDetails();
          });
        break;
    }
  }
  onShipDifferentAddress() {
    if (this.shipDifferentAddress) {
      let type;
      if (this.selectedDeliveryMethod.email) {
        type = DeliveryMethodModelType.CreateBookingCommunication;
        const email = _.cloneDeep(
          this.defaultDeliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6
          )
        );
        if (email) {
          email.value = undefined;
          this.openDeliveryMethodEditModel(type, this.contact, new Address(), this.booking.deliveryCommunicationMethods);
        }
      }
      if (this.selectedDeliveryMethod.postal) {
        type = DeliveryMethodModelType.CreateBookingAddress;
        // if (this.booking.deliveryAddress && this.booking.deliveryAddress.id) {
        //   this.openDeliveryMethodEditModel(type, this.client, this.booking.deliveryAddress)
        // } else {
        const address = new Address();
        address.id = this.booking.deliveryAddress.id;
        address.firstName = this.booking.deliveryAddress.firstName;
        address.lastName = this.booking.deliveryAddress.lastName;
        this.openDeliveryMethodEditModel(
          type,
          this.contact,
          address,
          this.booking.deliveryCommunicationMethods
        );
        // }
      }
    } else {
      //const uBooking = new Booking();
      //uBooking.id = this.booking.id;
      let uBooking: Booking;
      let bookingItemTypes
      switch (this.bookingMode) {
        case BOOKING_CART.NEW_BOOKING:
          uBooking = new Booking();
          uBooking.id = this.booking.id;
          break;
        case BOOKING_CART.EDIT_BOOKING:
          const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
          const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(sessions, this.PBReducer, this.cart);
          uBooking = newBooking;
          bookingItemTypes = bookingItemTypeNames;
          break;

        default:
          break;
      }
      uBooking.deliveryAddress = this.contact.deliveryAddress;
      uBooking.deliveryAddress.type = "Address";
      uBooking.deliveryAddress.id = this.booking.deliveryAddress.id;
      uBooking.deliveryAddress.firstName = this.contact["firstName"];
      uBooking.deliveryAddress.lastName = this.contact["lastName"];
      this.contact.communicationMethods.forEach((com) => {
        const ext = this.booking.deliveryCommunicationMethods.find(
          (x) => x.communicationTypeID == com.communicationTypeID
        );
        if (ext && !!com.value) {
          if (!uBooking.deliveryCommunicationMethods) {
            uBooking.deliveryCommunicationMethods = [];
          }
          const nC = new CommunicationMethod();
          nC.id = ext.id;
          nC.value = com.value;
          nC.communicationType = com.communicationTypeID;
          nC.communicationTypeID = com.communicationTypeID;
          uBooking.deliveryCommunicationMethods.push(nC);
        }
      });
      this.showLoader = true;
      this.bookingPatchCall(uBooking)

    }
  }
  onEditDefaultInfo() {
    let type;
    if (this.selectedDeliveryMethod.email) {
      type = DeliveryMethodModelType.EditDefaultEmail;
    } else if (this.selectedDeliveryMethod.postal) {
      type = DeliveryMethodModelType.EditDefaultAddress;
    }
    this.openDeliveryMethodEditModel(
      type,
      this.contact,
      this.defaultDeliveryAddress,
      this.defaultDeliveryCommunicationMethods
    );
  }
  getFieldDataNew(field: string, isDefault = true) {
    let value: any = false;
    if (
      !this.defaultDeliveryAddress ||
      !this.defaultDeliveryCommunicationMethods
    ) {
      return false;
    }
    switch (field) {
      case "firstName":
        value = isDefault
          ? this.defaultDeliveryAddress["firstName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["firstName"])
            : false
          : false;
        break;
      case "lastName":
        value = isDefault
          ? this.defaultDeliveryAddress["lastName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["lastName"])
            : false
          : false;
        break;
      case "companyName":
        value = isDefault
          ? this.defaultDeliveryAddress["companyName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["companyName"])
            : false
          : false;
        break;
      case "mobile":
        let com;
        if (isDefault) {
          com = this.defaultDeliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 3
          );
        } else {
          com = this.booking.deliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 3
          );
        }
        if (com && com["value"]) {
          value = Utility.sentenceCase(com["value"]);
        }
        break;
      case "email":
        let comMail;
        if (isDefault) {
          comMail = this.defaultDeliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6
          );
        } else {
          comMail = this.booking.deliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6
          );
        }
        if (comMail && comMail["value"]) {
          // value = Utility.sentenceCase(comMail["value"])
          value = comMail["value"]
        }
        break;
      case "address1":
        value = isDefault
          ? this.defaultDeliveryAddress["address1"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address1"])
            : false
          : false;

        break;
      case "address2":
        value = isDefault
          ? this.defaultDeliveryAddress["address2"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address2"])
            : false
          : false;

        break;
      case "address3":
        value = isDefault
          ? this.defaultDeliveryAddress["address3"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address3"])
            : false
          : false;

        break;
      case "city":
        value = isDefault
          ? this.defaultDeliveryAddress["city"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["city"])
            : false
          : false;

        break;
      case "stateCode":
        value = isDefault
          ? this.defaultDeliveryAddress["stateCode"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["stateCode"])
            : false
          : false;

        break;
      case "postCode":
        value = isDefault
          ? this.defaultDeliveryAddress["postCode"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["postCode"])
            : false
          : false;
        break;
      case "country":
        value = isDefault
          ? this.defaultDeliveryAddress["country"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["country"])
            : false
          : false;
        break;
    }
    if (this.defaultCountry) {
      if (field == "postCode" && !this.defaultCountry.postcodeRequired) {
        value = false;
      }
      if (field == "stateCode" && !this.defaultCountry.postcodeRequired) {
        value = false;
      }
    }

    return value;
  }
  getFieldData(field: Field, isDefault = true) {
    let value: any = false;
    if (
      !this.defaultDeliveryAddress ||
      !this.defaultDeliveryCommunicationMethods
    ) {
      return false;
    }
    switch (field.name) {
      case "firstName":
        value = isDefault
          ? this.defaultDeliveryAddress["firstName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["firstName"])
            : false
          : false;
        break;
      case "lastName":
        value = isDefault
          ? this.defaultDeliveryAddress["lastName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["lastName"])
            : false
          : false;
        break;
      case "companyName":
        value = isDefault
          ? this.defaultDeliveryAddress["companyName"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["companyName"])
            : false
          : false;
        break;
      case "mobile":
        let com;
        if (isDefault) {
          com = this.defaultDeliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 3
          );
        } else {
          com = this.booking.deliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 3
          );
        }
        if (com && com["value"]) {
          value = Utility.sentenceCase(com["value"]);
        }
        break;
      case "email":
        let comMail;
        if (isDefault) {
          comMail = this.defaultDeliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6
          );
        } else {
          comMail = this.booking.deliveryCommunicationMethods.find(
            (x) => +x.communicationTypeID == 6
          );
        }
        if (comMail && comMail["value"]) {
          // value = Utility.sentenceCase(comMail["value"])
          value = comMail["value"]
        }
        break;
      case "address1":
        value = isDefault
          ? this.defaultDeliveryAddress["address1"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address1"])
            : false
          : false;

        break;
      case "address2":
        value = isDefault
          ? this.defaultDeliveryAddress["address2"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address2"])
            : false
          : false;

        break;
      case "address3":
        value = isDefault
          ? this.defaultDeliveryAddress["address3"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["address3"])
            : false
          : false;

        break;
      case "city":
        value = isDefault
          ? this.defaultDeliveryAddress["city"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["city"])
            : false
          : false;

        break;
      case "stateCode":
        value = isDefault
          ? this.defaultDeliveryAddress["stateCode"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["stateCode"])
            : false
          : false;

        break;
      case "postCode":
        value = isDefault
          ? this.defaultDeliveryAddress["postCode"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["postCode"])
            : false
          : false;
        break;
      case "country":
        value = isDefault
          ? this.defaultDeliveryAddress["country"]
            ? Utility.sentenceCase(this.defaultDeliveryAddress["country"])
            : false
          : false;
        break;
    }
    if (this.defaultCountry) {
      if (field.name == "postCode" && !this.defaultCountry.postcodeRequired) {
        value = false;
      }
      if (field.name == "stateCode" && !this.defaultCountry.postcodeRequired) {
        value = false;
      }
    }

    return value;
  }
  getClientMobileNo() {
    const com = this.contact.communicationMethods.find(
      (x) => +x.communicationTypeID == 3
    );
    if (com) {
      return com.value;
    }
    return false;
  }

  getdeliveryMothodMobileNo(isDefault = true) {
    let com;
    let value;
    if (
      !this.defaultDeliveryAddress ||
      !this.defaultDeliveryCommunicationMethods
    ) {
      return false;
    }
    if (isDefault) {
      com = this.defaultDeliveryCommunicationMethods.find(
        (x) => +x.communicationTypeID == 3
      );
    } else {
      com = this.booking.deliveryCommunicationMethods.find(
        (x) => +x.communicationTypeID == 3
      );
    }
    if (com && com["value"]) {
      value = Utility.sentenceCase(com["value"]);
    }
    return value;
  }

  getdeliveryMothodMail(isDefault = true) {
    let comMail;
    let value;
    if (
      !this.defaultDeliveryAddress ||
      !this.defaultDeliveryCommunicationMethods
    ) {
      return false;
    }
    if (isDefault) {
      comMail = this.defaultDeliveryCommunicationMethods.find(
        (x) => +x.communicationTypeID == 6
      );
    } else {
      comMail = this.booking.deliveryCommunicationMethods.find(
        (x) => +x.communicationTypeID == 6
      );
    }
    if (comMail && comMail["value"]) {
      value = Utility.sentenceCase(comMail["value"]);
    }
    return value;
  }


  onDeliveryMethodChange() {
    // if (this.selectedDeliveryMethod) {
    //   this.getTotalAmount();
    // }
    this.checkTheDeliveryWithClientDelivery();
    this.patchDeliveryMethod()
  }
  //#endregion

  checkout() {
    switch (this.selectedPaymentMethod) {
      case PAYMENT_METHOD.CREDIT_DEBIT:
        this.proceedToPay();
        break;
      case PAYMENT_METHOD.PAYPAL:
        this.openSnackBarError(["PayPal method not implemented"]);
        break;
      default:
        this.openSnackBarError(["select Payment Method"]);
        break;
    }
  }
  proceedToPay() {
    if (this.IsValidInvoice) {
      if (this.OutStandingAmount != undefined && +this.OutStandingAmount > 0) {
        // if (+this.OutStandingAmount <= this.OutStandingAmountMax) {     
        if (environment.ExternalIntegrationIdForPaymentGateway) {
          this.directToPayment()
        } else {
          this.router.navigate(["/payment/secure-pay"], {
            queryParams: {
              amount: this.OutStandingAmount,
              bookingId: this.bookingId,
              immediate: 1,
            },
          });
        }
      } else {
        this.openSnackBarError(["Invalid Payment Amount"]);
      }
    }
  }
  directToPayment() {
    var url =
      environment.ApiUrl +
      (environment.ApiUrl.endsWith("/") ? "" : "/") +
      "Payment/Pay?InvoiceRef=" +
      this.ValidateBookingId;
    var url = url + "&amount=" + this.OutStandingAmount.toFixed(2);
    var url =
      url +
      "&immediate=1&bookingTypeId=" +
      this.booking.bookingTypeId;
    if (this.bookingId != undefined && this.bookingId != "") {
      url = url + "&bookingId=" + this.bookingId;
    }
    if (this.billerCode != undefined && this.billerCode != "") {
      url = url + "&billerCode=" + this.billerCode;
    }
    if (this.patchPreviewId != undefined && this.patchPreviewId != "") {
      url = url + "&IsGenerateInvoice=0&bookingPatchKey=" + this.patchPreviewId;
    }
    if(this.bookingMode === BOOKING_CART.NEW_BOOKING){
      url = url + "&IsNewBooking=1"
    }
    // window.location.href = url;
    const payData = {
      url: url,
      amount: this.OutStandingAmount
    };
    this._publicBookingService.setPaymentUrl(payData);
    this.router.navigate(['/make-payment']);
  }
  invoiceValueChange(val) {
    this.IsValidInvoiceRef = false;
  }
  // startPaymentSession() {
  //   this.paymentSession.isSessionTimerStart = true;
  //   this.paymentSessionKey = setInterval(() => {
  //     var now = new Date().getTime();
  //     // Time calculations for days, hours, minutes and seconds
  //     var distance = this.paymentSession.sessionEndTime - now;
  //     this.paymentSession.minutes = Math.floor(
  //       (distance % (1000 * 60 * 60)) / (1000 * 60)
  //     ).toString();
  //     this.paymentSession.seconds = Math.floor(
  //       (distance % (1000 * 60)) / 1000
  //     ).toString();

  //     if (
  //       this.paymentSession.minutes === "0" &&
  //       this.paymentSession.seconds === "0"
  //     ) {
  //       this.paymentSession.isSessionExpired = true;
  //       this.paymentSession.isSessionTimerStart = false;
  //       clearInterval(this.paymentSessionKey);
  //       let dialog = this.popupConfirmation();
  //       let sub = dialog.afterClosed().subscribe((e) => {
  //         this.paymentSessionKey = undefined;
  //         this.bookingSession.minutes = "0";
  //         this.bookingSession.seconds = "0";
  //         this.bookingSession.isSessionExpired = true;
  //         this.bookingSession.sessionExpended = false;
  //         this.bookingSession.isSessionTimerStart = false;
  //         this.store.dispatch(new ResetState());
  //         this.store.dispatch(new AddPaymentSession(this.paymentSession));
  //         this.store.dispatch(new AddBookingSession(this.bookingSession));
  //         this.store.dispatch(new Redirect("PublicWebHome"));
  //         sub.unsubscribe();
  //       });
  //     }
  //     if (--distance < 0 && this.paymentSessionKey) {
  //       this.paymentSession.isSessionExpired = true;
  //       this.paymentSession.isSessionTimerStart = false;
  //       this.bookingSession.minutes = "0";
  //       this.bookingSession.seconds = "0";
  //       this.bookingSession.isSessionExpired = true;
  //       this.bookingSession.sessionExpended = false;
  //       this.bookingSession.isSessionTimerStart = false;
  //       this.store.dispatch(new ResetState());
  //       this.store.dispatch(new AddPaymentSession(this.paymentSession));
  //       this.store.dispatch(new AddBookingSession(this.bookingSession));
  //       this.store.dispatch(new Redirect("PublicWebHome"));
  //       clearInterval(this.paymentSessionKey);
  //     }
  //   }, 1000);
  // }

  openPrivacyOrTerms(type) {
    if (type == 'privacy') {
      const dialogRef = this.dialog.open(PrivacyTermsModalComponent, {
        data: {
          type: type,
        },
        panelClass: [
          "custom-dialog-container",
          "w50modal",
          "modal-width",
          "extrapop",
        ],
        height: "auto",
        width: "50%",
      });
      dialogRef.afterClosed().safeSubscribe(this, (e) => {
        console.log(e);

      });
    } else if (type == 'terms') {
      const dialogRef = this.dialog.open(PrivacyTermsModalComponent, {
        data: {
          type: type,
        }, panelClass: [
          "custom-dialog-container",
          "w50modal",
          "modal-width",
          "extrapop",
        ],

        height: "auto",
        width: "50%",
      });
      dialogRef.afterClosed().safeSubscribe(this, (e) => {
        console.log(e);

      });
    }
  }
}

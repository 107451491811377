<div [id]="type + '_' + unique_key" class="contact-content"
 [style.transform]="layoutAttribute.transform">
  <nav>
    <ul class="contact-section">
      <li *ngIf="layoutAttribute.phone">
        <label>Phone</label>
        <ul *ngFor="let phone of layoutAttribute.phone" class="phone-item">
          <li>{{phone}}</li>
        </ul>
      </li>
      <li *ngIf="layoutAttribute.email ">
        <label>Email</label>
        <ul *ngFor="let email of layoutAttribute.email" class="email-item">
          <li>{{email}}</li>
        </ul>
      </li>
      <li *ngIf="layoutAttribute.fax">
        <label>Fax</label>
        <ul *ngFor="let fax of layoutAttribute.fax" class="fax-item">
          <li>{{fax}}</li>
        </ul>
      </li>
    </ul>
  </nav>
</div>

<div class="additional-events-page">
    <div class="mmc-modal-wrapper questionnaire-modal">
        <div class="mmc-modal-header mmc_flex--container">
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
                <i class="material-icons">insert_invitation
                </i>
            </div>
            <div class="mmc_flex__item mmc_flex__item--vcenter">
                <span class="section-title">{{ viewDay.date | calendarDate: "dayViewTitle"}}</span>
            </div>
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
                <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="body">
            <div *ngFor="let event of events" style="background-color: #ececec;" class="date-session-card d-flex row">
                <!-- <div class='card-content col-3'>
                    {{event.title}}
                </div> -->
                <div class='card-content col-4'>
                    {{event?.session?.startTime | date: " h:mm a"}} - {{event?.session?.endTime| date: " h:mm a"}}
                </div>
                <div class='card-content col-4' style="text-align: right;">
                    Starting from ${{event?.session?.totalPriceIncludingTax | number : '1.2-2'}}
                </div>
                <div class='card-content col-4' style="text-align: right;">
                    <button type="button" class="btn" (click)='selectSession(event)'>Select</button>
                </div>

                <!-- <div class="row">
                    <div class="col-3">
                        <div style="text-align:center; line-height: 60px;">{{event.title}}
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="text-center" style="text-align:center; vertical-align: middle; line-height: 60px;">
                            {{event?.session?.startTime | date: " h:mm a"}} - {{event?.session?.endTime| date: " h:mm"}}
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="row">
                            <div class="col">
                                <div class="text-center" style="text-align:center">Starting from</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="text-center" style="text-align:center"> $ {{event?.priceMinimum}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <div style="text-align:center; vertical-align: middle; line-height: 60px;">
                            <button type="button" style="width: fit-content; height: fit-content;"
                                class="btn btn-event-page" (click)='selectSession(event)'>Select</button>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
</div>
import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ICart, Store } from "..";
import {
  IBooingPackage,
  IBooingPackageBookedSeat,
  IDateAndSession,
  IPBReducer,
  IPublicPackage,
} from "./public-web-reducers";
import { Booking, DeliveryMethod } from "src/modules/models/booking/booking";
import { BookingPackages } from "src/modules/models/booking/booking-packages";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { EventService } from "src/modules/public-web/booking-application/services/event.service";
import {
  AddBooking,
  AddNewSession,
  BookingFailed,
  BookingPatchPreview,
  CheckBookingHasClient,
  PatchBooking,
  PostBooking,
  PBActionType,
  ResetSelectedPackageStatus,
  SelectBookedPackage,
  SelectPackageSessions,
  SetBookingType,
  SetPatchPreviewStatus,
  UpdateSelectedBooking,
  ResetAllPackageQuantity,
  UpdatePackage,
  StartBookingSession,
  StartGlobalSession,
  PatchUpSellQuantity,
  SelectBookedEvent,
  SetSelectedBookingPackage,
  AddPublicInternalBooking,
  SetRedirectionPoint,
  ResetState,
} from "./public-web-actions";
import {
  BookingSaveCompleted,
} from "../loading/actions";
import { Router } from "@angular/router";


import { BookingContact } from "src/modules/models/booking/booking-contact";
import { Utility } from "src/modules/utility";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import { Package } from "src/modules/models/public-web/Package";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { EMPTY, forkJoin } from "rxjs";
import { ErrorLog } from "src/modules/models/public-web/error";
import * as _ from "lodash";

import {
  BaseParam,
  SearchBaseFilter,
} from "src/modules/models/public-web/filter";
import { PAGE_CONFIGURATION } from "src/modules/public-web/components/layout/models/enums";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";
import { PBUtility } from "src/modules/public-web/pb-utility";
import { BOOKING_CART } from "src/modules/models/public-web/enum";
import { PublicBookingSelectService } from "src/modules/services/public-web/public-booking-select.service";
import { LoadEventDate, ReSetConfigurationState } from "../booking-application/Configuration.action";
import { BookingItems } from "src/modules/models/booking/booking-items";
@Injectable()
export class PublicWebEffects {

  @Effect({})
  updateDeliveryMethod$ = this.actions$.pipe(
    ofType("SET_DELIVERY_METHOD"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const booking = PBReducer.booking;
      const nbooking = new Booking();
      nbooking.id = PBReducer.booking.id;
      let canUpdate = false;
      if (booking && PBReducer.selectedDeliveryMethod) {
        canUpdate = true;
        nbooking.deliveryMethod = new DeliveryMethod();
        nbooking.deliveryMethod.id = PBReducer.selectedDeliveryMethod;
      }
      if (canUpdate) {
        let bookingResult = this.bookingService.BookingPatch(nbooking);
        return bookingResult.pipe(
          map((booking) => {
            let cbooking = booking as Booking;
            return {
              type: PBActionType.ADD_BOOKING,
              payload: cbooking,
            };
          }),
          catchError((error: any) => {
            this.bookingError(error.error.errors);
            return null;
          })
        );
      }
      return EMPTY;
    })
  );


  @Effect({})
  postBooking$ = this.actions$.pipe(
    ofType("POST_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const publicBookingSetting =
        environment.PublicBookingSetting as PublicBookingSettings;
      const cart = storeState.cart as ICart;
      const isSessionSelected = PBReducer.selectedSessions.length > 0;
      if (isSessionSelected) {
        const booking = new Booking();
        booking.confirmed = false;
        booking.id = "-1";
        booking.cancelled = false;
        booking.margin = 0;
        booking.bookingTypeId = publicBookingSetting.publicBookingType;
        booking.bookingPackages = [];
        booking.salesChannelId = 5;
        booking.isTemporary = true;
        PBReducer.selectedSessions.forEach((session) => {
          if (!!session.packages) {
            session.packages.forEach((pk) => {
              if (!pk.isBooked) {
                if (
                  pk.isHaveLinkPackage &&
                  pk.linkPackages &&
                  pk.linkPackages.length > 0
                ) {
                  const selectedLinkPk = pk.linkPackages.filter(
                    (p) => p.quantity > 0 && !pk.isBooked
                  );
                  selectedLinkPk.forEach((lpk) => {
                    booking.bookingPackages = PBUtility.generateBookingPackage(
                      lpk,
                      session,
                      booking.bookingPackages
                    );
                  });
                } else {
                  booking.bookingPackages = PBUtility.generateBookingPackage(
                    pk,
                    session,
                    booking.bookingPackages
                  );
                }
              }
            });
          }
        });
        if (
          !!PBReducer.selectedClient &&
          !!PBReducer.selectedClient.contactId
        ) {
          booking.contact = new BookingContact();
          booking.contact.id = PBReducer.selectedClient.contactId;
        } else if (cart.contact.id) {
          booking.contact = new BookingContact();
          booking.contact.id = cart.contact.id;
        }
        const bookingResult = this.bookingService.BookingSave(booking);
        return bookingResult.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                []
              );
              return new BookingFailed();
            } else {
              const cBooking = b.data as Booking;
              this.store.dispatch(new ResetSelectedPackageStatus());
              this.store.dispatch(new ResetAllPackageQuantity());
              this.store.dispatch(new SetBookingType(BOOKING_CART.NEW_BOOKING))
              return new AddBooking(cBooking);
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new ResetSelectedPackageStatus());
            this.store.dispatch(new BookingSaveCompleted());
            this.store.dispatch(new ResetSelectedPackageStatus());

            return EMPTY;
          })
        );
      } else {
        this.openSnackBarError(["Session or Package is not selected"]);
      }
      return EMPTY;
    })
  );


  @Effect({})
  patchBooking$ = this.actions$.pipe(
    ofType("PATCH_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const cart = storeState.cart as ICart;
      if (PBReducer.booking) {
        const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(
          PBReducer.selectedSessions,
          PBReducer,
          cart
        );
        const bookingResult = this.bookingService.BookingPatch(
          newBooking,
          bookingItemTypeNames
        );
        return bookingResult.pipe(
          map((b) => {
            if (b.isError) {
              this.store.dispatch(new ResetSelectedPackageStatus());
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
              return new BookingFailed();
            } else {
              const cbooking = b.data.booking as Booking;
              this.store.dispatch(new AddBooking(cbooking))
              this.store.dispatch(new ResetAllPackageQuantity());
              this.store.dispatch(new ResetSelectedPackageStatus());
              return new UpdateSelectedBooking();
            }
          }),
          catchError((error, caught) => {
            this.store.dispatch(new ResetSelectedPackageStatus());

            //  this.store.dispatch(new BookingSaveCompleted());
            let errorData = [];
            if (error.status == 500) {
              if (error.statusText) errorData.push(error.statusText);
              if (error.detail) errorData.push(error.detail);
            }

            if (
              error.error != undefined &&
              error.error.errors != null &&
              error.error.errors.length > 0
            ) {
              error.error.errors.forEach((error, i) => {
                if (error.detail) errorData.push(error.detail);
              });
            }

            this.snackBar.openFromComponent(AlertMessageComponent, {
              duration: 3000,
              verticalPosition: "top",
              data: errorData,
            });
            this.openSnackBarError(["Booking Failed"]);
            // this.bookingError(error.error.errors);
            return caught;
          })
        );
      } else {
        this.openSnackBarError(["Session is not selected"]);
      }
      return EMPTY;
    })
  );


  @Effect({ dispatch: false })
  bookingPatchPreview$ = this.actions$.pipe(
    ofType("BOOKING_PATCH_PREVIEW"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const cart = storeState.cart as ICart;
      let bPackages = [];
      this._publicBookingSelectService.getValue().subscribe(bookingpacks => {
        if (bookingpacks) {
          bPackages = bookingpacks;
        }
      })
      if (PBReducer.booking) {
        const sessions = PBUtility.makeFullBookingSession(PBReducer.selectedSessions
        );
        const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBodyV2(
          sessions,
          PBReducer,
          cart,
          bPackages
        );
        const bookingResult = this.bookingService.BookingPatchPreview(
          newBooking,
          PBReducer.patchPreviewId,
          bookingItemTypeNames
        );
        return bookingResult.pipe(
          map((b) => {
            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
              this.store.dispatch(new SetPatchPreviewStatus(true));
            } else {
              const cbooking = b.data as Booking;
              this.setBookingAfterPatchPreview(cbooking, bPackages);
              // this._publicBookingSelectService.setValue(b.data?.bookingPackages);
              // this.store.dispatch(new ResetAllPackageQuantity());
              // this.store.dispatch(new ResetSelectedPackageStatus());
              // this.store.dispatch(new AddBooking(cbooking));

            }
            return [action, storeState];
          }),
          catchError((e) => {
            this.mapPackagesIntoSessions(
              PBReducer.selectedSessions,
              PBReducer.booking.bookingPackages
            );
            return [action, storeState];
          })
        );
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  addPackage$ = this.actions$.pipe(
    ofType("ADD_PACKAGE"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking) {
        if (PBReducer.bookingMode === BOOKING_CART.EDIT_BOOKING) {
          if (PBReducer.sessionTimers) {
            if (PBReducer.sessionTimers.bookingSession && !PBReducer.sessionTimers.bookingSession.isSessionTimerStart) {
              this.store.dispatch(new StartBookingSession(new Date()));
              this.store.dispatch(new StartGlobalSession(new Date()));
            }
          }
          this.store.dispatch(new BookingPatchPreview());
        } else {
          this.store.dispatch(new PatchBooking());
        }
      } else {
        this.store.dispatch(new PostBooking());
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  setClient$ = this.actions$.pipe(
    ofType("SET_SECONDARY_CLIENT"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking && !!PBReducer.selectedClient) {
        this.store.dispatch(new CheckBookingHasClient());
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  checkBookingHasClient$ = this.actions$.pipe(
    ofType("CHECK_BOOKING_HAS_CLIENT"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      const booking = PBReducer.booking;
      const cart = storeState.cart as ICart;
      const nbooking = new Booking();
      let canUpdate = false;
      if (booking) {
        nbooking.id = booking.id;
        nbooking.contact = new BookingContact();
        if (cart && cart.contact && cart.contact.id) {
          if (
            !!PBReducer.selectedClient &&
            booking.contact.id !== PBReducer.selectedClient.contactId
          ) {
            canUpdate = true;
            nbooking.contact.id = PBReducer.selectedClient.contactId;
          } else if (
            booking.contact.id !== cart.contact.id &&
            booking.contact.id === (
              environment.PublicBookingSetting as PublicBookingSettings
            ).defaultClient // default client id
          ) {
            canUpdate = true;
            nbooking.contact.id = cart.contact.id;
          }
        } else if (
          PBReducer.guestContact.id &&
          (booking.contact.id === (
            environment.PublicBookingSetting as PublicBookingSettings
          ).defaultClient ||
            booking.contact.id !== PBReducer.guestContact.id)
        ) {
          canUpdate = true;
          nbooking.contact.id = PBReducer.guestContact.id;
        }

        if (canUpdate) {
          let bookingResult = this.bookingService.BookingPatch(nbooking);
          return bookingResult.pipe(
            map((result) => {
              if (!result.isError) {
                let cbooking = result.data.booking as Booking;
                this.store.dispatch(new AddBooking(cbooking))
                // this.store.dispatch(new BookingSaveCompleted());
                return {
                  type: PBActionType.ADD_BOOKING,
                  payload: cbooking,
                };
              } else {
                this.openSnackBarError(["Session or Package is not selected"]);
              }
            }),
            catchError((error: any) => {
              this.bookingError(error.error.errors);
              return null;
            })
          );
        }
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  addBooking$ = this.actions$.pipe(
    ofType("ADD_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;

      if (
        PBReducer.redirectionPoint &&
        PBReducer.redirectionPoint.length > 3
      ) {
        if (
          PBReducer.pageConfiguration.includes(
            PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION
          )
        ) {
          this.router.navigate(["/" + PBReducer.redirectionPoint]);
        } else {
          const isUpsell = PBReducer.redirectionPoint
            .toLocaleLowerCase()
            .includes("upsell");
          if (isUpsell) {
            const nav = PBReducer.redirectionPoint
              .toLocaleLowerCase()
              .toString();
            let id = nav.replace("upsell/", "");
            if (id && PBReducer.selectedSessionTime) {
              const sessionStart = Utility.convertToISO(
                Utility.convertISOToDate(
                  PBReducer.selectedSessionTime.startTime
                )
              );
              const sessionEnd = Utility.convertToISO(
                Utility.convertISOToDate(
                  PBReducer.selectedSessionTime.endTime
                )
              );
              const bookingPK = PBReducer.booking.bookingPackages.find(
                (bp) =>
                  bp.package.id === id &&
                  bp.startDate === sessionStart &&
                  bp.endDate === sessionEnd
              );
              if (bookingPK) {
                this.router.navigate(["/" + PBReducer.redirectionPoint], {
                  queryParams: {
                    BookingPackage: bookingPK.id,
                  },
                });
              } else {
                this.router.navigate(["/" + PBReducer.redirectionPoint]);
              }
            } else {
              this.router.navigate(["/" + PBReducer.redirectionPoint]);
            }
          } else {
            this.router.navigate(["/" + PBReducer.redirectionPoint]);
          }
        }
      }
      if (!!PBReducer.booking) {
        // PBReducer.booking.bookingDate
        if (!!PBReducer.booking && PBReducer.bookingMode === BOOKING_CART.NEW_BOOKING) {
          this.store.dispatch(new StartBookingSession(new Date()));
          this.store.dispatch(new StartGlobalSession(new Date()));
        }
        if (PBReducer.bookingMode === BOOKING_CART.NEW_BOOKING && PBReducer.booking.bookingPackages.length > 0 &&
          PBReducer.booking.bookingPackages.filter(pack => (+pack.quantity == 0))?.length > 0) {
          this.deletePackageFromBooking(PBReducer.booking);
        } else {
          if (PBReducer.booking.bookingPackages.filter(pack => !!pack.name)?.length > 0) {
            this.renderBookedPackages(PBReducer.booking, PBReducer.bookedPackages);
          }
          const patchUpSellQuantity = PBReducer.PatchItems;
          let isUpdated = false;
          if (patchUpSellQuantity.length > 0) {
            PBReducer.booking.bookingPackages.forEach(bookingPackage => {
              if (bookingPackage?.bookingItems?.length > 0) {
                bookingPackage.bookingItems.forEach(bookingItem => {
                  let item = patchUpSellQuantity.find(x => x.bookingItemId == bookingItem.id)
                  if (item && item?.quantity != bookingItem.quantity) {
                    bookingItem.quantity = item.quantity;
                    isUpdated = true;
                  }
                });
              }
            });
            if (isUpdated) {
              this.store.dispatch(new PatchUpSellQuantity([]));
              this.store.dispatch(new AddBooking(PBReducer.booking));
            }
          }
        }
        // this.mapPackagesIntoSessions(
        //   PBReducer.selectedSessions,
        //   PBReducer.booking.bookingPackages
        // );
        // this.store.dispatch(new FetchDeliveryMethods());
      }
      return [action, storeState];
    })
  );


  @Effect({})
  updateBooking$ = this.actions$.pipe(
    ofType("UPDATE_SELECTED_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking) {
        let bookingSub = this.bookingService.selectBooking(
          PBReducer.booking.id,
          false,
          false,
          true
        );
        return bookingSub.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
            } else {
              const cbooking = b.data as Booking;
              return {
                type: PBActionType.ADD_BOOKING,
                payload: cbooking,
              };
            }
          }),
          catchError((error: any) => {

            this.bookingError(error.error.errors);
            return null;
          })
        );
      }
      return EMPTY;
    })
  );


  @Effect({})
  translatedupdateBooking$ = this.actions$.pipe(
    ofType("UPDATE_TRANSLATE_SELECTED_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const PBReducer = storeState.PBReducer as IPBReducer;
      if (PBReducer.booking) {
        let bookingSub = this.bookingService.selectBooking(
          PBReducer.booking.id
        );
        return bookingSub.pipe(
          map((b) => {

            if (b.isError) {
              this.openSnackBarError(this.mapErrorMassage(b.errors));
              this.mapPackagesIntoSessions(
                PBReducer.selectedSessions,
                PBReducer.booking.bookingPackages
              );
            } else {
              const translatedBooking = b.data as Booking;
              const existingBooking = PBReducer.booking;
              existingBooking?.bookingPackages?.forEach((pkg) => {
                var index = translatedBooking.bookingPackages.findIndex(x => x.id === pkg.id);
                if (index != -1) {
                  if (pkg.name) {
                    pkg.name = translatedBooking.bookingPackages[index].name;
                    if (pkg?.package?.id === translatedBooking.bookingPackages[index].package.id) {
                      pkg.package.name = translatedBooking.bookingPackages[index].package.name;
                      pkg.package.description = translatedBooking.bookingPackages[index].package.description;
                      pkg.package.nameOnReport = translatedBooking.bookingPackages[index].package.nameOnReport;
                      pkg.package.webDescription = translatedBooking.bookingPackages[index].package.webDescription;
                    }
                  }
                  else {
                    let pack = new BookingPackages();
                    pack.id = pkg.id;
                    pack.type = pkg.type;
                    pack.name = translatedBooking.bookingPackages[index].name;
                    pack.package = new Package();
                    pack.package.name = translatedBooking.bookingPackages[index].package.name;
                    pack.package.description = translatedBooking.bookingPackages[index].package.description;
                    pack.package.nameOnReport = translatedBooking.bookingPackages[index].package.nameOnReport;
                    pack.package.webDescription = translatedBooking.bookingPackages[index].package.webDescription;
                    pkg = pack;
                  }
                  pkg?.bookingItems?.forEach((item) => {
                    var itemindex = translatedBooking.bookingPackages[index].bookingItems.findIndex(i => i.id === item.id);
                    if (itemindex != -1) {
                      item.name = translatedBooking.bookingPackages[index].bookingItems[itemindex].name;
                    }
                  })
                }
              })
              return {
                type: PBActionType.ADD_BOOKING,
                payload: existingBooking,
              };
            }
          }),
          catchError((error: any) => {
            console.table("booking Errors", error.message);
            return null;
          })
        );
      }
      return EMPTY;
    })
  );


  @Effect({ dispatch: false })
  updatePackageCart$ = this.actions$.pipe(
    ofType("SET_PACKAGE_LIST"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      let packages: IPublicPackage[] = (
        storeState.PBReducer.publicPackages as IPublicPackage[]
      ).filter(
        (x) =>
          x.isHaveLinkPackage &&
          (x.linkPackages === undefined || x.linkPackages.length === 0)
      );
      const packageIDs = [];
      const flitter = new SearchBaseFilter();
      flitter.packageIDs = packageIDs;
      packages.forEach((x) => {
        packageIDs.push(x.id);
      });
      if (packageIDs.length > 0) {
        return this._packageService
          .getPublicPackage(new BaseParam(), flitter)
          .pipe(
            map((pk: Package[]) => {
              if (pk.length > 0) {
                pk.forEach((p) => {
                  const x = packages.find((xd) => xd.id === p.id);
                  if (p && p.linkedPackages && !!x) {
                    p.linkedPackages = Array.from(
                      new Set(p.linkedPackages.map((a) => a.id))
                    ).map((id) => {
                      return p.linkedPackages.find((a) => a.id === id);
                    });
                    let linkPackage = _.cloneDeep(p.linkedPackages);
                    p.linkedPackages = [];
                    linkPackage.push(p);
                    p.linkedPackages.reverse();
                    x.linkPackages = linkPackage;
                    x.maximumAttendees = p.maximumAttendees;
                    x.linkPackages = x.linkPackages
                      .sort((x, y) => x.linkedPackageType.id > y.linkedPackageType.id ? -1 : 1)
                      .sort((x, y) => x.linkedPackageType.adult ? -1 : 1)
                    this.store.dispatch(new UpdatePackage(x));
                  }
                });

              }
              return EMPTY;
            }),
            catchError((error) => {
              console.log(error);

              return EMPTY;
            })
          );
      }
      return [action, storeState];
    })
  );


  @Effect({ dispatch: false })
  updateBookedPackage$ = this.actions$.pipe(
    ofType("SELECT_BOOKED_PACKAGES"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const bookedPackages = (storeState.PBReducer as IPBReducer).bookedPackages;
      let bookedEvents = (storeState.PBReducer as IPBReducer).bookedEvents;
      const isInternalUser = environment.IsInternalUser; 
      if (!isInternalUser) {
        const param = new SearchBaseFilter();
        param.packageIDs = bookedPackages.filter(p => !p.name).map(x => x.id);
        if (param.packageIDs.length > 0) {
          const getPackage = this._packageService
            .getPublicPackage(new BaseParam(), param)
          return getPackage.pipe(
            map((pk) => {
              if (pk) {
                const packages = bookedPackages.filter(p => !!p.name)
                pk.forEach(p => {
                  packages.push(p);
                  if (p.linkedPackages && p.linkedPackages.length > 0) {
                    packages.push(...p.linkedPackages)
                  }
                })
                let eventFromPackages = [...new Map(packages?.map(x => [x.publicEvent?.id, x.publicEvent])).values()];
                eventFromPackages.forEach(event => {
                  const isExist = bookedEvents.findIndex(p => p.id === event.id);
                  if(isExist == -1) {
                    bookedEvents.push(event);
                  }
                })
                this.store.dispatch(new SelectBookedPackage(packages))

                this.store.dispatch(new SelectBookedEvent(bookedEvents))
                return null;
              } else {
                return null;
              }
            }))
        } else if(bookedPackages.filter(p => p.name).length === bookedPackages.length) {
          let eventFromPackages = [...new Map(bookedPackages?.map(x => [x.publicEvent?.id, x.publicEvent])).values()];
          eventFromPackages.forEach(event => {
            const isExist = bookedEvents.findIndex(p => p.id === event.id);
            if(isExist == -1) {
              bookedEvents.push(event);
            }
          })
          this.store.dispatch(new SelectBookedEvent(bookedEvents));
          {
            if(storeState.PBReducer.booking?.bookingPackages?.filter(bp => !!bp.name)?.length > 0) {
              const sessions = PBUtility.convertBookingIntoPBSessionData(
                storeState.PBReducer.booking,
                storeState.PBReducer.bookedPackages
              );
              if (sessions.length > 0) {
                this.store.dispatch(new AddNewSession(sessions))
                return EMPTY;
              }
            } else {
              return EMPTY;
            }
          }
          return EMPTY;
        }
      } else {
        return EMPTY;
      }
    })
  );
  @Effect({ dispatch: false })
  updateBookedEvent$ = this.actions$.pipe(
    ofType("SELECT_BOOKED_EVENTS"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const bookedEvents = (storeState.PBReducer as IPBReducer).bookedEvents;
      if (bookedEvents.length > 0) {
        for (let i = 0; i < bookedEvents.length; i++) {
          if (bookedEvents[i].id && bookedEvents[i].eventDates.length == 0) {
            return this.eventService.getEventDates(bookedEvents[i].id).pipe(map((ed) => {
              if (ed?.length > 0) {
                ed.sort(
                  (a: any, b: any) =>
                    new Date(a.startTime).getTime() -
                    new Date(b.startTime).getTime()
                )
                bookedEvents[i].eventDates = ed;
                this.store.dispatch(new SelectBookedEvent(bookedEvents));
              }
            })
          );
          }
        }
      }
      return [action, storeState];
    })
  );
  @Effect({dispatch: false})
  selectBooking$ = this.actions$.pipe(
    ofType("SET_SELECTED_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const selectBooking = (storeState.PBReducer as IPBReducer).selectedBooking;
      const booking = (storeState.PBReducer as IPBReducer).booking;
      const isInternalUser = environment.IsInternalUser;
      const selectedInternalBookingPackageId = this._publicBookingSelectService.getVenueMapDisplayedInternalBookingPackageId();
      if(selectBooking.id && isInternalUser) {
        if(!booking || booking?.id != selectBooking.id) {
          this.bookingService.selectInternalBooking(selectBooking.id, false, false).subscribe((d) => {
            if (!d.isError && d.data) {
              let booking = d.data;
              this.store.dispatch(new AddPublicInternalBooking(d.data));
            }
          });
        }
      }
      return [action, storeState];
    })
  );
  @Effect({dispatch: false})
  addInternalBooking$ = this.actions$.pipe(
    ofType("ADD_PUBLIC_INTERNAL_BOOKING"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const booking = (storeState.PBReducer as IPBReducer).booking;
      const redirectionPoint = (storeState.PBReducer as IPBReducer).redirectionPoint;
      const isInternalUser = environment.IsInternalUser;
      const selectedbookedPackages: IBooingPackageBookedSeat[] = [];
      if (isInternalUser) {
        if (redirectionPoint === "/coprateBooking") {
          //here implement the redirection part
          this.store.dispatch(new ResetState());
          this.store.dispatch(new ReSetConfigurationState());
          this.store.dispatch(new SetRedirectionPoint(''));
          this._publicBookingSelectService.reSetValue();
          const cbRedirectionUrl = `${environment.CorporateBookingUrl}/#/booking-details/${booking.id}`;
          setTimeout(() => {window.open(cbRedirectionUrl, '_self', 'noopener,noreferrer');}, 0);
        } else {
          this._publicBookingSelectService.getVenueMapDisplayedInternalBookingPackageId().subscribe(async (value) => {
            if(!!booking && value != "") {
              if(booking.bookingPackages && booking.bookingPackages?.find(bp => bp.id == value)) {
                let request = [];
                let errors = [];
                let bookingPackage: BookingPackages;
                let bookingItems: BookingItems[];
                request.push(this.bookingService.GetBookingPackageForBooking(booking.id, value));
                request.push(this.bookingService.GetBookingItemForBookingPackage(booking.id, value));
                await new Promise((resolve) => {
                  const observable = forkJoin(request);
                  observable.subscribe((results: any[]) => {
                    results.forEach((res, i) => {
                      if(res.isError){
                        errors.push(res.message)
                      } else {
                        if(res.data instanceof Array){
                          bookingItems = res.data;
                        } else {
                          bookingPackage = res.data;
                          const selectedbookedPackage: IBooingPackageBookedSeat = {
                            id: bookingPackage?.id,
                            packageId: bookingPackage?.package?.id,
                            seat: null
                          }
                          selectedbookedPackages.push(selectedbookedPackage);
                        }
                      }
                    })
                    if(bookingItems && bookingPackage) {
                      bookingPackage.bookingItems = bookingItems;
                      this._publicBookingSelectService.setValue([bookingPackage]);
                      this.store.dispatch(new SetSelectedBookingPackage(selectedbookedPackages));
                    }
                    resolve(results)
                  })
                })
              }
            }
          });
        }
      }
      return [action, storeState];
    })
  );
  @Effect({dispatch: false})
  selectedSession$ = this.actions$.pipe(
    ofType("SELECT_SESSION"),
    withLatestFrom(this.store.source),
    switchMap(([action, storeState]) => {
      const selectedSession = (storeState.PBReducer as IPBReducer).selectedSessionTime;
      const isInternalUser = environment.IsInternalUser;
      if(!!selectedSession && isInternalUser) {
        this.store.dispatch(new LoadEventDate())
      }
      return EMPTY;
    })
  );
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private bookingService: PublicBookingService,
    private _packageService: PublicPackageService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _publicBookingSelectService: PublicBookingSelectService,
    private eventService: EventService) {

  }

  public renderBookedPackages(booking: Booking, bookedPackages: Package[]) {
    const packages: Package[] = [];
    if (booking.bookingPackages) {
      booking.bookingPackages.forEach(bp => {
        const isExist = bookedPackages.findIndex(p => p.id === bp.package.id);
        if (isExist != -1) {
          packages.push(bookedPackages[isExist])
        } else {
          const d = new Package()
          d.id = bp.package.id;
          packages.push(d)
        }
      })
    }
    this.store.dispatch(new SelectBookedPackage(packages))
  }
  public deletePackageFromBooking(booking: Booking) {
    let request = [];
    let errors = [];
    booking.bookingPackages.forEach(bp => {
      if (bp.id && (+bp.quantity == 0)) {
        request.push(this.bookingService.deleteBookingPackage(booking.id, bp.id, false));
      }
    })
    const observable = forkJoin(request);
    observable.subscribe((results: any[]) => {
      const isError = false;
      results.forEach(result => {
        if (result.body.isError) {
          errors.push(result.body.message);
        } else {
          this.store.dispatch(new AddBooking(result.body.data));
        }
      })
      if (errors.length > 0) {

      }
    });
  }
  private mapPackagesIntoSessions(
    sessions: IDateAndSession[],
    bookingPackages: BookingPackages[]
  ) {
    bookingPackages.forEach((bPack) => {
      const findPackage = (x: IPublicPackage, i) =>
        x.isHaveLinkPackage && x.linkPackages.length > 0
          ? !!x.linkPackages.find((p) => p.id === bPack.package.id)
          : x.id === bPack.package.id;
      let sessionIndex = sessions.findIndex(
        (ses) =>
          Utility.convertToISO(Utility.convertISOToDate(ses.startTime)) ===
          bPack.startDate &&
          Utility.convertToISO(Utility.convertISOToDate(ses.endTime)) ===
          bPack.endDate &&
          !!ses.packages.find(findPackage)
      );
      if (sessionIndex !== -1) {
        const bookedItems = bPack.bookingItems.filter((x) => x.upsell);
        const packIndex = sessions[sessionIndex].packages.findIndex(findPackage);
        if (packIndex !== -1) {
          if (sessions[sessionIndex].packages[packIndex].isHaveLinkPackage) {
            sessions[sessionIndex].packages[packIndex].linkPackages.forEach(
              (lp) => {
                if (lp.id === bPack.package.id) {
                  lp.quantity = +bPack.quantity;
                }
              }
            );
          } else if (
            sessions[sessionIndex].packages[packIndex].id === bPack.package.id
          ) {
            sessions[sessionIndex].packages[packIndex].quantity =
              +bPack.quantity;
          }
          if (bookedItems.length === 0) {
            sessions[sessionIndex].packages[packIndex].addOns = []
          } else {
            bookedItems.forEach((x) => {
              if (x && x.item) {
                const item: any = _.cloneDeep(x.item);
                item.qty = x.quantity;
                if (!sessions[sessionIndex].packages[packIndex].addOns) {
                  sessions[sessionIndex].packages[packIndex].addOns = [x.item];
                } else {
                  const isEx = sessions[sessionIndex].packages[
                    packIndex
                  ].addOns.findIndex((i) => i.id === item.id);
                  if (isEx !== -1) {
                    sessions[sessionIndex].packages[packIndex].addOns[isEx] =
                      item;
                  } else {
                    sessions[sessionIndex].packages[packIndex].addOns.push(item);
                  }
                }
              }
            });
          }
        }
        if (!sessions[sessionIndex].bookingPackages) {
          sessions[sessionIndex].bookingPackages = [];
        }
        const bPackage = sessions[sessionIndex].bookingPackages.find(
          (b) => b.id === bPack.id && b.packageId === bPack.package.id
        );
        if (!bPackage) {
          const bp: IBooingPackage = {
            id: bPack.id,
            packageId: bPack.package.id,
          };
          sessions[sessionIndex].bookingPackages.push(bp);
        }
      }
    });
    if (!bookingPackages || bookingPackages.length === 0) {
      sessions.forEach((s) => {
        s.packages = [];
      });
      sessions = [];
    }
    this.store.dispatch(new SelectPackageSessions(sessions));
  }
  private setBookingAfterPatchPreview(booking, bPackages) {
    let bookingPackages = [];
    booking?.bookingPackages.forEach(bpack => {
      if (+bpack.id < 0) {
        bPackages.push(bpack);
      } else if (+bpack.id > 0) {
        let packIndex = bPackages.findIndex(bp => bp.id == bpack.id);
        if (packIndex != -1) {
          if (bPackages[packIndex].attendees != bpack.attendees || bPackages[packIndex].totalAmount != bpack.totalAmount) {
            bPackages[packIndex] = bpack;
          }
        }
      }
    })
    this._publicBookingSelectService.setValue(bPackages);

    this.store.dispatch(new ResetAllPackageQuantity());
    this.store.dispatch(new ResetSelectedPackageStatus());
    this.store.dispatch(new AddBooking(booking));
  }
  // private generateBookingPatchBody(PBReducer, publicCart, cart) {
  //   const newBooking = new Booking();
  //   const bookingItemTypeNames = [];
  //   const repeatPolicies = (
  //     environment.PublicBookingSetting as PublicBookingSettings
  //   ).repeatPolicy;
  //   newBooking.id = PBReducer.booking.id;
  //   // Create Booking Package for Post And Patch
  //   if (publicCart && publicCart.publicPackages) {
  //     newBooking.bookingPackages = [];
  //     PBReducer.selectedSessions.forEach((session: IDateAndSession) => {
  //       if (!!session.packages) {
  //         const sessionStart = Utility.convertToISO(
  //           Utility.convertISOToDate(session.startTime)
  //         );
  //         const sessionEnd = Utility.convertToISO(
  //           Utility.convertISOToDate(session.endTime)
  //         );
  //         session.packages.forEach((pk) => {
  //           if (!pk.isBooked) {
  //             if (
  //               pk.isHaveLinkPackage &&
  //               pk.linkPackages &&
  //               pk.linkPackages.length > 0
  //             ) {
  //               const selectedLinkPk = pk.linkPackages.filter(
  //                 (p) => !pk.isBooked
  //               );
  //               selectedLinkPk.forEach((lpk) => {
  //                 newBooking.bookingPackages = this.generateBookingPackage(
  //                   lpk,
  //                   session,
  //                   newBooking.bookingPackages,
  //                   PBReducer.booking.bookingPackages.filter(
  //                     (apk) => apk.package.id === lpk.id
  //                   )
  //                 );
  //               });
  //               // }
  //             } else {
  //               newBooking.bookingPackages = this.generateBookingPackage(
  //                 pk,
  //                 session,
  //                 newBooking.bookingPackages,
  //                 PBReducer.booking.bookingPackages.filter(
  //                   (apk) => apk.package.id === pk.id
  //                 )
  //               );
  //             }
  //             pk.isBooked = true;
  //           }
  //           if (!!pk.addOns) {
  //             pk.addOns.forEach((item) => {
  //               if (item.isNeedToBeUpdate) {
  //                 let packageID = pk.id;
  //                 let bookingPackage: BookingPackages;
  //                 let durationDates = [];
  //                 if (
  //                   session.durationRange &&
  //                   session.durationRange.packageDates
  //                 ) {
  //                   durationDates = _.cloneDeep(
  //                     session.durationRange.packageDates
  //                   );
  //                   durationDates.sort((x, y) =>
  //                     x.eventDate.date > y.eventDate.date ? 1 : 0
  //                   );
  //                 }
  //                 if (PBReducer.booking.bookingPackages) {
  //                   const selectedPackage = _.cloneDeep(pk);
  //                   // * if it have linked package
  //                   if (
  //                     selectedPackage &&
  //                     selectedPackage.isHaveLinkPackage &&
  //                     selectedPackage.linkPackages &&
  //                     selectedPackage.linkPackages.length > 0
  //                   ) {
  //                     const select = selectedPackage.linkPackages.filter(
  //                       (x) =>
  //                         !!PBReducer.booking.bookingPackages.find(
  //                           (p) => p.package.id === x.id
  //                         ) && x.quantity > 0
  //                     );
  //                     if (select.length > 0) {
  //                       const val = select.sort(
  //                         (a, b) =>
  //                           parseInt(a.linkedPackageType.id, 10) -
  //                           parseInt(b.linkedPackageType.id, 10)
  //                       );
  //                       packageID = val[0].id;
  //                     }
  //                   }
  //                 }
  //                 const bookingPK =
  //                   PBReducer.booking.bookingPackages.find(
  //                     (bp) =>
  //                       bp.package.id === packageID &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.startDate)
  //                       ) === sessionStart &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.endDate)
  //                       ) === sessionEnd
  //                   );
  //                 // *Create Booking Package For Booking Item
  //                 if (bookingPK) {
  //                   bookingPackage = new BookingPackages();
  //                   bookingPackage.id = bookingPK.id;
  //                   bookingPackage.startDate = bookingPK.startDate;
  //                   bookingPackage.endDate = bookingPK.endDate;
  //                   // if (!!bookingPK.quantity && bookingPK.quantity !== '0') {
  //                   //   bookingPackage.quantity = bookingPK.quantity;
  //                   // }
  //                   bookingPackage.package = new Package();
  //                   bookingPackage.package.id = packageID;
  //                   bookingPackage.bookingItems = bookingPK.bookingItems
  //                     .filter((i) => i !== null && i.upsell && i.item)
  //                     .map((i) => {
  //                       const bookingItem = new BookingItems();
  //                       bookingItem.id = i.id;
  //                       bookingItem.type = i.type;
  //                       bookingItem.item.id = i.item.id;
  //                       bookingItem.item.type = i.item.type;
  //                       bookingItem.quantity = i.quantity;
  //                       return bookingItem;
  //                     });
  //                   if (newBooking.bookingPackages === undefined) {
  //                     newBooking.bookingPackages = [bookingPackage];
  //                   } else {
  //                     const bpIndex = newBooking.bookingPackages.findIndex(
  //                       (bp) =>
  //                         bp.package.id === packageID &&
  //                         Utility.convertToISO(
  //                           Utility.convertISOToDate(bp.startDate)
  //                         ) === sessionStart &&
  //                         Utility.convertToISO(
  //                           Utility.convertISOToDate(bp.endDate)
  //                         ) === sessionEnd
  //                     );
  //                     if (bpIndex !== -1) {
  //                       newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                     } else {
  //                       newBooking.bookingPackages.push(bookingPackage);
  //                     }
  //                   }
  //                   bookingPackage = newBooking.bookingPackages.find(
  //                     (bp) =>
  //                       bp.package.id === packageID &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.startDate)
  //                       ) === sessionStart &&
  //                       Utility.convertToISO(
  //                         Utility.convertISOToDate(bp.endDate)
  //                       ) === sessionEnd
  //                   );
  //                 }
  //                 if (bookingPackage) {
  //                   bookingPackage.bookingItems =
  //                     bookingPackage.bookingItems === undefined
  //                       ? []
  //                       : bookingPackage.bookingItems;
  //                   let bookingItem = bookingPackage.bookingItems.find(
  //                     (i) => i.item.id === item.id
  //                   );
  //                   bookingPackage.bookingItems = []; // Temp by Thuveepan
  //                   if (bookingItem === undefined) {
  //                     bookingItem = new BookingItems();
  //                     bookingItem.quantity = 0;
  //                     let biCount = 0;
  //                     newBooking.bookingPackages.forEach(
  //                       (b) =>
  //                         !!b.bookingItems && (biCount += b.bookingItems.length)
  //                     );
  //                     bookingItem.id = ((biCount + 1) * -1).toString();
  //                   }
  //                   var tp =
  //                     this.getItemPrefix(item.type.toLowerCase()) + item.type;
  //                   if (item.type.toLowerCase() == "assetitem") {
  //                     tp = "PublicBookingGeneralAdmissionItem";
  //                   }
  //                   bookingItemTypeNames.push(tp);
  //                   bookingItem.type = tp;
  //                   bookingItem.quantity = item.qty;
  //                   bookingItem.name = item.name;
  //                   bookingItem.upsell = true;
  //                   bookingItem.item.id = item.id;
  //                   bookingItem.item.type = item.type;
  //                   if (durationDates.length > 0) {
  //                     switch (item.repeatPolicy) {
  //                       case repeatPolicies.startDateOnly:
  //                         bookingItem.startTime =
  //                           durationDates[0].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[0].eventDate.endTime;
  //                         break;
  //                       case repeatPolicies.endDateOnly:
  //                         bookingItem.startTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.endTime;
  //                         break;
  //                       case repeatPolicies.repeatAllDays:
  //                         bookingItem.startTime = sessionStart;
  //                         bookingItem.endTime = sessionEnd;
  //                         break;
  //                       case repeatPolicies.allowIndividualDays:
  //                         // need to do
  //                         break;
  //                       default:
  //                         bookingItem.startTime =
  //                           durationDates[0].eventDate.startTime;
  //                         bookingItem.endTime =
  //                           durationDates[
  //                             durationDates.length - 1
  //                           ].eventDate.endTime;
  //                         break;
  //                     }
  //                   } else {
  //                     bookingItem.startTime = sessionStart;
  //                     bookingItem.endTime = sessionEnd;
  //                   }

  //                   const itemIndex = bookingPackage.bookingItems.findIndex(
  //                     (it) => it.id === bookingItem.id
  //                   );
  //                   if (itemIndex !== -1) {
  //                     bookingPackage.bookingItems[itemIndex] =
  //                       _.cloneDeep(bookingItem);
  //                   } else {
  //                     bookingPackage.bookingItems.push(
  //                       _.cloneDeep(bookingItem)
  //                     );
  //                   }
  //                   const ind = newBooking.bookingPackages.findIndex(
  //                     (b) => b.id === bookingPackage.id
  //                   );
  //                   if (ind !== -1) {
  //                     newBooking.bookingPackages[ind] = bookingPackage;
  //                   } else {
  //                     newBooking.bookingPackages.push(bookingPackage);
  //                   }
  //                   // });
  //                 } else {
  //                   this.openSnackBarError([
  //                     "Booking Package is not Available for this package",
  //                   ]);
  //                 }
  //                 // item.isNeedToBeUpdate = false;
  //               }
  //             });
  //           }
  //         });
  //         // Only work for Multi day package with upsell item repeat policy for individual date's
  //         // !!make sure validate that
  //         if (session.durationRange && session.durationRange.packageDates && session.durationRange.packageDates.length > 0) {
  //           session.durationRange.packageDates.forEach(packageDate => {
  //             const itemSessionStart = Utility.convertToISO(
  //               Utility.convertISOToDate(packageDate.eventDate.startTime)
  //             );
  //             const itemSessionEnd = Utility.convertToISO(
  //               Utility.convertISOToDate(packageDate.eventDate.endTime)
  //             );
  //             if (packageDate && packageDate.items && packageDate.items.length > 0) {
  //               const UPDATED_ITEMS = packageDate.items.filter(x => x.item.isNeedToBeUpdate && x.item.qty > 0);
  //               if (UPDATED_ITEMS && UPDATED_ITEMS.length > 0) {
  //                 UPDATED_ITEMS.forEach(d => {
  //                   let packageID = d.packageID.toString();
  //                   let bookingPackage: BookingPackages;
  //                   const item = d.item;
  //                   const selectedPackage = _.cloneDeep(session.packages.find(x => x.id == packageID));
  //                   if (selectedPackage) {
  //                     if (PBReducer.booking.bookingPackages) {
  //                       // * if it have linked package
  //                       if (
  //                         selectedPackage &&
  //                         selectedPackage.isHaveLinkPackage &&
  //                         selectedPackage.linkPackages &&
  //                         selectedPackage.linkPackages.length > 0
  //                       ) {
  //                         const select = selectedPackage.linkPackages.filter(
  //                           (x) =>
  //                             !!PBReducer.booking.bookingPackages.find(
  //                               (p) => p.package.id === x.id
  //                             ) && x.quantity > 0
  //                         );
  //                         if (select.length > 0) {
  //                           const val = select.sort(
  //                             (a, b) =>
  //                               parseInt(a.linkedPackageType.id, 10) -
  //                               parseInt(b.linkedPackageType.id, 10)
  //                           );
  //                           packageID = val[0].id;
  //                         }
  //                       }
  //                       // find a booking package for Up sell
  //                       const previousBookingPackage =
  //                         PBReducer.booking.bookingPackages.find(
  //                           (bp) => {
  //                             const s =  Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) ;
  //                             const e =  Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             )
  //                             return bp.package.id === packageID.toString() &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) === sessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             ) === sessionEnd
  //                           }

  //                         );
  //                       if (previousBookingPackage) {
  //                         bookingPackage = new BookingPackages();
  //                         bookingPackage.id = previousBookingPackage.id;
  //                         bookingPackage.startDate = previousBookingPackage.startDate;
  //                         bookingPackage.endDate = previousBookingPackage.endDate;
  //                         bookingPackage.package = new Package();
  //                         bookingPackage.package.id = packageID;
  //                         bookingPackage.bookingItems = previousBookingPackage.bookingItems
  //                           .filter((i) => i !== null && i.upsell && i.item)
  //                           .map((i) => {
  //                             const bookingItem = new BookingItems();
  //                             bookingItem.id = i.id;
  //                             bookingItem.type = i.type;
  //                             bookingItem.item.id = i.item.id;
  //                             bookingItem.item.type = i.item.type;
  //                             bookingItem.quantity = i.quantity;
  //                             bookingItem.startTime = Utility.convertToISO(
  //                               Utility.convertISOToDate(i.startTime)
  //                             );
  //                             bookingItem.endTime = Utility.convertToISO(
  //                               Utility.convertISOToDate(i.endTime)
  //                             );;
  //                             return bookingItem;
  //                           });
  //                         console.log("bookingPackage", bookingPackage);
  //                         if (newBooking.bookingPackages === undefined || newBooking.bookingPackages.length == 0) {
  //                           newBooking.bookingPackages = [bookingPackage];
  //                         } else {
  //                           const bpIndex = newBooking.bookingPackages.findIndex(
  //                             (bp) =>
  //                               bp.package.id === packageID &&
  //                               Utility.convertToISO(
  //                                 Utility.convertISOToDate(bp.startDate)
  //                               ) === sessionStart &&
  //                               Utility.convertToISO(
  //                                 Utility.convertISOToDate(bp.endDate)
  //                               ) === sessionEnd
  //                           );
  //                           if (bpIndex !== -1) {
  //                             if (!newBooking.bookingPackages[bpIndex].bookingItems) {
  //                               //   let bookingItemIndex = newBooking.bookingPackages[bpIndex].bookingItems.findIndex(
  //                               //     (i) => i.item.id === item.id &&
  //                               //       Utility.convertToISO(
  //                               //         Utility.convertISOToDate(i.startTime)
  //                               //       ) === itemSessionStart &&
  //                               //       Utility.convertToISO(
  //                               //         Utility.convertISOToDate(i.endTime)
  //                               //       ) === itemSessionEnd
  //                               //   );
  //                               //   if (bookingItemIndex == -1) {
  //                               //     newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                               //   }
  //                               // } else {
  //                               newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
  //                             }
  //                           } else {
  //                             newBooking.bookingPackages.push(bookingPackage);
  //                           }
  //                         }
  //                         bookingPackage = newBooking.bookingPackages.find(
  //                           (bp) =>
  //                             bp.package.id === packageID &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.startDate)
  //                             ) === sessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(bp.endDate)
  //                             ) === sessionEnd
  //                         );
  //                       }
  //                       if (bookingPackage) {
  //                         bookingPackage.bookingItems =
  //                           bookingPackage.bookingItems === undefined
  //                             ? []
  //                             : bookingPackage.bookingItems;
  //                         let bookingItem = bookingPackage.bookingItems.find(
  //                           (i) => i.item.id === item.id &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(i.startTime)
  //                             ) === itemSessionStart &&
  //                             Utility.convertToISO(
  //                               Utility.convertISOToDate(i.endTime)
  //                             ) === itemSessionEnd
  //                         );
  //                         // bookingPackage.bookingItems = []; // Temp by Thuveepan
  //                         if (bookingItem === undefined) {
  //                           bookingItem = new BookingItems();
  //                           bookingItem.quantity = 0;
  //                           let biCount = 0;
  //                           newBooking.bookingPackages.forEach(
  //                             (b) =>
  //                               !!b.bookingItems && (biCount += b.bookingItems.length)
  //                           );
  //                           bookingItem.id = ((biCount + 1) * -1).toString();
  //                         }
  //                         var tp =
  //                           this.getItemPrefix(item.type.toLowerCase()) + item.type;
  //                         if (item.type.toLowerCase() == "assetitem") {
  //                           tp = "PublicBookingGeneralAdmissionItem";
  //                         }
  //                         bookingItemTypeNames.push(tp);
  //                         bookingItem.type = tp;
  //                         bookingItem.quantity = item.qty;
  //                         bookingItem.name = item.name;
  //                         bookingItem.upsell = true;
  //                         bookingItem.item.id = item.id;
  //                         bookingItem.item.type = item.type;
  //                         bookingItem.startTime = itemSessionStart;
  //                         bookingItem.endTime = itemSessionEnd;

  //                         const itemIndex = bookingPackage.bookingItems.findIndex(
  //                           (it) => it.id === bookingItem.id
  //                         );
  //                         if (itemIndex !== -1) {
  //                           bookingPackage.bookingItems[itemIndex] =
  //                             _.cloneDeep(bookingItem);
  //                         } else {
  //                           bookingPackage.bookingItems.push(
  //                             _.cloneDeep(bookingItem)
  //                           );
  //                         }
  //                         const ind = newBooking.bookingPackages.findIndex(
  //                           (b) => b.id === bookingPackage.id
  //                         );
  //                         if (ind !== -1) {
  //                           newBooking.bookingPackages[ind] = bookingPackage;
  //                         } else {
  //                           newBooking.bookingPackages.push(bookingPackage);
  //                         }
  //                         // });
  //                       } else {
  //                         this.openSnackBarError([
  //                           "Booking Package is not Available for this package: " + selectedPackage.id,
  //                         ]);
  //                       }
  //                     }
  //                   } else {
  //                     console.error(`Package not selected for Individual day upsell: ${item.name} Date: ${packageDate.eventDate.startTime}`);
  //                   }
  //                 })
  //               }
  //             }
  //           })
  //         }
  //       }
  //     });
  //   }
  //   if (
  //     !!PBReducer.selectedClient &&
  //     !!PBReducer.selectedClient.contactId
  //   ) {
  //     newBooking.contact = new BookingContact();
  //     newBooking.contact.id = PBReducer.selectedClient.contactId;
  //   } else if (
  //     cart.contact.id &&
  //     cart.contact.id !== PBReducer.booking.contact.id &&
  //     PBReducer.booking.contact.id === (
  //       environment.PublicBookingSetting as PublicBookingSettings
  //     ).defaultClient
  //   ) {
  //     newBooking.contact = new BookingContact();
  //     newBooking.contact.id = cart.contact.id;
  //   }
  //   if (!!newBooking.bookingPackages && newBooking.bookingPackages.length == 0)
  //     newBooking.bookingPackages = undefined;
  //   else {
  //     let removeIDs = [];
  //     newBooking.bookingPackages.forEach((bp) => {
  //       const id = parseInt(bp.id, 10);
  //       if (id > 0) {
  //         bp.startDate = undefined;
  //         bp.endDate = undefined;
  //       }
  //       if (id < 0 && +bp.quantity === 0) {
  //         removeIDs.push(bp.id);
  //       }
  //     });
  //     removeIDs.forEach((id) => {
  //       let index = newBooking.bookingPackages.findIndex((x) => x.id === id);
  //       if (index !== -1) {
  //         newBooking.bookingPackages.splice(index, 1);
  //       }
  //     });
  //   }

  //   return { newBooking, bookingItemTypeNames };
  // }
  private bookingError(errors: any[]) {
    if (errors.length > 0) {
      let e: string[] = [];
      console.table(errors);
      errors.forEach((ex) => {
        e.push(ex.detail);
      });
      console.table("booking Errors", e);
      this.openSnackBarError(e);
    }
  }
  // private generateBookingPackage(
  //   pk: any,
  //   session: IDateAndSession,
  //   bookingPackages: any[],
  //   bookedPackage = []
  // ) {
  //   const bkpackages = new BookingPackages();
  //   bkpackages.bookingItems = undefined;
  //   bkpackages.attendees = pk.quantity;
  //   bkpackages.quantity = pk.quantity.toString();
  //   bkpackages.startDate = Utility.convertToISO(
  //     Utility.convertISOToDate(session.startTime)
  //   );
  //   bkpackages.endDate = Utility.convertToISO(
  //     Utility.convertISOToDate(session.endTime)
  //   );
  //   bkpackages.package = new Package();
  //   bkpackages.package.id = pk.id;
  //   if (bookedPackage.length > 0) {
  //     for (const apk of bookedPackage) {
  //       const st = Utility.convertToISO(
  //         Utility.convertISOToDate(apk.startDate)
  //       );
  //       const et = Utility.convertToISO(Utility.convertISOToDate(apk.endDate));
  //       if (bkpackages.startDate === st && bkpackages.endDate === et) {
  //         bkpackages.id = apk.id;
  //         // bkpackages.attendees = +bkpackages.attendees + +apk.attendees;
  //         // bkpackages.quantity = (
  //         //   +bkpackages.quantity + +apk.quantity
  //         // ).toString();
  //         break;
  //       } else {
  //         bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
  //         continue;
  //       }
  //     }
  //   } else {
  //     bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
  //   }
  //   bookingPackages.push(bkpackages);
  //   return bookingPackages;
  // }
  mapErrorMassage(errors: any[]) {
    const errorMassage = [];
    errors.forEach((e) => {
      if (e.detail) errorMassage.push(e.detail);
    });
    return errorMassage;
  }
  private openSnackBarError(message) {
    const error = new ErrorLog();
    error.message = message.toString();
    error.url = "";
    // this.errorService
    //   .insertError(error)
    //   .subscribe((mess) => console.log('Error Ers ', mess));
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }
}

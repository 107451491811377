import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FooterLayout } from '../../models/footerLayout.model';
import { Theme } from '../../models/globalTheme';
import { ILayout } from '../../models/interfaces';
import * as d3 from 'd3';
import { HEIGHT_SIZE, LAYOUT_TYPE } from '../../models/enums';
import { ThemeService } from '../../../../../services/public-web/theme.service';
import { FooterLocationLayout } from '../../models/footerLocationLayout.model';
import { LocationLayoutComponent } from '../location-layout/location-layout.component';
import { SocialMediaLayoutComponent } from '../social-media-layout/social-media-layout.component';
import { SocialMediaLayout } from '../../models/socialMediaLayout.model';
import { LogoLayout } from '../../models/logoLayout.model';
import { LogoLayoutComponent } from '../logo-layout/logo-layout.component';
import { ContactLayoutComponent } from '../contact-layout/contact-layout.component';
import { ContactLayout } from '../../models/contactLayout.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss']
})
export class FooterLayoutComponent implements OnInit, ILayout, AfterViewInit {
  @ViewChild('FooterContainerRef', { read: ViewContainerRef })
  VCR: ViewContainerRef;
  @Input() layoutAttribute: FooterLayout;
  @Input() unique_key: any;
  @Input() isFitImage: boolean;
  @Input() elementID = 'demo';
  @Input() type: string;

  parentRef: any;
  theme: Theme;
  isView: boolean;
  categoryCardRrs = [];

  private selectedLayout;
  private selectedType;

  constructor(
    private themeService: ThemeService,
    private CFR: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.themeService.theme$.subscribe((theme) => {
      this.theme = theme;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
      this.layoutAttribute.footerTemplateLayouts.forEach((layout) => {
        this.selectedLayout = undefined;
        this.selectedType = undefined;
        this.selectComponentForCreate(layout);
        if (this.selectedType && this.selectedLayout) {
          this.createComponent(layout);
        }
      });
    });
  }


  initializeAttributes() {
    d3.select('.footer-container-public-web').style('height', this.layoutAttribute.height + 'px');
    switch (this.layoutAttribute.sizeType) {

      case HEIGHT_SIZE.FitToContent:
        const footerHeight = (this.layoutAttribute.imageHieght / this.layoutAttribute.imageWidth) * document.body.offsetWidth;

        d3.select('.footer-container-public-web')
          .style('background-size', 'cover')
          .style('height', footerHeight + 'px');
        break;

      case HEIGHT_SIZE.SpecifySize:
        if (this.layoutAttribute.isFitImage) {
          d3.select('.footer-container-public-web')
            .style('height', this.layoutAttribute.height + 'px')
            .style('background-size', 'contain')
            .style('background-position', 'center')
            .style('background-repeat', 'no-repeat');
        } else {
          d3.select('.footer-container-public-web')
            .style('height', this.layoutAttribute.height + 'px')
            .style('background-size', 'cover')
            .style('background-position', 'center');
        }
        break;
    }
  }

  private selectComponentForCreate(layout: any) {
    switch (true) {
      case layout instanceof FooterLocationLayout:
        this.selectedType = LAYOUT_TYPE.LOCATION;
        this.selectedLayout = LocationLayoutComponent;
        break;
      case layout instanceof LogoLayout:
        this.selectedType = LAYOUT_TYPE.LOGO;
        this.selectedLayout = LogoLayoutComponent;
        break;
      case layout instanceof SocialMediaLayout:
        this.selectedType = LAYOUT_TYPE.SOCIAL_MEDIA;
        this.selectedLayout = SocialMediaLayoutComponent;
        break;
      case layout instanceof ContactLayout:
        this.selectedType = LAYOUT_TYPE.CONTACT;
        this.selectedLayout = ContactLayoutComponent;
        break;
      default:
        break;
    }
  }

  createComponent(layout) {
    const componentFactory = this.CFR.resolveComponentFactory(
      this.selectedLayout
    );
    const childComponentRef = this.VCR.createComponent(componentFactory);
    const childComponent: any = childComponentRef.instance;
    childComponent.unique_key = layout.componentKey;
    childComponent.isView = true;
    childComponent.type = this.selectedType;
    childComponent.layoutAttribute = layout;
    childComponent.elementID =
      'view_' + childComponent.type + '_' + childComponent.unique_key;
  }
}

 <div class="input-layout text-center" [id]="elementID">
<ng-container [ngSwitch]="layoutAttribute.type" *ngIf="canShowInput">
  <div class="containerSelect" v *ngSwitchCase="INPUT_TYPE.NUMBER">
    <div class="quantity-controls" style="margin-top:unset !important; padding: 0;" data-quantity="">
      <button class="quantity-btn" data-quantity-minus="" (click)="decrement()" ><svg viewBox="0 0 409.6 409.6" >
          <g>
            <g>
              <path fill="white" d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467 c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z" />
            </g>
          </g>
        </svg></button>
      <input type="number" class="quantity-input" data-quantity-target="" value="1" step="1" [(ngModel)] ='numberInputValue' min="0" max="10" >
      <button style="border-radius: 0px 3px 3px 0px !important;" class="quantity-btn" (click)="increment()" data-quantity-plus=""><svg viewBox="0 0 426.66667 426.66667">
          <path fill="white" d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" /></svg>
      </button>
    </div>
  </div>
  <div *ngSwitchCase="INPUT_TYPE.TEXT">
    
  </div>
</ng-container>
</div>


<!-- <div class="input-layout text-center" [id]="elementID">
  <ng-container [ngSwitch]="layoutAttribute.type">
    <span>{{layoutAttribute.label}}</span>
    <div *ngSwitchCase="INPUT_TYPE.NUMBER" class="number-input">
      <span class="input-number-decrement" (click)="decrement()">–</span
      ><input
        class="input-number"
        type="text"
        value="1"
        min="0"
        max="10"
        [(ngModel)] ='numberInputValue'
      /><span class="input-number-increment" (click)="increment()">+</span>
    </div>
    <div *ngSwitchCase="INPUT_TYPE.TEXT">dd</div>
  </ng-container>
</div> -->

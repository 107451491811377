<div class="nav-bar-layout" [ngClass]="{ 'force-display': isScroll }">
  <nav class="container p-0" style="margin-top: -30px !important;">
    <ul class="nav">
      <div class="col-12 header__alter-menu">
        <div class="d--flex col-md-4 col-lg-4 align--center sub_left">
          <!-- <div class="user__home pw-ico-color__tertiary border__r--3 d--flex" (click)="returnHome()" > -->
          <div *ngIf="getMenuVisibility('Home')" class="user__home pw-ico-color__tertiary border__r--3 d--flex"
            (click)="returnHome()" data-toggle="modal" >
            <i class="material-icons"> home </i>
          </div>
          <div *ngIf="isPackageListPage && isPackageListRoute" class="px-3 pw-txt-b2 pw-text-color__quaternary" (click)="viewAllEvents()">
            {{'NAVBAR.All Events' | optimotranslate}}
          </div>
          <div
            *ngIf="(cart$ | async)?.contact && (cart$ | async)?.contact.client && (cart$ | async)?.contact.client.clientTypeId === 2 && isShowClientActionsButtons"
            class="user__home pw-ico-color__tertiary border__r--3 d--flex" (click)="openSearchClient()">
            <i class="material-icons"> person_search </i>
          </div>
          <div class="user__home pw-ico-color__tertiary border__r--3 d--flex"
            *ngIf="(cart$ | async)?.contact && (cart$ | async)?.contact.client  && (cart$ | async)?.contact.client.clientTypeId === 2 && isShowClientActionsButtons"
            (click)="openAddClient()">
            <i class="material-icons"> person_add_alt_1 </i>
          </div>
          <span class="px-1 pw-txt-b3 pw-text-color__gray" *ngIf="!!selectedClient">{{ selectedClient.name }}</span>
        </div>
        <div class="d--flex col-md-8 col-lg-8 align--center sub">
          <div class="nav-user-controls align-items-center d-flex ml-auto">
            <!--Start- language-selector -->
            <opt-language-selector></opt-language-selector>
            <!--End- language-selector -->
            <div class="mmc_flex__item--vcenter">
              <div class="user__avatar border--circle d--flex ml-auto" [style.background-image]="'url(' + imgUrl + ')'">
                <!-- <img class="account__avatar__url  margin--auto" src="{{ ( cart$|async)?.contact.profilePicture ? ( cart$|async)?.contact.profilePicture: 'dist/assets/images/user-icon.png'}} " alt="" /> -->
              </div>
            </div>

          <div class="mmc_flex__item--vcenter sign-p">
            <a class="px-3 pw-txt-b2 pw-text-color__quaternary" (click)="gotoClientLogin()"
              [ngClass]="isLoggedIn && !(getMenuVisibility('MyProfile')) ? '' : 'link-primary'"
              [class.name-available]="(cart$ | async)?.contact.firstName">
              {{
              (cart$ | async)?.contact.firstName
              ? (cart$ | async)?.contact.firstName
              : ('NAVBAR.Sign In' | optimotranslate)}}
            </a>
            <!--<a routerLink="/client/login" class="user__login font font__regular">Login</a>-->
            <i mat-button [matMenuTriggerFor]="logout" class="material-icons btn-click pw-ico-color__tertiary pr-3"
              *ngIf="(cart$ | async)?.contact.firstName">
              menu
            </i>
            <mat-menu #logout="matMenu">
             <button mat-menu-item (click)="gotoClientLogin()" *ngIf="getMenuVisibility('MyProfile')">{{(getMenuName('MyProfile' ,'My Profile') | optimotranslate)}}</button>
              <button mat-menu-item (click)="changePassword()" *ngIf="getMenuVisibility('ChangePassword')">{{(getMenuName('ChangePassword' , 'Change Password') | optimotranslate)}}</button>
              <button mat-menu-item (click)="myBooking()" data-toggle="modal" data-target="#conformationModal">{{(getMenuName('MyBookings','My Bookings') | optimotranslate)}}</button>
              <button mat-menu-item (click)="signOut()" data-toggle="modal" data-target="#conformationModal">{{(getMenuName('SignOut', 'Sign Out') | optimotranslate)}}</button>
            </mat-menu>
          </div>
          <div class="mmc_flex__item user__left d--flex align--center">
            <div class="user__account pw-txt-b2 pw-text-color__tertiary d--flex align--center pl-0">
              <div *ngIf="!bookingSession.timerPaused">
              <div *ngIf=" bookingSession.isSessionTimerStart" class="nav-session" style="width: 152px;
            text-align: center;" >
                <div class="row session-resp" style="margin-right: 0px;
                margin-left: 0px;
                border-right: 1px solid #b7b7b7;">
                  <div class="col-12" style="font-size: 10px;">{{"NAVBAR.Session Expires in"| optimotranslate}}</div>
                  <div class="col-12 text-danger" style="font-weight: 900;font-size: 17px;">{{bookingSession.minutes | number:'1.0-0'}} : {{bookingSession.seconds | number:'2.0-0'}}</div>
                </div>
              </div>
            </div>
              <!-- <div class="nav-session" *ngIf="bookingSession.timerPaused && paymentSession.isSessionTimerStart" style="width: 152px;
              text-align: center;">
                  <div class="row session-resp" style="margin-right: 0px;
                  margin-left: 0px;
                  border-right: 1px solid #b7b7b7;">
                    <div class="col-12" style="font-size: 10px;">{{"NAVBAR.Session Expires in"| optimotranslate}}</div>
                    <div class="col-12 text-danger" style="font-weight: 900;font-size: 17px;">{{paymentSession.minutes | number:'1.0-0'}} : {{paymentSession.seconds | number:'2.0-0'}}</div>
                  </div>
                </div> -->
              <div class="user__account__text pw-txt-b2 pw-text-color__tertiary pr-0 px-3" (click)="cartClick()">
                {{ maxAmount  | PBCurrencyPipe }}
              </div>

                <div class="user__cart border__r--3 d--flex" (click)="cartClick()">
                  <i class="material-icons pw-ico-color"> shopping_cart </i>
                  <!-- img src="dist/assets/images/shopping-cart.svg" alt="" class="user__cart__icon margin--auto" /> -->
                  <div class="user__counter border--circle circle d--flex">
                    <div class="counter__number margin--auto">
                      <!-- {{ totalCount + packagestotalCount  }} -->
                      {{ bookingPackages | PBCustomItemQuantityPipe : (isBooking && isPendingChanges)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </nav>
</div>

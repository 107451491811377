import { LayoutObject } from './layoutObject.model';

// DONE
export class SearchBoxLayout extends LayoutObject {
  searchPanelData: SearchPanel

  constructor(data) {
    super(data);
    this.searchPanelData = new SearchPanel(JSON.parse(this.content))
  }
}

export class SearchPanel {
  IsEventSearch = false;
  IsVenueSearch = false;
  IsDate = false;
  IsAddOnSearch = false;
  IsAddOn = false;
  IsVeuneSelect = false;
  IsDays = false;
  IsAccess = false;
  IsAccommodation = false;
  IsParkSearch = false;
  IsRegionSearch = false;
  IsAvailability = false;
  IsEventGroup = false;
  IsPriceRange = false;
  IsAgeGroup = false;
  IsSalesCategory = false;
  IsBusinessArea = false;
  constructor(data) {
    let jsonParseData = JSON.parse(data)
    this.IsEventSearch = jsonParseData.IsEventSearch;
    this.IsVenueSearch = jsonParseData.IsVenueSearch;
    this.IsDate = jsonParseData.IsDate;
    this.IsAddOnSearch = jsonParseData.IsAddOnSearch;
    this.IsAddOn = jsonParseData.IsAddOn;
    this.IsVeuneSelect = jsonParseData.IsVeuneSelect;
    this.IsDays = jsonParseData.IsDays;
    this.IsAccess = jsonParseData.IsAccess;
    this.IsAccommodation = jsonParseData.IsAccommodation;
    this.IsParkSearch = jsonParseData.IsParkSearch;
    this.IsRegionSearch = jsonParseData.IsRegionSearch;
    this.IsAvailability = jsonParseData.IsAvailability;
    this.IsEventGroup = jsonParseData.IsEventGroup;
    this.IsPriceRange = jsonParseData.IsPriceRange;
    this.IsAgeGroup = jsonParseData.IsPriceRange;
    this.IsSalesCategory = jsonParseData.IsSalesCategory;
    this.IsBusinessArea = jsonParseData.IsBusinessArea;
  }
}


export class SearchPanelWidth {
  venue: number;
  date: number;
  search: number;

  constructor() {
    this.venue = 3;
    this.date = 3;
    this.search = 5;
  }
}

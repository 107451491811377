import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { PaymentService } from '../../services/payment.service';
import { Deserializer } from 'json-api-format';


import { Invoice } from './../../models/payment/Invoice';
import { PaymentConfiguration } from 'src/modules/models/settings/payment-portal/payment-configuration';;
import { environment } from 'src/environments/environment';
import { AlertMessageComponent } from '../../shared/alert-message/alert-message.component'
import { MatSnackBar } from '@angular/material/snack-bar';
import { PackageService } from 'src/modules/services/package.service';
import { BookingService } from 'src/modules/services/booking.service';
import { SystemService } from 'src/modules/services/system.service';
import { Observable, Subscription } from "rxjs";
import { NumberFormat } from 'src/modules/number-format';
import { CurrencyPipe } from '@angular/common';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


@Component({
    selector: 'opt-payment',
    templateUrl: './payment.component.html',
    //  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
    matcher = new MyErrorStateMatcher();
    IsValidInvoiceRef: boolean;

    OutStandingAmount: string;
    OutStandingAmountMax: number = 0;
    ValidateInvoiceId: string;
    IsValidInvoice: boolean;
    invoice: Invoice;
    paymentConfiguration: PaymentConfiguration;
    currency: string

    InvoiceReference: string
    IsDisable: boolean;
    allowToDoManualInvoiceSearch: boolean;
    bookingId: string;
    bSubscrption: Subscription;
    billerCode: string = "";
    showLoader: boolean = true;
    bookingTypeId: string = "";
    PaymentControl= new FormControl();
    formCtrlSub: Subscription;
    isBillerCodeLoading: boolean = false;

    constructor(private _paymentService: PaymentService,
        private router: Router,
        public snackBar: MatSnackBar,
        private _packageService: PackageService,
        private _bookingService: BookingService,
        private _systemService: SystemService,
        private _CurrencyPipe:CurrencyPipe
    ) {

        this.IsValidInvoiceRef = false;
        this.InvoiceReference = window["InvoiceRef"];
        this.OutStandingAmount = "";
        this.ValidateInvoiceId = "";
        this.IsValidInvoice = false;
        this.invoice = new Invoice();
        this.paymentConfiguration = new PaymentConfiguration();
        this.currency = environment.Currency;
        if (environment.PortalType == "1") this.bookingTypeId = "1";
        if (environment.PortalType == "3") this.bookingTypeId = "2";
        if (environment.PortalType == "5") this.bookingTypeId = "6";


        this.formCtrlSub = this.PaymentControl.valueChanges
        .debounceTime(3000)
        .subscribe(e => {
            this.OutStandingAmount = new NumberFormat(this._CurrencyPipe).transform(Number(this.PaymentControl.value));
          });

    }

    ngOnInit() {
        if (this.InvoiceReference == "") {
            this.IsValidInvoiceRef = false;
            this.showLoader = false;
        } else {
            this.GetInvoiceDetails(this.InvoiceReference);
        }
        this.IsDisable = environment.IsDisableInputsInPayment;
        this.allowToDoManualInvoiceSearch = environment.AllowToDoManualInvoiceSearch;

    }

    ngOnDestroy() {
        if (this.bSubscrption)
            this.bSubscrption.unsubscribe();

        if (this.formCtrlSub)
            this.formCtrlSub.unsubscribe();
    }

  GetInvoiceDetails(InvoiceRef: string) {
    this.isBillerCodeLoading = true;
    let result = this._paymentService.GetInvoiceDetails( InvoiceRef);
    result.subscribe(data => {
      if (data != null) {

                let json = new Deserializer().deserialize(data);
                if (json && json !== null && json.length > 0) {
                    this.ValidateInvoiceId = InvoiceRef;
                    this.OutStandingAmount = parseFloat(json[0].invoiceOutstandingAmount).toFixed(2);
                    this.OutStandingAmountMax = +json[0].invoiceOutstandingAmount;
                    this.IsValidInvoiceRef = true;
                    this.bookingId = json[0].booking.id;
                    if (environment.ExternalIntegrationIdForPaymentGateway == "21") {
                        this.showLoader = true;
                        this.bSubscrption = this._bookingService.BookingPackages(this.bookingId).subscribe((data) => {
                            if (data == undefined) {
                                this.openSnackBarError(["Cannot get data. Please check your Invoice."]);
                                this.IsValidInvoice = false;
                            } else {
                                if (data.bookingPackages && data.bookingPackages.length > 0 && data.bookingPackages[0].package.businessAreaId != "") {
                                    this.showLoader = true;
                                    let result = this._packageService.packageBusinessAreaSearch();
                                    result.subscribe((businessAreas) => {
                                      this.isBillerCodeLoading = false;
                                        if (businessAreas != undefined && businessAreas instanceof Array && businessAreas.length > 0) {
                                            let businessArea = businessAreas.filter(businessArea => +businessArea.id == data.bookingPackages[0].package.businessAreaId);
                                            if (businessArea[0].billerCode != "") {
                                                this.billerCode = businessArea[0].billerCode
                                            }
                                        }
                                    }, er => {
                                        this.showLoader = false;
                                    }, () => {
                                        this.showLoader = false;
                                    });
                                }
                                this.showLoader = false;
                            }

                        }, er => { this.showLoader = false; }, () => {
                            this.showLoader = false;
                        });
                    }
                    else{
                      this.showLoader = false
                      this.isBillerCodeLoading = false;
                    }

                    if (!json[0].cancelled) {
                        if (+this.OutStandingAmount > 0) {
                            this.IsValidInvoice = true;
                        }
                        else {
                            this.openSnackBarError(["This invoice is already paid."]);
                        }
                    }
                    else {
                        this.openSnackBarError([window["Canceled_Invoice"]]);
                    }

                } else {
                    this.openSnackBarError(["Invalid Invoice Reference. Please check your Invoice."]);
                }
            }
            else {
                this.openSnackBarError(["Invalid Invoice Reference. Please check your Invoice."]);
            }

        },
            err => {
                console.log("Error", err);
            },
            () => {
                // Do stuff after completion
            },
        );
    }

    ValidateInvoice() {
        this.GetInvoiceDetails(this.ValidateInvoiceId);
        window["InvoiceRef"] = this.ValidateInvoiceId;
    }
    proceedToPay() {
        if (this.IsValidInvoice) {
            if (this.OutStandingAmount != undefined && +this.OutStandingAmount > 0) {
                if (+this.OutStandingAmount  <= this.OutStandingAmountMax  ) {

                    let _isFromMyBooking;
                    if (window["isFromMyBooking"] != undefined && window["isFromMyBooking"]=="1") {
                        _isFromMyBooking = "1";
                    }else{
                        _isFromMyBooking = "0";
                    }

                    if (environment.ExternalIntegrationIdForPaymentGateway == "21") {
                        this._systemService.getExternalIntegration(environment.ExternalIntegrationIdForPaymentGateway).subscribe((data) => {
                            var externalIntegrationDetail = data.externalIntegrationDetail;
                            if (externalIntegrationDetail.length > 0) {
                                var integrationType = externalIntegrationDetail.filter(x => x.keyName == "Bpoint Payment Option");
                                if (integrationType.length > 0 && integrationType[0].keyValue == "2") {

                                   
                                    //    this.router.navigate(['/payment/secure-pay'], { queryParams: { amount: this.OutStandingAmount, bookingId: this.bookingId, billerCode: this.billerCode } });
                                    var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?InvoiceRef=" + this.ValidateInvoiceId
                                    var url = url + "&amount=" + this.OutStandingAmount;
                                    if (this.bookingId != undefined && this.bookingId != "") {
                                        url = url + "&bookingId=" + this.bookingId;
                                    }
                                    if (this.billerCode != undefined && this.billerCode != "") {
                                        url = url + "&billerCode=" + this.billerCode;
                                    }
                                    if (this.bookingTypeId != undefined && this.bookingTypeId != "") {
                                        url = url + "&bookingTypeId=" + this.bookingTypeId;
                                    }
                                    if (_isFromMyBooking != undefined && _isFromMyBooking=="1") {
                                        url = url + "&isFromMyBooking=1";
                                    }

                                    window.location.href = url;
                                } else
                                {
                                    this.router.navigate(['/payment/secure-pay'], { queryParams: { amount: this.OutStandingAmount, bookingId: this.bookingId, billerCode: this.billerCode, bookingTypeId: this.bookingTypeId,IsFromMyBooking:_isFromMyBooking} });
                                } 
                            }
                        });
                    }
                    else if(environment.ExternalIntegrationIdForPaymentGateway == "29"){
                      var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "" : "/") + "Payment/Pay?InvoiceRef=" + this.ValidateInvoiceId
                                    var url = url + "&amount=" + this.OutStandingAmount;
                                    if (this.bookingId != undefined && this.bookingId != "") {
                                        url = url + "&bookingId=" + this.bookingId;
                                    }
                                    if (this.billerCode != undefined && this.billerCode != "") {
                                        url = url + "&billerCode=" + this.billerCode;
                                    }
                                    if (this.bookingTypeId != undefined && this.bookingTypeId != "") {
                                        url = url + "&bookingTypeId=" + this.bookingTypeId;
                                    }
                                    if (window["isFromMyBooking"] != undefined && window["isFromMyBooking"]=="1") {
                                        url = url + "&isFromMyBooking=1";
                                    }

                                    window.location.href = url;
                    }
                    else {
                        this.router.navigate(['/payment/secure-pay'], { queryParams: { amount: this.OutStandingAmount, bookingId: this.bookingId, bookingTypeId: this.bookingTypeId,IsFromMyBooking:_isFromMyBooking} });
                    }
                } else {
                    this.openSnackBarError(["Payment Amount is greater than the Invoice Amount"]);
                }
            }
            else {
                this.openSnackBarError(["Invalid Payment Amount"]);
            }
        }
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top',
        });
    }
    invoiceValueChange(val) {
        this.IsValidInvoiceRef = false;
    }
}

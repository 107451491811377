<div class="package-selection-area">
  <div class="con d-flex flex-column h-100 justify-content-between" *ngIf="!isMultiSelectionPackage">
    <div class="price-quantity">
      <div class="price-available">
        <div class="price-available-inner">
          <p *ngIf="this.publicPackage?.isHaveLinkPackage"
            style="color: #707070; font-weight: 600 !important;" class="pb-1">{{'PACKAGESELECTIONAREALAYOUT.FROM'| optimotranslate}}</p>
          <div class="pkg-title">
            <div class="d-flex">
              <p style="color: black;"><span class="price bold-30">{{ packagePrice | PBCurrencyPipe}}</span>
                <span class="vat">inc VAT</span>
              </p>
            </div>
            <!-- change "status-green" to "status-yellow", "status-red" to change colours -->
            <div
              *ngIf="availabilityConfiguration.availabilityIndicatorOption==AVAILABILITY_INDICATOR_OPTION.EXACT_AVAILABILITY">
              <div *ngIf="layoutAttribute.available<=availabilityConfiguration.showExactAvailabilityNumberFrom"
                class="pkg-status status-green text-center">
                <p>{{ layoutAttribute.available }}{{"PACKAGESELECTIONAREALAYOUT.Available" | optimotranslate}}</p>
              </div>
            </div>
          </div>

          <!-- If exact availability is false -->


        </div>
        <div class="pkg-status text-center"
          *ngIf="availabilityConfiguration.availabilityIndicatorOption==AVAILABILITY_INDICATOR_OPTION.AVAILABILITY_LABELS"
          [ngStyle]="{'background-color': backgroundColor}" [style.color]="backgroundColor ? 'white' : 'black'"
          style="margin: 8px 0px;">
          <p
            *ngIf="layoutAttribute.available<=availabilityConfiguration.showExactAvailabilityNumberFrom else showLabel">
            {{layoutAttribute.available}}
            {{"PACKAGESELECTIONAREALAYOUT.Available" | optimotranslate}}</p>
          <ng-template #showLabel>
            <p #showLabel style="padding: 3px; text-align: center; font-size: 14px !important;">
              {{"PACKAGESELECTIONAREALAYOUT."+AVAILABILITY_TERM |optimotranslate}}</p>
          </ng-template>
        </div>

        <!-- <div *ngIf="!availabilityConfiguration.exactAvailability else showExact">
                <div  [ngStyle]="{'background-color': backgroundColor}" *ngIf="publicPackage && availabilityConfiguration.availabilityIndicatorOption && availabilityConfiguration.availabilityLabels " class="mb-0 pt-3" style="padding-top: 0px !important;color: white;
                border-radius: 15px;">
                  <p *ngIf="layoutAttribute.available>availabilityConfiguration.showExactAvailabilityNumberFrom else availability" style="padding: 5px 15px 3px 15px">{{ availabilityConfiguration.showExactAvailabilityNumberFrom }} Available</p>
                  <ng-template #availability>
                    <p style="padding: 5px 15px 3px 15px">{{ layoutAttribute.available }} Available</p>
                  </ng-template>
                </div>
              </div> -->
        <!-- If exact availability is true -->
        <!-- <ng-template #showExact>
                <div  [ngStyle]="{'background-color': backgroundColor}" *ngIf="publicPackage && availabilityConfiguration.availabilityIndicatorOption && availabilityConfiguration.availabilityLabels && layoutAttribute.available<=availabilityConfiguration.showExactAvailabilityNumberFrom else showLabel"  class="mb-0 pt-3" style="padding-top: 0px !important;color: white;
                border-radius: 15px;">
                    <p style="padding: 5px 15px 3px 15px">{{ layoutAttribute.available }} Available</p>
                </div>
                <ng-template #showLabel>
                  <div  [ngStyle]="{'background-color': backgroundColor}"   class="mb-0 pt-3" style="padding-top: 0px !important;color: white;
                  border-radius: 15px;">
                  <p style="padding: 5px 15px 3px 15px">{{AVAILABILITY_TERM}}</p>
                </div>
                </ng-template>
              </ng-template> -->
      </div>

    </div>
    <div class="pkg-count text-center" *ngIf="publicPackage && !redirectToBookingApplication">
      <opt-button-number-input [isLinkedPack]="publicPackage.isHaveLinkPackage" [publicPackage]="publicPackage"
        (valueChange)="onvalueChanges($event)" [linkPackages]="publicPackage.linkPackages"></opt-button-number-input>
      <!-- <div class="mx-auto d-flex">
        <div class="count-dec"> </div>
        <opt-pw-number-input style="background-color: #0076C0;" [styleClass]="layoutAttribute.componentClass"
          [value]="packageQuantity" [height]="20" (valueChange)="onvalueChanges($event)">
        </opt-pw-number-input>
        <div class="count-inc"></div>
      </div> -->

      <div class="total-n-dropdown" style=" display: flex; align-items: flex-end;">
        <p class="mb-0 bg-text pt-2" *ngIf="publicPackage?.quantity>0 || showTotal">
          {{"PACKAGESELECTIONAREALAYOUT.Total Price" | optimotranslate}} : {{ totalPrice |PBCurrencyPipe }}</p>
      </div>

      <div class="button-container d-flex" style="margin: 10px 0px;">
        <button class="btn bg-blue" type="button" (click)="onClickBuyNow()" id="material-icon-btn"
                [disabled]="(disableSelectBtn && !isMultiSelectionPackage) || totalPrice === 0"
                style="width: 12rem !important; color: white;">
          <span>{{"PACKAGESELECTIONAREALAYOUT.BUY NOW" | optimotranslate}}</span>
          <!-- <ng-template #Expansion>
            <span *ngIf="publicPackage && !publicPackage.isLinkPackageExpand; else collapse">
              {{selectionPanelName | optimotranslate}}
            </span>
            <ng-template #collapse>
              <span class="material-icons" style="color: white;">
                expand_less
              </span>
            </ng-template>
          </ng-template> -->
        </button>
      </div>
    </div>
  </div>

  <div class="collapse-cont" *ngIf="
      publicPackage &&
      !isMultiSelectionPackage &&
      publicPackage.isHaveLinkPackage &&
      publicPackage.isLinkPackageExpand &&
      publicPackage.linkPackages.length > 0 && !redirectToBookingApplication
    " [ngClass]="{ 'open-collapse': publicPackage.isLinkPackageExpand && publicPackage.linkPackages.length > 0 }">
    <div class="collapse-box">
      <div class="item-list" *ngFor="let pk of publicPackage.linkPackages">
        <div class="items d-flex justify-content-between font-weight-bold" style="font-size: 16px">
          <div class="title">
            {{ pk.linkedPackageType ? pk.linkedPackageType.name: 'Name Not Found'}}
          </div>
          <div class="amount font-weight-bold" style="font-size: 16px">
            <strong>{{ pk.variablePriceIncludingTax | PBCurrencyPipe }}</strong>
          </div>
          <div class="quantity-input">
            <div class="quantity-controls"
              style="margin-top: unset !important; background: #f7f7f7 !important; color:black !important"
              data-quantity="">
              <opt-pw-number-input [value]="pk.quantity" [disabled]="validated(pk)" [height]="20"
                (valueChange)="onvalueChanges(pk.quantity=$event)">
              </opt-pw-number-input>
            </div>
          </div>
          <div class="total-price font-weight-bold" style="font-size: 16px">
            {{ pk.getTotalPrice | PBCurrencyPipe }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="publicPackage.linkPackages">
      <div class="item-list-button-con">
        <div class="row justify-content-end m-0">
          <div class="btn-con-link" id="secondary-btn-linked" *ngIf="GET_WIDTH">
            <button class="btn btn-primary mt-2 ml-2 float-right pk-btn-link" (click)="linkPackageSelection()"
              [disabled]="totalPrice === 0">
              {{"PACKAGESELECTIONAREALAYOUT.Book Now" | optimotranslate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pkg-count text-center" *ngIf="publicPackage && redirectToBookingApplication">
    <div class="button-container d-flex" style="margin: 10px 0px;">
      <button [disabled]="!layoutAttribute.available" class="btn bg-blue" type="button" (click)="onClickBuyNow()" id="material-icon-btn"
              style="width: 12rem !important; color: white;">
        <span>{{"PACKAGESELECTIONAREALAYOUT.BUY NOW" | optimotranslate}}</span>
      </button>
    </div>
  </div>
</div>

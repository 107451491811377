import { LayoutObject } from './layoutObject.model';

export class NameLayout extends LayoutObject {
    content: string;

    constructor(data) {
        super(data);

        this.content = data.content;
    }
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Theme, Typography } from '../../models/globalTheme';
import { ILayout } from '../../models/interfaces';
import * as d3 from 'd3';
import { HTMLLayout } from '../../models/htmlLayout.model';
import { ThemeService } from 'src/modules/services/public-web/theme.service';
import { HEIGHT_SIZE } from '../../models/enums';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/modules/shared/base.component';
import { MatDialog } from '@angular/material/dialog';
import { SeatViewModalComponent } from '../../modals/seat-view-modal/seat-view-modal.component';

declare var $: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-html-layout',
  templateUrl: './html-layout.component.html'
})

export class HtmlLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() layoutAttribute: HTMLLayout;
  @Input() elementID;

  theme: Theme = new Theme();
  parentRef: any;
  static isClickable: boolean = true;
  constructor(
    private themeService: ThemeService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });

    this.translate.onLangChange.safeSubscribe(this, (event: LangChangeEvent) => {
      this.translateLanguage();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
      this.convertDecimal();
      this.translateLanguage();

      //This is added for click function to the dynamically rendered element
      document.querySelectorAll("[class='SeatView']")?.forEach(element => {
        element.addEventListener('click', this.clickSeatView.bind(this));
      });
    });
  }

  translateLanguage() {
    const matchedElements = Array.from(document.querySelectorAll('[id^=Translate_]'));

    matchedElements.forEach(ele => {
      this.translate.get("DynamicContent." + ele.id).safeSubscribe(this, (translated: string) => {
        ele.innerHTML = translated;
      });
    });
  }

  clickSeatView(id) {
    //Prevent multiple time calling this function when having multiple element(Package List).
    setTimeout(() => {
      HtmlLayoutComponent.isClickable = true;
    }, 1000);
    if (HtmlLayoutComponent.isClickable) {
      HtmlLayoutComponent.isClickable = false
      this.dialog.open(SeatViewModalComponent)
    }
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      let height;
      switch (this.layoutAttribute.sizeType) {
        case HEIGHT_SIZE.FitToContent:
          height = this.layoutAttribute.height ? this.layoutAttribute.height + 'px' : '100%';
          // height = '-webkit-fill-available';
          break;
        case HEIGHT_SIZE.SpecifySize:
          height = this.layoutAttribute.height ? this.layoutAttribute.height + 'px' : '100%';
          break;
      }
      d3.select(contentElement.closest('.Html-layout'))
        // .style('height', height)
        .style('max-height', height)
        .data([this.layoutAttribute.gridColumn]);

      if (this.layoutAttribute.componentClass.includes('grid')) {
        let componentClass = this.layoutAttribute.componentClass.split(',');
        d3.select(contentElement.parentElement).attr("class", `${componentClass[0].replace('grid', '')}`);
        if (componentClass.length > 1)
          this.layoutAttribute.componentClass = componentClass[1];
        else
          this.layoutAttribute.componentClass = '';
      } else {
        d3.select(contentElement.parentElement).attr(
          'class',
          `col-md-${this.layoutAttribute.gridColumn}
                    col-lg-${this.layoutAttribute.gridColumn}
                    col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
      }

      d3.select(contentElement.closest('.Html-layout'))
        .select('.html-content').html(this.layoutAttribute.content);
      d3.select('.row').style('margin-right', '0px')
        .style('margin-left', '0px');
      //d3.select('.container-fluid') .style('margin-top','20px');



      // d3.select(contentElement).select('iframe')
      //   .attr('src', `data:text/html;charset=utf-8,${escape(this.layoutAttribute.content)}`);
    }
  }

  convertDecimal() {
    if (document.getElementById('format-decimal')) {
      document.getElementById('format-decimal').innerText =
        parseFloat(document.getElementById('format-decimal').innerText).toFixed(2)
    }
  }
}

import { BUTTON_ACTION, BUTTON_STYLE, ROUTE_LIST } from './enums';
import { LayoutObject } from './layoutObject.model';

export class ButtonLayout extends LayoutObject {
  buttonName: string;
  buttonAction: BUTTON_ACTION;

  navigationURL: string;
  isSingleDay:boolean;

  constructor(data) {
    super(data);
    this.buttonName = data.content;
    this.buttonAction = data.buttonAction;
    this.navigationURL = data.navigationURL;
    this.isSingleDay = data.isSingleDay;
  }
}

import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'opt-seat-view-modal',
  templateUrl: './seat-view-modal.component.html',
  styleUrls: ['./seat-view-modal.component.scss']
})
export class SeatViewModalComponent implements OnInit {
  images = [
    "../Content/Images/package-detail/Capturefaw.png",
    "../Content/Images/PublicWeb/Community_Lunch_Members2.jpg",
    "../Content/Images/PublicWeb/Community_Lunch2.jpg",
  ]

  currentImage = 0
  isZoom = false;

  constructor(
    public dialogRef: MatDialogRef<SeatViewModalComponent>,
  ) { }

  handleNext() {
    this.isZoom = false;
    if (this.currentImage === this.images.length - 1) this.currentImage = 0
    else this.currentImage++
  }

  handlePrev() {
    this.isZoom = false;
    if (this.currentImage === 0) this.currentImage = this.images.length - 1
    else this.currentImage--
  }

  toggleZoom() {
    this.isZoom = !this.isZoom
  }

  ngOnInit(): void {
  }

  handleClose() {
    this.dialogRef.close()
  }

}

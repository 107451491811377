import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { BaseComponent } from "src/modules/shared/base.component";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { startOfDay, endOfDay, addHours } from "date-fns";
import { Subject } from "rxjs/Subject";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "src/modules/public-web/Calender-module";
import {
  CalenderLayout,
  EventListByDate,
} from "../../layout/models/CalenderLayout.model";
import { Pager } from "../../layout/models/pager.model";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import {
  BaseParam,
  SearchBaseFilter,
} from "src/modules/models/public-web/filter";
import {
  PackageDate,
  PackageSession,
} from "src/modules/models/public-web/PackageSession";
import { Package } from "src/modules/models/public-web/Package";
import { AddCurrentPageType } from "../../../../store/public-web/public-web-actions";
import { Store } from "@ngrx/store";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { Observable } from "rxjs";

import { from } from "rxjs";
import { groupBy, mergeMap, reduce, toArray } from "rxjs/operators";
import moment from "moment";
import { Utility } from "src/modules/utility";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/pairwise";
import { PBUtility } from "src/modules/public-web/pb-utility";
import { IPublicWebSession } from "../../../../store/public-web/public-web-reducers";
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { TranslateService } from "@ngx-translate/core";
import { Session } from "protractor";

export const colors: any = {
  red: {
    primary: "#ca4e5a",
    secondary: "#ec192d",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#0081ff",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#ffd40e",
  },
  green: {
    primary: "#0ed10e",
    secondary: "#00ff00",
  },
  orange: {
    primary: "#ffa500",
    secondary: "#cf8600",
  },
  brown: {
    primary: "#522b2b",
    secondary: "#6f3939",
  },
  default: {
    primary: "#f8971d",
    secondary: "#ff8e00",
  },
};

export enum EventListView {
  Calender = "Calender",
  List = "List",
}
@Component({
  selector: "opt-multi-day-session",
  templateUrl: "./multi-day-session.html",
})
export class MultiDaySessionComponent extends BaseComponent implements OnInit {
  IS_MULTI_DAY: boolean;
  eventID: any;
  packageID: any;
  response = {
    redirect: true,
  };

  isMultiDaySession: boolean = false;
  multiDayPackageID: any;
  multiDayEventID: any;
  multiDayEventName: any;
  multiDayEventDuration: string = "";
  multiDayEventTime: any;
  multiDayAvailabilityCount: number = 0;

  private pageType = PAGE_TYPE.DATE_AND_SESSION;
  calenderEvents: any[];
  eventsByDate: EventListByDate[];
  EventView = EventListView;
  CalendarView = CalendarView;
  packageSession: PackageSession[];
  calenderView: CalendarView = CalendarView.Month;
  eventListview: EventListView = EventListView.List;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  pageSize = 5;
  pager: Pager = new Pager();
  pagedItems: PackageDate[];
  private isMobileResolution: boolean;
  publicWebSession$: Observable<IPublicWebSession>;
  bookingSession: SystemOption;

  startDate: Date = new Date();
  endDate: Date = new Date();
  searchFilter: SearchBaseFilter = new SearchBaseFilter();
  isClearEvent: boolean = false;
  selected: any;
  alwaysShowCalendars: boolean;
  minSessionPrice: number;
  publicPackage: any;
  publicPackageSessions: any;
  packageData: any;
  isFirst = true;
  packageDuration: any = "";
  constructor(
    public dialogRef: MatDialogRef<MultiDaySessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private router: Router,
    private publicPackageService: PublicPackageService,
    private store: Store<any>,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    super();
    dialogRef.disableClose = false;
    this.multiDayEventID = data?.eventID;
    this.multiDayPackageID = data?.packageID;
    this.isMultiDaySession = data?.isMultiDaySession;
    this.publicPackage = data?.publicPackage;
    this.publicPackageSessions = data?.publicPackageSessions;
    this.packageDuration = data?.packageDuration;
    this.packageData = data;
    this.calenderEvents = [];
    this.eventsByDate = [];
    this.packageSession = [];
    this.store.dispatch(new AddCurrentPageType(this.pageType));
    this.alwaysShowCalendars = true;
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    window.innerWidth < 768
      ? (this.isMobileResolution = true)
      : (this.isMobileResolution = false);
  }
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  invalidDates: moment.Moment[] = [
    moment().add(2, "days"),
    moment().add(3, "days"),
    moment().add(5, "days"),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, "day"));
  };

  openDialog(templateRef) {
    // let dialogRef = this.dialog.open(templateRef, {
    //   height: "max-content",
    //   width: "max-content",
    // });
  }

  choosedDateTime(event) {
    this.startDate = moment(event.startDate).toDate();
    this.endDate = moment(event.endDate).toDate();
    this.isClearEvent = true;

    this.searchFilter.fromDate = Utility.convertToISO(
      Utility.convertISOToDate(this.startDate)
    );
    this.searchFilter.toDate = Utility.convertToISO(
      Utility.convertISOToDate(this.endDate)
    );

    this.publicPackageSessions
      ? this.getSessionDataFromPackage()
      : this.getSessionData();

    this.viewDate = this.startDate;
    this.selected =
      moment(event.startDate).format("YYYY-MM-DD") +
      " - " +
      moment(event.endDate).format("YYYY-MM-DD");
    //this.dialog.closeAll();
  }
  getTranslate(data: any) {
    var translatedData = data.replace(/[^A-Za-z]/g, '');
    this.translate.get('MONTHS_DAYS.' + translatedData).safeSubscribe(this, (res: string) => {
      data = data.replace(translatedData, res);
    });
    return data;
  }

  getTimeTranslate(data: any) {
    this.translate.get('AM').safeSubscribe(this, (res: string) => {
      data = data.replace(RegExp('AM', 'g'), res);
    });
    this.translate.get('PM').safeSubscribe(this, (res: string) => {
      data = data.replace(RegExp('PM', 'g'), res);
    });
    return data;
  }

  ngOnInit() {
    this.router.events
      .filter((e) => e instanceof RoutesRecognized)
      .pairwise()
      .subscribe((event: any[]) => {
        console.log(event[0].urlAfterRedirects);
      });
    this.route.params.safeSubscribe(this, (params) => {
      this.isMultiDaySession
        ? (this.eventID = +params["eventID"])
        : (this.eventID = this.multiDayEventID);
    });
    // this.eventListview = this.getIsMobileResolution()
    //   ? EventListView.List
    //   : EventListView.Calender;
    this.onResize(event);
    this.publicWebSession$.safeSubscribe(this, (config) => {
      if (config.bookingSession) {
        this.bookingSession = config.bookingSession;
      }
    });
    // to set viewDate as event first date

    this.publicPackageSessions
      ? this.getSessionDataFromPackage()
      : this.getSessionData();
  }

  onResize(event) {
    // this.eventListview = window.innerWidth <= 768 ? EventListView.List : EventListView.Calender;
    this.setPage(1);
  }

  clearEvents() {
    this.isClearEvent = false;
    if (!this.isClearEvent) {
      this.searchFilter.fromDate = null;
      this.searchFilter.toDate = null;
    }
    this.packageSession && this.packageSession?.length > 0
      ? (this.startDate = new Date(this.packageSession[0].startTime))
      : (this.startDate = new Date());

    this.publicPackageSessions
      ? this.getSessionDataFromPackage()
      : this.getSessionData();
  }
  ngAfterViewInit(): void {
    this.publicPackageSessions
      ? this.getSessionDataFromPackage()
      : this.getSessionData();
    this.setCustomWidth();
    //this.getIsMobileResolution() ? document.getElementById('view-selector').style.display="none" : '';
  }
  getSessionDataFromPackage() {
    this.multiDayAvailabilityCount = 0;
    this.multiDayEventName = this.publicPackage.name;
    this.packageSession = this.publicPackageSessions;
    this.packageDates = this.publicPackage.packageDates;
    this.packageSessions = this.publicPackage.packageSessions;
    this.totalPriceIncludingTax = this.publicPackage.variablePriceIncludingTax;
    this.filterPackageSessions(this.packageSession);

    //sort event by date ascending sometimes those packages are not in order
    this.packageSession.sort((val1, val2) => {
      return (
        new Date(val1.startTime).getDate() - new Date(val2.startTime).getDate()
      );
    });
    this.packageDates = this.packageDates.filter(
      (x) => x.packageDurationRange.id == this.packageDuration.id
    );
    this.mapPackageDatesToCalenderEvent(this.packageDates);
    this.mapSessionIntoCalenderEvent(this.packageSession);
    //this.viewDate = new Date(this.packageSession[0].startTime);
    // this.multiDayEventDuration =
    //   moment(this.packageSession[0].startTime).format("YYYY-MM-DD") +
    //   " to " +
    //   moment(
    //     this.packageSession[this.packageSession.length - 1].endTime
    //   ).format("YYYY-MM-DD");

    //set Event Duration with format of dd Month YYYY
    const startDate = new Date(this.packageDates[0].eventDate.date);
    const endDate = new Date(
      this.packageDates[this.packageDates.length - 1].eventDate.date
    );

    //set format to from 01 March 2019 to 01 March 2019
    // this.multiDayEventDuration =
    //   moment(startDate).format("DD MMM YYYY") +
    //   " - " +
    //   moment(endDate).format("DD MMM YYYY");
    this.multiDayEventDuration = this.setDateFormats(startDate, endDate);
    //set format to from 01 March  - 01 March 2019 if both date years are same
    // if (startDate.getFullYear() === endDate.getFullYear()) {
    //   this.multiDayEventDuration =
    //     moment(startDate).format("DD MMM") +
    //     " - " +
    //     moment(endDate).format("DD MMM YYYY");
    // }

    //show only start date if both date,month,year are same
    // if (
    //   startDate.getDate() === endDate.getDate() &&
    //   startDate.getMonth() === endDate.getMonth() &&
    //   startDate.getFullYear() === endDate.getFullYear()
    // ) {
    //   this.multiDayEventDuration =
    //     "On " + moment(startDate).format("DD MMM YYYY");
    // }

    this.multiDayEventTime =
      moment(this.packageSession[0].startTime).format("hh:mm A") +
      " - " +
      moment(
        this.packageSession[this.packageSession.length - 1].endTime
      ).format("hh:mm A");
    this.packageSession.forEach((pkg) => {
      this.multiDayAvailabilityCount += pkg.availability;
    });
    let minDate = new Date(this.packageSession[0].startTime);
    this.packageSession.forEach((ps) => {
      let startTime = ps.startTime;
      if (moment(minDate) > moment(startTime)) {
        minDate = startTime;
      }
    });
    //this.startDate=moment(this.packageSession[0].startTime.toString()).toDate()
    this.isFirst
      ? (this.startDate = minDate)
      : (this.viewDate = this.startDate);

    if (this.isFirst && this.packageSession.length > 5) {
      this.eventListview = EventListView.Calender;
      this.startDate = new Date(this.packageSession[0].startTime);
      this.viewDate = this.startDate;
    }
    this.isFirst = false;

    //session minimum price
    this.minSessionPrice = Math.min.apply(
      Math,
      this.packageSession.map((o) => {
        return o.totalPriceIncludingTax;
      })
    );
  }

  setDateFormats(startDate, endDate) {

    let start = moment(startDate).format('MMM DD, YYYY');
    let end = moment(endDate).format('MMM DD, YYYY');

    if (startDate && endDate) {
      //get month
      let startMonth = moment(start).format('MMMM');
      let endMonth = moment(end).format('MMMM');

      //get Year
      let startYear = moment(start).format('YYYY');
      let endYear = moment(end).format('YYYY');

      //get day
      let startDay = moment(start).format('DD');
      let endDay = moment(end).format('DD');

      let startTransMonth = '';
      let endTransMonth = '';
      this.translate.get(startMonth).safeSubscribe(this, (startrest: string) => {
        this.translate.get(endMonth).safeSubscribe(this, (endrest: string) => {
          startTransMonth = startrest;
          endTransMonth = endrest;
        });
      });


      if (startMonth === endMonth && startYear === endYear) {
        return parseInt(startDay) + " - " + parseInt(endDay) + " " + startTransMonth + " " + startYear;
      }
      else if (startMonth !== endMonth && startYear === endYear) {
        return parseInt(startDay) + " " + startTransMonth + " - " + parseInt(endDay) + " " + endTransMonth + " " + startYear;
      }
      else {
        return parseInt(startDay) + " " + startTransMonth + " " + startYear + " - " + parseInt(endDay) + " " + endTransMonth + " " + endYear;
      }
    }
    return "Invalid Date";
  }
  packageDates: PackageDate[] = [];
  packageSessions: PackageSession[] = [];
  totalPriceIncludingTax: any = "0.00";
  getSessionData() {
    // const params: BaseParam = new BaseParam();
    // if (this.isMultiDaySession) {
    //   params.eventID = this.multiDayEventID;
    //   params.packageID = this.multiDayPackageID;
    // } else {
    //   params.eventID = this.eventID;
    // }
    // this.publicPackageService
    //   .getPublicPackage(params, this.searchFilter)
    //   .safeSubscribe(this, (data: Package[]) => {
    if (this.packageData && this.packageData.sessionFromData && this.packageData.sessionFromData.length > 0) {
      this.multiDayAvailabilityCount = 0;
      this.packageSession = [];
      this.multiDayEventName = this.packageData.sessionFromData[0].name;
      this.packageDates = this.packageData.sessionFromData[0].packageDates;
      this.packageSessions = this.packageData.sessionFromData[0].packageSessions;

      this.totalPriceIncludingTax = this.packageData.sessionFromData[0].variablePriceIncludingTax;
      this.packageData.sessionFromData
        .filter((x) => x.packageSessions != undefined)
        .forEach((pack) => this.packageSession.push(...pack.packageSessions));
      this.filterPackageSessions(this.packageSession);

      //sort event by date ascending sometimes those packages are not in order
      this.packageSession.sort((val1, val2) => {
        return (
          new Date(val1.startTime).getDate() -
          new Date(val2.startTime).getDate()
        );
      });
      this.mapPackageDatesToCalenderEvent(this.packageDates);
      this.mapSessionIntoCalenderEvent(this.packageSession);
      this.viewDate = new Date(this.packageSession[0].startTime);

      //sort packageDates by date ascending
      this.packageDates.sort((val1, val2) => {
        return (
          new Date(val1.eventDate.date).getDate() -
          new Date(val2.eventDate.date).getDate()
        );
      });

      //set Event Duration with format of dd Month YYYY
      const startDate = new Date(this.packageDates[0].eventDate.date);
      const endDate = new Date(
        this.packageDates[this.packageDates.length - 1].eventDate.date
      );

      //set format to from 01 March 2019 to 01 March 2019
      // this.multiDayEventDuration =
      //   moment(startDate).format("DD MMM YYYY") +
      //   " - " +
      //   moment(endDate).format("DD MMM YYYY");
      this.multiDayEventDuration = this.setDateFormats(startDate, endDate);
      //set format to from 01 March  - 01 March 2019 if both date years are same
      // if (startDate.getFullYear() === endDate.getFullYear()) {
      //   this.multiDayEventDuration =
      //     moment(startDate).format("DD MMM") +
      //     " - " +
      //     moment(endDate).format("DD MMM YYYY");
      // }

      //show only start date if both date,month,year are same
      // if (
      //   startDate.getDate() === endDate.getDate() &&
      //   startDate.getMonth() === endDate.getMonth() &&
      //   startDate.getFullYear() === endDate.getFullYear()
      // ) {
      //   this.multiDayEventDuration =
      //     "On " + moment(startDate).format("DD MMM YYYY");
      // }



      // this.multiDayEventDuration=moment(this.packageSession[0].startTime).format('YYYY-MM-DD') + ' to ' + moment(this.packageSession[this.packageSession.length-1].endTime).format('YYYY-MM-DD')
      this.multiDayEventTime =
        moment(this.packageSession[0].startTime).format("hh:mm A") +
        " - " +
        moment(
          this.packageSession[this.packageSession.length - 1].endTime
        ).format("hh:mm A");
      this.packageSession.forEach((pkg) => {
        this.multiDayAvailabilityCount += pkg.availability;
      });
      let minDate = new Date(this.packageSession[0].startTime);
      this.packageSession.forEach((ps) => {
        let startTime = ps.startTime;
        if (moment(minDate) > moment(startTime)) {
          minDate = startTime;
        }
      });
      //this.startDate=moment(this.packageSession[0].startTime.toString()).toDate()
      this.isFirst
        ? (this.startDate = minDate)
        : (this.viewDate = this.startDate);

      if (this.isFirst && this.packageSession.length > 5) {
        this.eventListview = EventListView.Calender;
        this.startDate = new Date(this.packageSession[0].startTime);
        this.viewDate = this.startDate;
      }
      this.isFirst = false;

      //session minimum price
      this.minSessionPrice = Math.min.apply(
        Math,
        this.packageSession.map((o) => {
          return o.totalPriceIncludingTax;
        })
      );
    }
  }

  filterPackageSessions(sessions: PackageSession[]) {
    const groupedSessions$ = from(sessions).pipe(
      groupBy(
        (person) =>
          person.startTime.toISOString() && person.endTime.toISOString()
      ),
      mergeMap((group) =>
        group.pipe(
          reduce(
            (acc, cur) => {
              acc.values.push(cur);
              return acc;
            },
            { key: group.key, values: [] }
          )
        )
      ),
      toArray()
    );

    this.packageSession = [];

    groupedSessions$.forEach((element) => {
      element.forEach((value: any, key) => {
        value.values.sort(function (a, b) {
          return a.totalPriceIncludingTax - b.totalPriceIncludingTax;
        });
        this.packageSession.push(value.values[0]);
      });
    });
    // groupedEmployees$.subscribe(console.log);
  }

  //get random color from colors object
  getRandomColor() {
    const keys = Object.keys(colors);
    const randomIndex = Math.floor(Math.random() * keys.length);
    return colors[keys[randomIndex]];
  }

  //get color in order
  colorIndex = -1;
  calenderEventsDate: any;
  uniqueRangenumber: any[] = []
  colorIndexs: any[] = []
  getColor() {
    const keys = Object.keys(colors);
    if (this.colorIndex == keys.length - 1) {
      this.colorIndex = -1;
    }
    this.colorIndex++;
    return colors[keys[this.colorIndex]];
  }

  mapPackageDatesToCalenderEvent(packageDates: PackageDate[]) {
    //find unique id from package dates
    packageDates.forEach((pd) => {
      if (this.uniqueRangenumber.indexOf(pd.packageDurationRange.id) == -1) {
        this.uniqueRangenumber.push(pd.packageDurationRange.id);
      }
    });

    this.uniqueRangenumber.forEach((id) => {
      this.colorIndexs.push({ id: id, color: this.getColor() })
    });

    this.calenderEventsDate = [];
    packageDates.forEach((date) => {
      let eventDate = date?.eventDate;
      const event = {
        start: new Date(eventDate.startTime),
        end: new Date(eventDate.endTime),
        color: this.colorIndexs.find(x => x.id == date.packageDurationRange.id).color,
        title: this.totalPriceIncludingTax.toString(),
      };
      this.calenderEventsDate.push(event);
    });
    console.log(this.calenderEventsDate);
  }

  mapSessionIntoCalenderEvent(sessions: PackageSession[]) {
    this.calenderEvents = [];
    this.eventsByDate = [];

    sessions.forEach((session) => {
      const event = {
        id: session.id,
        start: addHours(startOfDay(new Date(session.startTime)), 2),
        end: addHours(startOfDay(new Date(session.endTime)), 2),
        actions: [],
        color: this.getColor(),
        title: session.salesChannel,
        session: session,
      };
      this.calenderEvents.push(event);
    });

    this.calenderEvents.forEach((cEvent) => {
      let d = new Date(cEvent.start);
      let index = this.eventsByDate.findIndex(
        (e) => e.date.getTime() === d.getTime()
      );
      if (index != -1) {
        this.eventsByDate[index].calendarEvents.push(cEvent);
      } else {
        const event: EventListByDate = {
          date: d,
          calendarEvents: [cEvent],
          startTime: null,
          endTime: null,
        };
        this.eventsByDate.push(event);
      }
    });
    this.setPage(1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 3) {
    const totalPages = Math.ceil(totalItems / pageSize);
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
    const pager: Pager = {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
    return pager;
  }

  public handlePage(e: any) {
    const currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.setPage(currentPage + 1);
  }

  setPage(page: number) {
    this.pager = this.getPager(this.packageDates.length, page, this.pageSize);
    this.pagedItems = this.packageDates.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.calenderEvents = this.calenderEvents.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  setEventView(view: EventListView) {
    this.eventListview = view;
    this.setPage(1);
    this.setCustomWidth();
  }

  setCustomWidth() {
    let d = document.getElementsByClassName('multiday-session');
    if (this.eventListview != EventListView.Calender) {
      d[0].classList.remove('max-width-calender');
      d[0].classList.add('min-width-calender');
    } else {
      d[0].classList.remove('min-width-calender');
      d[0].classList.add('max-width-calender');
    }


  }

  getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    console.log(this.modalData);
  }

  addEvent(): void {
    this.calenderEvents = [
      ...this.calenderEvents,
      {
        title: "New event",
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.calenderEvents = this.calenderEvents.filter(
      (event) => event !== eventToDelete
    );
  }

  setView(view: CalendarView) {
    this.calenderView = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  monthChange(type) {
    this.isClearEvent = true;
    type == "d"
      ? this.startDate.getMonth() == 0
        ? (this.startDate = new Date(this.startDate.getFullYear() - 1, 11, 1))
        : (this.startDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth() - 1,
          1
        ))
      : this.startDate.getMonth() == 11
        ? (this.startDate = new Date(this.startDate.getFullYear() + 1, 0, 1))
        : (this.startDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth() + 1,
          1
        ));

    if (this.startDate.getMonth() + 1 == new Date().getMonth() + 1) {
      this.isClearEvent = false;
    }

    let eDate = moment(this.startDate).endOf("month");

    this.searchFilter.fromDate = Utility.convertToISO(
      Utility.convertISOToDate(this.startDate)
    );
    this.searchFilter.toDate = Utility.convertToISO(
      Utility.convertISOToDate(eDate.toDate())
    );

    this.publicPackageSessions
      ? this.getSessionDataFromPackage()
      : this.getSessionData();
    this.viewDate = this.startDate;
  }

  //to open first model
  indexToOpen = 0;
  setDateFormat(event,sessions){
    let startMonth;
    let showYear=false;
    let filteredDate=[];
    this.packageDates.forEach(date => {
      if (date.packageDurationRange.id==sessions.packageDate.packageDurationRange.id) {
        filteredDate.push(date);
      }
    });
    if(filteredDate.length>1){
      filteredDate.forEach(date=>{
        let startYear=moment(date.eventDate.date).format('YYYY');
        let eventYear=moment(event.eventDate.date).format('YYYY')
        if(startYear!=eventYear){
          showYear=true;
        }
        startMonth = moment(date.eventDate.date).format('MMMM');
      })
    }
    let startTransMonth = '';
    this.translate.get('MONTHS_DAYS.'+ startMonth).safeSubscribe(this, (startrest: string) => {
      startTransMonth = startrest;
    });
    if (showYear){
      return parseInt(moment(event.eventDate.date).format('DD'))+" " + startTransMonth + moment(event.eventDate.date).format('YYYY');
    }else{
      return parseInt(moment(event.eventDate.date).format('DD'))+" " + startTransMonth;
    }
  }
}

<div class="">
  <!-- <opt-date-and-session [isMultiDaySession]="true" [multiDayPackageID]="packageID" [multiDayEventID]="eventID"></opt-date-and-session> -->
  <div class="body-wrapper date-and-session-page date-and-session-page-popup" (window:resize)="onResize($event)">
    <ng-template #template style="overflow: hidden;">
      <mat-card class="example-card" style="width: max-content; padding: 17px 5px 0 5px;">
        <mat-card-content>
          <ngx-daterangepicker-material (datesUpdated)="choosedDateTime($event)" [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true" [showClearButton]="false"
            [customRangeDirection]="true" [keepCalendarOpeningWithRange]="true">
          </ngx-daterangepicker-material>
        </mat-card-content>
      </mat-card>
    </ng-template>
    <div class="container" style="min-width: 60%;" *ngIf="packageSession.length > 0">
      <div [ngSwitch]="eventListview" class="mt-2">
        <div class="calender-event-view" *ngSwitchCase="EventView.Calender">
          <div class="container row" style="min-width: 60%;margin: unset !important;padding-right: unset;">

            <div class="row" style="width:100%;">
              <div class="col-md-9 event-name" style="padding: 0;">
                <div class="calender-view-switch">
                  <h5 class="multiDayEventName">
                    {{multiDayEventName}}
                  </h5>
                  <p class="multiDayInfo">
                    {{getTranslate(multiDayEventDuration)}}
                  </p>
                  <p class="multiDayInfo">
                    {{getTimeTranslate(multiDayEventTime)}}
                  </p>
                </div>
              </div>
  
              <div class="col-md-3 calender-view-switch-wrapper" style="padding: 0;">
                <div class="calender-view-switch justify-center-content" style="justify-content:flex-end;">
                  <div class="btn-group">
                    <div class="btn" (click)="setEventView(EventView.List)"
                      [class.active]="eventListview === EventView.List">
                      <span class="material-icons"> view_list </span>
                    </div>
                    <div class="btn" (click)="setEventView(EventView.Calender)"
                      [class.active]="eventListview === EventView.Calender">
                      <span class="material-icons">today</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row" style="width:100%;display:flex;justify-content:flex-end;">
              <!-- calendar week view -->
            <div class="col-md-5 custom-calendar">
              <div class="row justify-content-center justify-center-content" style="cursor: pointer;" >
                <div class="calenderHeader" >
                  <span style="vertical-align: middle;" class="material-icons" (click)="monthChange('d')">
                    keyboard_arrow_left
                  </span>
                  <span style="vertical-align: middle; padding-left: 10px; padding-right: 10px;font-size: 18px;" (click)="openDialog(template)">{{ getTranslate(startDate | date:'MMMM&nbsp;y')}}</span>
                  <span style="vertical-align: middle;" class="material-icons"
                    (click)="monthChange('i')">keyboard_arrow_right</span>
                </div>
              </div>
            </div>

            <div class=" col-md-5 seats-price-wrapper">
            <div class="col-md-7 seatsAvailable">
              <div class="calender-view-switch">
               <p class="SeatsCount">{{multiDayAvailabilityCount}} {{"MULTIDAY.Available"| optimotranslate}}</p> 
              </div>
            </div>
            <div class="clearSessionBtn minPriceCalenderSession"
              [ngClass]="{'col-md-5': isClearEvent, 'col-md-6': !isClearEvent}">
              <p class="SeatsCount"> {{this.minSessionPrice | PBCurrencyPipe}}</p>
            </div>
          </div>

            <div class="col-md-2 clearSessionBtn" style="padding: 0; text-align: end;" *ngIf="isClearEvent">
              <div class="calender-view-switch">
                <div class="btn-group">
                  <div class="btn active" (click)="clearEvents()">
                    <span>{{"MULTIDAY.Clear" |optimotranslate}}</span><i class="material-icons">clear</i>
                  </div>
                </div>
              </div>
            </div>
            </div>

          </div>
          <br />
          <div [ngSwitch]="calenderView">
            <div *ngIf="calenderEventsDate.length > 0">
              <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                [events]=" calenderEventsDate" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                [isMultiDayEvent]="isMultiDaySession">
                <!-- (eventClicked)="handleEvent('Clicked', $event.event)" -->
              </mwl-calendar-month-view>
              <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
                [events]=" calenderEventsDate" [refresh]="refresh">
                <!-- (eventClicked)="handleEvent('Clicked', $event.event)" -->
              </mwl-calendar-week-view>
            </div>
          </div>
          <div class="footerBtn">
            <span><button class="btn btn-block btn-primary" mat-button mat-dialog-close>{{"MULTIDAY.Cancel"| optimotranslate}}</button></span>
          </div>
        </div>
        <div class="event-list-view" *ngSwitchCase="EventView.List">
          <div class="container row" style="min-width: 60%;display: flex;flex-direction: column;" id="view-selector">
            <!-- <div class="col-md-3" style="padding: 0;"> -->
            <div class="desc-cal-wrapper" style="display: flex;width: 100% !important;justify-content: space-between;">
              <div class="" style="padding: 0;width: 80%;">
                <div class="calender-view-switch">
                  <h5 class="multiDayEventName">
                    {{multiDayEventName}}
                  </h5>
                  <p class="multiDayInfo">
                    {{ getTranslate(multiDayEventDuration)}}
                  </p>
                  <p class="multiDayInfo">
                    {{getTimeTranslate(multiDayEventTime)}}
                  </p>
                </div>
              </div>

              
            <!-- <div class="col-md-1 calendarSelect" style="padding: 0;"> -->
            <div class="calendarSelect" style="padding: 0;">
              <div class="calender-view-switch justify-center-content">
                <div class="btn-group">
                  <div class="btn" (click)="setEventView(EventView.List)"
                    [class.active]="eventListview === EventView.List">
                    <span class="material-icons"> view_list </span>
                  </div>
                  <div class="btn" (click)="setEventView(EventView.Calender)"
                    [class.active]="eventListview === EventView.Calender">
                    <span class="material-icons">today</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Calendar Month View -->
            <!-- <div class="col-md-3 custom-calendar" > -->
            <div class="custom-calendar" >
              <div class="row justify-content-center justify-center-content" style="cursor: pointer;" *ngSwitchCase="CalendarView.Month">
                <div class="calenderHeader">
                  <span style="vertical-align: middle;" class="material-icons" (click)="monthChange('d')">
                    keyboard_arrow_left
                  </span>
                  <span style="vertical-align: middle; padding-left: 10px; padding-right: 10px;"
                    (click)="openDialog(template)">{{getTranslate(startDate | date:'MMMMy')}}</span>
                  <span style="vertical-align: middle; " class="material-icons"
                    (click)="monthChange('i')">keyboard_arrow_right</span>
                </div>
              </div>
            </div>


            </div>
              

          <div class="seat-price-clear-wrapper" style="display: flex;justify-content: flex-end;">
            <!-- <div class="col-md-3 seatsAvailable"> -->
              <div class="seatsAvailable" style="margin-right: 20px;">
                <div class="calender">
                <p class="SeatsCount">{{multiDayAvailabilityCount}} {{"MULTIDAY.Available"| optimotranslate}}</p> 
                </div>
              </div>

              <div class="clearSessionBtn minPriceCalenderSession"
                [ngClass]="{'col-md-1': isClearEvent, 'col-md-2': !isClearEvent}">
                <p class="SeatsCount"> {{this.minSessionPrice |PBCurrencyPipe}}</p>
              </div>

              <!-- <div class="col-md-1 clearSessionBtn" *ngIf="isClearEvent"> -->
              <div class="clearSessionBtn" *ngIf="isClearEvent" style="margin-left:20px;">
                <div class="calender-view-switch">
                  <div class="btn-group">
                    <div class="btn active" (click)="clearEvents()">
                      <span>{{"MULTIDAY.Clear" |optimotranslate}}</span><i class="material-icons">clear</i>
                    </div>
                  </div>
                </div>
              </div>
          </div>
           
          </div>

          <!-- <div class="event-container" *ngFor="let event of pagedItems">
            <div class="header" id="header">
              <div class="date-container">
                <span class="day">{{ event.eventDate.date | calendarDate: "monthViewDayNumber" }}</span>
                <div>
                  <div class="week">
                    {{ event.eventDate.date | calendarDate: "weekViewColumnHeader" }}
                  </div>
                  <div class="month">
                    {{ event.eventDate.date | calendarDate: "monthViewTitle" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="event-list-content">
              <div class="date-cards row">
                <div class="date-card col-10" style="text-align: center;">
                  <span style="color: gray;"><i class="fa fa-clock-o"></i> </span>
                  <strong>{{event?.eventDate?.startTime | date: " h:mm a"}}</strong>
                  -
                  <strong>{{event?.eventDate?.endTime| date: " h:mm a"}}</strong>
                </div>
                <div class="date-card col-2" style="text-align: right;">
                  <a style="font-size: 12px !important; color: gray;"> from</a>
                  <strong>
                    {{totalPriceIncludingTax | PBCurrencyPipe }}</strong>
                </div>
              </div>
            </div>
          </div> -->

          <!-- New Style Similar to Selected Sessions -->
          <div class="mmc-modal-body pt-3 mt-3" style="max-height: 415px;overflow: auto;padding: 5px;">
            <mat-expansion-panel *ngFor="let sessions of packageSessions;first as isFirst" [expanded]="isFirst" class="mb-3">
              <mat-expansion-panel-header>
                <mat-panel-title style="justify-content: space-between;">
                  <div class="pw-txt-bold" bis_skin_checked="1">
                    {{getTranslate(sessions.packageDate.packageDurationRange.label)}}
                  </div>
                  <!-- <div class="pw-txt-bold" bis_skin_checked="1">
                   From ${{sessions.totalPriceIncludingTax | number :
                      '1.2-2'}}
                  </div> -->
                </mat-panel-title>
              </mat-expansion-panel-header>
              
              <div class="card-body">
        
                <div  *ngFor="let event of packageDates">
                    <div class="row mt-3 mr-1 " *ngIf="event.packageDurationRange.id==sessions.packageDate.packageDurationRange.id"> 
                      <div class="col-5 col-md-5 col-sm-12 col-lg-5 text-left">
                        <h6 class="card-subtitle  mb-3 text-muted">{{ setDateFormat(event,sessions)}}</h6>
                        <!-- <h6 class="card-subtitle  mb-3 text-muted">{{ event.eventDate.date | calendarDate: "monthViewDayNumber" }} {{ event.eventDate.date | calendarDate: "weekViewColumnHeader" }}  {{ event.eventDate.date | calendarDate: "monthViewTitle" }}</h6> -->
                      </div>
                      <div class="col-7 col-md-7 col-sm-12 col-lg-7 text-right card-link pr-2" style="padding-right:unset !important;" >
                        <h6 class="card-subtitle mb-3" ><span style="color: gray;"><i class="fa fa-clock-o"></i> </span>
                          <strong>{{getTranslate(event?.eventDate?.startTime | date: " h:mm a")}}</strong>
                          -
                          <strong>{{getTranslate(event?.eventDate?.endTime| date: " h:mm a")}}</strong></h6> 
                      </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="footerBtn">
            <!-- <mat-paginator [length]=" packageDates.length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]"
            (page)="pageEvent = handlePage($event)">
          </mat-paginator> -->
            <span><button class="btn btn-block btn-primary" mat-button mat-dialog-close>{{"MULTIDAY.Cancel"| optimotranslate}}</button></span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="pw-number-input p-0 d-flex align-items-center justify-content-center" [ngClass]="styleClass">
  <div class="quantity-controls" style="margin-top: unset !important;" data-quantity="">
    <!-- <button *ngIf="!isCart || value>1 " -->
    <!-- data-toggle="tooltip" data-placement="top" title="Tooltip on top" -->
    <!-- [matTooltip]="reduceButtonTooltip"
    matTooltipPosition="above" -->

    <button class="quantity-btn"
      #tooltip="matTooltip"
      [matTooltip]="reduceButtonTooltip"
      [matTooltipDisabled]="disableTooltip"
      (click)="onDecrease()" [ngStyle]="{'height': height}"
      [ngStyle]="reduceBtnStyle && {'color':'#9a9494', 'cursor':'not-allowed'}">
      <!-- <mat-icon>remove</mat-icon> -->
      <i aria-hidden="true" class="fa fa-minus-circle"></i>

    </button>
    <input type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;"  [ngStyle]="{'font-size': fontSize}" class="quantity-input custom-style" data-quantity-target=""
      [value]="value" (input)="inputChange($event)" step="1"  (keyup)="onKeyup($event)"/>
    <button [ngStyle]="{'height': height}" class="quantity-btn" [ngStyle]="{'height': height}" (click)="onIncrease()"
      data-quantity-plus="" [disabled]="disableIncreaseBtnD"
      [matTooltip]="value==9999? ('BOOKINGCART.You can not be able to book more than 9999 for a single instance.'|optimotranslate):''"
      [ngStyle]="(increaseBtnStyle || disableIncreaseBtnD) && {'color':'#9a9494', 'cursor': 'not-allowed', 'pointer-events': 'auto'}">
      <!-- <mat-icon>add</mat-icon> -->
      <i aria-hidden="true" class="fa fa-plus-circle"></i>
    </button>
  </div>
</div>

import { Allocations } from "../../booking/allocations";

export class Allocation extends Allocations {

  constructor() {
    super();
    this.isDelete = false;
  }
  public eventConfigurationDetailId: string;
  public preliminary: number;
  public reserved: number;
  public isDelete: boolean;
}

export enum AllocationType{
  SEAT = 'seatAllocation',
  TABLE = 'tableAllocation'
}
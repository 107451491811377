<div class="button_number_input_container">
    <div class="button_row">
        <button class="material-icons minus_btn" (click)="decreaseSeat()" [disabled]="totalSeats <= 0">remove</button>
        <button class="input_number">
            <input type="number" [disabled]="isLinkedPack" [value]="totalSeats" (input)="inputChange($event)" (keydown)="validateInput($event)" min="0" />
        </button>
        <button class="material-icons plus_btn" (click)="increaseSeat()">add</button>
        <button class="selected_text_btn" (click)="toggleModal()" *ngIf="isLinkedPack">
            <span>{{ selectedSeatText }}</span>
            <i class="material-icons chevron_icon">arrow_forward_ios</i>
        </button>
    </div>

    <div class="seat_modal" *ngIf="seatTypeModal && isLinkedPack">
        <div class="seat_row" *ngFor="let pk of linkPackages">
            <div class="seat_type">
                <p>{{ pk.linkedPackageType ? pk.linkedPackageType.name: 'Name Not Found'}}</p>
                <p class="seat_type_sub">{{ pk.variablePriceIncludingTax | PBCurrencyPipe }}</p>
            </div>
            <div class="button_row">
                <button class="material-icons minus_btn" (click)="decreaseSeat(pk)"
                    [disabled]="pk.quantity <= 0">remove</button>
                <button class="input_number">
                    <input type="number" [value]="pk.quantity" (input)="inputChange($event, pk)"
                        (keydown)="validateInput($event)" min="0" />
                </button>
                <button class="material-icons plus_btn" (click)="increaseSeat(pk)">add</button>
            </div>
        </div>

        <div class="action_btn">
            <button (click)="toggleModal()">{{'BUTTONNUMBERINPUT.Done'| optimotranslate}}</button>
        </div>
    </div>
</div>
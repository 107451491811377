import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
import { ThemeService } from 'src/modules/services/public-web/theme.service';
import { DescriptionLayout } from '../../models/descriptionLayout.model';
import { FONT_CASE } from '../../models/enums';
import { TextStyle, Theme } from '../../models/globalTheme';
import { ILayout } from '../../models/interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-description-layout',
  templateUrl: './description-layout.component.html',
  styleUrls: ['./description-layout.component.scss']
})
export class DescriptionLayoutComponent implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: DescriptionLayout;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID: string;

  theme: Theme = new Theme();
  descriptionStyle: TextStyle;
  parentRef: any;

  constructor(
    private themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
      this.descriptionStyle = theme.card.description;
    });
  }

  ngAfterViewInit(): void {
    this.initializeAttributes();
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);

    if (contentElement) {
      d3.select(contentElement.closest('.description-layout'))
        .data([this.layoutAttribute.gridColumn])
        .style('margin', 0 + 'px');
        // .style('margin', this.theme.card.margin + 'px');
      d3.select(contentElement.parentElement)
        .attr('class',
          `col-md-${this.layoutAttribute.gridColumn}
            col-lg-${this.layoutAttribute.gridColumn}
            col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
    }

    if (this.descriptionStyle && this.descriptionStyle.fontCase !== FONT_CASE.NORMAL) {
      this.layoutAttribute.content = this.layoutAttribute.content.toLowerCase();
    }
  }
}

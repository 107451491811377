import { LayoutObject } from './layoutObject.model';

export class EmptyColumn extends LayoutObject {
  afterElementID;

  constructor(data) {
    super(data);

    this.afterElementID = data.afterElementID;
  }
}

import { HEIGHT_SIZE } from './enums';
import { LocalImage } from './image.model';
import { LayoutObject } from './layoutObject.model';
import { SliderImage } from './sliderImage.model';

// DONE
export class HeaderLayout extends LayoutObject {
  height: number;
  imageTransitionTime: number;
  sizeType = HEIGHT_SIZE.SpecifySize;
  isFitImage: boolean;
  sliderImages: SliderImage[] = [];

  constructor(data) {
    super(data);

    this.height = data.height ? data.height : 200;
    this.imageTransitionTime = data.imageTransitionTime;
    this.sizeType = data.sizeType;
    this.isFitImage = data.FitImage;
    this.sliderImages = data.sliderImages.map(d => new SliderImage(d));
  }
}

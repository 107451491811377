export class PublicBookingSettings {
  repeatPolicy: RepeatPolicy;
  lateRegistrationChargePolicy: LateRegistrationChargePolicy;
  deliveryMethodSetting: DeliveryMethodSetting;
  enableCapturingAddressAtThePointOfMakingPayment: boolean;
  regionalSetting: RegionalSetting;
  publicBookingType: number;
  configurationTypes: ConfigurationType;
  defaultClient: string;
  availabilityConfiguration: AvailabilityConfiguration;
  physicalTicketBookingCancellationMessage: string
  editBookingButtonConfiguration: EditBookingButtonConfiguration;
  enableFacilityWall : boolean;
  priceBandFilterConfiguration : PriceBandFilterConfiguration;
  checkoutPageConfiguration: CheckoutPageConfiguration;
  navigationConfirmationMessages : NavigationConfirmationMessages;
  enableNavigationConfirmationMessageNewBooking : boolean;
  enableNavigationConfirmationMessageEditBooking :boolean;
  paginationSetting: PaginationSetting;
  socialMediaLogin: boolean;
  selectedSeatIconType : number;
  showHideButton : boolean;
  showClientActionsButtons : boolean;
  requestChangeContactNumber: string;
  phrases: Phrases;
}

export class AvailabilityConfiguration {
  availabilityIndicatorOption: AVAILABILITY_INDICATOR_OPTION
  showExactAvailabilityNumberFrom: number
  availabilityThresholds: AvailabilityThresholds[]
}

export enum AVAILABILITY_INDICATOR_OPTION {
  EXACT_AVAILABILITY = "EXACT_AVAILABILITY",
  AVAILABILITY_LABELS = "AVAILABILITY_LABELS",
}

export class AvailabilityThresholds {
  availabilityFrom: number
  availabilityTo: number
  thresholdColor: string
  thresholdTerm: string
}
export class EditBookingButtonConfiguration {
  bookingListButton: ButtonProperty
  addNewBookingButton: ButtonProperty
  editBookingButton: ButtonProperty
  deleteBookingButton: ButtonProperty
  continueShoppingEditBooking: ButtonProperty
  changeYourSeatsEditBooking: ButtonProperty
  requestChangeEditBooking: ButtonProperty
  continueShoppingNewBooking: ButtonProperty
  changeYourSeatsNewBooking: ButtonProperty
  requestChangeNewBooking: ButtonProperty
  redeemArea: ButtonProperty
  specialNoteAreaNewBooking: ButtonProperty
  specialNoteAreaEditBooking: ButtonProperty
  questionnaire: ButtonProperty
  hostGuest: ButtonProperty
  addOns:ButtonProperty
  eventBanner:ButtonProperty
  downLoadTicket : ButtonProperty;
}
export class PriceBandFilterConfiguration {
  defaultFilterOptions: ButtonProperty;
  priceSlider: ButtonProperty;
  packageType: ButtonProperty;
  priceBand: ButtonProperty;
  packageCategory: ButtonProperty;
}
export class CheckoutPageConfiguration{
  backgroundImage: ButtonProperty;
  privacyAndTerms: ButtonProperty;
  paymentSessionTimer: ButtonProperty;
  backButton: ButtonProperty;
  checkoutStep: ButtonProperty;
  paypalPaymentMethod: ButtonProperty;
}
export class ButtonProperty {
  visible: boolean
}

export class ConfigurationType {
  seating: string;
}
export class RegionalSetting {
  startDayOfWeek: string;
  defaultDateFormat: string;
  defaultCurrencyFormat: string;
  defaultCurrencySymbol: string;
}
export class LateRegistrationChargePolicy {
  chargeInFull: string;
  chargeProportionally: string;
}

export class RepeatPolicy {
  startDateOnly: string;
  endDateOnly: string;
  repeatAllDays: string;
  allowIndividualDays: string;
}
export class EmailFields {
  field: Field[];
}

export class PostalFields {
  field: Field[];
}

export class Field {
  name: string;
  placeHolder: string;
  label: string;
  type: string;
  required: boolean;
  col: number;
  canUpdate: boolean;
}

export class DeliveryMethodSetting {
  nonCommonDeliveryMethodMessage: string;
  emailFields: EmailFields;
  postalFields: PostalFields;
  entityConfigurationProfile: number;
}

export class NavigationConfirmationMessages {
  massages: Massage[];
}

export class Massage{
    name : string;
    id: string;
		value: string;
    isEnabled: boolean;
}

export class Phrases
{
  bookingTermAndConditionMessage: string;
  termsAndConditionContents: TermsAndConditionContents;
  purchasePolicyExitRedirectionUrl: string;
}

export class TermsAndConditionContents {
  signUpContent: string;
  footerContent: string;
}

export class PaginationSetting{
  isEnable : boolean;
  minimumRequiredPageSize : number;
  pageSizeOptions : PageSizeOption[];
}

export class PageSizeOption
{
	isDisable : boolean;
	value : number;
}

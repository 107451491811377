import {
  AfterViewInit,
  Component,
  DoCheck,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ILayout } from "../../models/interfaces";
import * as d3 from "d3";
import {
  SearchBoxLayout,
  SearchPanel,
  SearchPanelWidth,
} from "../../models/searchboxLayout.model";
import { Theme } from "../../models/globalTheme";
import { ThemeService } from "../../../../../services/public-web/theme.service";
import { CardLayoutService } from "../../../../../services/public-web/card-layout.service";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchBaseFilter, SearchFilterInclude } from "src/modules/models/public-web/filter";
import { VenueService } from "src/modules/services/public-web/venue.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { Observable } from "rxjs/Observable";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { FormBuilder, FormControl } from "@angular/forms";
import moment, { duration } from "moment";
import { MatDialog } from "@angular/material/dialog";
import { Utility } from "src/modules/utility";
import { Store } from "src/modules/store";
import { PAGE_CONFIGURATION } from "../../models/enums";
import { VenueTypeService } from "../../../../../services/public-web/venue-types.service";
import { forkJoin, of, Subscription } from "rxjs";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { PublicEventService } from "../../../../../services/public-web/public-event.service";
import _, { cloneDeep, indexOf, parseInt } from "lodash";
import { Region } from "src/modules/public-web/regional-setting";
import { environment } from "src/environments/environment";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";
import { IPBReducer } from "../../../../../store/public-web/public-web-reducers";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { convertTypeAcquisitionFromJson } from "typescript";

@Component({
  selector: "opt-filter-layout",
  templateUrl: "./filter-layout.component.html",
  styleUrls: ['./filter-layout.component.scss']
})
export class FilterLayoutComponent
  extends BaseComponent
  implements OnInit, ILayout, AfterViewInit, OnDestroy {
  @Input() layoutAttribute: SearchBoxLayout;
  @Input() unique_key: string;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() elementID = "";
  @Input() canLoad = false;

  // @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChildren(MatAutocompleteTrigger) autoCompleteTriggers: QueryList<MatAutocompleteTrigger>;

  searchPanelData: SearchPanel;
  theme: Theme;
  toppings = new FormControl();
  searchPanelWidth = new SearchPanelWidth();
  filter = new SearchBaseFilter();
  publicWebConfig$: Observable<any>;
  public subscriptions = new Subscription();
  lessThan: string;
  selectLabel: string;
  greater: string;
  years: string;
  filteredOptions: Observable<any[]>;
  filteredOptionsBusiArea: Observable<any[]>;
  customSearchQuery: string;



  parentRef: any;

  venues: any[];
  salesCategories: any[];
  businessAreas: any[];

  alwaysShowCalendars: boolean;
  regionList = [];
  eventCategoryID: number;
  pageConfig: string[];

  isNoResultFound: boolean = false;
  isNoResultFoundBusiArea: boolean = false;
  isFilter = false;
  isMoreFilters = false;

  selected: any;
  classValue = "col-md-2 col-sm-3 col-lg-2 col-xs-3 col-xl-2";
  countOfFilters = 0;
  datechooser = true;
  dropdownSettings = {};
  dropdownList: any[];
  selectedItems: any[] = undefined;
  countDays;

  selectedVenue;
  selectedBusinessArea;

  displayRegion;
  selectedValue = "";
  selectedRegionEvent;
  displayDateRange;
  venuesList;
  sortedVenueList = [];
  searchFb;
  eventGroups: any[];

  eventGropsFilteredOptions: any;
  selectedEventGroup: any;
  isNoEventGropResultFound: boolean = false;

  eventByPriceRange: any[];
  minValue: number = 0;
  maxValue: number = 0;
  // set min and max price range

  //ngx-slider options for price range
  options: Options = {
    floor: this.minValue,
    ceil: this.maxValue,
    enforceStep: false,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
          );
        case LabelType.High:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
          );
        default:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value
          );
      }
    },
  };
  ageGroupIds = "";
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  ageItem: any;
  eventSalesCategories: any[];
  selectedEventSalesCategory: any;
  eventSalesCategoryFilteredOptions: any;
  isNoEventSalesCategoryResultFound: boolean = false;
  PriceValueChanged: boolean = false;
  invalidDates: moment.Moment[] = [
    // moment().add(2, "days"),
    // moment().add(3, "days"),
    // moment().add(5, "days"),
  ];

  priceRange: any;
  PBReducer$: any;
  eventName: string;
  filterDataChangeSubscription: Subscription;
  get isMultiSelectionPackage() {
    return this.pageConfig.includes(
      PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION
    );
  }
  get datePickerLocale() {
    return {
      firstDay: Region.StartDayOfWeek(
        (environment.PublicBookingSetting as PublicBookingSettings)
          .regionalSetting.startDayOfWeek
      ),
      format: (environment.PublicBookingSetting as PublicBookingSettings)
        .regionalSetting.defaultDateFormat,
    };
  }

  searchFilterInclude: SearchFilterInclude = new SearchFilterInclude();
  isEnableSearch: boolean = false;
  isLoading: boolean = true;
  private filterData;
  constructor(
    public dialog: MatDialog,
    private themeService: ThemeService,
    private cardService: CardLayoutService,
    private route: Router,
    private actRoute: ActivatedRoute,
    private venueService: VenueService,
    private venueTypeService: VenueTypeService,
    private eventService: PublicEventService,
    private fb: FormBuilder,
    private store: Store<any>,
    private aRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
    this.venues = [];
    this.eventGroups = [];
    this.alwaysShowCalendars = true;
    this.PBReducer$ = this.store.select(PBSelector.selectPageConfiguration);
    this.searchFb = this.fb.group({
      venueName: [""],
      eventName: [""],
      businessArea: [""],
      eventGroup: [""],
      isAddon: [""],
      addOnSearch: [""],
      days: [""],
      access: [""],
      accommodation: [""],
      parkSearch: [""],
      selectedValueRange: [""],
      selectedValue: [""],
      parkEvents: [""],
      toppings: this.toppings,
      availability: [0],
      // fromDate:[''],
      // toDate:['']
      eventByPriceRange: [""],
      ageGroupId:
        this.selectedItems != undefined && this.selectedItems.length > 0
          ? this.selectedItems
          : "",
      eventSalesCategory: [""],
    });
    this.setCalenderStartWeek();
    this.eventSalesCategories = [];
    this.searchFb.controls["eventByPriceRange"].disable();
  }
  setCalenderStartWeek() { }
  previousValue: any;
  get ranges() {
    var rangeArray = ['Today', 'Current Week', 'Current WeekEnd', 'Next Week', 'This Month', 'Next Month']
    var index = 0;
    rangeArray.forEach(element => {
      this.translate.get('DATE_PICKER.' + element).safeSubscribe(this, (res: string) => {
        rangeArray[index++] = res;
      });
    });
    var ranges = {
      [rangeArray[0]]: [moment(), moment()],
      [rangeArray[1]]: [
        moment().startOf("isoWeek").subtract(1, "days").startOf("day"),
        moment().endOf("isoWeek").subtract(1, "days").startOf("day"),
      ],
      [rangeArray[2]]: [
        moment().endOf("isoWeek").subtract(1, "days"),
        moment().endOf("isoWeek"),
      ],
      [rangeArray[3]]: [
        moment().add(1, "weeks").startOf("isoWeek").subtract(1, "days"),
        moment().add(1, "weeks").endOf("isoWeek").subtract(1, "days"),
      ],
      [rangeArray[4]]: [
        moment().clone().startOf("month"),
        moment().clone().endOf("month"),
      ],
      [rangeArray[5]]: [
        moment().add(1, "month").startOf("month"),
        moment().add(1, "month").endOf("month"),
      ]
    };
    return ranges;
  }
  get months() {
    var transalatemonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var index = 0;
    transalatemonths.forEach(element => {
      this.translate.get('MONTHS_DAYS.' + element).safeSubscribe(this, (res: string) => {
        transalatemonths[index++] = res;
      });
    });
    return transalatemonths;
  }
  get days() {
    var transalatedays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    var index = 0;
    transalatedays.forEach(element => {
      this.translate.get( 'MONTHS_DAYS.' + element).safeSubscribe(this, (res: string) => {
        transalatedays[index++] = res;
      });
    });
    return transalatedays;
  }
  get apply() {
    var transalateApply;
    this.translate.get('DATE_PICKER.APPLY').safeSubscribe(this, (res: string) => {
      transalateApply = res;
    });
    return transalateApply;
  }
  get customRange() {
    var translatecustomRange;
    this.translate.get('DATE_PICKER.Custom range').safeSubscribe(this, (res: string) => {
      translatecustomRange = res;
    });
    return translatecustomRange;
  }
  ngOnInit(): void {
    this.themeService.theme$.safeSubscribe(this, (theme) => {
      this.theme = theme;
    });

    if (this.disabled) {
      this.searchFb.controls.eventByPriceRange.disable();
    } else {
      this.searchFb.controls.eventByPriceRange.enable();
    }

    this.aRoute.params.safeSubscribe(this, (params) => {
      if (params["categoryID"]) this.eventCategoryID = +params["categoryID"];
    });
    this.aRoute.queryParams.safeSubscribe(this, (params) => {
      if(!this.eventCategoryID){
        this.eventCategoryID = params.eventCategoryID && params.eventCategoryID;
      }
    });

    Object.values(this.layoutAttribute.searchPanelData).forEach((prop) => {
      if (prop) {
        this.countOfFilters++;
      }
    });
    this.PBReducer$.safeSubscribe(this, (d: IPBReducer) => {
      this.pageConfig = d.pageConfiguration || [];
    });
    this.setSearchIncludes(this.layoutAttribute);
    this.actRoute.queryParams.safeSubscribe(this, (params) => {
      if (params.eventName && params.eventName) {
        this.isFilter = true;
      }
      this.customSearchQuery = params.eventName && params.eventName;
      this.searchFb.controls.eventName.value=this.customSearchQuery;
      // this.filter.venue = params.venue && params.venue;
      this.filter.sessionFromDate =
        params.sessionFromDate && params.sessionFromDate;
      this.filter.sessionToDate = params.sessionToDate && params.sessionToDate;
      this.filter.fromDate = params.fromDate && params.fromDate;
      this.filter.toDate = params.toDate && params.toDate;
      if (this.filter.fromDate && this.filter.toDate) {
        this.searchFb.controls["selectedValue"].setValue([
          moment(this.filter.fromDate) + " - " + moment(this.filter.toDate),
        ]);
      }

      if ((params.isAddon && params.isAddon && params.isAddon != "") ||
        (params.addOnSearch && params.addOnSearch && params.addOnSearch != "") ||
        (params.days && params.days && params.days != "") ||
        (params.access && params.access && params.access != "") ||
        (params.accommodation && params.accommodation && params.accommodation != "") ||
        (params.venueType && params.venueType && params.venueType != "") ||
        (params.venue && params.venue && params.venue != "") ||
        (params.eventGroup && params.eventGroup && params.eventGroup != "") ||
        (params.ageGroupId && params.ageGroupId && params.ageGroupId != "") ||
        (params.businessArea && params.businessArea && params.businessArea != "") ||
        (params.eventSalesCategory && params.eventSalesCategory && params.eventSalesCategory != "") ||
        (params.minPrice && params.minPrice && params.minPrice != "") ||
        (params.maxPrice && params.maxPrice && params.maxPrice != "") ||
        (params.parkSearch && params.parkSearch && params.parkSearch != "") ||
        (params.availability && params.availability && params.availability != "")) {
        this.isFilter = true;
      }
      // if(params.eventGroup && params.eventGroup){
      //   this.isFilter=true;
      //   return params.eventGroup
      // }
      this.filter.minPrice = params.minPrice && params.minPrice;
      this.filter.maxPrice = params.maxPrice && params.maxPrice;
      if (this.filter.minPrice && this.filter.maxPrice) {
        this.minValue = parseFloat(this.filter.minPrice);
        this.maxValue = parseFloat(this.filter.maxPrice);
      }
      this.filter.ageGroupId = params.ageGroupId && params.ageGroupId;
      this.filter.venue = params.venue && params.venue;
      this.filter.businessArea = params.businessArea && params.businessArea;
      this.filter.eventGroup = params.eventGroup && params.eventGroup;
      this.filter.eventSalesCategory =
        params.eventSalesCategory && params.eventSalesCategory;
      if (this.loadFilterByDefault()) {
        this.getFilterData();
      }
      this.setValueIntoFB();
      this.isEnableSearch = false;
      this.previousValue = _.cloneDeep(this.searchFb.value);
    });
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter,
      maxHeight: 197,
    };
    (this.filter.minPrice != undefined || this.filter.minPrice != null) &&
      (this.filter.maxPrice != undefined || this.filter.maxPrice != null)
      ? this.searchFb.controls["eventByPriceRange"].enable()
      : this.searchFb.controls["eventByPriceRange"].disable();
    this.filter.ageGroupId != undefined
      ? this.searchFb.controls["ageGroupId"].enable()
      : this.searchFb.controls["ageGroupId"].disable();
  }
  ngAfterViewInit(): void {
    this.initializeAttributes();
    let filter = new SearchBaseFilter();
    if (this.eventCategoryID) {
      filter.eventCategoryID = this.eventCategoryID.toString();
    }
    this.displayRegion =
      this.layoutAttribute.searchPanelData.IsRegionSearch &&
        this.layoutAttribute.searchPanelData.IsParkSearch
        ? true
        : false;
    this.displayDateRange =
      this.layoutAttribute.searchPanelData.IsDate &&
        this.layoutAttribute.searchPanelData.IsDays
        ? true
        : false;
    // this.filterDataChangeSubscription = this.subscriptions.add(this.searchFb.valueChanges
    //   .pipe(distinctUntilChanged()) // makes sure the value has actually changed.
    //   .subscribe(data => {
    //     let newData = Object.entries(data);
    //     let oldData = Object.entries(this.previousValue);
    //     //for of new D
    //     let index = -1;
    //     for (const element of newData) {
    //       let index = newData.indexOf(element);
    //       if (index != -1) {
    //         if (element[1] && Array.isArray(element[1]) && Array.isArray(oldData[index][1])) {
    //           for (const val of element[1]) {
    //             if (val[0] != oldData[index][1][0] || val[1] != oldData[index][1][1]) {
    //               if (this.isEnableSearch) {
    //                 this.onSubmit();
    //               }
    //             }
    //           }
    //         }
    //         if (element[1] != null && element[1] != undefined && element[1] !== oldData[index][1] && element[0] == oldData[index][0]) {
    //           if (this.isEnableSearch) {
    //             this.onSubmit();
    //           }
    //           break
    //         }
    //       }
    //     }
    //   }));
  }
  ngOnDestroy(): void {
    // this.filterDataChangeSubscription.unsubscribe();
    // ensure when component is destroyed the subscription is also and not left open.
  }
  loadFilterByDefault() {
    var re = false;
    for (const property in this.filter) {
      var d = this.filter[property];
      if (d || (!!d && d.length > 0)) {
        re = true
        break
      }
    }
    return re
  }

  get IS_FORM_HAS_VALUES() {
    if (this.searchFb.controls.access.value != "" && this.searchFb.controls.access.value != null) {
      return true
    }
    if (this.searchFb.controls.accommodation.value != "" && this.searchFb.controls.accommodation.value != null) {
      return true
    }
    if (this.searchFb.controls.addOnSearch.value != "" && this.searchFb.controls.addOnSearch.value != null) {
      return true
    }
    if (this.searchFb.controls.availability.value != "" && this.searchFb.controls.availability.value != null) {
      return true
    }
    if (this.searchFb.controls.availability.value != "" && this.searchFb.controls.availability.value != null) {
      return true
    }
    if (this.searchFb.controls.businessArea.value != "" && this.searchFb.controls.businessArea.value != null) {
      return true
    }
    if (this.searchFb.controls.days.value != "" && this.searchFb.controls.days.value != null) {
      return true
    }
    if (this.searchFb.controls.eventGroup.value != "" && this.searchFb.controls.eventGroup.value != null) {
      return true
    }
    if (this.searchFb.controls.eventName.value != "" && this.searchFb.controls.eventName.value != null) {
      return true
    }
    if (this.searchFb.controls.eventSalesCategory.value != "" && this.searchFb.controls.eventSalesCategory.value != null) {
      return true
    }
    if (this.searchFb.controls.isAddon.value != "" && this.searchFb.controls.isAddon.value != null) {
      return true
    }
    if (this.searchFb.controls.parkEvents.value != "" && this.searchFb.controls.parkEvents.value != null) {
      return true
    }
    if (this.searchFb.controls.parkSearch.value != "" && this.searchFb.controls.parkSearch.value != null) {
      return true
    }
    if (this.searchFb.controls.selectedValue.value != "" && this.searchFb.controls.selectedValue.value != null) {
      return true
    }
    if (this.searchFb.controls.selectedValueRange.value != "" && this.searchFb.controls.selectedValueRange.value != null) {
      return true
    }
    if (this.searchFb.controls.ageGroupId.value != "" && this.searchFb.controls.ageGroupId.value != null) {
      return true
    }
    if (this.searchFb.controls.toppings.value != "" && this.searchFb.controls.toppings.value != null) {
      return true
    }
    if (this.searchFb.controls.venueName.value != "" && this.searchFb.controls.venueName.value != null) {
      return true
    }
    if ((this.searchFb.controls.eventByPriceRange.value != "" && this.searchFb.controls.eventByPriceRange.value != null)) {
      if (this.priceRange != undefined) {
        if (this.searchFb.controls.eventByPriceRange.value[0] == parseFloat(this.priceRange.minPrice) && this.searchFb.controls.eventByPriceRange.value[1] == parseFloat(this.priceRange.maxPrice)) {
          return false
        } else {
          return true
        }
      }
      else {
        return false
      }
    }
  }

  clearFields() {
    this.selectedValue = "";
    this.selected = undefined;
    this.isNoResultFound = false;
    this.selectedVenue = undefined;
    this.searchFb.reset();
    this.filter.sessionFromDate = null;
    this.filter.sessionToDate = null;
    this.filter.fromDate = null;
    this.filter.toDate = null;
    this.filter.eventName = null;
    this.selectedEventGroup = null;
    this.isNoEventGropResultFound = false;
    this.selectedBusinessArea = null;
    this.isNoResultFoundBusiArea = false;
    this.selectedItems = null;
    this.selectedEventSalesCategory = null;
    this.isNoEventSalesCategoryResultFound = false;
    this.searchFb.controls.eventByPriceRange[0] = this.minValue;
    this.searchFb.controls.eventByPriceRange[1] = this.maxValue;
    this.searchFb.reset({eventByPriceRange: [parseFloat(this.priceRange?.minPrice), parseFloat(this.priceRange?.maxPrice)]});
    this.clearPriceRangeValue();
    this.getValues();
    if (!this.eventCategoryID) {
      this.eventCategoryID = 0;
    }
    this.filter.eventCategoryID = this.eventCategoryID.toString();
    if(this.eventCategoryID == 0){
      if (this.cardService.getPageType === PAGE_TYPE.EVENT_LIST) {
        window.open(
          environment.PublicWebPortalUrl +
          "#/event-list",
          "_self"
        );
      }
    }
    else{
    this.route.navigate(["/event-list"], { queryParams: { eventCategoryID: this.eventCategoryID } });
    }
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
}

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, "day"));
  };

  private _filter(value: any, filterType: string): any[] {
    if (!!value) {
      const filterValue = value.toLowerCase();
      if (filterType === "venueName") {
        let venue = this.venues.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoResultFound = venue.length == 0;
        if (
          venue.length === 1 &&
          venue[0].name.toLowerCase()=== value.toLowerCase()
        ) {
          this.selectedVenue = venue[0];
          this.isFilter = true;

        } else {
          this.selectedVenue = undefined;
        }
        this.isFilter = true;
        return venue;


      } else if (filterType === "businessArea") {
        let businessArea = this.businessAreas.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoResultFoundBusiArea = businessArea.length == 0;
        if (
          businessArea.length === 1 &&
          businessArea[0].name.toLowerCase() === value.toLowerCase()
        ) {
          this.selectedBusinessArea = businessArea[0];
        } else {
          this.selectedBusinessArea = undefined;
        }
        return businessArea;
      } else if (filterType === "eventGroup") {
        let eventGroup = this.eventGroups.filter((option) =>
          option.name.toLowerCase() === (filterValue)
        );
        this.isNoEventGropResultFound = eventGroup.length == 0;
        if (
          eventGroup.length === 1 &&
          eventGroup[0].name.toLowerCase()=== value.toLowerCase()
        ) {
          this.selectedEventGroup = eventGroup[0];
        } else {
          this.selectedEventGroup = undefined;
        }
        return eventGroup;
      } else if (filterType === "eventSalesCategory") {
        let eventSalesCategory = this.eventSalesCategories.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoEventSalesCategoryResultFound = eventSalesCategory.length == 0;
        if (
          eventSalesCategory.length === 1 &&
          eventSalesCategory[0].name.toLowerCase()=== value.toLowerCase()
        ) {
          this.selectedEventSalesCategory = eventSalesCategory[0];
        } else {
          this.selectedEventSalesCategory = undefined;
        }
        return eventSalesCategory;
      }
    }

    if (filterType === "venueName") {
      this.selectedVenue = undefined;
      this.isNoResultFound = !(this.venues.length > 0);
      return this.venues;
    } else if (filterType === "businessArea") {
      this.selectedBusinessArea = undefined;
      this.isNoResultFoundBusiArea = !(this.businessAreas.length > 0);
      return this.businessAreas;
    } else if (filterType === "eventGroup") {
      this.selectedEventGroup = undefined;
      this.isNoEventGropResultFound = !(this.eventGroups.length > 0);
      return this.eventGroups;
    } else if (filterType === "eventSalesCategory") {
      this.selectedEventSalesCategory = undefined;
      this.isNoEventSalesCategoryResultFound = !(
        this.eventSalesCategories.length > 0
      );
      return this.eventSalesCategories;
    }
  }
  openFilters() {
    if (!this.isFilter) {
      if (!this.filterData) {
        this.getFilterData();
      } else {
        this.isFilter = true
      }
    } else
      this.isFilter = false

  }

  getValues() {
    this.toppings.value != null ? (this.sortedVenueList = []) : "";
    let length;
    let e = document.getElementById("matLabelRegion");
    if (e != undefined && e != null) {
      try {
        length = this.toppings.value.length;
        if (length > 0) {
          this.toppings.value.forEach((topping) => {
            this.venuesList.forEach((element) => {
              if (length > 0 && topping == element.venueType) {
                this.sortedVenueList.push(element);
              }
            });
          });
        } else {
          this.venuesList.forEach((element) => {
            this.sortedVenueList.push(element);
          });
        }
      } catch (error) { }
      if (this.filter.parkSearch && this.venuesList != undefined) {
        this.venuesList.forEach((venue) => {
          if (venue.id == this.filter.parkSearch) {
            this.filter.parkSearch = venue.name;
          }
        });
        this.sortedVenueList.push();
      }
      length == undefined || length == null
        ? e.setAttribute("style", "display:block")
        : length > 0
          ? e.setAttribute("style", "display:none")
          : e.setAttribute("style", "display:block");

      //  this.filter.venueType ? e.setAttribute("style", "display:none") : e.setAttribute("style", "display:block");
    }
  }

  getSelectedValue(r) {
    this.toppings.setValue([r.venueType]);
    this.getValues();
  }

  daysChange(event) {
    const value = event.target.value;
    this.filter.sessionFromDate ? "" : "";
    this.filter.sessionToDate ? "" : "";

    if (
      this.filter.sessionFromDate == null &&
      this.filter.sessionToDate == null
    ) {
      this.filter.sessionFromDate = new Date().toString();
      this.filter.sessionToDate = new Date().toString();
    }
    let tempSelected = {
      startDate: moment(),
      endDate: moment(),
    };
    if (this.isMultiSelectionPackage) {
      if (
        this.filter.sessionFromDate &&
        !this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (
        this.filter.sessionFromDate &&
        this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate);
        tempSelected.endDate = moment(this.filter.sessionToDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.sessionToDate).add(
          newValue,
          "days"
        );
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }

      if (
        this.filter.sessionFromDate &&
        !this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (
        this.filter.sessionFromDate &&
        this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate);
        tempSelected.endDate = moment(this.filter.sessionToDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.sessionToDate).add(
          newValue,
          "days"
        );
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }
    } else {
      if (this.filter.fromDate && !this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (this.filter.fromDate && this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate);
        tempSelected.endDate = moment(this.filter.toDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.toDate).add(newValue, "days");
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }

      if (this.filter.fromDate && !this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (this.filter.fromDate && this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate);
        tempSelected.endDate = moment(this.filter.toDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.toDate).add(newValue, "days");
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }
    }

    this.searchFb.patchValue({
      selectedValue: this.selectedValue,
    });
  }

  setValueIntoFB() {
    //Changed By Denu Added Start
    if(this.filter.addOnSearch==""){
      this.filter.addOnSearch=undefined;
    }
    if(this.filter.parkSearch==""){
      this.filter.parkSearch=undefined;
    }
    //Changed By Denu Added End
    if (this.filter.venue && this.filter.venue != "" && this.sortedVenueList && this.sortedVenueList.length > 0) {
      this.searchFb.patchValue({
        venueName: this.sortedVenueList.find(x => x.id == this.filter.venue).name
      })
    }
    if (this.filter.venueType && this.filter.venueType.length > 0) {
      for (let index = 0; index < this.filter.venueType.length; index++) {
        const element = this.filter.venueType[index];
        this.regionList.forEach((vt) => {
          if (vt.id == element) {
            this.toppings.value == null
              ? this.toppings.setValue([vt.name])
              : this.toppings.value.push(vt.name);
          }
        });
      }
    }
    let venueValue = "";
    if (
      this.filter.venue &&
      this.sortedVenueList &&
      this.sortedVenueList.length > 0
    ) {
      this.sortedVenueList.forEach((element) => {
        if (element.id == this.filter.venue) {
          venueValue = element.name;
        }
      });
    }
    this.isMultiSelectionPackage ? this.getValues() : "";
    this.selectedVenue = this.venues.find((x) => x.id == this.filter.venue);
    let fromDate;
    let toDate;
    let dateCount;
    this.filter.sessionFromDate
      ? (fromDate = moment(this.filter.sessionFromDate, "YYYY-MM-DD"))
      : this.filter.fromDate
        ? (fromDate = moment(this.filter.fromDate, "YYYY-MM-DD"))
        : "";

    this.filter.sessionToDate
      ? (toDate = moment(this.filter.sessionToDate, "YYYY-MM-DD"))
      : this.filter.toDate
        ? (toDate = moment(this.filter.toDate, "YYYY-MM-DD"))
        : "";

    fromDate && toDate
      ? (dateCount = moment.duration(toDate.diff(fromDate)).asDays() + 1)
      : fromDate && toDate == undefined
        ? (dateCount = 1)
        : (dateCount = "");

    this.filter.sessionFromDate
      ? (this.selectedValue = moment(this.filter.sessionFromDate).format(
        "YYYY-MM-DD"
      ))
      : this.filter.fromDate
        ? (this.selectedValue = moment(this.filter.fromDate).format("YYYY-MM-DD"))
        : "";

    this.filter.sessionFromDate && this.filter.sessionToDate
      ? (this.selectedValue =
        moment(this.filter.sessionFromDate).format("YYYY-MM-DD") +
        " - " +
        moment(this.filter.sessionToDate).format("YYYY-MM-DD"))
      : this.filter.fromDate && this.filter.toDate
        ? (this.selectedValue =
          moment(this.filter.fromDate).format("YYYY-MM-DD") +
          " - " +
          moment(this.filter.toDate).format("YYYY-MM-DD"))
        : "";

    this.selectedEventGroup = this.eventGroups.find(
      (x) => x.id == this.filter.eventGroup
    )?.name;
    this.selectedEventSalesCategory = this.eventSalesCategories.find(
      (x) => x.id == this.filter.eventSalesCategory
    )?.name;
    this.businessAreas != undefined && this.businessAreas.length > 0
      ? (this.selectedBusinessArea = this.businessAreas.find(
        (x) => x.id == this.filter.businessArea
      )?.name)
      : "";
    // this.eventGroups != undefined && this.eventGroups.length > 0 ? (this.selectedEventGroup = this.eventGroups.find(
    //   (x) => x.id == this.filter.eventGroup
    // )) : ""
    let ageGroup ;
    let data =[]
    if(this.selectedItems){
      this.subscriptions = this.translate.get('FILTER_INFO.Less than').safeSubscribe(this,(res: string) => {
        this.subscriptions=this.translate.get('FILTER_INFO.Years').safeSubscribe(this,(resYear: string) => {
          this.subscriptions=this.translate.get('FILTER_INFO.Greater than').safeSubscribe(this,(resGreater: string) => {
          this.lessThan=res;
          this.years=resYear;
          this.greater=resGreater;
          this.selectedItems.forEach((element1) =>{
            this.ageItem.forEach((element) =>{
            if( element1.id==element.id){
              data.push(element)
            }})
          })
          ageGroup =this.createDropdownList(data);
        });
      });
    });
  }


    this.searchFb.patchValue({
      venueName: this.selectedVenue ? this.selectedVenue.name : "",
      businessArea: this.selectedBusinessArea
        ? this.selectedBusinessArea
        : "",

      eventName:this.customSearchQuery,

      addOnSearch: this.filter.addOnSearch ? this.filter.addOnSearch : undefined,
      days: dateCount,
      parkEvents: this.filter.parkSearch ? this.filter.parkSearch : undefined,
      selectedValue: this.selectedValue,
      eventByPriceRange:
        this.filter.minPrice != undefined && this.filter.maxPrice != undefined
          ? [this.filter.minPrice, this.filter.maxPrice]
          : "",
      availability: this.filter.availability ? this.filter.availability : "",
      eventGroup: this.selectedEventGroup ?this.selectedEventGroup : "",
      eventSalesCategory: this.selectedEventSalesCategory
        ? this.selectedEventSalesCategory
        : "",
      ageGroupId:
        this.selectedItems != undefined && this.selectedItems.length > 0
          ? ageGroup
          : "",
    });
    this.isEnableSearch = true;
  }


  getFilterData() {
    this.eventService.getFilterOption(this.filter, this.searchFilterInclude).safeSubscribe(this, (data) => {
      this.filterData = data;
      // Start Venue Type List
      if (data.venueTypes && data.venueTypes.length > 0) {
        let regionList: any[] = data.venueTypes; //VenueTypes Means Regions
        if (regionList && regionList.length > 0) {
          regionList.map((r) => {
            this.regionList.push(r);
          });
        }
        if (this.filter.venueType && this.filter.venueType.length > 0) {
          this.filter.venueType.forEach((vt) => {
            this.regionList.forEach((r) => {
              if (r.id == vt) {
                this.toppings.value == null ? this.toppings.setValue([r.name]) : this.toppings.value.push(r.name);
              }
            });
          });
          this.isFilter = true;
        }
      }
      this.isMultiSelectionPackage ? this.getValues() : "";
      //End Venue Type List

      // Start Venue Name List
      if (data.venues && data.venues.length > 0) {
        this.venues = data.venues;
        if (this.venues && this.venues.length > 0) {
          this.venuesList = this.venues.map((v) => {
            this.sortedVenueList.push(v);
          });
        }
        this.filteredOptions = this.searchFb
          .get("venueName")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              return this._filter(value, "venueName");
            })
          );
      }
      // End Venue Name List

      // Start Business Area List
      if (data.businessAreas && data.businessAreas.length > 0) {
        this.businessAreas = data.businessAreas;
        this.filteredOptionsBusiArea = this.searchFb
          .get("businessArea")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              return this._filter(value, "businessArea");
            })
          );
      }
      // End Business Area List

      // Start of Event Groups
      if (data.eventGroups && data.eventGroups.length > 0) {
        this.eventGroups = data.eventGroups;
        this.eventGropsFilteredOptions = this.searchFb
          .get("eventGroup")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              return this._filter(value, "eventGroup");
            })
          );
      }
      // End of Event Groups

      // Start of Age Group
      if (data.ageGroups) {
        this.ageItem=data.ageGroups;
        this.subscriptions=this.translate.get('Less than').subscribe((res: string) => {
          this.subscriptions=this.translate.get('Years').subscribe((resYear: string) => {
            this.subscriptions=this.translate.get('Greater than').subscribe((resGreater: string) => {
              this.lessThan=res;
              this.years=resYear;
              this.greater=resGreater;
              this.createDropdownList(data.ageGroups);
              this.createSelectedDropDownFromUrlParam();
              this.subscriptions.unsubscribe();
            });
          });
        });
      }
      // End of Age Groups

      // Start of Event Sales Category
      if (data.salesCategories && data.salesCategories.length > 0) {
        this.eventSalesCategories = data.salesCategories;
        this.eventSalesCategoryFilteredOptions = this.searchFb
          .get("eventSalesCategory")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              return this._filter(value, "eventSalesCategory");
            })
          );
      }

      // Start Price Range Set
      if (data.priceRange) {
        let min = 0;
        let max = 1000;

        this.priceRange = data.priceRange;
        this.priceRange.minPrice = _.cloneDeep(this.priceRange.minPrice.toString());
        this.priceRange.maxPrice = _.cloneDeep(this.priceRange.maxPrice.toString());
        if (this.priceRange) {
          if (isNaN(parseFloat(this.filter.minPrice))) {
            min = parseFloat(this.priceRange.minPrice);
          } else {
            min = parseFloat(this.filter.minPrice);
          }
          if (isNaN(parseFloat(this.filter.maxPrice))) {
            max = parseFloat(this.priceRange.maxPrice);
          } else {
            max = parseFloat(this.filter.maxPrice);
          }
          setTimeout(() => {
            this.assignPriceRangeValue(
              min,
              max,
              parseFloat(this.priceRange.minPrice),
              parseFloat(this.priceRange.maxPrice)
            );
          }, 1000);
        }
      }
      //if filter has date
      // this.isFilter = false;
      if (this.filter.fromDate && this.filter.fromDate != "" || this.filter.toDate) {
        this.isFilter = true;
      }
      // End Price Range Set
      this.setValueIntoFB();
      this.previousValue = _.cloneDeep(this.searchFb.value);
      this.isLoading = false;
    })
  }

  setSearchIncludes(layoutAttribute: SearchBoxLayout) {
    let searchPanelData: SearchPanel = layoutAttribute.searchPanelData
    if (searchPanelData.IsVenueSearch) {
      this.searchFilterInclude.Venues = true
    }
    if (searchPanelData.IsVeuneSelect) {
      this.searchFilterInclude.VenueTypes = true
    }
    if (searchPanelData.IsBusinessArea) {
      this.searchFilterInclude.BusinessAreas = true
    }
    if (searchPanelData.IsAgeGroup) {
      this.searchFilterInclude.AgeGroups = true
    }
    if (searchPanelData.IsEventGroup) {
      this.searchFilterInclude.EventGroups = true
    }
    if (searchPanelData.IsSalesCategory) {
      this.searchFilterInclude.SalesCategories = true
    }
    if (searchPanelData.IsPriceRange) {
      this.searchFilterInclude.PriceRange = true
    }
  }

  searchVenue() {
    if (this.searchFb.get("eventName").value != "") {
      this.onSubmit()
    }
  }

  onSubmit() {

    if (this.selected) {
      if (this.isMultiSelectionPackage) {
        this.filter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        );
        this.filter.sessionToDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        );
      } else {
        this.filter.fromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        );
        this.filter.toDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        );
      }
    }
    if (this.toppings.value != null) {
      let venueTypeID = [];
      this.toppings.value.forEach((element) => {
        this.regionList.forEach((vt) => {
          if (element == vt.name) {
            venueTypeID.push(vt.id);
          }
        });
      });
      this.filter.venueType = venueTypeID;
    }
    if (this.searchFb.get("parkEvents").value) {
      let value = this.searchFb.get("parkEvents").value;
      if (this.sortedVenueList && this.sortedVenueList.length > 0) {
        this.sortedVenueList.forEach((element) => {
          if (element.name == value) {
            this.filter.parkSearch = element.id;
          }
        });
        // this.filter.venue = this.searchFb.get("parkEvents").value;
      }
    }
    this.filter.eventName = this.searchFb.get("eventName").value
      ? this.searchFb.get("eventName").value
      : undefined;
    this.filter.eventGroup = this.selectedEventGroup
      ? this.selectedEventGroup.id
      : undefined;
    this.filter.minPrice = this.PriceValueChanged
      ? this.searchFb.get("eventByPriceRange").value[0]
      : this.filter.minPrice != undefined && this.filter.minPrice
        ? this.filter.minPrice
        : undefined;
    this.filter.maxPrice = this.PriceValueChanged
      ? this.searchFb.get("eventByPriceRange").value[1]
      : this.filter.maxPrice != undefined && this.filter.maxPrice
        ? this.filter.maxPrice
        : undefined;
    this.filter.ageGroupId = this.concadinateAgeGroupIds();
    this.filter.eventSalesCategory = this.selectedEventSalesCategory
      ? this.selectedEventSalesCategory.id
      : undefined;
    if (this.searchFb.get("availability").value !== 0) {
      this.filter.availability = this.searchFb.get("availability").value
        ? this.searchFb.get("availability").value
        : undefined;
    }
    this.filter.venue = this.selectedVenue ? this.selectedVenue.id : this.filter.venue;
    this.filter.businessArea = this.selectedBusinessArea
      ? this.selectedBusinessArea.id
      : undefined;
    this.filter.clientCategoryId = this.filter.clientCategoryId
      ? this.filter.clientCategoryId
      : undefined;
    this.filter.clientID = this.filter.clientID
      ? this.filter.clientID
      : undefined;
    this.filter.itemName = this.filter.itemName
      ? this.filter.itemName
      : undefined;

    if (this.cardService.getPageType === PAGE_TYPE.EVENT_CATEGORY) {
      this.route.navigate(["/event-list"], { queryParams: this.filter });
    } else if (this.cardService.getPageType === PAGE_TYPE.EVENT_LIST) {
      this.route
        .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
        .then(() => {
          this.eventCategoryID
            ? this.route.navigate(["/event-list", this.eventCategoryID], {
              queryParams: this.filter,
            })
            : this.route.navigate(["/event-list"], {
              queryParams: this.filter,
            });
        });
    }
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      const mainContainer = contentElement.closest(".main-layout-container");
      if (this.layoutAttribute.isGrid) {
        d3.select(contentElement.parentElement).attr(
          "class",
          `col-md-${this.layoutAttribute.gridColumn}
                    col-lg-${this.layoutAttribute.gridColumn}
                    col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
      }
      if (mainContainer) {
        d3.select(mainContainer)
          .style(
            "margin-top",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          )
          .style(
            "margin-bottom",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          )
          .selectAll(".s-p-btn")
          .style("background-color", this.theme.global.buttonColor);
      }
    }
  }

  setMaxWidth() {
    let dateRangeContainer = document.getElementsByClassName(
      "mat-dialog-container"
    );
    dateRangeContainer[0].removeAttribute("style");
    let inLine = document.getElementsByClassName("inline");
    inLine[0].removeAttribute("style");
  }
  assignPriceRangeValue(minValue: number,
    maxValue: number,
    floor: number,
    ceil: number) {
    this.maxValue = _.cloneDeep(maxValue);
    this.minValue = _.cloneDeep(minValue);
    //change ngx-slider min and max value
    this.options = {
      floor: parseFloat(floor.toFixed(2)),
      ceil: parseFloat(ceil.toFixed(2)),
      enforceStep: false,
      step: 5,
      disabled: this.minValue == this.maxValue ? true : false,
      translate: (value: number, label: LabelType): string => {
        //if not min & max are same
        if (this.minValue != this.maxValue) {
          switch (label) {
            case LabelType.Low:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
            case LabelType.High:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
            default:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
          }
        } else {
          switch (label) {
            case LabelType.Low:
              return "";
            case LabelType.High:
              return "";
            default:
              return "";
          }
        }
      },
    };
    if (this.disabled) {
      this.searchFb.controls.eventByPriceRange.disable();
    } else {
      this.searchFb.controls.eventByPriceRange.enable();
    }
    if (
      parseFloat(this.priceRange.minPrice) ==
      parseFloat(this.priceRange.maxPrice)
    ) {
      this.options = Object.assign({}, this.options, { disabled: true });
      if (this.disabled) {
        this.searchFb.controls.eventByPriceRange.disable();
      } else {
        this.searchFb.controls.eventByPriceRange.enable();
      }
      document
        .getElementById("ngx-price-range-slider")?.getElementsByClassName("ngx-slider-model-value")[0]
        .remove();
      document
        .getElementById("ngx-price-range-slider")?.getElementsByClassName("ngx-slider-model-high")[0]
        .remove();
    }
  }
  dateRange(event) {
    this.selected = {
      startDate: "",
      endDate: "",
    };
    this.countDays =
      moment.duration(event.endDate.diff(event.startDate)).asDays() + 1;
    this.selected.startDate = moment(event.startDate);
    this.selected.endDate = moment(event.endDate);
    if (parseInt(this.countDays) < 2) {
      this.selectedValue = moment(event.startDate).format("YYYY-MM-DD");
      // this.selectedValue = moment(event.startDate).format("YYYY-MM-DD")+ " - " +  moment(event.endDate).format("YYYY-MM-DD");;
      //this.filter.fromDate = moment(event.startDate).format();
      this.isMultiSelectionPackage
        ? (this.filter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        ))
        : (this.filter.fromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        ));

      this.filter.sessionToDate = null;
    } else {
      this.selectedValue =
        moment(event.startDate).format("YYYY-MM-DD") +
        " - " +
        moment(event.endDate).format("YYYY-MM-DD");
      this.isMultiSelectionPackage
        ? (this.filter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        ))
        : (this.filter.fromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        ));

      this.isMultiSelectionPackage
        ? (this.filter.sessionToDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        ))
        : (this.filter.toDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        ));
    }
    this.searchFb.patchValue({
      selectedValue: this.selectedValue,
    });
  }

  choosedDateTime(event) {
    this.selectedValue =
      moment(event.startDate).format("YYYY-MM-DD") +
      " - " +
      moment(event.endDate).format("YYYY-MM-DD");
    this.selected = event;
    if (this.isMultiSelectionPackage) {
      this.filter.sessionFromDate = moment(event.startDate).format();
      this.filter.sessionToDate = moment(event.endDate).format();
    } else {
      this.filter.fromDate = moment(event.startDate).format();
      this.filter.toDate = moment(event.endDate).format();
    }
    this.datechooser = false;
    this.selected = event;
    this.dateRange(event);
    this.layoutAttribute.searchPanelData.IsDays ? "" : (this.countDays = "");
    this.searchFb.patchValue({
      selectedValueRange: this.selectedValue,
      days: parseInt(this.countDays),
      // formControlName2: myValue2 (can be omitted)
    });
    this.dialog.closeAll();
    (<HTMLInputElement>document.getElementById("dateRangePicker")).value =
      this.selectedValue;

    this.onSubmit();
  }

  openDialog(templateRef) {
    let dialogRef = this.dialog.open(templateRef, {
      height: "max-content",
      width: "max-content",
    });
  }

  setWidth(venue, date, search) {
    this.searchPanelWidth.venue = venue;
    this.searchPanelWidth.date = date;
    this.searchPanelWidth.search = search;
  }

  setStyles(className) {
    if (className == "cdk-panel") {
      let cls = document.getElementById("cdk-overlay-0");
      cls.style.overflow = "hidden";
      cls.style.transform = "translateX(-12px) translateY(18px)";
      cls.style.minWidth = "325px";
    }
  }

  onNoDelBtnVisible(searchFbValues: {
    venueName: "";
    eventName: "";
    businessArea: "";
    isAddon: "";
    addOnSearch: "";
    days: "";
    access: "";
    accommodation: "";
    parkSearch: "";
    selectedValueRange: "";
    selectedValue: undefined | ""; //noted
    parkEvents: "";
    toppings: any[];
    availability: 0;
    eventGroup: "";
    ageGroupId: "";
    eventSalesCategory: "";
  }) {
    for (const value in searchFbValues) {
      if (value === "toppings") {
        if (searchFbValues[value] !== null) {
          if (searchFbValues[value].length !== 0) {
            return false;
          }
        }
      }
      if (value !== "toppings" && !!searchFbValues[value]) {
        return false;
      }
    }
    return true;
  }

  onInputDateRangeText(event: KeyboardEvent) {
    const textAtDatePicker = (event.target as HTMLInputElement).value;
    if (textAtDatePicker === "") {
      this.searchFb.patchValue({
        selectedValueRange: null,
        selectedValue: null,
        days: "",
      });

      this.selected = undefined;
      this.filter.sessionFromDate = null;
      this.filter.sessionToDate = null;
      this.filter.fromDate = null;
      this.filter.toDate = null;
      this.selectedValue = "";
    }
  }

  resetOnFalseVenue() {
    if (!this.selectedVenue) {
      this.searchFb.get("venueName").reset();
    }
  }

  concadinateAgeGroupIds() {
    if (
      this.searchFb.get("ageGroupId").value != null &&
      this.searchFb.get("ageGroupId").value.length > 0
    ) {
      let ageGroupId = [];
      for (
        let index = 0;
        index < this.searchFb.get("ageGroupId").value.length;
        index++
      ) {
        ageGroupId.push(this.searchFb.get("ageGroupId").value[index].id);
      }
      return ageGroupId;
    }
  }

  createDropdownList(item: any) {
    let tmp = [];
    if (item) {
      for (let index = 0; index < item.length; index++) {
        var name = "";
        if (item[index].minimum == 0) {
          name = this.lessThan + ' ' + item[index].maximum + ' ' + this.years;
        } else if (item[index].maximum == 0) {
          name = this.greater + ' ' + item[index].minimum + ' ' + this.years;
        } else {
          name = item[index].minimum + "-" + item[index].maximum + ' ' + this.years;
        }
        tmp.push({ id: item[index].id.toString(), name: name });
      }
    }
    this.dropdownList = tmp;
    return tmp;
  }
  onItemSelect($event) {
    this.searchFb.controls["ageGroupId"].enable();
    this.onSubmit();
  }

  resetOnFalseDate(event) {
    let dateRange = event.target.value;

    if (!this.selected?.endDate && !this.selected?.startDate) {
      if (!moment(dateRange, "DD-MMM-YYYY HH:mm a").isValid()) {
        this.selectedValue = "";
        event.target.value = this.selectedValue;
      }
    }
  }
  createSelectedDropDownFromUrlParam() {
    if (this.dropdownList.length > 0) {
      if (
        this.filter.ageGroupId != undefined &&
        this.filter.ageGroupId.length > 0
      ) {
        let selectedIds = this.filter.ageGroupId;
        let selectedItems = this.dropdownList;
        let temp = [];
        for (let index = 0; index < selectedIds.length; index++) {
          temp.push(selectedItems[parseInt(selectedIds[index]) - 1]);
        }
        this.selectedItems = temp;
      }
    }
  }
  sliderValue = {
    low: 0,
    high: 0,
  }
  isValueChanged: boolean = false
  userChangeStart(e) {
    if (!this.isValueChanged) {
      this.sliderValue.high = e.highValue;
      this.sliderValue.low = e.value;
      this.isValueChanged = true;
    }
  }
  userChangeEnd(e) {
    if (e.value != this.sliderValue.low && e.highValue != this.sliderValue.high) {
      this.onSubmit();
      this.isValueChanged = true;
    }
  }

  valueChanges() {
    this.PriceValueChanged = true;
    this.searchFb.controls["eventByPriceRange"].enable();
  }
  @HostListener("window:scroll", ["$event"])
  scrollHandler() {
    if (document.querySelector(".custom-style")) {
      var domRect = document
        .querySelector(".custom-style")
        .getBoundingClientRect();
      var spaceBelow = window.innerHeight - domRect.bottom;
      if (spaceBelow < this.dropdownSettings["maxHeight"]) {
        document.getElementsByClassName("dropdown-list")[0]["style"][
          "marginTop"
        ] = "-284px";
      } else {
        document.getElementsByClassName("dropdown-list")[0]["style"][
          "marginTop"
        ] = "0px";
      }
    }
  }
  dropdownOpenBasedOnSpace() {
    let UnSelect ='' ;
    this.translate.get('FILTER_INFO.UnSelect All').safeSubscribe(this, (res: string) => {
      UnSelect=  res;
    });

    this.subscriptions=this.translate.get('FILTER_INFO.Select All').subscribe((res: string) => {
        this.selectLabel=res;
      this.dropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        selectAllText: this.selectLabel,
        unSelectAllText:UnSelect,
        itemsShowLimit: 1,
        allowSearchFilter: this.ShowFilter,
        maxHeight: 197,
      };
    });
    this.subscriptions=this.translate.get('FILTER_INFO.Less than').subscribe((res: string) => {
      this.subscriptions=this.translate.get('FILTER_INFO.Years').subscribe((resYear: string) => {
        this.subscriptions=this.translate.get('FILTER_INFO.Greater than').subscribe((resGreater: string) => {
        this.lessThan=res;
        this.greater=resGreater;
        this.years=resYear;

          this.createDropdownList(this.ageItem);
          this.subscriptions.unsubscribe();
        });
      });
    });
    this.scrollHandler();
  }

  clearPriceRangeValue() {
    if (this.priceRange) {
      var min = parseFloat(this.priceRange.minPrice);
      var max = parseFloat(this.priceRange.maxPrice);
      setTimeout(() => {
        this.assignPriceRangeValue(min, max, min, max);
      }, 0);
      this.filter.minPrice = "";
      this.filter.maxPrice = "";
      this.PriceValueChanged = false;
      this.searchFb.controls["eventByPriceRange"].disable();
    }
  }
  onItemDeSelect($event) {
    if (this.searchFb.get("ageGroupId").value == 0) {
      this.searchFb.controls["ageGroupId"].disable();
    }
  }
  selectallItems($event) {
    this.searchFb.controls["ageGroupId"].enable();
    this.searchFb.controls['ageGroupId'].setValue($event);
    this.onSubmit();
  }
  unselectallItems($event) {
    this.searchFb.controls["ageGroupId"].disable();
  }

  toggleMenuOpen() {
    var wrapper = document.getElementById("wrapperToggle");
    if (wrapper.classList.contains('open')) {
      wrapper.classList.remove('open');
      wrapper.setAttribute('style', 'display:none');
    } else {
      wrapper.classList.add('open');
      wrapper.setAttribute('style', 'transition: height 200ms ease-in-out 0ms;display:flex;height:auto;');
    }
  }

  customSearch() {
    //Added Start Denu
   this.filter.maxPrice=undefined;
   this.filter.minPrice=undefined;
    //Added End Denu
    this.customSearchQuery = this.searchFb.get("eventName").value;
    this.filter.eventName = this.customSearchQuery;
    if (!this.eventCategoryID) {
      this.eventCategoryID = 0;
    }
    this.filter.eventCategoryID = this.eventCategoryID.toString();
    if (this.cardService.getPageType === PAGE_TYPE.EVENT_CATEGORY && this.customSearchQuery != "") {
      this.route.navigate(["/event-list"], { queryParams: this.filter });
    }
    else if (this.cardService.getPageType === PAGE_TYPE.EVENT_LIST && this.customSearchQuery != "") {
      this.route.routeReuseStrategy.shouldReuseRoute = () => false;
      this.route.onSameUrlNavigation = 'reload';
      this.route.navigate(["/event-list"], { queryParams: this.filter });
    }
    if ( this.customSearchQuery == "") {
      this.clearFields();
    }
  }

  customSearchKey() {
   this.customSearch();
  }
}

export class PbCustomer {
    contactId: number;
    clientId: number;
    ref: string;
    title: string;
    name: string;
    email: string;
    tel: string;
    postCode: string;
    address: string;
    client?: any;
    constructor(obj?) {
      if (obj) {
        this.contactId = obj.contactId;
        this.clientId = obj.clientId;
        this.ref = obj.ref;
        this.title = obj.title;
        this.name = obj.name;
        this.email = obj.email;
        this.tel = obj.tel;
        this.postCode = obj.postCode;
        this.address = obj.address;
      }
  
    }
  }
  
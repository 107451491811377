import { BaseResource } from 'src/modules/models/base-resource';
import { LAYOUT_TYPE } from './enums';

// DONE
export class LayoutObject extends BaseResource {
  layoutType: LAYOUT_TYPE;
  layoutTypeID: string;
  pageLayoutTemplateID: string;
  componentKey: string;
  parentComponentKey: string;
  nextComponentKey: string;
  isGrid: boolean;
  isHead: boolean;
  isEmptyLayout: boolean;
  gridColumn: number;
  componentClass: string;
  content: string;
  hasTicketing: boolean;
  constructor(data) {
    super();
    this.id = data.id ? data.id : '';
    this.componentKey = data.componentKey ? data.componentKey : null;
    this.parentComponentKey = data.parentComponentKey ? data.parentComponentKey : null;
    this.nextComponentKey = data.nextComponentKey ? data.nextComponentKey : null;
    this.isGrid = data.grid ? data.grid : null;
    this.isHead = data.head ? data.head : null;
    this.layoutType = data.type ? data.type : null;
    this.layoutTypeID = data.layoutTypeID ? data.layoutTypeID : null;
    this.pageLayoutTemplateID = data.pageLayoutTemplateID ? data.pageLayoutTemplateID : null;
    this.gridColumn = data.gridColumn ? data.gridColumn : null;
    this.componentClass = data.componentClass ? data.componentClass : '';
    this.content = data.content ? data.content : null;
    this.hasTicketing = data.hasTicketing ? data.hasTicketing : false
  }
}

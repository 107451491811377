import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ILayout } from "../../models/interfaces";
import { CardListLayout } from "../../models/cardListLayout.model";
import * as d3 from "d3";
import { Theme } from "../../models/globalTheme";
import {
  CARD_LIST_TOGGLE,
  COLUMN,
  LAYOUT_TYPE,
  PAGE_CONFIGURATION,
  PAGINATION_POSITION,
  PUBLIC_PAGE_DATA_TYPE,
  VIEW_ROW,
} from "../../models/enums";
import { CardLayoutComponent } from "../card-layout/card-layout.component";
import { CardLayout } from "../../models/cardLayout.model";
import { Pager } from "../../models/pager.model";
import { ThemeService } from "../../../../../services/public-web/theme.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { CardLayoutService } from "../../../../../services/public-web/card-layout.service";
import { Paging } from "src/modules/models/base-param";
import { SearchBaseFilter } from "src/modules/models/public-web/filter";
import { ActivatedRoute, Router } from "@angular/router";
import { VenueService } from "src/modules/services/public-web/venue.service";

import { PublicEventService } from "../../../../../services/public-web/public-event.service";
import { Observable, Subscription } from "rxjs";
import { Store } from "src/modules/store";
import {
  IDateAndSession,
  IPBReducer,
  IPublicPackage,
} from "../../../../../store/public-web/public-web-reducers";
import { Utility } from "src/modules/utility";
import { AddEvent, SetPackageList } from "../../../../../store/public-web/public-web-actions";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';

@Component({
  // tslint:disable-next-line: component-selector
  selector: "opt-card-list-layout",
  templateUrl: "./card-list-layout.component.html",
})
@HostListener("window:resize", ["$event"])
export class CardListLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {
  venues: any;
  VCR: ViewContainerRef;
  eventCategoryID: number;
  openedMapWindow: any;
  previousAGMWindow: any;
  pageConfiguration: any;
  @ViewChild("cardContainerRef", { read: ViewContainerRef })
  set content(content: ViewContainerRef) {
    if (content) {
      this.VCR = content;
    }
  }
  @ViewChild(CardListLayoutComponent) ECCL: CardLayoutComponent;

  @Input() layoutAttribute: CardListLayout;
  @Input() unique_key: any;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() elementID = "demo";
  @Input() canLoad = false;
  @Input() searchBaseFilter: SearchBaseFilter;
  categoryCardRrs = [];
  parentRef: any;
  theme: Theme;
  lazyLoadPageZise = 0;
  paddingTop = "0";
  paddingBottom = "0";

  pager: Pager = new Pager();
  pagedItems: any[];
  pageNo;

  filter: SearchBaseFilter;
  selectedCardListView: CARD_LIST_TOGGLE;
  rootLat = 5;
  rootLng = 5;
  mapClickEvent: MouseEvent;
  eventsForVenue: any[];
  canShowMap = false;
  attendees: number;
  constructor(
    private themeService: ThemeService,
    private CFR: ComponentFactoryResolver,
    private cardService: CardLayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private venueService: VenueService,
    private eventService: PublicEventService,
    private store: Store<any>
  ) {
    super();
    this.layoutAttribute = undefined;
    this.filter = new SearchBaseFilter();
    this.cardService.searchCardResult.next([]);
    this.store.select(PBSelector.selectPageConfiguration).subscribe((d: any) => {
      this.pageConfiguration = d.pageConfiguration;
      if (d.packageAvailability > 0) {
        this.attendees = d.packageAvailability;
      }
    });
  }
  get GET_RESULT_COUNT() {
    if (this.layoutAttribute.cardLayouts.length == 0) {
      d3.select(".pack-header").style("display", "none")
      return true
    } else {
      d3.select(".pack-header").style("display", "block")
    }
    return false
  }
  ngOnInit(): void {
    this.pageNo = 0;
    this.selectedCardListView = CARD_LIST_TOGGLE.CARD_VIEW;
    this.themeService.theme$.subscribe((theme) => {
      this.theme = theme;
    });
    this.route.params.safeSubscribe(this, (params) => {
      if (params["categoryID"]) this.eventCategoryID = +params["categoryID"];
    });
    this.cardService.searchCardResult.safeSubscribe(this, (childCard) => {
      if (childCard.length > 0) {
        if (this.layoutAttribute.isPagination) {
          if (this.VCR) this.VCR.clear();
        }
        this.layoutAttribute.cardLayouts.push(...childCard);
        const packageList = []
        childCard.forEach((c) => {
          this.createCardComponent(c, this.layoutAttribute.height);
          if (
            c.cardDataType &&
            c.cardDataType === PUBLIC_PAGE_DATA_TYPE.PUBLIC_PACKAGE
          ) {
            let packageData: IPublicPackage = {
              id: c.referenceID,
              quantity: 0,
              isHaveLinkPackage: c.isHaveLinkPackage,
              isNotMemberPackage: c.isNotMemberPackage,
              isLinkPackageExpand: false,
              linkPackages: [],
              isBooked: false,
              itemQuantity: 0,
              singleDayPackage: c.singleDayPackage,
              hasTicketing: c.hasTicketing,
              maximumAttendees: 10,
              clientCategories: c.clientCategories,
              durationRange: c.durationRange,
              packageSessions: c.packageSessions,
              availability: 0
            };
            packageList.push(packageData)
          }
        });
        if (packageList.length) {
          this.store.dispatch(new SetPackageList(packageList));
        }
      } else {
        if (this.VCR) this.VCR.clear();
        this.layoutAttribute.cardLayouts.forEach((c) => {
          this.createCardComponent(c, this.layoutAttribute.height);
        });
        if (this.layoutAttribute.cardLayouts.length == 0) {
          d3.select(".pack-header").style("display", "none")
        } else {
          d3.select(".pack-header").style("display", "block")
        }
      }
    });
    this.cardService.setCardTemplate = this.layoutAttribute.cardTemplate;
    this.cardService.setPaging = this.layoutAttribute.paging;

    this.route.queryParams.safeSubscribe(this, (params) => {
      this.filter.eventName = params.eventName && params.eventName;
      this.filter.venue = params.venue && params.venue;
      this.filter.fromDate = params.fromDate && params.fromDate;
      this.filter.toDate = params.toDate && params.toDate;
      this.filter.eventGroup = params.eventGroup && params.eventGroup;
      this.filter.sessionFromDate =
        params.sessionFromDate && params.sessionFromDate;
      this.filter.sessionToDate = params.sessionToDate && params.sessionToDate;
      if (this.filter.sessionFromDate && this.filter.sessionToDate) {
        this.filter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.filter.sessionFromDate)
        );
        this.filter.sessionToDate = Utility.convertToISO(
          Utility.convertISOToDate(this.filter.sessionToDate)
        );
      }
      if (params.pageSize && params.pageNumber) {
        this.filter.paging.size = params.pageSize;
        this.filter.paging.number = params.pageNumber;
        this.layoutAttribute.paging.number = params.pageNumber;
        this.pageNo = params.pageNumber - 1;
        this.layoutAttribute.paging.size = params.pageSize;
        this.cardService.getCardLayout(this.filter);
      }
    });
    this.onResize(event);
  }
  ngAfterViewInit(): void {
    // setTimeout(() => {
    this.selectedCardListView =
      this.layoutAttribute.isMapView &&
        this.layoutAttribute.cardLayouts.length > 0
        ? CARD_LIST_TOGGLE.MAP_VIEW
        : CARD_LIST_TOGGLE.CARD_VIEW;
    this.initializeAttributes();
    this.VCR.clear();
    const packageList = []
    this.layoutAttribute.cardLayouts.forEach((c) => {
      this.createCardComponent(c, this.layoutAttribute.height);
      if (
        c.cardDataType &&
        c.cardDataType === PUBLIC_PAGE_DATA_TYPE.PUBLIC_PACKAGE
      ) {
        let packageData: IPublicPackage = {
          id: c.referenceID,
          quantity: 0,
          isHaveLinkPackage: c.isHaveLinkPackage,
          isNotMemberPackage: c.isNotMemberPackage,
          isLinkPackageExpand: false,
          linkPackages: [],
          isBooked: false,
          itemQuantity: 0,
          singleDayPackage: c.singleDayPackage,
          hasTicketing:c.hasTicketing,
          maximumAttendees: 10,
          clientCategories: c.clientCategories,
          durationRange: c.durationRange,
          packageSessions: c.packageSessions,
          availability: 0
        };
        packageList.push(packageData)
      }
    });
    if (packageList.length) {
      this.store.dispatch(new SetPackageList(packageList));
    }
    this.pagerRedirect(1);
    // });

    if (this.layoutAttribute.isMapView) {
      let filter = new SearchBaseFilter();
      if (this.eventCategoryID) {
        filter.eventCategoryID = this.eventCategoryID.toString();
        filter.venue = this.filter.venue ? this.filter.venue : undefined;
      }
      this.venueService.getVenue(filter).safeSubscribe(this, (data) => {
        this.venues = this.filter.venue
          ? data.filter((x) => x.id === this.filter.venue)
          : data;
        if (this.venues[0]) {
          this.rootLat = +this.venues[0].latitude;
          this.rootLng = +this.venues[0].longitude;
        }
        this.canShowMap = true;
      });
    }
    this.onResize(event);
  }
  onCardViewChange(t?) {
    t === CARD_LIST_TOGGLE.MAP_VIEW
      ? (this.layoutAttribute.paging.number = 1)
      : "";
    this.selectedCardListView = t;
    this.previousAGMWindow = undefined;
    if (this.selectedCardListView === CARD_LIST_TOGGLE.CARD_VIEW) {
      this.categoryCardRrs = [];
      if (this.VCR) this.VCR.clear();
      setTimeout(() => {
        this.initializeAttributes();
        this.layoutAttribute.cardLayouts.forEach((c) => {
          this.createCardComponent(c, this.layoutAttribute.height);
        });
        this.pagerRedirect(1);
      }, 1);
    }
  }
  bookEvent(e) {
    if (
      this.pageConfiguration.includes(
        PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION
      )
    ) {
      console.log(this.attendees);
      this.store.dispatch(new AddEvent(e.id));
      this.router.navigate(["package-list/" + e.id], {
        queryParams: {
          // startTime: '2021-04-29T20:00:00',
          // endTime: '2021-05-2T20:00:00'
          attendees:
            !!this.attendees && this.attendees != 0
              ? this.attendees
              : undefined,
        },
      });
    } else {
      this.router.navigate(["date-and-session", e.id]);
    }
  }
  onVenueLocationSelect(vid, infowindow) {
    this.eventsForVenue = [];
    this.openedMapWindow = vid; //
    if (this.previousAGMWindow) {
      this.previousAGMWindow.close();
    }
    this.previousAGMWindow = infowindow;
    this.previousAGMWindow.close();
    let filter = new SearchBaseFilter();
    filter.venue = vid;
    if (this.filter.eventName) {
      filter.eventName = this.filter.eventName;
    }
    if (this.eventCategoryID) {
      filter.eventCategoryID = this.eventCategoryID.toString();
    }
    if (this.filter.sessionFromDate && this.filter.sessionToDate) {
      filter.sessionFromDate = this.filter.sessionFromDate;
      filter.sessionToDate = this.filter.sessionToDate;
    }
    let sub: Subscription = this.eventService.searchEvent(filter).subscribe((e) => {
      if (e.length === 0) {
        this.previousAGMWindow.close();
      } else {
        this.previousAGMWindow.open();
        this.eventsForVenue = e;
        console.log(e);
      }
      sub.unsubscribe();
    });
  }
  isInfoWindowOpen(id) {
    return this.openedMapWindow == id; // alternative: check if id is in array
  }
  get CARD_LIST_TOGGLE() {
    return CARD_LIST_TOGGLE;
  }
  initializeAttributes() {
    this.setPadding();
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      const mainContainer = contentElement.closest(".main-layout-container");
      if (mainContainer) {
        if (this.layoutAttribute.isGrid) {
          d3.select(contentElement.parentElement).attr(
            "class",
            `col-md-${this.layoutAttribute.gridColumn}
                      col-lg-${this.layoutAttribute.gridColumn}
                      col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
          );
        }
        d3.select(mainContainer)
          .style(
            "margin-top",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          )
          .style(
            "margin-bottom",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          );

        if (this.layoutAttribute.isLazyLoading) {
          this.lazyLoadPageZise = this.layoutAttribute.paging.size;
          d3.select(mainContainer)
            .select(".card-List")
            .style(
              "max-height",
              this.layoutAttribute.height *
              this.layoutAttribute.lazyLoadingEnableAfter +
              // 50 +
              "px"
            )
            .style("overflow-y", "auto");
        }
      }
    }
  }
  onScroll(e) {
    if (
      !this.layoutAttribute.isPagination &&
      this.layoutAttribute.paging &&
      this.layoutAttribute.paging.totalResultCount >=
      this.layoutAttribute.paging.size * this.layoutAttribute.paging.number
    ) {
      this.layoutAttribute.paging.number += 1;
      this.cardService.setPaging = this.layoutAttribute.paging;
      this.cardService.getCardLayout(this.filter);
    }
  }
  onResize(event) {
    if (document.getElementById("mobile-view")) {
      var view = document.getElementById("mobile-view").classList;
      var viewPaginator = document.getElementById("paginator-mobile");
      if (window.innerWidth <= 768) {
        // view.remove(this.layoutAttribute.paginationPosition);
        // view.add('Top_Center');
        viewPaginator.setAttribute(
          "style",
          "width:max-content;margin-top:10px"
        );
      } else {
        view.add(this.layoutAttribute.paginationPosition);
        viewPaginator.removeAttribute("style");
      }
    }
  }
  setPadding() {
    if (
      this.layoutAttribute.isPagination &&
      this.layoutAttribute.paging
      // this.pager.pages &&
      // this.pager.pages.length > 1
    ) {
      if (
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.TOP_RIGHT ||
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.TOP_LEFT ||
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.TOP_CENTER
      ) {
        if (this.layoutAttribute.isMapView) {
          this.paddingTop = "50px";
        } else {
          this.paddingTop = "45px";
        }
        this.paddingBottom = "0px";
      } else if (
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.BOTTOM_RIGHT ||
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.BOTTOM_CENTER ||
        this.layoutAttribute.paginationPosition ===
        PAGINATION_POSITION.BOTTOM_LEFT
      ) {
        this.paddingBottom = "30px";
        if (this.layoutAttribute.isMapView) {
          this.paddingTop = "15px";
        } else {
          this.paddingTop = "25px";
        }
      }
    }
  }

  pageEvents(event: any) {
    const filter = !!this.filter ? this.filter : new SearchBaseFilter();
    filter.paging = new Paging();
    filter.paging.size = this.layoutAttribute.paging.size.toString();
    filter.sessionFromDate = this.searchBaseFilter.sessionFromDate;
    filter.sessionToDate = this.searchBaseFilter.sessionToDate;
    filter.businessArea = this.searchBaseFilter.businessArea;
    filter.eventSalesCategory = this.searchBaseFilter.eventSalesCategory;
    this.layoutAttribute.paging.number =
      event.previousPageIndex > event.pageIndex
        ? Number(this.layoutAttribute.paging.number) - 1
        : Number(this.layoutAttribute.paging.number) + 1;
    filter.paging.number = this.layoutAttribute.paging.number.toString();
    let url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { 'pageSize': filter.paging.size, 'pageNumber': filter.paging.number } }).toString();
    this.router.navigateByUrl(url);
  }

  pagerRedirect(n: number) {
    this.categoryCardRrs = [];
    if (this.VCR) this.VCR.clear();
    // this.layoutAttribute.cardLayouts = [];
    this.setPage(n);
    this.createCardForPages();
  }

  createCardComponent(card: CardLayout, height: number) {
    if (!this.VCR) return;
    const componentFactory =
      this.CFR.resolveComponentFactory(CardLayoutComponent);
    const childComponentRef = this.VCR.createComponent(componentFactory);
    const childComponent: any = childComponentRef.instance;
    childComponent.unique_key = card.componentKey;
    childComponent.isView = true;
    childComponent.type = LAYOUT_TYPE.CARD_LIST;
    childComponent.cardGridSize =
      this.layoutAttribute.numberOfColumns === 1
        ? 12
        : this.layoutAttribute.numberOfColumns === 2
          ? 6
          : this.layoutAttribute.numberOfColumns === 3
            ? 4
            : this.layoutAttribute.numberOfColumns === 4
              ? 3
              : 12;
    childComponent.layoutAttribute = card;
    childComponent.layoutAttribute.height = height;
    childComponent.elementID =
      "view_" + childComponent.type + "_" + childComponent.unique_key;
    this.categoryCardRrs.push(childComponentRef);
  }

  setPage(page: number) {
    const cardPerPage =
      this.layoutAttribute.rowsPerPage * this.layoutAttribute.numberOfColumns;
    this.pager = this.getPager(
      this.layoutAttribute.cardLayouts.length,
      page,
      cardPerPage
    );
    if (this.pager.endPage < page) {
      this.pagerRedirect(this.pager.endPage);
    }
    this.pagedItems = this.layoutAttribute.cardLayouts.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  createCardForPages() {
    this.pagedItems.forEach((c) => {
      const isComponentCreated =
        this.categoryCardRrs.filter(
          (x) => x.instance.unique_key === c.unique_key
        ).length > 0;
      if (!isComponentCreated) {
        this.createCardComponent(c, this.layoutAttribute.height);
      }
    });
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 3) {
    const totalPages = Math.ceil(totalItems / pageSize);
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
    const pager: Pager = {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
    return pager;
  }
}

import { environment } from "src/environments/environment";
import { Booking, DeliveryMethod } from "src/modules/models/booking/booking";
import { Package } from "../models/public-web/Package";
import { AvailabilityConfiguration, Massage, PublicBookingSettings, RepeatPolicy } from "../models/settings/public-booking/public-booking-setting";
import { IDateAndSession, IPublicPackage } from "../store/public-web/public-web-reducers";
import { Utility } from "../utility";
import * as _ from 'lodash';
import { BookingPackages } from "../models/booking/booking-packages";
import { BookingContact } from "../models/booking/booking-contact";
import { BookingItems } from "../models/booking/booking-items";
import moment from "moment";
import { BookingQuestion } from "../models/regular-portal/booking/question/booking-question";
import { Store } from "@ngrx/store";
import { SelectBookedPackage } from "../store/public-web/public-web-actions";
import { from, of } from "rxjs";
import { groupBy, map, mergeMap, reduce, toArray } from "rxjs/operators";
import { Host } from "../models/booking/Attendee";
import { Allocation, AllocationType } from "../models/public-web/booking/allocation";
import { PublicEventFacilityTimeslot } from "../models/public-web/booking/publicEventFacilityTimeslot";
import { Asset } from "src/modules/models/booking/asset";
import { EventSeatingConfiguration } from "../models/public-web/booking/eventSeatingConfiguration";
import { BOOKING_CART } from "../models/public-web/enum";

export class PBUtility {
  static availabilityConfiguration: AvailabilityConfiguration;

  static sortDates(a, b) {
    return a.getTime() - b.getTime();
  }

  // for multi day attendee
  static mapHostByPAckageDate(hosts: Host[]) {
    // const selectedHost = [];
    // const groupedEmployees$: any =
    from(hosts).pipe(
      groupBy(host => host.packageDate.id),
      mergeMap(group => group
        .pipe(
          reduce((acc, cur) => {
            acc.values.push(cur);
            return acc;
          },
            { key: group.key, values: [] }
          )
        )
      ),
      toArray()
    ).subscribe(d => {
      if (d.length > 0) {
        return d[0]
      }
    });
  }
  static getAvailability(available, publicPackage) {
    this.availabilityConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration;
    let backgroundColor = "";
    //Need to find the percentage of availablity
    const capacity = publicPackage.packageSessions.reduce((accumulator, obj) => {
      return accumulator + obj.capacity;
    }, 0);
    const availablePercentage = Math.ceil(available / capacity * 100);
    const thresholds = this.availabilityConfiguration.availabilityThresholds;
    if (thresholds.length > 0) {
      thresholds.forEach(threshold => {
        if (availablePercentage >= threshold.availabilityFrom && availablePercentage <= threshold.availabilityTo) {
          backgroundColor = threshold.thresholdColor;
          return;
        }
        if (availablePercentage > 100) {
          backgroundColor = "green";
        }
      })
    }
    return backgroundColor;
  }

  static getSeatAvailability(totalSeats: number, availableSeats: number) {
    this.availabilityConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration;
    let backgroundColor = "";
    let availabilitytext = "";
    let textColor = ""
    let availablePercentage = 0;
    if (totalSeats != 0) {
      availablePercentage = Math.ceil((availableSeats / totalSeats) * 100);
    }
    const thresholds = this.availabilityConfiguration.availabilityThresholds;
    if (thresholds.length > 0) {
      thresholds.forEach(threshold => {
        if (availableSeats == 0 && totalSeats > 0) {
          backgroundColor = "unset";
          availabilitytext = "Sold out";
          textColor = "#00000";
        }
        else if (availablePercentage >= threshold.availabilityFrom && availablePercentage <= threshold.availabilityTo) {
          backgroundColor = threshold.thresholdColor;
          if (availableSeats >= 100) {
            availabilitytext = "99+";
          }
          else {
            availabilitytext = availableSeats.toString();
          }
          textColor = "#ffffff";
          return;
        }
      })
    }
    return { backgroundColor, availabilitytext, textColor};
  }

  static getBookingTotal(booking: any) {
    let amount = 0
    amount = booking && booking.grossAmount ? booking.grossAmount : 0;
    return amount
  }
  // make booking session to update every booking package and booking item
  static makeFullBookingSession(sessions: IDateAndSession[]) {
    sessions.forEach(session => {
      session.packages.forEach(pak => {
        pak.isBooked = false;
        if (pak.addOns && pak.addOns.length > 0) {
          pak.addOns.forEach(x => x.isNeedToBeUpdate = true)
        }
        if (pak.linkPackages && pak.linkPackages.length > 0)
          pak.linkPackages.forEach((lpk) => {
            lpk.isBooked = false;
          })
      })
      if (session.durationRange && session.durationRange.packageDates && session.durationRange.packageDates.length > 0)
        session.durationRange.packageDates.filter(x => x.items && x.items.length > 0)
          .forEach(x => x.items.forEach(it => it.item.isNeedToBeUpdate = true))
    })
    return sessions;
  }
  static convertBookingIntoPBSessionData(booking: Booking, packages: Package[], store: Store<any> = undefined) {
    const sessions: IDateAndSession[] = []
    if(booking && packages.length > 0){
      booking.bookingPackages.forEach(bookingPackage => {
        const packageData = packages.filter(p => !!p.name).find(p => p.id === bookingPackage.package.id);
        if (packageData) {
          const bookingPackageStartDate = Utility.convertToISO(
            Utility.convertISOToDate(bookingPackage.startDate)
          );
          const bookingPackageEndDate = Utility.convertToISO(
            Utility.convertISOToDate(bookingPackage.endDate)
          );
          let sessionIndex = sessions.findIndex(x =>
            x.startTime === bookingPackageStartDate
            && x.endTime === bookingPackageEndDate);
          let session: IDateAndSession;
          if (sessionIndex === -1) {
            session = {
              id: sessions ? (sessions.length + 1).toString() : '-1',
              startTime: bookingPackageStartDate,
              endTime: bookingPackageEndDate,
              availability: 0,
              packages: [],
              bookingPackages: []
            }
            sessions.push(session)
            sessionIndex = sessionIndex = sessions.findIndex(x =>
              x.startTime === bookingPackageStartDate
              && x.endTime === bookingPackageEndDate);
          }

          sessions[sessionIndex].bookingPackages.push({ id: bookingPackage.id, packageId: packageData.id });
          const isChildPackage = packageData.linkedPackageType.adultRequired || (packageData.primaryPackage && packageData.primaryPackage.id);
          let iPackage: IPublicPackage;
          let iPackIndex;
          if (isChildPackage && packageData.primaryPackage && packageData.primaryPackage.id) {
            iPackIndex = sessions[sessionIndex].packages.findIndex(x => x.id == packageData.primaryPackage.id);
            iPackage = sessions[sessionIndex].packages[iPackIndex];
            if (iPackage) {
              iPackage.linkPackages.filter(x => x.id === packageData.id).forEach(x => {
                x.attendees = parseInt(bookingPackage.quantity);
                x.quantity = parseInt(bookingPackage.quantity);
              })
            }
          } else {
            const linked = _.cloneDeep(packageData);
            linked.linkedPackages = undefined;
            iPackage = {
              id: packageData.id,
              isBooked: true,
              quantity: parseInt(bookingPackage.quantity),
              maximumAttendees: packageData.minimumAttendees,
              isHaveLinkPackage: packageData.linkedPackages && packageData.linkedPackages.length > 0,
              singleDayPackage: packageData.singleDayPackage,
              hasTicketing: false,
              isLinkPackageExpand: false,
              linkPackages: [],
              itemQuantity: 0,
              isNotMemberPackage: false,
              clientCategories: packageData.packageClientCategories,
              packageSessions: packageData.packageSessions,
              addOns: [],
              availability: 0

            };
            if (iPackage.isHaveLinkPackage) {
              iPackage.linkPackages = [linked, ...packageData.linkedPackages]
              iPackage.linkPackages.filter(x => x.id === iPackage.id).forEach(x => {
                x.attendees = iPackage.quantity;
                x.quantity = iPackage.quantity;
              })
            }
            sessions[sessionIndex].packages.push(iPackage)
            iPackIndex = sessions[sessionIndex].packages.findIndex(x => x.id == packageData.id);
          }
          if (iPackage) {
            if (!packageData.singleDayPackage && packageData.packageDurationRanges && packageData.packageDurationRanges.length > 0) {
              // for multi day with repeat policy as select individual day
              let packageDurationRange;
              if (!sessions[sessionIndex].durationRange) {
                packageDurationRange = packageData.packageDurationRanges.find(pdr =>
                  pdr.packageDates.length !== 0 &&
                  Utility.convertToISO(
                    Utility.convertISOToDate(
                      pdr.packageDates.sort((x, y) =>
                        x.eventDate.date > y.eventDate.date ? 1 : 0
                      )[0].eventDate.startTime
                    )
                  ) === bookingPackageStartDate &&
                  Utility.convertToISO(
                    Utility.convertISOToDate(
                      pdr.packageDates.sort((x, y) =>
                        x.eventDate.date > y.eventDate.date ? 1 : 0
                      )[pdr.packageDates.length - 1].eventDate.endTime
                    )
                  ) === bookingPackageEndDate
                );
              } else {
                packageDurationRange = sessions[sessionIndex].durationRange
              }

              if (packageDurationRange) {
                let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell && (bp.item as any).repeatPolicy === (
                  environment.PublicBookingSetting as PublicBookingSettings
                ).repeatPolicy.allowIndividualDays);
                bookingUpSellItem.forEach(bookingItem => {
                  const bookingItemStartDate = Utility.convertToISO(
                    Utility.convertISOToDate(bookingItem.startTime)
                  );
                  const bookingItemEndDate = Utility.convertToISO(
                    Utility.convertISOToDate(bookingItem.endTime)
                  );
                  const item: any = _.cloneDeep(bookingItem.item);
                  item.qty = bookingItem.quantity;
                  item.isNeedToBeUpdate = false;
                  const packageDateIndex = packageDurationRange.packageDates.findIndex(x =>
                    Utility.convertToISO(
                      Utility.convertISOToDate(x.eventDate.startTime)
                    )
                    === bookingItemStartDate
                    && Utility.convertToISO(
                      Utility.convertISOToDate(x.eventDate.endTime)
                    ) === bookingItemEndDate);
                  if (packageDateIndex != -1) {
                    if (!packageDurationRange.packageDates[packageDateIndex].items)
                      packageDurationRange.packageDates[packageDateIndex].items = [];
                    const isItemExist = packageDurationRange.packageDates[packageDateIndex].items.findIndex(x =>
                      x.packageID === packageData.id && x.item.id === item.id);
                    if (isItemExist === -1) {
                      packageDurationRange.packageDates[packageDateIndex].items.push({
                        packageID: packageData.id,
                        item: item,
                        bookingItemID: bookingItem.id
                      });
                    } else {
                      packageDurationRange.packageDates[packageDateIndex].items[isItemExist] = {
                        packageID: packageData.id,
                        item: item,
                        bookingItemID: bookingItem.id
                      }
                    }
                  } else {
                    console.error(`Package Date not selected for Up sell: ${item.name} Date ${bookingItemStartDate}`);

                  }
                })
                sessions[sessionIndex].durationRange = packageDurationRange;
              } else {
                console.error(`package Duration Range is undefined for Package: ${packageData.name} Booking Date: ${bookingPackageStartDate}`);
              }
            }
            // let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell && (!(bp.item as any).repeatPolicy || (bp.item as any).repeatPolicy !== (
            //   environment.PublicBookingSetting as PublicBookingSettings
            // ).repeatPolicy.allowIndividualDays));
            let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell);
            if (bookingUpSellItem && bookingUpSellItem.length > 0) {
              bookingUpSellItem.forEach(bookingItem => {
                const item: any = _.cloneDeep(bookingItem.item);
                item.quantity = bookingItem.quantity;
                item.qty = bookingItem.quantity;
                item.isNeedToBeUpdate = false;
                iPackage.addOns.push(item)
              })
            }
            sessions[sessionIndex].packages[iPackIndex] = iPackage;
          }
        } else {
          // Check whether is a linked package or not
          const adultPackage = packages.find(p => !!p.linkedPackages && p.linkedPackages.find(x => x.id === bookingPackage.package.id));
          if (adultPackage) {
            const linkedPackageID = bookingPackage.package.id;
            const bookingPackageStartDate = Utility.convertToISO(
              Utility.convertISOToDate(bookingPackage.startDate)
            );
            const bookingPackageEndDate = Utility.convertToISO(
              Utility.convertISOToDate(bookingPackage.endDate)
            );
            let sessionIndex = sessions.findIndex(x =>
              x.startTime === bookingPackageStartDate
              && x.endTime === bookingPackageEndDate);

            if (sessionIndex != -1) {
              sessions[sessionIndex].bookingPackages.push({ id: bookingPackage.id, packageId: linkedPackageID })
              const adultPackageIndex = sessions[sessionIndex].packages.findIndex(x => x.id === adultPackage.id);
              if (adultPackageIndex != -1) {
                const linkedPackageIndex = sessions[sessionIndex].packages[adultPackageIndex].linkPackages.findIndex(X => X.id === linkedPackageID);
                if (linkedPackageIndex != -1) {
                  sessions[sessionIndex]
                    .packages[adultPackageIndex]
                    .linkPackages[linkedPackageIndex].quantity = parseInt(bookingPackage.quantity);
                  sessions[sessionIndex]
                    .packages[adultPackageIndex]
                    .linkPackages[linkedPackageIndex].isBooked = false;
                }
              }
            } else {
              debugger
            }
          } else {
            //Suppose the package is not in the bookedPackages list. here package pushed into the bookedPackages array
            //console.error(`package undefined in the list ${bookingPackage.name}`);
            const renderBookedPackages = (booking: Booking, bookedPackages: Package[]) => {
              const packages: Package[] = [];
              booking.bookingPackages.forEach(bp => {
                const isExist = bookedPackages.findIndex(p => p.id === bp.package.id);
                if (isExist != -1) {
                  packages.push(bookedPackages[isExist])
                } else {
                  const d = new Package()
                  d.id = bp.package.id;
                  packages.push(d)
                }
              })
              if (store)
                store.dispatch(new SelectBookedPackage(packages))
            }
            renderBookedPackages(booking, packages)
          }
        }
      });
    }
    return sessions;
  }
  static convertBookingPackageIntoPBSessionData(bookingPackages: any[], packages: Package[], store: Store<any> = undefined) {
    const sessions: IDateAndSession[] = []
    if(bookingPackages.length > 0 && packages.length > 0){
      bookingPackages.forEach(bookingPackage => {
        const packageData = packages.filter(p => !!p.name).find(p => p.id === bookingPackage.package.id);
        if (packageData) {
          const bookingPackageStartDate = Utility.convertToISO(
            Utility.convertISOToDate(bookingPackage.startDate)
          );
          const bookingPackageEndDate = Utility.convertToISO(
            Utility.convertISOToDate(bookingPackage.endDate)
          );
          let sessionIndex = sessions.findIndex(x =>
            x.startTime === bookingPackageStartDate
            && x.endTime === bookingPackageEndDate);
          let session: IDateAndSession;
          if (sessionIndex === -1) {
            session = {
              id: sessions ? (sessions.length + 1).toString() : '-1',
              startTime: bookingPackageStartDate,
              endTime: bookingPackageEndDate,
              availability: 0,
              packages: [],
              bookingPackages: []
            }
            sessions.push(session)
            sessionIndex = sessionIndex = sessions.findIndex(x =>
              x.startTime === bookingPackageStartDate
              && x.endTime === bookingPackageEndDate);
          }

          sessions[sessionIndex].bookingPackages.push({ id: bookingPackage.id, packageId: packageData.id });
          const isChildPackage = packageData.linkedPackageType.adultRequired || (packageData.primaryPackage && packageData.primaryPackage.id);
          let iPackage: IPublicPackage;
          let iPackIndex;
          if (isChildPackage && packageData.primaryPackage && packageData.primaryPackage.id) {
            iPackIndex = sessions[sessionIndex].packages.findIndex(x => x.id == packageData.primaryPackage.id);
            iPackage = sessions[sessionIndex].packages[iPackIndex];
            if (iPackage) {
              iPackage.linkPackages.filter(x => x.id === packageData.id).forEach(x => {
                x.attendees = parseInt(bookingPackage.quantity);
                x.quantity = parseInt(bookingPackage.quantity);
              })
            }
          } else {
            const linked = _.cloneDeep(packageData);
            linked.linkedPackages = undefined;
            iPackage = {
              id: packageData.id,
              isBooked: true,
              quantity: parseInt(bookingPackage.quantity),
              maximumAttendees: packageData.minimumAttendees,
              isHaveLinkPackage: packageData.linkedPackages && packageData.linkedPackages.length > 0,
              singleDayPackage: packageData.singleDayPackage,
              hasTicketing: false,
              isLinkPackageExpand: false,
              linkPackages: [],
              itemQuantity: 0,
              isNotMemberPackage: false,
              clientCategories: packageData.packageClientCategories,
              packageSessions: packageData.packageSessions,
              addOns: [],
              availability: 0

            };
            if (iPackage.isHaveLinkPackage) {
              iPackage.linkPackages = [linked, ...packageData.linkedPackages]
              iPackage.linkPackages.filter(x => x.id === iPackage.id).forEach(x => {
                x.attendees = iPackage.quantity;
                x.quantity = iPackage.quantity;
              })
            }
            sessions[sessionIndex].packages.push(iPackage)
            iPackIndex = sessions[sessionIndex].packages.findIndex(x => x.id == packageData.id);
          }
          if (iPackage) {
            if (!packageData.singleDayPackage && packageData.packageDurationRanges && packageData.packageDurationRanges.length > 0) {
              // for multi day with repeat policy as select individual day
              let packageDurationRange;
              if (!sessions[sessionIndex].durationRange) {
                packageDurationRange = packageData.packageDurationRanges.find(pdr =>
                  pdr.packageDates.length !== 0 &&
                  Utility.convertToISO(
                    Utility.convertISOToDate(
                      pdr.packageDates.sort((x, y) =>
                        x.eventDate.date > y.eventDate.date ? 1 : 0
                      )[0].eventDate.startTime
                    )
                  ) === bookingPackageStartDate &&
                  Utility.convertToISO(
                    Utility.convertISOToDate(
                      pdr.packageDates.sort((x, y) =>
                        x.eventDate.date > y.eventDate.date ? 1 : 0
                      )[pdr.packageDates.length - 1].eventDate.endTime
                    )
                  ) === bookingPackageEndDate
                );
              } else {
                packageDurationRange = sessions[sessionIndex].durationRange
              }

              if (packageDurationRange) {
                let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell && (bp.item as any).repeatPolicy === (
                  environment.PublicBookingSetting as PublicBookingSettings
                ).repeatPolicy.allowIndividualDays);
                bookingUpSellItem.forEach(bookingItem => {
                  const bookingItemStartDate = Utility.convertToISO(
                    Utility.convertISOToDate(bookingItem.startTime)
                  );
                  const bookingItemEndDate = Utility.convertToISO(
                    Utility.convertISOToDate(bookingItem.endTime)
                  );
                  const item: any = _.cloneDeep(bookingItem.item);
                  item.qty = bookingItem.quantity;
                  item.isNeedToBeUpdate = false;
                  const packageDateIndex = packageDurationRange.packageDates.findIndex(x =>
                    Utility.convertToISO(
                      Utility.convertISOToDate(x.eventDate.startTime)
                    )
                    === bookingItemStartDate
                    && Utility.convertToISO(
                      Utility.convertISOToDate(x.eventDate.endTime)
                    ) === bookingItemEndDate);
                  if (packageDateIndex != -1) {
                    if (!packageDurationRange.packageDates[packageDateIndex].items)
                      packageDurationRange.packageDates[packageDateIndex].items = [];
                    const isItemExist = packageDurationRange.packageDates[packageDateIndex].items.findIndex(x =>
                      x.packageID === packageData.id && x.item.id === item.id);
                    if (isItemExist === -1) {
                      packageDurationRange.packageDates[packageDateIndex].items.push({
                        packageID: packageData.id,
                        item: item,
                        bookingItemID: bookingItem.id
                      });
                    } else {
                      packageDurationRange.packageDates[packageDateIndex].items[isItemExist] = {
                        packageID: packageData.id,
                        item: item,
                        bookingItemID: bookingItem.id
                      }
                    }
                  } else {
                    console.error(`Package Date not selected for Up sell: ${item.name} Date ${bookingItemStartDate}`);

                  }
                })
                sessions[sessionIndex].durationRange = packageDurationRange;
              } else {
                console.error(`package Duration Range is undefined for Package: ${packageData.name} Booking Date: ${bookingPackageStartDate}`);
              }
            }
            // let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell && (!(bp.item as any).repeatPolicy || (bp.item as any).repeatPolicy !== (
            //   environment.PublicBookingSetting as PublicBookingSettings
            // ).repeatPolicy.allowIndividualDays));
            let bookingUpSellItem = bookingPackage.bookingItems.filter(bp => bp.upsell);
            if (bookingUpSellItem && bookingUpSellItem.length > 0) {
              bookingUpSellItem.forEach(bookingItem => {
                const item: any = _.cloneDeep(bookingItem.item);
                item.quantity = bookingItem.quantity;
                item.qty = bookingItem.quantity;
                item.isNeedToBeUpdate = false;
                iPackage.addOns.push(item)
              })
            }
            sessions[sessionIndex].packages[iPackIndex] = iPackage;
          }
        } else {
          // Check whether is a linked package or not
          const adultPackage = packages.find(p => !!p.linkedPackages && p.linkedPackages.find(x => x.id === bookingPackage.package.id));
          if (adultPackage) {
            const linkedPackageID = bookingPackage.package.id;
            const bookingPackageStartDate = Utility.convertToISO(
              Utility.convertISOToDate(bookingPackage.startDate)
            );
            const bookingPackageEndDate = Utility.convertToISO(
              Utility.convertISOToDate(bookingPackage.endDate)
            );
            let sessionIndex = sessions.findIndex(x =>
              x.startTime === bookingPackageStartDate
              && x.endTime === bookingPackageEndDate);

            if (sessionIndex != -1) {
              sessions[sessionIndex].bookingPackages.push({ id: bookingPackage.id, packageId: linkedPackageID })
              const adultPackageIndex = sessions[sessionIndex].packages.findIndex(x => x.id === adultPackage.id);
              if (adultPackageIndex != -1) {
                const linkedPackageIndex = sessions[sessionIndex].packages[adultPackageIndex].linkPackages.findIndex(X => X.id === linkedPackageID);
                if (linkedPackageIndex != -1) {
                  sessions[sessionIndex]
                    .packages[adultPackageIndex]
                    .linkPackages[linkedPackageIndex].quantity = parseInt(bookingPackage.quantity);
                  sessions[sessionIndex]
                    .packages[adultPackageIndex]
                    .linkPackages[linkedPackageIndex].isBooked = false;
                }
              }
            } else {
              debugger
            }
          } else {
            //Suppose the package is not in the bookedPackages list. here package pushed into the bookedPackages array
            //console.error(`package undefined in the list ${bookingPackage.name}`);
            // const renderBookedPackages = (booking: Booking, bookedPackages: Package[]) => {
            //   const packages: Package[] = [];
            //   booking.bookingPackages.forEach(bp => {
            //     const isExist = bookedPackages.findIndex(p => p.id === bp.package.id);
            //     if (isExist != -1) {
            //       packages.push(bookedPackages[isExist])
            //     } else {
            //       const d = new Package()
            //       d.id = bp.package.id;
            //       packages.push(d)
            //     }
            //   })
            //   if (store)
            //     store.dispatch(new SelectBookedPackage(packages))
            // }
            // renderBookedPackages(booking, packages)
          }
        }
      });
    }
    return sessions;
  }
  static includes(text: any, searchString: string) {
    return text.indexOf(searchString) >= 0;
  }

  static setDateFormat(start: any, end: any) {

    let startDate = moment(start).format('MMM DD, YYYY');
    let endDate = moment(end).format('MMM DD, YYYY');

    if (startDate && endDate) {
      //get month
      let startMonth = moment(start).format('MMMM');
      let endMonth = moment(end).format('MMMM');

      //get Year
      let startYear = moment(start).format('YYYY');
      let endYear = moment(end).format('YYYY');

      //get day
      let startDay = moment(start).format('DD');
      let endDay = moment(end).format('DD');

      if (startMonth === endMonth && startYear === endYear) {
        return parseInt(startDay) + " - " + parseInt(endDay) + " " + startMonth + " " + startYear;
      }
      else if (startMonth !== endMonth && startYear === endYear) {
        return parseInt(startDay) + " " + startMonth + " - " + parseInt(endDay) + " " + endMonth + " " + startYear;
      }
      else {
        return parseInt(startDay) + " " + startMonth + " " + startYear + " - " + parseInt(endDay) + " " + endMonth + " " + endYear;
      }
    }
    return "Invalid Date";
    // Date Format should be Below for Upsell as well
    // Same month: 3-5 February 2025
    // Same year: 3 February - 5 March 2025
    // Different years: 3 February 2025 - 5 March 2026
  }

  static updateBookingWithUpdateQuestionnaireAnswer(booking: any, bookingQuestions: BookingQuestion[], questionToPatch: BookingQuestion[]) {
    let updatedBookingQuestion;
    questionToPatch.forEach(bookingQuestion => {
      let bqsIndex, bqIndex, bqPatchIndex;
      if(bookingQuestions.length > 0){
        updatedBookingQuestion = bookingQuestions.find(bq => bqIndex = bq?.id === bookingQuestion.id);
      }
      bqsIndex = booking.bookingQuestionnaires.findIndex(bqs => {
        bqIndex = bqs.bookingQuestions.findIndex(bq => bqIndex = bq.id === bookingQuestion.id);
        return bqIndex != -1;
      });
      if (bqsIndex !== -1 && bqIndex != -1) {
        const oldBQ = _.cloneDeep(booking.bookingQuestionnaires[bqsIndex].bookingQuestions[bqIndex]);
        if(updatedBookingQuestion) {
          booking.bookingQuestionnaires[bqsIndex].bookingQuestions[bqIndex] = this.mapBookingQuestion(oldBQ, updatedBookingQuestion);
        } else {
          booking.bookingQuestionnaires[bqsIndex].bookingQuestions[bqIndex] = this.mapBookingQuestion(oldBQ, bookingQuestion);
        }
      }
    })
    return booking;
  }
  private static mapBookingQuestion(old, bookingQuestion) {
    old.answeredTime = bookingQuestion.answeredTime
    switch (bookingQuestion.type) {
      case "textQuestionAnswer":
        old.answer = bookingQuestion.answer
        break;
      case "richTextQuestionAnswer":
        old.answer = bookingQuestion.answer

        break;
      case "booleanQuestionAnswer":
        old.answer = bookingQuestion.answer
        break;
      case "singleChoiceQuestionAnswer":
        old.bookingAnswerChoice = bookingQuestion.bookingAnswerChoice
        break;
      case "multipleChoiceQuestionAnswer":
        old.bookingAnswerChoice = bookingQuestion.bookingAnswerChoice
        break;
      case "dateAndTimeQuestionAnswer":
        old.date = bookingQuestion.date
        old.time = bookingQuestion.time
        break;
      case "fileUploadQuestionAnswer":
        old.answerUplodedFiles = bookingQuestion.answerUplodedFiles
        break;
    }
    if (old.conditionalBookingQuestions && old.conditionalBookingQuestions.length > 0) {
      old.conditionalBookingQuestions = old.conditionalBookingQuestions.map(bq => {
        if (bookingQuestion.conditionalBookingQuestions) {
          const newBQ = bookingQuestion.conditionalBookingQuestions.find(x => x.id === bq.id)
          if (newBQ) {
            return this.mapBookingQuestion(bq, newBQ);
          }
        }
        return bq;
      })
    }
    return old;
  }
  static generateBookingPatchBody(sessions: IDateAndSession[], PBReducer, cart) {
    const newBooking = new Booking();
    const bookingItemTypeNames = [];
    const repeatPolicies = (environment.PublicBookingSetting as PublicBookingSettings).repeatPolicy;
    newBooking.id = PBReducer.booking.id;
    if (!!PBReducer.booking.deliveryMethod) {
      newBooking.deliveryMethod = new DeliveryMethod();
      newBooking.deliveryMethod.id = PBReducer.booking.deliveryMethod.id
    }
    newBooking.specialNote = PBReducer.booking.specialNote;
    // Create Booking Package for Post And Patch
    if (PBReducer) {
      newBooking.bookingPackages = [];
      sessions.forEach((session: IDateAndSession) => {
        if (!!session.packages) {
          const sessionStart = Utility.convertToISO(
            Utility.convertISOToDate(session.startTime)
          );
          const sessionEnd = Utility.convertToISO(
            Utility.convertISOToDate(session.endTime)
          );
          session.packages.forEach((pk) => {
            if (!pk.isBooked) {
              if (
                pk.isHaveLinkPackage &&
                pk.linkPackages &&
                pk.linkPackages.length > 0
              ) {
                const selectedLinkPk = pk.linkPackages.filter(
                  (p) => p.isBooked === false
                );
                selectedLinkPk.forEach((lpk) => {
                  newBooking.bookingPackages = this.generateBookingPackage(
                    lpk,
                    session,
                    newBooking.bookingPackages,
                    PBReducer.booking.bookingPackages.filter(
                      (apk) => apk.package.id === lpk.id
                    )
                  );
                });
                // }
              } else {
                newBooking.bookingPackages = this.generateBookingPackage(
                  pk,
                  session,
                  newBooking.bookingPackages,
                  PBReducer.booking.bookingPackages.filter(
                    (apk) => apk.package.id === pk.id
                  )
                );
              }
              pk.isBooked = true;
            }
            if (!!pk.addOns) {
              pk.addOns.forEach((item) => {
                if (item.isNeedToBeUpdate && item.itemTypeID !== 2) {
                  let packageID = pk.id;
                  let bookingPackage: BookingPackages;

                  let durationDates = [];
                  if (
                    session.durationRange &&
                    session.durationRange.packageDates
                  ) {
                    durationDates = _.cloneDeep(
                      session.durationRange.packageDates
                    );
                    durationDates.sort((x, y) =>
                      x.eventDate.date > y.eventDate.date ? 1 : 0
                    );
                  }
                  if (PBReducer.booking.bookingPackages) {
                    const selectedPackage = _.cloneDeep(pk);
                    // * if it have linked package
                    if (
                      selectedPackage &&
                      selectedPackage.isHaveLinkPackage &&
                      selectedPackage.linkPackages &&
                      selectedPackage.linkPackages.length > 0
                    ) {
                      const select = selectedPackage.linkPackages.filter(
                        (x) =>
                          !!PBReducer.booking.bookingPackages.find(
                            (p) => p.package.id === x.id
                          ) && x.quantity > 0
                      );
                      if (select.length > 0) {
                        const val = select.sort(
                          (a, b) =>
                            parseInt(a.linkedPackageType.id, 10) -
                            parseInt(b.linkedPackageType.id, 10)
                        );
                        packageID = val[0].id;
                      }
                    }
                  }
                  const bookingPK =
                    PBReducer.booking.bookingPackages.find(
                      (bp) =>
                        bp.package.id === packageID &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.startDate)
                        ) === sessionStart &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.endDate)
                        ) === sessionEnd
                    );
                  // *Create Booking Package For Booking Item
                  if (bookingPK) {
                    bookingPackage = new BookingPackages();
                    bookingPackage.id = bookingPK.id;
                    bookingPackage.startDate = bookingPK.startDate;
                    bookingPackage.endDate = bookingPK.endDate;
                    if (parseInt(bookingPK.id) < 0) {
                      bookingPackage.quantity = bookingPK.quantity;
                    }
                    bookingPackage.package = new Package();
                    bookingPackage.package.id = packageID;
                    bookingPackage.bookingItems = bookingPK.bookingItems
                      .filter((i) => i !== null && i.upsell && i.item && i.item.itemTypeID !== 2)
                      .map((i) => {
                        const bookingItem = new BookingItems();
                        bookingItem.id = i.id;
                        bookingItem.type = i.type;
                        bookingItem.item.id = i.item.id;
                        bookingItem.item.type = i.item.type;
                        bookingItem.quantity = i.quantity;
                        bookingItem.startTime = Utility.convertToISO(
                          Utility.convertISOToDate(i.startTime)
                        );
                        bookingItem.endTime = Utility.convertToISO(
                          Utility.convertISOToDate(i.endTime)
                        );;
                        return bookingItem;
                      });
                    if (newBooking.bookingPackages === undefined) {
                      newBooking.bookingPackages = [bookingPackage];
                    } else {
                      const bpIndex = newBooking.bookingPackages.findIndex(
                        (bp) =>
                          bp.package.id === packageID &&
                          Utility.convertToISO(
                            Utility.convertISOToDate(bp.startDate)
                          ) === sessionStart &&
                          Utility.convertToISO(
                            Utility.convertISOToDate(bp.endDate)
                          ) === sessionEnd
                      );
                      if (bpIndex !== -1) {
                        if (!newBooking.bookingPackages[bpIndex].bookingItems) {
                            newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                        }else {
                          newBooking.bookingPackages[bpIndex].bookingItems.push(...bookingPackage.bookingItems);
                        }
                      } else {
                        newBooking.bookingPackages.push(bookingPackage);
                      }
                    }
                    bookingPackage = newBooking.bookingPackages.find(
                      (bp) =>
                        bp.package.id === packageID &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.startDate)
                        ) === sessionStart &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.endDate)
                        ) === sessionEnd
                    );
                  }
                  if (bookingPackage) {
                    bookingPackage.bookingItems =
                      bookingPackage.bookingItems === undefined
                        ? []
                        : bookingPackage.bookingItems;
                    let bookingItem = bookingPackage.bookingItems.find(
                      (i) => i.item.id === item.id
                    );
                    // bookingPackage.bookingItems = []; // Temp by Thuveepan
                    if (bookingItem === undefined) {
                      bookingItem = new BookingItems();
                      bookingItem.quantity = 0;
                      let biCount = 0;
                      newBooking.bookingPackages.forEach(
                        (b) =>
                          !!b.bookingItems && (biCount += b.bookingItems.length)
                      );
                      bookingItem.id = ((biCount + 1) * -1).toString();
                    }
                    let tp =
                      this.getItemPrefix(item.type.toLowerCase()) + item.type;
                    if (item.type.toLowerCase() == 'assetitem') {
                      tp = 'PublicBookingGeneralAdmissionItem';
                    }
                    bookingItemTypeNames.push(tp);
                    bookingItem.type = tp;
                    bookingItem.quantity = item.qty;
                    bookingItem.unitPriceExcludingTax = item.unitPriceExcludingTax;
                    bookingItem.showInInvoice = true;
                    bookingItem.name = item.name;
                    bookingItem.upsell = true;
                    bookingItem.item.id = item.id;
                    bookingItem.item.type = item.type;
                    if (durationDates.length > 0) {
                      switch (item.repeatPolicy) {
                        case repeatPolicies.startDateOnly:
                          bookingItem.startTime =
                            durationDates[0].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[0].eventDate.endTime;
                          break;
                        case repeatPolicies.endDateOnly:
                          bookingItem.startTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.endTime;
                          break;
                        case repeatPolicies.repeatAllDays:
                          bookingItem.startTime = sessionStart;
                          bookingItem.endTime = sessionEnd;
                          break;
                        case repeatPolicies.allowIndividualDays:
                          break;
                        default:
                          bookingItem.startTime =
                            durationDates[0].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.endTime;
                          break;
                      }
                    } else {
                      bookingItem.startTime = sessionStart;
                      bookingItem.endTime = sessionEnd;
                    }

                    const itemIndex = bookingPackage.bookingItems.findIndex(
                      (it) => it.id === bookingItem.id
                    );
                    if (itemIndex !== -1) {
                      bookingPackage.bookingItems[itemIndex] =
                        _.cloneDeep(bookingItem);
                    } else {
                      bookingPackage.bookingItems.push(
                        _.cloneDeep(bookingItem)
                      );
                    }
                    const ind = newBooking.bookingPackages.findIndex(
                      (b) => b.id === bookingPackage.id
                    );
                    if (ind !== -1) {
                      newBooking.bookingPackages[ind] = bookingPackage;
                    } else {
                      newBooking.bookingPackages.push(bookingPackage);
                    }
                    // });
                  } else {
                    // this.openSnackBarError([
                    //   ,
                    // ]);
                    console.error('Booking Package is not Available for this package');

                  }
                  // item.isNeedToBeUpdate = false;
                }
              });
            }
          });

          // Only work for Multi day package with upsell item repeat policy for individual date's
          // !!make sure validate that
          if (session.durationRange && session.durationRange.packageDates && session.durationRange.packageDates.length > 0) {
            session.durationRange.packageDates.forEach(packageDate => {
              const itemSessionStart = Utility.convertToISO(
                Utility.convertISOToDate(packageDate.eventDate.startTime)
              );
              const itemSessionEnd = Utility.convertToISO(
                Utility.convertISOToDate(packageDate.eventDate.endTime)
              );
              if (packageDate && packageDate.items && packageDate.items.length > 0) {
                const UPDATED_ITEMS = packageDate.items.filter(x => x.item.isNeedToBeUpdate && (x.bookingItemID || (x.bookingItemID === undefined && x.item.qty > 0)));
                if (UPDATED_ITEMS && UPDATED_ITEMS.length > 0) {
                  UPDATED_ITEMS.forEach(d => {
                    let packageID = d.packageID.toString();
                    let bookingPackage: BookingPackages;
                    const item = d.item;
                    const selectedPackage = _.cloneDeep(session.packages.find(x => x.id == packageID));
                    if (selectedPackage) {
                      if (PBReducer.booking.bookingPackages) {
                        // * if it have linked package
                        if (
                          selectedPackage &&
                          selectedPackage.isHaveLinkPackage &&
                          selectedPackage.linkPackages &&
                          selectedPackage.linkPackages.length > 0
                        ) {
                          const select = selectedPackage.linkPackages.filter(
                            (x) =>
                              !!PBReducer.booking.bookingPackages.find(
                                (p) => p.package.id === x.id
                              ) && x.quantity > 0
                          );
                          if (select.length > 0) {
                            const val = select.sort(
                              (a, b) =>
                                parseInt(a.linkedPackageType.id, 10) -
                                parseInt(b.linkedPackageType.id, 10)
                            );
                            packageID = val[0].id;
                          }
                        }
                        // find a booking package for Up sell
                        const previousBookingPackage =
                          PBReducer.booking.bookingPackages.find(
                            (bp) => {
                              const s = Utility.convertToISO(
                                Utility.convertISOToDate(bp.startDate)
                              );
                              const e = Utility.convertToISO(
                                Utility.convertISOToDate(bp.endDate)
                              )
                              return bp.package.id === packageID.toString() &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.startDate)
                                ) === sessionStart &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.endDate)
                                ) === sessionEnd
                            }

                          );
                        if (previousBookingPackage) {
                          bookingPackage = new BookingPackages();
                          bookingPackage.id = previousBookingPackage.id;
                          if (parseInt(bookingPackage.id) < 0) {
                            bookingPackage.quantity = previousBookingPackage.quantity;
                          }
                          bookingPackage.startDate = previousBookingPackage.startDate;
                          bookingPackage.endDate = previousBookingPackage.endDate;
                          bookingPackage.package = new Package();
                          bookingPackage.package.id = packageID;
                          bookingPackage.bookingItems = previousBookingPackage.bookingItems
                            .filter((i) => i !== null && i.upsell && i.item && i.item.itemTypeID !== 2)
                            .map((i) => {
                              const bookingItem = new BookingItems();
                              bookingItem.id = i.id;
                              bookingItem.type = i.type;
                              bookingItem.item.id = i.item.id;
                              bookingItem.item.type = i.item.type;
                              bookingItem.quantity = i.quantity;
                              bookingItem.startTime = Utility.convertToISO(
                                Utility.convertISOToDate(i.startTime)
                              );
                              bookingItem.endTime = Utility.convertToISO(
                                Utility.convertISOToDate(i.endTime)
                              );;
                              return bookingItem;
                            });
                          console.log("bookingPackage", bookingPackage);
                          if (newBooking.bookingPackages === undefined || newBooking.bookingPackages.length == 0) {
                            newBooking.bookingPackages = [bookingPackage];
                          } else {
                            const bpIndex = newBooking.bookingPackages.findIndex(
                              (bp) =>
                                bp.package.id === packageID &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.startDate)
                                ) === sessionStart &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.endDate)
                                ) === sessionEnd
                            );
                            if (bpIndex !== -1) {
                              if (!newBooking.bookingPackages[bpIndex].bookingItems) {
                                //   let bookingItemIndex = newBooking.bookingPackages[bpIndex].bookingItems.findIndex(
                                //     (i) => i.item.id === item.id &&
                                //       Utility.convertToISO(
                                //         Utility.convertISOToDate(i.startTime)
                                //       ) === itemSessionStart &&
                                //       Utility.convertToISO(
                                //         Utility.convertISOToDate(i.endTime)
                                //       ) === itemSessionEnd
                                //   );
                                //   if (bookingItemIndex == -1) {
                                //     newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                                //   }
                                // } else {
                                newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                              }
                            } else {
                              newBooking.bookingPackages.push(bookingPackage);
                            }
                          }
                          bookingPackage = newBooking.bookingPackages.find(
                            (bp) =>
                              bp.package.id === packageID &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(bp.startDate)
                              ) === sessionStart &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(bp.endDate)
                              ) === sessionEnd
                          );
                        }
                        if (bookingPackage) {
                          bookingPackage.bookingItems =
                            bookingPackage.bookingItems === undefined
                              ? []
                              : bookingPackage.bookingItems;
                          let bookingItem = bookingPackage.bookingItems.find(
                            (i) => i.item.id === item.id &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(i.startTime)
                              ) === itemSessionStart &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(i.endTime)
                              ) === itemSessionEnd
                          );
                          // bookingPackage.bookingItems = []; // Temp by Thuveepan
                          if (bookingItem === undefined) {
                            bookingItem = new BookingItems();
                            bookingItem.quantity = 0;
                            let biCount = 0;
                            newBooking.bookingPackages.forEach(
                              (b) =>
                                !!b.bookingItems && (biCount += b.bookingItems.length)
                            );
                            bookingItem.id = ((biCount + 1) * -1).toString();
                          }
                          var tp =
                            this.getItemPrefix(item.type.toLowerCase()) + item.type;
                          if (item.type.toLowerCase() == "assetitem") {
                            tp = "PublicBookingGeneralAdmissionItem";
                          }
                          bookingItemTypeNames.push(tp);
                          bookingItem.type = tp;
                          bookingItem.quantity = item.qty;
                          bookingItem.name = item.name;
                          bookingItem.upsell = true;
                          // bookingItem.unitPriceExcludingTax = item.unitPriceExcludingTax;
                          bookingItem.showInInvoice = true;
                          bookingItem.item.id = item.id;
                          bookingItem.item.type = item.type;
                          bookingItem.startTime = itemSessionStart;
                          bookingItem.endTime = itemSessionEnd;

                          const itemIndex = bookingPackage.bookingItems.findIndex(
                            (it) => it.id === bookingItem.id
                          );
                          if (itemIndex !== -1) {
                            bookingPackage.bookingItems[itemIndex] =
                              _.cloneDeep(bookingItem);
                          } else {
                            bookingPackage.bookingItems.push(
                              _.cloneDeep(bookingItem)
                            );
                          }
                          const ind = newBooking.bookingPackages.findIndex(
                            (b) => b.id === bookingPackage.id
                          );
                          if (ind !== -1) {
                            newBooking.bookingPackages[ind] = bookingPackage;
                          } else {
                            newBooking.bookingPackages.push(bookingPackage);
                          }
                          // });
                        } else {
                          // this.openSnackBarError([
                          //   "Booking Package is not Available for this package: " + selectedPackage.id,
                          // ]);
                        }
                      }
                    } else {
                      console.error(`Package not selected for Individual day upsell: ${item.name} Date: ${packageDate.eventDate.startTime}`);
                    }
                  })
                }
              }
            })
          }
        }
      });
    }
    if (
      !!PBReducer.selectedClient &&
      !!PBReducer.selectedClient.contactId
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = PBReducer.selectedClient.contactId;
    } else if (
      cart.contact.id &&
      cart.contact.id !== PBReducer.booking.contact.id &&
      PBReducer.booking.contact.id === (
        environment.PublicBookingSetting as PublicBookingSettings
      ).defaultClient
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = cart.contact.id;
    }
    if (!!newBooking.bookingPackages && newBooking.bookingPackages.length == 0) {
      newBooking.bookingPackages = undefined;
    } else {
      const removeIDs = [];
      newBooking.bookingPackages.forEach((bp) => {
        const id = parseInt(bp.id, 10);
        if (id > 0) {
          bp.startDate = undefined;
          bp.endDate = undefined;
        }
        if (id < 0 && +bp.quantity === 0) {
          removeIDs.push(bp.id);
        }
      });
      removeIDs.forEach((id) => {
        const index = newBooking.bookingPackages.findIndex((x) => x.id === id);
        if (index !== -1) {
          newBooking.bookingPackages.splice(index, 1);
        }
      });
    }

    return { newBooking, bookingItemTypeNames };
  }
  static generateBookingPatchBodyV2(sessions: IDateAndSession[], PBReducer, cart, bookingPackageList) {
    const newBooking = new Booking();
    const bookingItemTypeNames = [];
    const repeatPolicies = (environment.PublicBookingSetting as PublicBookingSettings).repeatPolicy;
    newBooking.id = PBReducer.booking.id;
    if (!!PBReducer.booking.deliveryMethod) {
      newBooking.deliveryMethod = new DeliveryMethod();
      newBooking.deliveryMethod.id = PBReducer.booking.deliveryMethod.id
    }
    newBooking.specialNote = PBReducer.booking.specialNote;
    // Create Booking Package for Post And Patch
    if (PBReducer) {
      newBooking.bookingPackages = [];
      sessions.forEach((session: IDateAndSession) => {
        if (!!session.packages) {
          const sessionStart = Utility.convertToISO(
            Utility.convertISOToDate(session.startTime)
          );
          const sessionEnd = Utility.convertToISO(
            Utility.convertISOToDate(session.endTime)
          );
          session.packages.forEach((pk) => {
            if (!pk.isBooked) {
              if (
                pk.isHaveLinkPackage &&
                pk.linkPackages &&
                pk.linkPackages.length > 0
              ) {
                const selectedLinkPk = pk.linkPackages.filter(
                  (p) => p.isBooked === false
                );
                selectedLinkPk.forEach((lpk) => {
                  newBooking.bookingPackages = this.generateBookingPackage(
                    lpk,
                    session,
                    newBooking.bookingPackages,
                    bookingPackageList.filter(
                      (apk) => apk.package.id === lpk.id
                    )
                  );
                });
                // }
              } else {
                newBooking.bookingPackages = this.generateBookingPackage(
                  pk,
                  session,
                  newBooking.bookingPackages,
                  bookingPackageList.filter(
                    (apk) => apk.package.id === pk.id
                  )
                );
              }
              pk.isBooked = true;
            }
            if (!!pk.addOns) {
              pk.addOns.forEach((item) => {
                if (item.isNeedToBeUpdate && item.itemTypeID !== 2) {
                  let packageID = pk.id;
                  let bookingPackage: BookingPackages;

                  let durationDates = [];
                  if (
                    session.durationRange &&
                    session.durationRange.packageDates
                  ) {
                    durationDates = _.cloneDeep(
                      session.durationRange.packageDates
                    );
                    durationDates.sort((x, y) =>
                      x.eventDate.date > y.eventDate.date ? 1 : 0
                    );
                  }
                  if (bookingPackageList) {
                    const selectedPackage = _.cloneDeep(pk);
                    // * if it have linked package
                    if (
                      selectedPackage &&
                      selectedPackage.isHaveLinkPackage &&
                      selectedPackage.linkPackages &&
                      selectedPackage.linkPackages.length > 0
                    ) {
                      const select = selectedPackage.linkPackages.filter(
                        (x) =>
                          !!bookingPackageList.find(
                            (p) => p.package.id === x.id
                          ) && x.quantity > 0
                      );
                      if (select.length > 0) {
                        const val = select.sort(
                          (a, b) =>
                            parseInt(a.linkedPackageType.id, 10) -
                            parseInt(b.linkedPackageType.id, 10)
                        );
                        packageID = val[0].id;
                      }
                    }
                  }
                  const bookingPK =
                  bookingPackageList.find(
                      (bp) =>
                        bp.package.id === packageID &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.startDate)
                        ) === sessionStart &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.endDate)
                        ) === sessionEnd
                    );
                  // *Create Booking Package For Booking Item
                  if (bookingPK) {
                    bookingPackage = new BookingPackages();
                    bookingPackage.id = bookingPK.id;
                    bookingPackage.startDate = bookingPK.startDate;
                    bookingPackage.endDate = bookingPK.endDate;
                    if (parseInt(bookingPK.id) < 0) {
                      bookingPackage.quantity = bookingPK.quantity;
                    }
                    bookingPackage.package = new Package();
                    bookingPackage.package.id = packageID;
                    bookingPackage.bookingItems = bookingPK.bookingItems
                      .filter((i) => i !== null && i.upsell && i.item && i.item.itemTypeID !== 2)
                      .map((i) => {
                        const bookingItem = new BookingItems();
                        bookingItem.id = i.id;
                        bookingItem.type = i.type;
                        bookingItem.item.id = i.item.id;
                        bookingItem.item.type = i.item.type;
                        bookingItem.quantity = i.quantity;
                        bookingItem.startTime = Utility.convertToISO(
                          Utility.convertISOToDate(i.startTime)
                        );
                        bookingItem.endTime = Utility.convertToISO(
                          Utility.convertISOToDate(i.endTime)
                        );;
                        return bookingItem;
                      });
                    if (newBooking.bookingPackages === undefined) {
                      newBooking.bookingPackages = [bookingPackage];
                    } else {
                      const bpIndex = newBooking.bookingPackages.findIndex(
                        (bp) =>
                          bp.package.id === packageID &&
                          Utility.convertToISO(
                            Utility.convertISOToDate(bp.startDate)
                          ) === sessionStart &&
                          Utility.convertToISO(
                            Utility.convertISOToDate(bp.endDate)
                          ) === sessionEnd
                      );
                      if (bpIndex !== -1) {
                        if (!newBooking.bookingPackages[bpIndex].bookingItems) {
                            newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                        }else {
                          newBooking.bookingPackages[bpIndex].bookingItems.push(...bookingPackage.bookingItems);
                        }
                      } else {
                        newBooking.bookingPackages.push(bookingPackage);
                      }
                    }
                    bookingPackage = newBooking.bookingPackages.find(
                      (bp) =>
                        bp.package.id === packageID &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.startDate)
                        ) === sessionStart &&
                        Utility.convertToISO(
                          Utility.convertISOToDate(bp.endDate)
                        ) === sessionEnd
                    );
                  }
                  if (bookingPackage) {
                    bookingPackage.bookingItems =
                      bookingPackage.bookingItems === undefined
                        ? []
                        : bookingPackage.bookingItems;
                    let bookingItem = bookingPackage.bookingItems.find(
                      (i) => i.item.id === item.id
                    );
                    // bookingPackage.bookingItems = []; // Temp by Thuveepan
                    if (bookingItem === undefined) {
                      bookingItem = new BookingItems();
                      bookingItem.quantity = 0;
                      let biCount = 0;
                      newBooking.bookingPackages.forEach(
                        (b) =>
                          !!b.bookingItems && (biCount += b.bookingItems.length)
                      );
                      bookingItem.id = ((biCount + 1) * -1).toString();
                    }
                    let tp =
                      this.getItemPrefix(item.type.toLowerCase()) + item.type;
                    if (item.type.toLowerCase() == 'assetitem') {
                      tp = 'PublicBookingGeneralAdmissionItem';
                    }
                    bookingItemTypeNames.push(tp);
                    bookingItem.type = tp;
                    bookingItem.quantity = item.qty;
                    bookingItem.unitPriceExcludingTax = item.unitPriceExcludingTax;
                    bookingItem.showInInvoice = true;
                    bookingItem.name = item.name;
                    bookingItem.upsell = true;
                    bookingItem.item.id = item.id;
                    bookingItem.item.type = item.type;
                    if (durationDates.length > 0) {
                      switch (item.repeatPolicy) {
                        case repeatPolicies.startDateOnly:
                          bookingItem.startTime =
                            durationDates[0].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[0].eventDate.endTime;
                          break;
                        case repeatPolicies.endDateOnly:
                          bookingItem.startTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.endTime;
                          break;
                        case repeatPolicies.repeatAllDays:
                          bookingItem.startTime = sessionStart;
                          bookingItem.endTime = sessionEnd;
                          break;
                        case repeatPolicies.allowIndividualDays:
                          break;
                        default:
                          bookingItem.startTime =
                            durationDates[0].eventDate.startTime;
                          bookingItem.endTime =
                            durationDates[
                              durationDates.length - 1
                            ].eventDate.endTime;
                          break;
                      }
                    } else {
                      bookingItem.startTime = sessionStart;
                      bookingItem.endTime = sessionEnd;
                    }

                    const itemIndex = bookingPackage.bookingItems.findIndex(
                      (it) => it.id === bookingItem.id
                    );
                    if (itemIndex !== -1) {
                      bookingPackage.bookingItems[itemIndex] =
                        _.cloneDeep(bookingItem);
                    } else {
                      bookingPackage.bookingItems.push(
                        _.cloneDeep(bookingItem)
                      );
                    }
                    const ind = newBooking.bookingPackages.findIndex(
                      (b) => b.id === bookingPackage.id
                    );
                    if (ind !== -1) {
                      newBooking.bookingPackages[ind] = bookingPackage;
                    } else {
                      newBooking.bookingPackages.push(bookingPackage);
                    }
                    // });
                  } else {
                    // this.openSnackBarError([
                    //   ,
                    // ]);
                    console.error('Booking Package is not Available for this package');

                  }
                  // item.isNeedToBeUpdate = false;
                }
              });
            }
          });

          // Only work for Multi day package with upsell item repeat policy for individual date's
          // !!make sure validate that
          if (session.durationRange && session.durationRange.packageDates && session.durationRange.packageDates.length > 0) {
            session.durationRange.packageDates.forEach(packageDate => {
              const itemSessionStart = Utility.convertToISO(
                Utility.convertISOToDate(packageDate.eventDate.startTime)
              );
              const itemSessionEnd = Utility.convertToISO(
                Utility.convertISOToDate(packageDate.eventDate.endTime)
              );
              if (packageDate && packageDate.items && packageDate.items.length > 0) {
                const UPDATED_ITEMS = packageDate.items.filter(x => x.item.isNeedToBeUpdate && (x.bookingItemID || (x.bookingItemID === undefined && x.item.qty > 0)));
                if (UPDATED_ITEMS && UPDATED_ITEMS.length > 0) {
                  UPDATED_ITEMS.forEach(d => {
                    let packageID = d.packageID.toString();
                    let bookingPackage: BookingPackages;
                    const item = d.item;
                    const selectedPackage = _.cloneDeep(session.packages.find(x => x.id == packageID));
                    if (selectedPackage) {
                      if (bookingPackageList) {
                        // * if it have linked package
                        if (
                          selectedPackage &&
                          selectedPackage.isHaveLinkPackage &&
                          selectedPackage.linkPackages &&
                          selectedPackage.linkPackages.length > 0
                        ) {
                          const select = selectedPackage.linkPackages.filter(
                            (x) =>
                              !!bookingPackageList.find(
                                (p) => p.package.id === x.id
                              ) && x.quantity > 0
                          );
                          if (select.length > 0) {
                            const val = select.sort(
                              (a, b) =>
                                parseInt(a.linkedPackageType.id, 10) -
                                parseInt(b.linkedPackageType.id, 10)
                            );
                            packageID = val[0].id;
                          }
                        }
                        // find a booking package for Up sell
                        const previousBookingPackage =
                          bookingPackageList.find(
                            (bp) => {
                              const s = Utility.convertToISO(
                                Utility.convertISOToDate(bp.startDate)
                              );
                              const e = Utility.convertToISO(
                                Utility.convertISOToDate(bp.endDate)
                              )
                              return bp.package.id === packageID.toString() &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.startDate)
                                ) === sessionStart &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.endDate)
                                ) === sessionEnd
                            }

                          );
                        if (previousBookingPackage) {
                          bookingPackage = new BookingPackages();
                          bookingPackage.id = previousBookingPackage.id;
                          if (parseInt(bookingPackage.id) < 0) {
                            bookingPackage.quantity = previousBookingPackage.quantity;
                          }
                          bookingPackage.startDate = previousBookingPackage.startDate;
                          bookingPackage.endDate = previousBookingPackage.endDate;
                          bookingPackage.package = new Package();
                          bookingPackage.package.id = packageID;
                          bookingPackage.bookingItems = previousBookingPackage.bookingItems
                            .filter((i) => i !== null && i.upsell && i.item && i.item.itemTypeID !== 2)
                            .map((i) => {
                              const bookingItem = new BookingItems();
                              bookingItem.id = i.id;
                              bookingItem.type = i.type;
                              bookingItem.item.id = i.item.id;
                              bookingItem.item.type = i.item.type;
                              bookingItem.quantity = i.quantity;
                              bookingItem.startTime = Utility.convertToISO(
                                Utility.convertISOToDate(i.startTime)
                              );
                              bookingItem.endTime = Utility.convertToISO(
                                Utility.convertISOToDate(i.endTime)
                              );;
                              return bookingItem;
                            });
                          console.log("bookingPackage", bookingPackage);
                          if (newBooking.bookingPackages === undefined || newBooking.bookingPackages.length == 0) {
                            newBooking.bookingPackages = [bookingPackage];
                          } else {
                            const bpIndex = newBooking.bookingPackages.findIndex(
                              (bp) =>
                                bp.package.id === packageID &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.startDate)
                                ) === sessionStart &&
                                Utility.convertToISO(
                                  Utility.convertISOToDate(bp.endDate)
                                ) === sessionEnd
                            );
                            if (bpIndex !== -1) {
                              if (!newBooking.bookingPackages[bpIndex].bookingItems) {
                                //   let bookingItemIndex = newBooking.bookingPackages[bpIndex].bookingItems.findIndex(
                                //     (i) => i.item.id === item.id &&
                                //       Utility.convertToISO(
                                //         Utility.convertISOToDate(i.startTime)
                                //       ) === itemSessionStart &&
                                //       Utility.convertToISO(
                                //         Utility.convertISOToDate(i.endTime)
                                //       ) === itemSessionEnd
                                //   );
                                //   if (bookingItemIndex == -1) {
                                //     newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                                //   }
                                // } else {
                                newBooking.bookingPackages[bpIndex].bookingItems = bookingPackage.bookingItems;
                              }
                            } else {
                              newBooking.bookingPackages.push(bookingPackage);
                            }
                          }
                          bookingPackage = newBooking.bookingPackages.find(
                            (bp) =>
                              bp.package.id === packageID &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(bp.startDate)
                              ) === sessionStart &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(bp.endDate)
                              ) === sessionEnd
                          );
                        }
                        if (bookingPackage) {
                          bookingPackage.bookingItems =
                            bookingPackage.bookingItems === undefined
                              ? []
                              : bookingPackage.bookingItems;
                          let bookingItem = bookingPackage.bookingItems.find(
                            (i) => i.item.id === item.id &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(i.startTime)
                              ) === itemSessionStart &&
                              Utility.convertToISO(
                                Utility.convertISOToDate(i.endTime)
                              ) === itemSessionEnd
                          );
                          // bookingPackage.bookingItems = []; // Temp by Thuveepan
                          if (bookingItem === undefined) {
                            bookingItem = new BookingItems();
                            bookingItem.quantity = 0;
                            let biCount = 0;
                            newBooking.bookingPackages.forEach(
                              (b) =>
                                !!b.bookingItems && (biCount += b.bookingItems.length)
                            );
                            bookingItem.id = ((biCount + 1) * -1).toString();
                          }
                          var tp =
                            this.getItemPrefix(item.type.toLowerCase()) + item.type;
                          if (item.type.toLowerCase() == "assetitem") {
                            tp = "PublicBookingGeneralAdmissionItem";
                          }
                          bookingItemTypeNames.push(tp);
                          bookingItem.type = tp;
                          bookingItem.quantity = item.qty;
                          bookingItem.name = item.name;
                          bookingItem.upsell = true;
                          // bookingItem.unitPriceExcludingTax = item.unitPriceExcludingTax;
                          bookingItem.showInInvoice = true;
                          bookingItem.item.id = item.id;
                          bookingItem.item.type = item.type;
                          bookingItem.startTime = itemSessionStart;
                          bookingItem.endTime = itemSessionEnd;

                          const itemIndex = bookingPackage.bookingItems.findIndex(
                            (it) => it.id === bookingItem.id
                          );
                          if (itemIndex !== -1) {
                            bookingPackage.bookingItems[itemIndex] =
                              _.cloneDeep(bookingItem);
                          } else {
                            bookingPackage.bookingItems.push(
                              _.cloneDeep(bookingItem)
                            );
                          }
                          const ind = newBooking.bookingPackages.findIndex(
                            (b) => b.id === bookingPackage.id
                          );
                          if (ind !== -1) {
                            newBooking.bookingPackages[ind] = bookingPackage;
                          } else {
                            newBooking.bookingPackages.push(bookingPackage);
                          }
                          // });
                        } else {
                          // this.openSnackBarError([
                          //   "Booking Package is not Available for this package: " + selectedPackage.id,
                          // ]);
                        }
                      }
                    } else {
                      console.error(`Package not selected for Individual day upsell: ${item.name} Date: ${packageDate.eventDate.startTime}`);
                    }
                  })
                }
              }
            })
          }
        }
      });
    }
    if (
      !!PBReducer.selectedClient &&
      !!PBReducer.selectedClient.contactId
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = PBReducer.selectedClient.contactId;
    } else if (
      cart.contact.id &&
      cart.contact.id !== PBReducer.booking.contact.id &&
      PBReducer.booking.contact.id === (
        environment.PublicBookingSetting as PublicBookingSettings
      ).defaultClient
    ) {
      newBooking.contact = new BookingContact();
      newBooking.contact.id = cart.contact.id;
    }
    if (!!newBooking.bookingPackages && newBooking.bookingPackages.length == 0) {
      newBooking.bookingPackages = undefined;
    } else {
      const removeIDs = [];
      newBooking.bookingPackages.forEach((bp) => {
        const id = parseInt(bp.id, 10);
        if (id > 0) {
          bp.startDate = undefined;
          bp.endDate = undefined;
        }
        if (id < 0 && +bp.quantity === 0) {
          removeIDs.push(bp.id);
        }
      });
      removeIDs.forEach((id) => {
        const index = newBooking.bookingPackages.findIndex((x) => x.id === id);
        if (index !== -1) {
          newBooking.bookingPackages.splice(index, 1);
        }
      });
    }

    return { newBooking, bookingItemTypeNames };
  }
  static getItemPrefix(val: string) {
    let prifix = 'PublicBooking';

    if (
      val === 'cateringitem' ||
      val === 'productitem' ||
      val === 'serviceitem' ||
      val === 'wineitem'
    ) {
      prifix = 'Booking';
    }
    return prifix;
  }
  static generateBookingPackage(
    pk: any,
    session: IDateAndSession,
    bookingPackages: any[],
    bookedPackage = []
  ) {
    const bkpackages = new BookingPackages();
    bkpackages.bookingItems = undefined;
    bkpackages.attendees = pk.quantity;
    bkpackages.quantity = pk.quantity.toString();
    bkpackages.startDate = Utility.convertToISO(
      Utility.convertISOToDate(session.startTime)
    );
    bkpackages.endDate = Utility.convertToISO(
      Utility.convertISOToDate(session.endTime)
    );
    bkpackages.package = new Package();
    bkpackages.package.id = pk.id;
    if (bookedPackage.length > 0) {
      for (const apk of bookedPackage) {
        const st = Utility.convertToISO(
          Utility.convertISOToDate(apk.startDate)
        );
        const et = Utility.convertToISO(Utility.convertISOToDate(apk.endDate));
        if (bkpackages.startDate === st && bkpackages.endDate === et) {
          bkpackages.id = apk.id;
          // bkpackages.attendees = +bkpackages.attendees + +apk.attendees;
          // bkpackages.quantity = (
          //   +bkpackages.quantity + +apk.quantity
          // ).toString();
          const bookedItem = apk.bookingItems?.find((bi : BookingItems) => bi?.type == 'publicBookingSeatingItem' && bi?.item?.type == 'seatingItem');
          if(bookedItem){
            const updatedPriviewAllocations = bookedItem.allocations?.filter((a: Allocation) => (a.id.includes("-")) || (a.isDelete));
            if (updatedPriviewAllocations && updatedPriviewAllocations.length > 0 && (!bkpackages.bookingItems || bkpackages.bookingItems?.length === 0)) {
              bkpackages.bookingItems = [];
              bkpackages.bookingItems.push(this.generateBookingSeatingItem(bookedItem, session));
            }
          }
          break;
        } else {
          bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
          continue;
        }
      }
    } else {
      bkpackages.id = ((bookingPackages.length + 1) * -1).toString();
    }
    bookingPackages.push(bkpackages);
    return bookingPackages;
  }

  static generateBookingSeatingItem(
    bookedItem : BookingItems,
    session: IDateAndSession
  ) {
      let bookingItem = new BookingItems();
      bookingItem.type = 'PublicBookingSeatingItem';
      bookingItem.id = bookedItem.id;
      bookingItem.quantity = bookedItem.quantity;
      bookingItem.name = bookedItem.name;
      bookingItem.upsell = false;
      bookingItem.item.id = bookedItem.item.id;
      bookingItem.item.type = bookedItem.item.type;
      bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(session.startTime));
      bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(session.endTime));
      if (!bookingItem.allocations)
        bookingItem.allocations = [];

      if(bookedItem.allocations.length > 0){
        bookedItem.allocations.forEach((a: Allocation) => {
          let allocation = new Allocation();
          allocation.id = a.id;
          allocation.type = AllocationType.SEAT;
          allocation.preliminary = a.preliminary ? 1 : 0;
          allocation.reserved = a.reserved ? 1 : 0;
          allocation.isDelete = a.isDelete ? true : false;
          allocation.timeslot = new PublicEventFacilityTimeslot();
          allocation.timeslot.id = a.timeslot.id;
          allocation.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(session.startTime));
          allocation.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(session.endTime));
          allocation.timeslot.asset = new Asset();
          allocation.timeslot.asset.id = a.timeslot.asset.id;
          allocation.timeslot.eventConfiguration = new EventSeatingConfiguration()
          allocation.timeslot.eventConfiguration.id = a.timeslot.eventConfiguration.id;
          allocation.seat.id = a.seat.id;

          bookingItem.allocations.push(allocation);
        });
      }
    return bookingItem;
  }

  static getConfirmationMessage(cartType: BOOKING_CART) {
    const navigationConfirmationMessages = (environment.PublicBookingSetting as PublicBookingSettings).navigationConfirmationMessages;
    let message: Massage;
    if(navigationConfirmationMessages) {
      if (cartType == BOOKING_CART.EDIT_BOOKING) {
        message = navigationConfirmationMessages.massages.find(meg => parseInt(meg.id) == BOOKING_CART.EDIT_BOOKING && meg.isEnabled === true)
      } else if(cartType == BOOKING_CART.NEW_BOOKING){
        message = navigationConfirmationMessages.massages.find(meg => parseInt(meg.id) == BOOKING_CART.NEW_BOOKING && meg.isEnabled === true)
      }
    }
    return message;
  }
}

<div class="button-layout" [id]="elementID">
  <div>
    <button class="btn" [ngClass]="layoutAttribute.componentClass" type="button" (click)="buttonAction()"
      [disabled]="disablePublicSelect">
      <span *ngIf="!isExpandButton else Expandtion">{{selecttionPannelName | optimotranslate}}</span>
      <ng-template #Expandtion>
      
        <span *ngIf="publicPackage && !publicPackage.isLinkPackageExpand; else collapse">
          Select >
        </span>
        <ng-template #collapse>
          <span class="material-icons">
            expand_less
          </span>
        </ng-template>
      </ng-template>

    </button>
  </div>
</div>
<div class="mmc_alert __error mcc_warning after-date-warning"
    style="width:100%;background-color:hwb(55 64% 1%);border:2px solid chocolate !important;border-radius:10px">
    <div class="row">
        <div class="col sm 12 col md 12 col lg 12">
            <div class="mmc_flex--container inner-wrapper mmc--depth__2;" style="display:flex">
                <div class="mmc_flex__item--vcenter">
                    <mat-icon
                        style="font-size:40px;color: chocolate !important;border:3px solid #EEDC82;border-radius: 50%;background-color:#EEDC82 ;height:auto;width:auto">warning</mat-icon>
                </div>

                <div class="mmc_flex__item--vcenter middle-content">
                    <div class="text-content">
                        <div class="font__h5 wider-text" style="color: chocolate;text-align: center;">Your request could not be processed.</div>
                        <div class="font__h5 wider-text"
                            style="color: chocolate;font-family: Alegreya !important; font-style: italic;text-align:center;word-wrap:break-word"
                            *ngFor="let errorMessage of data">{{errorMessage}}</div>
                    </div>
                </div>
                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
                    <button mat-button [mat-dialog-close]="true" (click)="closeMessage()" tabindex="0"><i
                            class="material-icons"
                            style="font-size:40px;color: chocolate !important;">close</i></button>
                </div>
            </div>
        </div>
    </div>
</div>
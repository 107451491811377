<div class="seat_view_modal">
    <h3 class="heading">Seat view</h3>
    <i class="material-icons close-btn" (click)="handleClose()">close</i>

    <div class="seat_main_img_container">
        <img [src]="images[currentImage]" class="seat_main_img" [ngStyle]="isZoom && {'transform': 'scale(2)'}"
            (click)="toggleZoom()">
    </div>

    <div class="seat_view_footer">
        <div class="block_action">
            <p class="block_name">Block 124</p>
            <div class="slide_btns">
                <i class="material-icons arrow-btn" (click)="handleNext()">arrow_left</i>
                <i class="material-icons arrow-btn" (click)="handlePrev()">arrow_right</i>
            </div>
        </div>

        <button class="close_text_btn" (click)="handleClose()">Close</button>
    </div>
</div>
import { INPUT_TYPE } from "./enums";
import { LayoutObject } from "./layoutObject.model";

export class InputLayout extends LayoutObject {
  type: INPUT_TYPE;
  label;
  constructor(data) {
    super(data);
    this.label = data.content
    this.type = data.componentClass.includes('number-input-type') ? INPUT_TYPE.NUMBER : INPUT_TYPE.TEXT;
  }
}

export class NavBarLayout extends LayoutObject {
  constructor(data) {
    super(data);
  }
}
export class Paging {
  size: number;
  number: number;
  totalResultCount: number;
}

export class PackageSelectionAreaLayout extends LayoutObject {
  unitPrice: number;
  available: number;
  redirectionURL: string;
  packageID: string;
  constructor(data) {
    super(data);
    let jsonData = JSON.parse(this.content)
    if (jsonData) {
      this.unitPrice = parseFloat(jsonData.UnitPrice.replace(/[^0-9.-]+/g,""));
      this.available = parseInt(jsonData.Available);
      this.redirectionURL = jsonData.RedirectionURL;
      this.packageID = jsonData.PackageId;
    }
  }

  reverseString(str) {
    return str.split("").reverse().join("");
  }
}

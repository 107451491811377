import { COLUMN, PAGINATION_POSITION, VIEW_ROW } from './enums';
import { CardLayout } from './cardLayout.model';
import { LayoutObject } from './layoutObject.model';
import { Paging } from './main';
import { timeout } from 'rxjs/operators';

// DONE
export class CardListLayout extends LayoutObject {
  numberOfColumns: number;
  height: number;
  rowsPerPage: number;
  isPagination: boolean;
  paginationPosition: PAGINATION_POSITION;
  isLazyLoading: boolean;
  lazyLoadingEnableAfter: number;
  paging: Paging;
  cardLayouts: CardLayout[] = [];
  orderedcardLayouts: CardLayout[] = [];
  cardTemplate: CardLayout;
  title: string;
  isMapView: boolean = false;
  constructor(data: any) {
    super(data);
    this.numberOfColumns = data.numberOfColumn;
    this.height = data.height;
    this.rowsPerPage = data.rowsPerPage;
    this.isPagination = data.pagination;
    this.paginationPosition = data.paginationPosition;
    this.isLazyLoading = data.lazyLoading;
    this.lazyLoadingEnableAfter = data.lazyLoadingEnableAfter;
    if(data.childLayout){
      data.childLayout.forEach(element => {
        if(element.availableToBook){
          this.orderedcardLayouts.push(element);
        }
      });
        data.childLayout.forEach(element => {
          if(!element.availableToBook){
            this.orderedcardLayouts.push(element);
          }
        });
    }
    this.cardLayouts = this.orderedcardLayouts ? this.orderedcardLayouts.map(card => new CardLayout(card)) : [];
    this.cardTemplate = data.searchLayouts && data.searchLayouts.length > 0 ? data.searchLayouts[0] : undefined;
    let jsonData = JSON.parse(this.content)
    if (jsonData) {
      this.title = jsonData.cardTitle && jsonData.cardTitle;
      this.isMapView = jsonData.isMapView && jsonData.isMapView;
    }
    if (data.paging && data.totalCardCount) {
      this.paging = new Paging();
      this.paging.number = parseInt(data.paging.number, 10);
      this.paging.size = parseInt(data.paging.size, 10);
      this.paging.totalResultCount = parseInt(data.totalCardCount, 10);
    }

  }
}

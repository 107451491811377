import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as d3 from 'd3';
import { ThemeService } from 'src/modules/services/public-web/theme.service';
import { FONT_CASE } from '../../models/enums';
import { TextStyle, Theme } from '../../models/globalTheme';
import { ILayout } from '../../models/interfaces';
import { NameLayout } from '../../models/nameLayout.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-name-layout',
  templateUrl: './name-layout.component.html'
})
export class NameLayoutComponent implements OnInit, AfterViewInit, ILayout {
  @Input() layoutAttribute: NameLayout;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() gridColumn: number;
  @Input() elementID = "";

  theme: Theme = new Theme();
  headingStyle =  new TextStyle();
  parentRef: any;

  constructor(
    private themeService: ThemeService,
  ) { 
  }

  ngOnInit(): void {
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
      this.headingStyle = theme.card.heading;
    });
  }

  ngAfterViewInit(): void {
    this.initializeAttributes();
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);

    if (contentElement) {
      d3.select(contentElement.closest('.name-layout'))
        .data([this.layoutAttribute.gridColumn])
        .style('margin', 0 + 'px');
        // .style('margin', this.theme.card.margin + 'px');
      d3.select(contentElement.parentElement)
        .attr(
          'class',
          `col-md-${this.layoutAttribute.gridColumn}
              col-lg-${this.layoutAttribute.gridColumn}
              col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
    }

    if (this.headingStyle && this.headingStyle.fontCase !== FONT_CASE.NORMAL) {
      this.layoutAttribute.content = this.layoutAttribute.content.toLowerCase();
    }
  }
}

export class Region {
  static StartDayOfWeek(day: string) {
    let value = 7 // Sunday
    switch (day.toLocaleLowerCase()) {
      case 'monday':
        value = 1;
        break;
      case 'tuesday':
        value = 2;
        break;
      case 'wednesday':
        value = 3;
        break;
      case 'thursday':
        value = 4;
        break;
      case 'friday':
        value = 5;
        break;
      case 'saturday':
        value = 6;
        break;
      case 'sunday':
        value = 7;
        break;
    }
    return value;
  }
}

<div class="booking-cancel-modal">
  <div class="pw-modals-container pw-custom-modal-wrapper pw-cancel-paid-booking-modal">
    <div class="mmc-modal-header mmc_flex--container pw-header">

      <div class="sub-header-left" style="margin-bottom: -5px;">
        <div class="_icon-carge" [ngStyle]="{'background-color': isInternalUser ? '#e6c62d' : '#4bb3fa' }">
          <span class="material-icons" style="color: #000">
            search
          </span>
        </div>
        <div class="left-header-title">
          <span *ngIf="data.title">{{data.title }}</span>
        </div>
      </div>
      <div class="sub-header-right">
        <!-- <div class="booking-ref" style="color: #4bb3fa;">{{data.bookingId}}</div> -->
        <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
          <button [mat-dialog-close]="true" mat-button><i class="material-icons">close</i></button>
        </div>
      </div>
    </div>
    <div class="mmc-modal-body p-0">
    <iframe [src]="url" style="width:100%;min-height:500px; height:auto; border:none;"></iframe>
  </div>

</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Booking } from 'src/modules/models/booking/booking';
import { Address, Client, CommunicationMethod, User } from 'src/modules/models/client/client';
import { EntityConfigurationDetail, EntityConfigurationProfile } from 'src/modules/models/public-web/EntityConfigurationProfile';
import { DeliveryMethodSetting, Field, PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BaseComponent } from 'src/modules/shared/base.component';
import * as _ from 'lodash'
import { Utility } from 'src/modules/utility';
import { CustomerService } from 'src/modules/services/customer.service';
import { Country } from 'src/modules/models/public-web/customer/country';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Contact } from 'src/modules/models/client/contact';
import { Store } from '@ngrx/store';
import { AddBooking } from '../../../../store/public-web/public-web-actions';
import * as PBSelector from '../../../../store/public-web/public-web-selectors';
import { BOOKING_CART } from 'src/modules/models/public-web/enum';
import { TranslateService } from '@ngx-translate/core';
import { PBUtility } from 'src/modules/public-web/pb-utility';
import { IPBReducer } from '../../../../store/public-web/public-web-reducers';

export enum DeliveryMethodModelType {
  EditDefaultAddress,
  EditDefaultEmail,
  CreateBookingAddress,
  EditBookingAddress,
  CreateBookingCommunication,
  EditBookingCommunication
}
@Component({
  selector: 'opt-delivery-method-edit-modal',
  templateUrl: './delivery-method-edit-modal.component.html'
})
export class DeliveryMethodEditModalComponent extends BaseComponent implements OnInit {
  showLoader: boolean;
  modelType: DeliveryMethodModelType;
  address: Address;
  communicationMethods: CommunicationMethod[];
  communicationMethod: CommunicationMethod;
  contact: Contact;
  deliveryMethodSetting: DeliveryMethodSetting;
  entityConfigurationProfile: EntityConfigurationProfile;
  addressFormBuilder: any;
  booking: Booking;
  modalFormGroup: FormGroup;
  response: { cancelDifferentShipment: boolean }
  defaultCountryName: string;
  defaultCountryId: number;
  countries: Country[]
  filteredCountriesOptions: Observable<Country[]>;
  defaultCountry: Country;
  bookingMode: any;
  patchPreviewId: string;
  PBReducer: IPBReducer;
  cart: any;
  constructor(
    public dialogRef: MatDialogRef<DeliveryMethodEditModalComponent>,
    public snackBar: MatSnackBar,
    private _customerService: CustomerService,
    private store: Store<any>,
    private _bookingService: PublicBookingService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super()
    dialogRef.disableClose = true;
    this.showLoader = false;
    this.response = {
      cancelDifferentShipment: false
    }
    //#region modal input binding
    this.PBReducer = data.pbreducer;
    this.cart = data.cart;
    this.countries = data.countries;
    this.defaultCountryName = environment.DefaultCountryName;

    this.modelType = data.modelType;
    this.communicationMethod = data.communicationMethod.find(x => +x.communicationTypeID == 6);
    this.address = _.cloneDeep(data.address);
    if (this.address.countryID) {
      this.defaultCountryId = Number(this.address.countryID);
    } else {
      this.defaultCountryId = Number(environment.DefaultCountryId);
    }
    this.defaultCountry = this.countries.find(x => x.id === this.defaultCountryId.toString());
    this.communicationMethods = _.cloneDeep(data.communicationMethod);
    this.contact = data.contact;
    this.booking = data.booking;
    this.patchPreviewId = data.patchPreviewId;
    //#endregion
    // this.generateModelsForForms()
    this.deliveryMethodSetting = (environment.PublicBookingSetting as PublicBookingSettings).deliveryMethodSetting;
    this.modalFormGroup = this.toFormGroup(this.formFields(false), this.formData)
  }

  ngOnInit(): void {
    this._customerService.GetCountries().safeSubscribe(this, c => {
      this.countries = c.sort((a, b) => {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      this._filter(this.address.country)
    })
    this.store.select(PBSelector.selectBookingMode).safeSubscribe(this, (state: any) => {
      this.bookingMode = state;
    });
    if (this.modalFormGroup.get('country')) {
      this.filteredCountriesOptions = this.modalFormGroup.get('country').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }
  }

  isNoResultFound = false;
  private _filter(value: string): Country[] {
    let filterCountry = this.countries;
    if (value) {
      const filterValue = value.toLowerCase();
      filterCountry = this.countries.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
      this.isNoResultFound = false;
      if (filterCountry.length === 1 && filterCountry[0].name.toLowerCase() === filterValue.toLowerCase()) {
        // this.setAttendeeDetails(filterAttendees[0]);
        this.address.countryID = +filterCountry[0].id;
        this.defaultCountry = filterCountry[0];
      } else if (filterCountry.length == 0) {
        this.address.countryID = +environment.DefaultCountryId;
        this.isNoResultFound = true;
      }
    }
    return filterCountry.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
  getCountrySelect(e) {
    const filterCountry = this.countries.find(option => option.name.toLowerCase() === e.toLowerCase());
    if (filterCountry) {
      this.address.countryID = +filterCountry.id;
      this.defaultCountry = filterCountry;
    }
  }
  //#region create and validate form builder
  private toFormGroup(fields: Field[], data: any) {
    const group: any = {};
    fields.forEach(field => {
      //  && field.entityConfigurationDetail.visible
      if (field.canUpdate) {
        group[field.name] =
          new FormControl(this.getAttribute(data, field.name), this.getFormValidators(field))
      }
    });
    return new FormGroup(group);
  }
  private getAttribute(data, key) {
    let value = ""
    if (data) {
      for (const field of this.formFields(false)) {
        if (field.name === key) {
          if (data[key]) {
            value = Utility.sentenceCase(data[key])
          }
          switch (key) {
            case 'mobile':
              const comm = this.communicationMethods.find(x => +x.communicationTypeID == 3 || +x.communicationTypeID == 1)
              if (comm) {
                value = comm.value;
              }
              break;
            case 'email':
              const commE = this.communicationMethods.find(x => +x.communicationTypeID == 5 || +x.communicationTypeID == 6)
              if (data.value) {
                value = data.value;
              }
              break;
          }
          break;
        }
      }
    }
    return value;
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  private getFormValidators(field: Field): any[] {
    let validators = []
    if (field.required) {
      validators = [...validators, Validators.required, this.noWhitespaceValidator]
    }
    switch (field.name) {
      case 'email':
        validators = [...validators, Validators.email]
        break;
      case 'mobile':
        validators = [...validators, Validators.pattern('[- +()0-9]+')]
        break;
    }
    return validators;
  }
  getFormControl(key) {
    return this.modalFormGroup.controls[key]
  }
  //#endregion
  //#region getters

  get postPatchData() {
    let obj = undefined;
    if (this.modelType === DeliveryMethodModelType.CreateBookingAddress || this.modelType === DeliveryMethodModelType.EditDefaultAddress
      || this.modelType === DeliveryMethodModelType.EditBookingAddress) {
      obj = this.address
    }
    if (this.modelType === DeliveryMethodModelType.CreateBookingCommunication ||
      this.modelType === DeliveryMethodModelType.EditDefaultEmail
      || this.modelType === DeliveryMethodModelType.EditBookingCommunication) {
      obj = this.communicationMethod;
    }
    return obj;
  }
  get formData(): any {
    let data;
    if (this.modelType === this.MODAL_TYPE.EditDefaultEmail || this.modelType === this.MODAL_TYPE.CreateBookingCommunication ||
      this.modelType === this.MODAL_TYPE.EditBookingCommunication) {
      data = this.communicationMethod;
    } else if (this.modelType === this.MODAL_TYPE.EditDefaultAddress || this.modelType === this.MODAL_TYPE.CreateBookingAddress ||
      this.modelType === this.MODAL_TYPE.EditBookingAddress) {
      data = this.address;
    }
    return data;
  }
  formFields(filter = true): Field[] {
    if (this.modelType === this.MODAL_TYPE.EditDefaultEmail ||
      this.modelType === this.MODAL_TYPE.CreateBookingCommunication ||
      this.modelType === this.MODAL_TYPE.EditBookingCommunication) {
      return this.deliveryMethodSetting.emailFields.field
    }
    if (this.modelType === this.MODAL_TYPE.EditDefaultAddress ||
      this.modelType === this.MODAL_TYPE.CreateBookingAddress ||
      this.modelType === this.MODAL_TYPE.EditBookingAddress) {
      return filter ? this.deliveryMethodSetting.postalFields.field.map(x => {
        if (x.name == 'postCode') {
          x.canUpdate = this.defaultCountry.postcodeRequired;
          x.required = this.defaultCountry.postcodeRequired;
        }
        if (x.name == 'stateCode') {
          x.canUpdate = this.defaultCountry.statecodeRequired;
          x.required = this.defaultCountry.statecodeRequired;
        }
        return x
      }) : this.deliveryMethodSetting.postalFields.field
    }
    return []
  }
  get modelHeaderName() {
    let title='';
    if (this.modelType === this.MODAL_TYPE.EditDefaultEmail ||
      this.modelType === this.MODAL_TYPE.EditBookingCommunication) {
      title = 'Edit Email Address';
    } else if (this.modelType === this.MODAL_TYPE.CreateBookingCommunication) {
      title = 'Enter The New Email Address';
    } else if (this.modelType === this.MODAL_TYPE.EditDefaultAddress ||
      this.modelType === this.MODAL_TYPE.EditBookingAddress) {
      title = 'Edit Delivery Address';
    } else if (this.modelType === this.MODAL_TYPE.CreateBookingAddress) {
      title = 'Enter The New Address';
    }
    this.translate.get(title).safeSubscribe(this, (res: string) => {
      title =res;
    });
    return title;
  }
  get MODAL_TYPE() {
    return DeliveryMethodModelType;
  }
  //#endregion
  onSubmit() {
    // debugger
    // console.log(this.modalFormGroup.invalid);
    if (this.modalFormGroup.valid) {
      const data = this.modalFormGroup.value;
      // const object = this.postPatchData;
      this.formFields().forEach(field => {
        const value = data[field.name] && data[field.name]
        if (value && field.canUpdate) {
          if (field.name === 'email') {
            const index = this.communicationMethods.findIndex(x => +x.communicationTypeID === 6)
            if (index != -1) {
              this.communicationMethods[index].value = value
            }
          } if (field.name === 'mobile') {
            const index = this.communicationMethods.findIndex(x => +x.communicationTypeID === 3)
            if (index != -1) {
              this.communicationMethods[index].value = value
            }
          } else {
            this.address[field.name] = value
          }
        }
      })
      let uBooking: Booking;
      let bookingItemTypes
      switch (this.bookingMode) {
        case BOOKING_CART.NEW_BOOKING:
          uBooking = new Booking();
          uBooking.id = this.booking.id;
          break;
        case BOOKING_CART.EDIT_BOOKING:
          const sessions = PBUtility.makeFullBookingSession(PBUtility.convertBookingIntoPBSessionData(this.booking, this.PBReducer.bookedPackages));
          const { newBooking, bookingItemTypeNames } = PBUtility.generateBookingPatchBody(sessions, this.PBReducer, this.cart);
          uBooking = newBooking;
          bookingItemTypes = bookingItemTypeNames;
          break;

        default:
          break;
      }
      if (this.modelType === this.MODAL_TYPE.EditDefaultAddress || this.modelType === this.MODAL_TYPE.CreateBookingAddress ||
        this.modelType === this.MODAL_TYPE.EditBookingAddress) {
        uBooking.deliveryAddress = this.address;
        uBooking.deliveryAddress.type = 'Address';
      }
      if (this.communicationMethods) {
        this.communicationMethods.forEach(com => {
          const ext = this.booking.deliveryCommunicationMethods.find(x => x.id == com.id);
          if (ext && !!com.value) {
            if (!uBooking.deliveryCommunicationMethods) {
              uBooking.deliveryCommunicationMethods = [];
            }
            const nC = new CommunicationMethod();
            nC.id = com.id;
            nC.value = com.value;
            nC.communicationTypeID = com.communicationTypeID;
            uBooking.deliveryCommunicationMethods.push(nC)
          }
        })
      }
      this.showLoader = true;
      switch (this.bookingMode) {
        case BOOKING_CART.NEW_BOOKING:
          this._bookingService.BookingPatch(uBooking).safeSubscribe(this, res => {
            if (!res.isError && res.data.booking) {
              this.store.dispatch(new AddBooking(res.data.booking))
            } else {
              this.openSnackBarError("Delivery method update failed")
            }
            this.dialogRef.close(this.response);
            this.showLoader = false;
          }, (e) => {
            this.showLoader = false;
          })
          break;
        case BOOKING_CART.EDIT_BOOKING:
          this._bookingService.BookingPatchPreview(uBooking, this.patchPreviewId).safeSubscribe(this, res => {
            if (!res.isError && res.data) {
              this.store.dispatch(new AddBooking(res.data))
            } else {
              this.openSnackBarError("Delivery method update failed")
            }
            this.dialogRef.close(this.response);
            this.showLoader = false;
          }, (e) => {
            this.showLoader = false;
          })
          break;

        default:
          break;
      }

    }
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: 'top',
    });
  }

  close() {
    this.response.cancelDifferentShipment = true;
    this.dialogRef.close(this.response);
  }
}

export enum LAYOUT_TYPE {
  SEARCHBOX = 'searchBoxLayout',
  HEADER = 'headerLayout',
  CARD_LIST = 'cardListLayout',
  ROW = 'rowLayout',
  CARD = 'cardLayout',
  NAME = 'nameLayout',
  IMAGE = 'imageLayout',
  BUTTON = 'buttonLayout',
  DESCRIPTION = 'descriptionLayout',
  EMPTY = 'emptyLayout',
  HTML = 'htmlLayout',
  FOOTER = 'footerLayout',
  LOGO = 'logoLayout',
  LOCATION = 'locationLayout',
  CONTACT = 'contactLayout',
  SOCIAL_MEDIA = 'socialMediaLayout',
  CALENDER = 'calenderLayout',
  INPUT = 'inputLayout',
  NAVBAR = 'navBarLayout',
  PACKAGESELECTIONAREALAYOUT = 'pacakageSelectionAreaLayout'
}
export enum PUBLIC_PAGE_DATA_TYPE {
  PUBLIC_PACKAGE = '1'
}
// Pagination rows
export enum VIEW_ROW {
  ROW_3 = '3 Rows',
  ROW_4 = '4 Rows',
  ROW_5 = '5 Rows'
}

export enum CARD_LIST_TOGGLE {
  MAP_VIEW,
  CARD_VIEW
}
export enum SESSION_SELECTION_MODEL_TYPE {
  BOOKING_PACKAGE_SESSION,
  BOOKING_ITEM_SESSION
}
//Previous Code Do not use
export enum PAGE_CONFIGURATION {
  CAMPING_CONFIGURATION = "CAMPING_CONFIGURATION",
  CAN_DISPLAY_EVENT_CARD = "CAN_DISPLAY_EVENT_CARD",
  CONFIG_CAN_SELECT_MULTI_SESSION = 'CONFIG_CAN_SELECT_MULTI_SESSION'
}
// Grid of card
export enum COLUMN {
  ONE_COLUMN = 'One column',
  TWO_COLUMN = 'Two column',
  THREE_COLUMN = 'Three column',
  FOUR_COLUMN = 'Four column'
}

export enum PAGINATION_POSITION {
  TOP_LEFT = 'Top_Left',
  TOP_RIGHT = 'Top_Right',
  TOP_CENTER = 'Top_Center',
  BOTTOM_LEFT = 'Bottom_Left',
  BOTTOM_RIGHT = 'Bottom_Right',
  BOTTOM_CENTER = 'Bottom_Center'
}

export enum DISPLAY_VIEW {
  DESKTOP_VIEW = 'Desktop_View',
  MOBILE__VIEW = 'Mobile View',
  LAPTOP_VIEW = 'Laptop View',
  TABLET_VIEW = 'Tablet_VIEW'
}

export enum FONT_CASE {
  SENTENCE_CASE = 'sentencecase',
  LOWER_CASE = 'lowercase',
  UPPER_CASE = 'uppercase',
  NORMAL = 'nocase'
}

export enum BUTTON_STYLE {
  RECTANGLE = 'Rectangle',
  CURVED = 'Curved',
  OVAL = 'Oval',
  Link = 'Link',
  Default = 'Default'
}
//should need to change the session to common model
export enum BUTTON_ACTION {
  ROUTE = 'Route',
  URL = 'URL',
  SCRIPT = 'Script',
  SESSION = 'Session'
}

export enum HEIGHT_SIZE {
  SpecifySize = 'Specify_Size',
  FitToContent = 'Fit_to_Content'
}

export enum ROUTE_LIST {
  CATEGORY = 'category',
  EVENT_LIST = 'event_list',
  EVENT_INFO = 'event_info',
}

export enum INPUT_TYPE {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseComponent } from "src/modules/shared/base.component";
import { Store } from "src/modules/store";


@Component({
  selector: "opt-privacy-terms",
  templateUrl: "./privacy-terms.component.html",
})
export class PrivacyTermsModalComponent extends BaseComponent implements OnInit {
 type:string;
  constructor(
    public dialogRef: MatDialogRef<PrivacyTermsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
   // dialogRef.disableClose = true;
  }
 
  ngOnInit() {
    this.type=this.data.type;
  }
  
}

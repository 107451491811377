import { LayoutObject } from './layoutObject.model';

// DONE
export class LocalImage {
  id: string;
  src: string;
  name: string;
  width: number;
  height: number;

  constructor(data) {
    this.id = data.id;
    this.src = data.file;
    this.height = data.height;
    this.width = data.width;
  }
}
export class ImageLayout extends LayoutObject {
  height: number;
  image: LocalImage;
  url: string;
  content: string;
  navigationUrl: string;

  constructor(data) {
    super(data);
    this.height = data.height;
    this.content = data.content;
    if (!!data.image) {
      this.image = new LocalImage(data.image);
    }

    if (data.url && data.url.length > 50) {
      if (this.isBase64(data.url)) {
        if (!data.url.includes('base64')) {
          data.url = "data:image/png;base64," + data.url
        }
      }
      this.url = data.url;
    } else {
      this.navigationUrl = data.url ? data.url : null;
    }
  }

  isBase64(str) {
    if (str === '' || str.trim() === '') { return false; }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

//Layout
//import { ThemeUtility } from '../layout/layout';
import { SiteLayoutModule } from "src/modules/layout/site-layout/site-layout.module";

import { OptFormModules } from "src/modules/shared/form.modules";

import { PaymentRoutingModule } from "./payment-routing.module";
import { PaymentMainComponent } from "./payment-main.component";
import { PaymentComponent } from "./payment/payment.component";

//Services
import { OptHttpProvider } from "../services/base/api-request-handler";
import { PaymentService } from "../services/payment.service";
import { SecurePayComponent } from "./secure-pay/secure-pay.component";

//pipe
import { SafePipe } from 'src/modules/safe-pipe';
import { NumberFormat } from 'src/modules/number-format';
import { CurrencyPipe } from '@angular/common';

//html
//import { MmcLoaderComponent } from 'src/modules/shared/mmc-loader/mmc-loader.component';
//import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component'
import { HttpLoaderFactory,OptCommonModules } from 'src/modules/shared/common.modules';

import {
  StoreModule,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from "@ngrx/store";
import { statusReducer } from "../store/loading/reducer";
import { cartReducer } from "../store/cart-reducer";
import { AppState } from "../store/cart";

const reducers: ActionReducerMap<AppState> = {
  cart: cartReducer,
  loader: statusReducer,
  // PBReducer: PBReducer,
};

import { SiteLayoutModule as LayoutModule } from 'src/modules/layout/site-layout/site-layout.module';
import { SiteLayoutComponent as LayoutComponent } from 'src/modules/layout/site-layout/site-layout.component';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}

import { localStorageSync } from "ngrx-store-localstorage";
import { CheckoutComponent } from "../public-web/components/pages/checkout/checkout.component";
import { EffectsModule } from "@ngrx/effects";
import { PublicWebEffects } from "../store/public-web/public-web-effects";
import { LoginEffects } from "../store/effects/login-effects";
import { OptMaterialModule } from "../shared/opt-material/opt-material.module";
import { PbCommonModule } from "../public-web/pb-common.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { LayoutsModule } from "../public-web/components/layout/layouts.module";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [

    PbCommonModule,
    CommonModule,
    PaymentRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SiteLayoutModule,
    OptCommonModules,
    OptMaterialModule,
    LayoutsModule,
    OptFormModules,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([LoginEffects, PublicWebEffects]),
    StoreDevtoolsModule.instrument(),
  ],
  declarations: [
    PaymentMainComponent,
    CheckoutComponent,
    PaymentComponent,
    SecurePayComponent,
    SafePipe,
    NumberFormat
    //AlertMessageComponent, MmcLoaderComponent
  ],
  bootstrap: [PaymentMainComponent],
  providers: [OptHttpProvider, PaymentService, CurrencyPipe, CurrencyPipe],
  // entryComponents: [AlertMessageComponent]
})
export class PaymentModule {}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PaymentModule } from './payment.module';
import { Init } from '../Init'

//set url from web config
Init().then(() => {
    platformBrowserDynamic().bootstrapModule(PaymentModule);
})
//Init();

//    platformBrowserDynamic().bootstrapModule(PaymentModule);

// if (document.readyState === 'complete') {
//     platformBrowserDynamic().bootstrapModule(BookingModule);

//} else {
//     document.addEventListener('DOMContentLoaded', function () {
//         return platformBrowserDynamic().bootstrapModule(BookingModule);
//     });
//}


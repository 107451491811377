<!-- [ngClass] = "headingStyle.fontCase" -->
<div class="name-layout" 
  [id] = "elementID" 
  [ngClass]="layoutAttribute.componentClass"
  >
  {{layoutAttribute.content}}
</div>

<!-- [style.background-color] = "headingStyle.backgroundColour"
[style.font-size.px] = "headingStyle.fontSize"
[style.color] = "headingStyle.fontColor"
[style.font-weight] = "headingStyle.isBold ? 'bolder':''"
[style.font-style] = "headingStyle.isItalic ? 'italic':''" -->
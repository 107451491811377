import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { environment } from 'src/environments/environment';


@Pipe({
    name: 'NumberFormat'
})
export class NumberFormat implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }


    transform(value: number): string {
        if (value != undefined && value != null && !isNaN(value)) {
            value = Math.round(value * 1000) / 1000;

            return this.currencyPipe.transform(value, '', '', '1.2-2').replace(/,/gi, '');
        }
    }
}
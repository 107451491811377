<div class="mmc-modal-wrapper questionnaire-modal">
  <div class="mmc-modal-header mmc_flex--container">
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter icon-cage mmc_just-content-center">
          <i class="material-icons">insert_invitation
          </i>
      </div>
      <div class="mmc_flex__item mmc_flex__item--vcenter">
          <span class="section-title">Link Package</span>
      </div>
      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center">
          <button mat-button [mat-dialog-close]="true"><i class="material-icons">close</i></button>
      </div>
  </div>
  <div class="mmc-modal-body">
   
  </div>
</div>
<div class="body-wrapper checkout-page pb-5 pb-xl-0" (window:resize)="onResize($event)">
  <div class="checkout-page-img" *ngIf="checkoutPageConfiguration.backgroundImage.visible">
    <img src="../dist/assets/images/4 - Sport in the city 2.png" class="bg-img">
    <div class="video-overlay"></div>
  </div>
  <opt-nav-bar-layout></opt-nav-bar-layout>
  <div class="container pt-3 checkout-page-content">
    <div class="row col-md-1 col-lg-1 checkout-row back-btn-checkout">
      <i (click)="checkoutBackBtn()" class="material-icons back-btn">arrow_back</i>
    </div>
    <div class="row col-md-11 col-lg-11 checkout-row">
      <div class="col-md-8 col-lg-8 checkout-stepper pb-3">
        <mat-card class="checkout-mat-card">
          <h3 class="font-weight-bold checkout-header-title text-primary">
            {{'CHECKOUTINFO.Checkout Details' | optimotranslate}}
          </h3>
          <div [ngSwitch]="isShipmentConfirm">
            <div *ngSwitchCase="false">
              <div class="shipping-form-container">
                <div class="sepper-header">
                  <div class="step-icon">
                    <div class="step-icon-content">
                      <span class="ng-star-inserted">1</span>
                    </div>
                  </div>
                  <div class="s-left">
                    <p class="title">{{'CHECKOUTINFO.Shipping Method' | optimotranslate }}</p>
                    <span class="header-sub">{{"CHECKOUTINFO.How would you like these tickets delivered?" | optimotranslate}}</span>
                  </div>
                  <div class="s-right">
                    <p class="checkout-header-subtitile">{{"CHECKOUTINFO.Step 1 of 2" | optimotranslate}}</p>
                  </div>
                </div>
              </div>
              <div class="delivery-method-section">
                <div class="mmc_flex--container pb-2 pt-2">
                  <div class="mmc_flex__item mmc_flex__item w-25">
                    <p class="opt-fontweight__bolder title px-0">{{'CHECKOUTINFO.Delivery Method' | optimotranslate}}</p>
                  </div>
                  <div class="mmc_flex__item mmc_flex__item--vcenter  w-25">
                    <p class="opt-fontweight__bolder title px-0">{{'CHECKOUTINFO.Price' | optimotranslate}}</p>
                  </div>
                </div>
                <mat-radio-group [(ngModel)]="selectedDeliveryMethod" (change)="onDeliveryMethodChange()"
                  class="pb-radio-group">
                  <div class="mmc_flex--container pb-2" *ngFor="let deliveryMethod of deliveryMethods">
                    <div class="mmc_flex__item mmc_flex__item  w-25 deliverym-name">
                      <mat-radio-button [value]="deliveryMethod">{{deliveryMethod.name}}</mat-radio-button>
                    </div>
                    <div class="mmc_flex__item mmc_flex__item--vcenter  w-25 deliverym-price">
                      {{ deliveryMethod.price | PBCurrencyPipe}}
                    </div>
                  </div>
                </mat-radio-group>
              </div>
              <div class="delivery-method-details pt-2 "
                *ngIf="selectedDeliveryMethod && !selectedDeliveryMethod.collection && booking">
                <div class="mmc_flex--container pb-2">
                  <div class="mmc_flex__item mmc_flex__item  mmc_just-content-end" *ngIf="canEditDeliveryAddress">
                    <button id="submitBtn" mat-button class="mmc-btn-secondary--small" (click)="onEditDefaultInfo()">
                      {{"Edit"  | optimotranslate}}
                    </button>
                  </div>
                </div>
                <div class="inner-container">
                  <div class="row">
                    <div class="col-sm-12">
                      <div *ngIf="selectedDeliveryMethod.postal" class="addresscard">
                        <h5 class="headertitle">{{"CHECKOUTINFO.Shipping Address" | optimotranslate}}</h5>
                        <div class="shippingaddress">
                          <p><span class="title">{{ getFieldDataNew("firstName", true) ? getFieldDataNew("firstName", true) : ''}}{{ ' ' }}{{ getFieldDataNew("lastName", true) ? getFieldDataNew("lastName", true) : ''}}</span></p>
                          <P>
                            <span class="address">
                            {{ getFieldDataNew("postCode", true) ? getFieldDataNew("postCode", true)+', ' : '' }}{{ getFieldDataNew("address1", true) ? getFieldDataNew("address1", true)+', ' : '' }}
                            {{ getFieldDataNew("address2", true) ? getFieldDataNew("address2", true)+', ' : '' }}{{ getFieldDataNew("address3", true) ? getFieldDataNew("address3", true)+', ' : ''}}
                            <br/>
                            {{ getFieldDataNew("city", true) ? getFieldDataNew("city", true)+', ' : ''}}{{ getFieldDataNew("country", true) ? getFieldDataNew("country", true)+', ' : '' }}
                            <br />
                            <span *ngIf="getFieldDataNew('mobile', true)">
                              {{ getFieldDataNew("mobile", true) ? getFieldDataNew("mobile", true) : '' }}
                            </span>
                            </span>
                          </P>
                        </div>
                      </div>
                      <div *ngIf="selectedDeliveryMethod.email" class="addresscard">
                        <h5 class="headertitle">{{"CHECKOUTINFO.Email Address" | optimotranslate}}</h5>
                        <div class="shippingaddress">
                          <!-- <p><span class="title">{{ getFieldDataNew("firstName", true) ? getFieldDataNew("firstName", true) : ''}}{{ ' ' }}{{ getFieldDataNew("lastName", true) ? getFieldDataNew("lastName", true) : ''}}</span></p> -->
                          <P>
                            <span class="address" *ngIf="getFieldDataNew('email', true)">
                              {{ getFieldDataNew("email", true) }}
                            </span>
                          </P>
                          <!-- <P *ngIf="getFieldDataNew('mobile', true)">
                            <span class="address" >
                              {{  getFieldDataNew("mobile", true) }}
                            </span>
                          </P> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <ng-container *ngFor="let field of formFields">
                      <div class="col-sm-{{field.col}} col-md-{{field.col}} col-lg-{{field.col}} col-xl-{{field.col}}"
                        *ngIf="getFieldData(field, true)">
                        <div class="pb-3">
                          <p class="opt-txt__secondary title px-0">{{field.label}}</p>
                          <p class="opt-txt__primary  title px-0 pt-1">{{getFieldData(field, true)}}</p>
                        </div>
                      </div>
                    </ng-container>
                  </div> -->
                </div>
                <div class="mmc_flex--container pb-2">
                  <div class="mmc_flex__item mmc_flex__item">
                    <mat-checkbox [(ngModel)]="shipDifferentAddress" (change)="onShipDifferentAddress()">
                      {{"CHECKOUTINFO.Ship to different" | optimotranslate }} {{(selectedDeliveryMethod.email ? 'CHECKOUTINFO.mail': selectedDeliveryMethod.postal ?
                      'CHECKOUTINFO.address' : '') | optimotranslate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
              <div class="btn-container">
                <button mat-button class="mmc-btn-primary--small" (click)="checkoutBackBtn()">
                  {{"CHECKOUTINFO.Back" | optimotranslate }}
                </button>
                <button id="submitBtn" mat-button class="mmc-btn-primary--small" (click)="isShipmentConfirm=true"
                  [disabled]="disableDeliveryMethodDisable">
                  {{"CHECKOUTINFO.Next" | optimotranslate }}
                </button>
              </div>
            </div>
            <div *ngSwitchCase="true">
              <div class="payment-form-container">
                <div class="sepper-header">
                  <div class="step-icon" *ngIf="!skipDeliveryMethod">
                    <div class="step-icon-content">
                      <span class="ng-star-inserted">2</span>
                    </div>
                  </div>
                  <div class="s-left">
                    <p class="title">{{"CHECKOUTINFO.Payment method"| optimotranslate }}</p>
                    <span class="header-sub">{{"CHECKOUTINFO.Please proceed with available options given below for payment" | optimotranslate}}</span>
                  </div>
                  <div class="s-right" *ngIf="!skipDeliveryMethod && checkoutPageConfiguration.checkoutStep.visible">
                    <p class="checkout-header-subtitile">{{"CHECKOUTINFO.Step 2 of 2" | optimotranslate}}</p>
                  </div>
                </div>
                <div class="payment-section">
                  <section class="radio-btn-section">
                    <mat-radio-group class="radio-btn-group row" [(ngModel)]="selectedPaymentMethod">
                      <mat-radio-button class="radio-btn-selector col-md-6 col-lg-6"
                        [value]="PAYMENT_METHOD.CREDIT_DEBIT">
                        <p class="title">{{"CHECKOUTINFO.Credit Card" | optimotranslate}} / {{"CHECKOUTINFO.Debit Card"| optimotranslate}}</p>
                        <span class="sub-title">{{"CHECKOUTINFO.Pay with your credit card" | optimotranslate}}</span>
                        <div class="logo-container">
                          <img alt="Visa Master Amex Card Logos" style="height: 35px;"
                            src="https://freepngimg.com/download/credit_card/26058-2-credit-card-transparent.png" />

                        </div>
                      </mat-radio-button>
                      <mat-radio-button *ngIf="checkoutPageConfiguration?.paypalPaymentMethod?.visible" class="radio-btn-selector col-md-6 col-lg-6" [value]="PAYMENT_METHOD.PAYPAL">
                        <p class="title">{{"CHECKOUTINFO.Paypal" | optimotranslate}}</p>
                        <span class="sub-title">{{"CHECKOUTINFO.Pay with Paypal" | optimotranslate}}</span>
                        <div class="logo-container">
                          <img alt="Paypal Logo" style="height: 35px;" src="https://pngimg.com/uploads/paypal/paypal_PNG22.png" />
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>
                  <section class="check-box-g mt-2 ml-3" *ngIf="checkoutPageConfiguration.privacyAndTerms.visible">
                    <mat-checkbox class="check-box custom-padding-style" [(ngModel)]="isPPandTC">
                        {{"CHECKOUTINFO.I accept company privacy policy and terms & conditions."| optimotranslate}}</mat-checkbox>
                  </section>
                  <div class="policy-terms-container ml-3" *ngIf="checkoutPageConfiguration.privacyAndTerms.visible">
                    <a class="href-style" (click)="openPrivacyOrTerms('privacy')">{{"FOOTERCONTENTOLDEF.Privacy Policy"| optimotranslate}}</a> &nbsp; &nbsp;
                    <a class="href-style" (click)="openPrivacyOrTerms('terms')">{{"CHECKOUTINFO.Terms & Conditions"| optimotranslate}}</a>
                  </div>
                </div>
              </div>
              <div class="btn-container payment-btn">
                <button *ngIf="checkoutPageConfiguration.backButton.visible" class="btn-check payment-btn-check" (click)="checkoutBackBtn()">
                  {{"CHECKOUTINFO.Back"| optimotranslate}}
                </button>
                <button class="btn-check payment-btn-check mr-0 btn btn-disabled" (click)="checkout()"
                  [disabled]="checkoutPageConfiguration.privacyAndTerms.visible && !isPPandTC">
                  {{"CHECKOUTINFO.Pay"| optimotranslate}}
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-md-4 col-lg-4 pb-3 checkout-cart-right" id="mobileView">
        <mat-card *ngIf="checkoutPageConfiguration.paymentSessionTimer.visible" class="d-block d-lg-block d-md-block p-0 session-card">
          <div class="card session-timer">
            <div class="row">
              <div class="col-8 m-auto font-600">{{"CHECKOUTINFO.Session Expires in"| optimotranslate}}</div>
              <div class="col-4 text-left">
                <span class="timer">{{ paymentSession?.minutes | number: "1.0-0" }} :
                  {{ paymentSession?.seconds | number: "2.0-0" }}</span>
              </div>
            </div>
          </div>
        </mat-card>
        <h6 class="font-weight-bold checkout-summary mt-3">
          <strong>{{"CHECKOUTINFO.Order Summary"| optimotranslate}}</strong>
        </h6>
        <mat-card class="mt-3 order-2 p-0 order-summary-card">
          <div class="order-summery" *ngIf="booking">
            <!-- <div
              class="order-list checkout-order-list"
              *ngFor="let mpk of booking.bookingPackages"
            >
              <div class="order-flex main-package">
                <div class="left">
                  <p>{{ mpk.name }}</p>
                </div>
                <div class="right">
                  <p class="checkout-order-right">${{ mpk.unitPrice }}</p>
                </div>
              </div>
              <hr />
            </div> -->
            <div class="totalOrder order-flex p-4">
              <div class="total-amount-text">
                <p>{{"CHECKOUTINFO.Order Total"| optimotranslate}} : <span *ngIf="TaxLabelToDisplayName">({{"CHECKOUTINFO." + TaxLabelToDisplayName | optimotranslate}})</span></p>
              </div>
              <div class="total-amount">
                <p class="checkout-order-total">{{ OutStandingAmount | PBCurrencyPipe }}</p>
              </div>
            </div>
            <div class="checkout-info">
              <div class="checkout-info-sub">
                <mat-icon>remove_circle</mat-icon>
                <p class="mb-3">{{"CHECKOUTINFO.Clicking Pay will initiate the payment session on the next page" | optimotranslate}}</p>
              </div>
              <div class="checkout-info-sub"> 
                <mat-icon>remove_circle</mat-icon> 
                <p class="checkout-info-p"> {{"CHECKOUTINFO.Navigate to previous page to edit delivery details" | optimotranslate}}</p>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="showLoader || (isShipmentConfirm===null)">
    <!-- <opt-mmc-loader></opt-mmc-loader> -->
    <opt-loader></opt-loader>
  </div>
</div>

import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { INPUT_TYPE } from "../../models/enums";
import { Theme } from "../../models/globalTheme";
import { ILayout } from "../../models/interfaces";
import { InputLayout } from "../../models/main";
import * as d3 from "d3";
import { Observable } from "rxjs";

import { IPBReducer, IPublicPackage } from "../../../../../store/public-web/public-web-reducers";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { Store } from "src/modules/store";
import { BaseComponent } from "src/modules/shared/base.component";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { UpdatePackage } from "../../../../../store/public-web/public-web-actions";

@Component({
  selector: "opt-input-layout",
  templateUrl: "./input-layout.component.html",
  styleUrls: ["./input-layout.component.scss"],
})
export class InputLayoutComponent
  extends BaseComponent
  implements OnInit, ILayout, AfterViewInit
{
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() layoutAttribute: InputLayout;
  @Input() elementID;
  parentRef: any;
  theme: Theme;
  numberInputValue = 0;
  INPUT_TYPE = INPUT_TYPE;

  publicCart$: Observable<IPublicPackage[]>;
  publicPackage: IPublicPackage;
  PBReducer$: Observable<any>;
  currentPageType: PAGE_TYPE;

  constructor(private store: Store<any>) {
    super();
    this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageCarts);
    this.PBReducer$ = this.store.select(PBSelector.selectPageType);
  }

  ngOnInit(): void {
    this.PBReducer$.safeSubscribe(this, (config) => {
      this.currentPageType = config.pageType;
      if (config.pageType) {
      }
    });
  }

  get PAGE_TYPE() {
    return PAGE_TYPE;
  }
  get canShowInput() {
    if (this.currentPageType === PAGE_TYPE.PACKAGE_LIST && this.publicPackage) {
      return !this.publicPackage.isHaveLinkPackage;
    }
    return true;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
    });
    this.publicCart$.safeSubscribe(this, (cart) => {
      const p = cart;
      if (this.currentPageType === PAGE_TYPE.PACKAGE_LIST) {
        let tableRef = this.getTableRefFromComponentClass();
        this.publicPackage = p.find((x) => x.id === tableRef);
        if (this.publicPackage)
          this.publicPackage.quantity = this.numberInputValue;
      }
    });
  }
  getTableRefFromComponentClass() {
    const classes = this.layoutAttribute.componentClass.split(" ");
    const c = classes.find((x) => x.includes("ref_id"));
    return c ? c.replace("ref_id", "") : undefined;
  }
  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      d3.select(contentElement.closest(".input-layout")).data([
        this.layoutAttribute.gridColumn,
      ]);
      d3.select(contentElement.parentElement).attr(
        "class",
        `col-md-${this.layoutAttribute.gridColumn}
            col-lg-${this.layoutAttribute.gridColumn}
            col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
      );
    }
  }
  decrement() {
    if (this.numberInputValue > 0) {
      this.numberInputValue--;
      this.updatePackage(this.numberInputValue);
    }
  }
  increment() {
    this.numberInputValue++;
    this.updatePackage(this.numberInputValue);
  }
  updatePackage(q) {
    if (this.publicPackage) {
      this.publicPackage.quantity = q;
      if (this.currentPageType === PAGE_TYPE.PACKAGE_LIST) {
        this.store.dispatch(new UpdatePackage(this.publicPackage));
      }
    }
  }
}

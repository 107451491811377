import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EmptyColumn } from '../../models/gridLayout.model';
import { ILayout } from '../../models/interfaces';
import * as d3 from 'd3';
import { Theme } from '../../models/globalTheme';
import { ThemeService } from 'src/modules/services/public-web/theme.service';

@Component({
  selector: 'opt-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.scss']
})
export class EmptyLayoutComponent implements OnInit, AfterViewInit, ILayout{
  @Input() layoutAttribute: EmptyColumn;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() gridColumn: number;
  @Input() elementID: string;
  parentRef: any;
  theme: Theme;

  constructor(
    private themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });
  }

  ngAfterViewInit(): void {
    this.initializeAttributes();
  }
  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      d3.select(contentElement.closest('.empty-layout'))
        .data([this.layoutAttribute.gridColumn])
        .style('margin', 0 + 'px');
        // .style('margin', this.theme.card.margin + 'px');
      d3.select(contentElement.parentElement)
        .attr(
          'class',
          `col-md-${this.layoutAttribute.gridColumn}
              col-lg-${this.layoutAttribute.gridColumn}
              col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
    }
  }
}

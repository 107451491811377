import { LayoutObject } from './layoutObject.model';

export class SocialMediaLayout extends LayoutObject {
  height: number;
  width: number;
  transform: string;
  facebookUrl?: string;
  youtubeUrl?: string;
  twitterUrl?: string;
  instagramUrl?: string;
  isFacebook: boolean;
  isYoutube: boolean;
  isTwitter: boolean;
  isInstagram: boolean;

  constructor(data) {
    super(data);
    this.height = data.height;
    this.width = data.width;
    this.transform = data.transform;
    this.facebookUrl = data.facebookUrl ? data.facebookUrl : null;
    this.youtubeUrl = data.youtubeUrl ? data.youtubeUrl : null;
    this.twitterUrl = data.twitterUrl ? data.twitterUrl : null;
    this.instagramUrl = data.instagramUrl ? data.instagramUrl : null;
  }
}

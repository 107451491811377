import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderLayoutComponent } from "./components/header-layout/header-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SearchBoxLayoutComponent } from "./components/searchbox-layout/search-box-layout.component";
import { CardListLayoutComponent } from "./components/card-list-layout/card-list-layout.component";
import { CardLayoutComponent } from "./components/card-layout/card-layout.component";
import { NameLayoutComponent } from "./components/name-layout/name-layout.component";
import { DescriptionLayoutComponent } from "./components/description-layout/description-layout.component";
import { ImageLayoutComponent } from "./components/image-layout/image-layout.component";
import { RowLayoutComponent } from "./components/row-layout/row-layout.component";
import { ButtonLayoutComponent } from "./components/button-layout/button-layout.component";
import { HtmlLayoutComponent } from "./components/html-layout/html-layout.component";
import { NavBarLayoutComponent } from "./components/nav-bar-layout/nav-bar-layout.component";
import { FooterLayoutComponent } from "./components/footer-layout/footer-layout.component";
import { LocationLayoutComponent } from "./components/location-layout/location-layout.component";
import { SocialMediaLayoutComponent } from "./components/social-media-layout/social-media-layout.component";
import { LogoLayoutComponent } from "./components/logo-layout/logo-layout.component";
import { ContactLayoutComponent } from "./components/contact-layout/contact-layout.component";
import { AgmCoreModule } from "@agm/core";
import { EmptyLayoutComponent } from "./components/empty-layout/empty-layout.component";
import { CalendarModule, DateAdapter } from "../../Calender-module";
import { adapterFactory } from "calendar-utils/date-adapters/date-fns";
import { OptCommonModules } from "src/modules/shared/common.modules";
import { OptFormModules } from "src/modules/shared/form.modules";
import { InputLayoutComponent } from "./components/input-layout/input-layout.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MetaReducer, StoreModule } from "@ngrx/store";
import { PBReducer } from "../../../store/public-web/public-web-reducers";
import { EffectsModule } from "@ngrx/effects";
import { PublicWebEffects } from "../../../store/public-web/public-web-effects";
import { LinkPackageListComponent } from "./modals/link-package-list/link-package-list.component";
import { statusReducer } from "src/modules/store/loading/reducer";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { localStorageSync } from "ngrx-store-localstorage";
import { ActionReducer } from "ngx-bootstrap/mini-ngrx";
import { cartReducer } from "src/modules/store/cart-reducer";
import { PackageSelectionAreaLayoutComponent } from "./components/package-selection-area-layout/package-selection-area-layout.component";
import { SessionModalComponent } from "./modals/session-modal/session-modal.component";
import { SeatViewModalComponent } from "./modals/seat-view-modal/seat-view-modal.component";
import { PwNumberInputComponent } from "src/modules/shared/pw-number-input/pw-number-input.component";
import { ButtonNumberInputComponent } from "src/modules/shared/button-number-input/button-number-input.component"
import { SeatInputComponent } from "src/modules/shared/seat-input/seat-input.component"

import { BrowserModule } from "@angular/platform-browser";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NguCarouselModule } from "@ngu/carousel";
import { PbCommonModule } from "../../pb-common.module";
import { FilterLayoutComponent } from "./components/filter-layout/filter-layout.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { LanguageReducer } from "../../../store/public-web/select-language/language-reducer";

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["cart", "PBReducer", "LanguageReducer"],
    rehydrate: true,
  })(reducer);
}
//translation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../dist/assets/i18n/", ".json");
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    HeaderLayoutComponent,
    SearchBoxLayoutComponent,
    FilterLayoutComponent,
    CardListLayoutComponent,
    CardLayoutComponent,
    NameLayoutComponent,
    DescriptionLayoutComponent,
    ImageLayoutComponent,
    RowLayoutComponent,
    ButtonLayoutComponent,
    HtmlLayoutComponent,
    NavBarLayoutComponent,
    FooterLayoutComponent,
    LocationLayoutComponent,
    SocialMediaLayoutComponent,
    LogoLayoutComponent,
    ContactLayoutComponent,
    EmptyLayoutComponent,
    InputLayoutComponent,
    LinkPackageListComponent,
    PackageSelectionAreaLayoutComponent,
    SessionModalComponent,
    SeatViewModalComponent,
    PwNumberInputComponent,
    ButtonNumberInputComponent,
    SeatInputComponent,
  ],

  imports: [
    OptCommonModules,
    PbCommonModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    BrowserModule,
    InfiniteScrollModule,
    BsDatepickerModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    StoreModule.forRoot(
      {
        cart: cartReducer,
        PBReducer: PBReducer,
        LanguageReducer: LanguageReducer,
        loader: statusReducer,
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([PublicWebEffects]),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDeYieR5QS_8QoAjU7gRe_Yr8fwh6GkVGM",
    }),
    NgxSliderModule,
    NgMultiSelectDropDownModule.forRoot(),
    NguCarouselModule,
  ],
  exports: [
    HeaderLayoutComponent,
    SearchBoxLayoutComponent,
    FilterLayoutComponent,
    CardListLayoutComponent,
    CardLayoutComponent,
    NameLayoutComponent,
    DescriptionLayoutComponent,
    ImageLayoutComponent,
    RowLayoutComponent,
    ButtonLayoutComponent,
    HtmlLayoutComponent,
    EmptyLayoutComponent,
    NavBarLayoutComponent,
    InputLayoutComponent,
    PwNumberInputComponent,
    ButtonNumberInputComponent,
    SeatInputComponent,
  ],
  entryComponents: [
    EmptyLayoutComponent,
    HeaderLayoutComponent,
    SearchBoxLayoutComponent,
    FilterLayoutComponent,
    CardListLayoutComponent,
    CardLayoutComponent,
    NameLayoutComponent,
    DescriptionLayoutComponent,
    ImageLayoutComponent,
    RowLayoutComponent,
    ButtonLayoutComponent,
    HtmlLayoutComponent,
    FooterLayoutComponent,
    LocationLayoutComponent,
    SocialMediaLayoutComponent,
    LogoLayoutComponent,
    ContactLayoutComponent,
    InputLayoutComponent,
    NavBarLayoutComponent,
    LinkPackageListComponent,
    PackageSelectionAreaLayoutComponent,
    SessionModalComponent,
    SeatViewModalComponent
  ],
})
export class LayoutsModule { }

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Event, EventDate, TimeSlot } from "../models/BookingEvent";
import { Utility } from "src/modules/utility";
import { BaseService } from "src/modules/services/base/base.service";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(private baseService: BaseService) {
  }

  getEvent(id: string): Observable<any> {
    const url = `api/public/events/${id}?IncludeVenueMapLayout=true`;

    return this.baseService.Internal_WebAPI_GET(url).pipe(
      map((d: any) => {
        if (!d.body.isError) {
          return new Event().initialize(d.body.data);
        }
        return undefined;
      })
    );
  }

  getEventDates(
    eventID: string,
    fromDate: any = undefined,
    toDate: any = undefined
  ): Observable<EventDate[]> {
    let url = '';
    if (fromDate && toDate) {
      fromDate = Utility.convertToISO(new Date(fromDate));
      toDate = Utility.convertToISO(new Date(toDate));
      url = `api/public/event/${eventID}/event-date?FromDate=${fromDate}&ToDate=${toDate}`;
    } else {
      url = `api/public/event/${eventID}/event-date?`;
    }
    return this.baseService.InternalWebAPIGET(url).pipe(
      map((d: any) => {
        if (d.data) {
          return d.data.map((e) => new EventDate().initialize(e));
        }
        return undefined;
      })
    );
  }

  getEventConfigurations(eventID: string, eventDate: EventDate, allocatedFacilityIds: any[]): Observable<TimeSlot[]> {
    const date = Utility.convertToDate(
      Utility.convertISOToDate(eventDate.endTime)
    );
    const url = `api/public/events/${eventID}/event-date/${eventDate.id}/event-configurations?${allocatedFacilityIds.map((x) => `&filters.AssetID=${x}`)
      .join("")}&filters.date=${date}`;

    return this.baseService.InternalWebAPIGET(url).pipe(
      map((d: any) => {
        if (d.data) {
          const result = d.data
            .filter((d) => d.configuration.configurationTypeId === 3)
            ?.map((d) => new TimeSlot().initialize(d));
          return result;
        }
        return undefined;
      })
    );
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//Layout
//import { ThemeUtility } from '../layout/layout';
import { SiteLayoutModule as LayoutModule } from 'src/modules/layout/site-layout/site-layout.module';
import { SiteLayoutComponent as LayoutComponent } from 'src/modules/layout/site-layout/site-layout.component';


import { PaymentComponent } from './payment/payment.component';
import { SecurePayComponent } from './secure-pay/secure-pay.component';
import { CheckoutComponent } from "../public-web/components/pages/checkout/checkout.component";
import { environment } from "src/environments/environment";
//var LayoutComponent = ThemeUtility.getThemeComponent();
//var LayoutModule = ThemeUtility.getThemeModule();
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "payment",
  },
  {
    path: "payment",
    component: LayoutComponent,
    children: [
      {
        path: "",
        component: PaymentComponent,
        pathMatch: "full",
      },
      { path: "secure-pay", component: SecurePayComponent, pathMatch: "full" },
    ],
  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }), LayoutModule],
    exports: [RouterModule]
})
export class PaymentRoutingModule {}

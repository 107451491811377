import { LocalImage } from './image.model';

// DONE
export class SliderImage {
    sliderImageID: string;
    transform: string;
    isSelected: boolean;
    image: LocalImage;

    constructor(data) {
        this.sliderImageID = data.imageID;
        this.transform = data.transform;
        this.isSelected = data.isSelected;
        this.image = new LocalImage(data.image);
    }
}

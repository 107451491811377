<div
  class="mmc-modal-wrapper pw-session-modal"
  id="session-modal-popup"
  style="display: none; overflow: auto; overflow-x: hidden;"
>
  <div class="mmc-modal-header mmc_flex--container modalTitle" style="min-height: 50px;">
  <div class="w-100" >
   <div class="row" *ngIf="modalTitle">
      <div class="col-1">Event</div>
      <div class="col-10">: {{modalTitle}}</div>
    </div>
   <div class="row" *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
      <div class="col-1">Package</div>
      <div class="col-10">: {{UpsellModalTitle}}</div>
  </div>
</div>
    <div
      class="mat-button"
      style="
        padding: 0;
        text-align: end;
        max-width: fit-content;
        min-width: fit-content;
      "
      (click)="dialogRef.close(false)"
    >
      <i class="material-icons" style="padding: 5px;margin-top: -10px;">close</i>
    </div>

    <!-- <button mat-button="" type="button" class="mat-button">
      <span class="mat-button-wrapper" (click)="dialogRef.close(false)"
        ><i class="material-icons">close</i></span
      >
      <div class="mat-button-ripple mat-ripple" matripple=""></div>
      <div class="mat-button-focus-overlay"></div>
    </button> -->
  </div>
  <div
    class="mmc-modal-body scrollbar"
    style="padding: 15px 30px;
    overflow-y: overlay;"
    *ngIf="canLoad"
  >
    <div class="mmc_flex--container">
      <!-- * select All Session For Booking -->
      <!-- <mat-checkbox
        (click)="accordion.closeAll()"
        [(ngModel)]="selectAllSession"
        (ngModelChange)="sessionChoices('Multi', accordion)"
      >
        Add All sessions
      </mat-checkbox> -->
      <div (click)="accordion.closeAll();sessionChoices('Multi', accordion)">
        <input id="radio-1" class="radio-custom" name="radio-group" type="radio" [checked]="selectAllSession" />
        <label for="radio-1" class="radio-custom-label">Add All sessions</label>
      </div>
    </div>
    <!-- Add All Packages -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="selectAllSession">
        <mat-expansion-panel-header style="
            border-bottom: none;
            background: rgb(247, 247, 249);
            height: 45px !important;
            border-radius: 0px;
          ">
          <mat-panel-title style="width: 100%;" class="row">
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 responsive-header">
              <strong>{{ multiSession.startDate | date: "mediumDate" }}</strong>
              -
              <strong>{{ multiSession.endDate | date: "mediumDate" }}</strong>
            </div>

            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 responsive-header" style="padding: 0px 15px 0px 0px;">
              <strong>{{publicPackageLength}} nights </strong><i class="fa fa-moon-o" aria-hidden="true"></i>
            </div>

            <div class="col-xs-10 col-sm-3 col-md-3 col-lg-3 text-success text-left" style="padding: 0;"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
              {{multiSession.availability}} Available
              <strong style="color: gray;">
                <a style="font-size: 12px !important;"> from</a>
                ${{multiSession.minimumPrice | number: "1.2-2" }} (per
                night)</strong>
            </div>
            <div class="col-xs-10 col-sm-3 col-md-3 col-lg-3 text-success text-left"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION" style="padding: 0;">
              <strong style="color: gray;">
                <a style="font-size: 12px !important;"> from</a>
                ${{this.upsellItem.itemPriceGroups[0].priceIncludingTax | number: "1.2-2" }} (per night)</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row" style="padding: 5px 25px 0px 25px;">
          <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-center"
            style="font-size: 14px; padding: 0; line-height: 5;"></div>

          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-left" style="font-size: 14px; padding: 0;"
            *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
            <div class="text-left" style="padding: 0px 0px 30px 15px;" *ngFor="let lp of publicPackage.linkPackages">
              <a style="font-size: 12px !important; color: gray;"> from</a>
              <strong>{{lp.variablePriceIncludingTax*publicPackageLength | number: "1.2-2"}} ({{publicPackageLength}} nights)</strong>
            </div>
            <!-- <div class="text-left" style="padding: 0px 0px 30px 15px;"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
              <a style="font-size: 12px !important; color: gray;"> from</a>
              <strong>${{publicPackage.linkPackages[1].linkedPackageType.name==='Child'
                ?
                (publicPackage.linkPackages[1].variablePriceIncludingTax*publicPackageLength
                | number: "1.2-2") :
                (publicPackage.linkPackages[0].variablePriceIncludingTax*publicPackageLength
                | number: "1.2-2")}} ({{publicPackageLength}} nights)</strong>
            </div> -->
          </div>

          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center" style="font-size: 14px; padding: 0;" *ngIf="
              type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION
            ">
            <div class="" *ngIf="
              type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION
            ">
              <div class="row" style="padding: 0px 0px 20px 0px;" *ngFor="let pk of publicPackage.linkPackages">
                <div class="col-xs-12 col-sm-5 col-md-5 col-lg-3">
                  {{ pk.linkedPackageType.name }}
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                  <!-- <opt-pw-number-input [(value)]="pk.quantity" (valueChange)="onselectAllSession()">
                  </opt-pw-number-input> -->
                  <opt-pw-number-input
                  [value]="pk.quantity" [canValueChange]="false"
                  (increase)="onMultiSelectionChange($event, NUMBER_OUTPUT.increase, pk)"
                  (decrease)="onMultiSelectionChange($event, NUMBER_OUTPUT.decrease, pk)">
                </opt-pw-number-input>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-right" style="line-height: 2; padding-left: 5px;">
                  ${{ (pk.quantity* (pk.linkedPackageType.name==='Child' ?
                  (pk.variablePriceIncludingTax) :
                  (pk.variablePriceIncludingTax)))*publicPackageLength | number:
                  "1.2-2"}}
                </div>
              </div>
              <ng-template #packagelist>
                <div class="mmc_flex--container">
                  <div class="mmc_just-content-end">
                    <opt-pw-number-input [(value)]="publicPackage.quantity" (valueChange)="onselectAllSession()">
                    </opt-pw-number-input>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-left" style="font-size: 14px; padding: 0px;"
            *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
            <div class="" style="padding: 0px 0px 30px 15px;">
              <a style="font-size: 12px !important; color: gray;"> from</a>
              <strong>
                ${{this.upsellItem.itemPriceGroups[0].priceIncludingTax*publicPackageLength
                | number: "1.2-2" }} ({{publicPackageLength}} nights)</strong>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center" style="font-size: 14px; padding: 0;"
            *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
            <div class="" *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
              <div class="row" style="padding: 0px 0px 20px 0px;">
                <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4"></div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                  <opt-pw-number-input [(value)]="publicPackage.itemQuantity" (valueChange)="onselectAllSession()">
                  </opt-pw-number-input>

                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-2 text-right" style="line-height: 2; padding-left: 0;">
                  ${{publicPackageLength*this.upsellItem.itemPriceGroups[0].priceIncludingTax*
                  publicPackage.itemQuantity | number: "1.2-2"}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- End Add All Packages -->

    <!------------------------------------------------------------------------------------- -->
    <div class="mmc_flex--container" style="padding-top: 10px;">
      <!--  * Select package as single session -->
      <!-- <mat-checkbox
        [(ngModel)]="selectSpecifigSession"
        (ngModelChange)="sessionChoices('Single')"
      >
        {{ type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION ?
        "Package" : "AddOns" }} session
      </mat-checkbox> -->
      <div (click)="sessionChoices('Single')">
        <input id="radio-2" class="radio-custom" name="radio-group" type="radio" [checked]="selectSpecifigSession" />
        <label for="radio-2" class="radio-custom-label">
          {{ type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION ?
          "Package" : "AddOns" }} session</label>
      </div>
    </div>

    <mat-accordion #accordion="matAccordion" [multi]="true">
      <div *ngFor="let event of sessionItems index as id; first as isFirst">
        <mat-expansion-panel style="margin-bottom: 15px;" [expanded]="isFirst && selectSpecifigSession">
          <mat-expansion-panel-header style="
              border-bottom: none;
              background: rgb(247, 247, 249);
              height: 45px;
              border-radius: 0px;
            ">
            <mat-panel-title style="width: 100%;" class="row">
              <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 responsive-header" style="padding: 0px 10px 0px 15px;">
                <strong><span class="day" style="color: black;">{{ event.date | date: "mediumDate" }}</span></strong>
              </div>
              <div class="col-xs-10 col-sm-3 col-md-3 col-lg-3 text-success text-left"
                *ngIf="type===SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
                {{ event.availability }} Available
                <span>
                  <a style="font-size: 12px !important; color: gray;"> from</a>
                  <strong style="color: gray;">
                     ${{linkedPackageMinPrice | number: "1.2-2" }}
                      </strong></span>
              </div>
              <div class="col-xs-10 col-sm-3 col-md-3 col-lg-3 text-success text-left" style="padding-left: 15px;"
                *ngIf="type===SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
                <strong style="color: gray;">
                  <a style="font-size: 12px !important;"> from</a>
                  ${{this.upsellItem.itemPriceGroups[0].priceIncludingTax |
                  number: "1.2-2" }}</strong>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row" style="padding: 5px 25px 0px 25px;" *ngFor="let e of event.sessions; i as index">
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-center"
              style="font-size: 14px; padding: 0; line-height: 5;" [ngClass]="{
            'line-height-4':
              e.package.isHaveLinkPackage &&
              type !== SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION
          }"></div>

            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-left" style="font-size: 14px; padding: 0px;"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
              <div class="" style="padding: 0px 0px 30px 15px;" *ngFor="let pk of e.package.linkPackages">
                <a style="font-size: 12px !important; color: gray;"> from</a>
                <strong>${{getMinimumPriceOfLinkedPacakgeType(pk) | number: "1.2-2"}}</strong>
                <!-- <strong>${{e.package.linkPackages[0].linkedPackageType.name==='Adult'
                  ? (e.package.linkPackages[0].variablePriceIncludingTax |
                  number: "1.2-2"):
                  (e.package.linkPackages[1].variablePriceIncludingTax | number:
                  "1.2-2")}}</strong> -->
              </div>
              <!-- <div style="padding: 0px 0px 30px 15px;"
                *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
                <a style="font-size: 12px !important; color: gray;"> from</a>
                <strong>${{e.package.linkPackages[1].linkedPackageType.name==='Child'
                  ? (e.package.linkPackages[1].variablePriceIncludingTax |
                  number: "1.2-2") :
                  (e.package.linkPackages[0].variablePriceIncludingTax | number:
                  "1.2-2")}}</strong>
              </div> -->
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center" style="font-size: 14px; padding: 0;" *ngIf="
              type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION
            ">
              <div *ngIf="
              type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION
            ">
                <div *ngIf="e.package.isHaveLinkPackage; else packagelist">
                  <div class="row" style="padding: 0px 0px 20px 0px;" *ngFor="let pk of e.package.linkPackages">
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-3">
                      {{ pk.linkedPackageType.name }}
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                      <opt-pw-number-input [value]="pk.quantity" [canValueChange]="false"
                        (increase)="OnSingleSelectionChange($event, NUMBER_OUTPUT.increase, e, pk)"
                        (decrease)="OnSingleSelectionChange($event, NUMBER_OUTPUT.decrease, e, pk)">
                      </opt-pw-number-input>
                      <!-- (valueChange)="onselectSession(e)" -->
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-right"
                      style="line-height: 2; padding-left: 5px;">
                      ${{ pk.quantity* (pk.linkedPackageType.name==='Child' ?
                      (pk.variablePriceIncludingTax) :
                      (pk.variablePriceIncludingTax)) | number: "1.2-2"}}
                    </div>
                  </div>
                </div>
                <ng-template #packagelist>
                  <div class="mmc_flex--container">
                    <div class="mmc_just-content-end">
                      <opt-pw-number-input [(value)]="e.package.quantity" (valueChange)="onselectSession(e)">
                      </opt-pw-number-input>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-left" style="font-size: 14px; padding: 0px;"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
              <div class="" style="padding: 0px 0px 30px 15px;">
                <a style="font-size: 12px !important; color: gray;"> from</a>
                <strong>
                  ${{this.upsellItem.itemPriceGroups[0].priceIncludingTax |
                  number: "1.2-2" }}</strong>
              </div>
              <!-- <div style="padding: 0px 0px 30px 15px;"
                *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
                <a style="font-size: 12px !important; color: gray;"> from 123</a>
                <strong>${{e.package.linkPackages[1].linkedPackageType.name==='Child'
                  ? (e.package.linkPackages[1].variablePriceIncludingTax |
                  number: "1.2-2") :
                  (e.package.linkPackages[0].variablePriceIncludingTax | number:
                  "1.2-2")}}</strong>
              </div> -->
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center" style="font-size: 14px; padding: 0;"
              *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
              <div class="" *ngIf="type === SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
                <div class="row" style="padding: 0px 0px 20px 0px;">
                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4"></div>
                  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-3">
                    <opt-pw-number-input [(value)]="e.package.itemQuantity" (valueChange)="onselectSession(e)">
                    </opt-pw-number-input>
                  </div>
                  <div class="col-xs-12 col-sm-3 col-md-3 col-lg-2 text-right" style="line-height: 2; padding-left: 0;">
                    ${{this.upsellItem.itemPriceGroups[0].priceIncludingTax*
                    e.package.itemQuantity | number: "1.2-2"}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>

  <div class="row mmc-modal-footer mmc_flex--container p-2">
    <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7" style="padding-left: 30px;">
      <div class="row">
        <div class="col text-right">
          <mat-paginator class="pull-left" [length]="pager.totalItems" [pageSize]="sessionPerPage"
            [pageSizeOptions]="[5, 10]" (page)="onPageChange($event)">
          </mat-paginator>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5" style="padding-right: 30px;">
      <div class="row" style="padding-top: 15px;">
        <div class="col pull-right">
          <h5 style="line-height: 2;" class="pull-right"
            *ngIf="type===SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION">
            ${{ !selectAllSession ? (totalAmountToDisplay | number:"1.2-2") :
            (totalAmountAllToDisplay | number:"1.2-2")}}
          </h5>
          <h5 class="pull-right" style="line-height: 2;"
            *ngIf="type===SESSION_SELECTION_MODEL_TYPE.BOOKING_ITEM_SESSION">
            ${{ !selectAllSession ? (totalAmountToDisplay | number:"1.2-2") :
            (publicPackageLength*this.upsellItem.itemPriceGroups[0].priceIncludingTax*
            publicPackage.itemQuantity | number: "1.2-2")}}
          </h5>
        </div>
        <div class="col-5 text-right">
          <button type="submit" class="pull-right mmc-btn mmc-btn-secondary--small main mmc_flex__item--vcenter" style="
              background-color: #4bb3fa !important;
              border: none !important;
            " mat-button (click)="addPackageIntoBooking()">
            ADD TO BOOKING
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

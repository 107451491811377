import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "src/modules/shared/base.component";
import { Theme } from "../../models/globalTheme";
import { ILayout } from "../../models/interfaces";
import { PackageSelectionAreaLayout } from "../../models/main";
import * as d3 from "d3";

import { Observable } from "rxjs";
import { ICart, SignOut, Store } from "src/modules/store";

import {
    AddPackage,
    SelectPackageSessions,
    SelectSession,
    SetRedirectionPoint,
    UpdatePackage,
} from "../../../../../store/public-web/public-web-actions";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { Package, PackageDurationRange } from "src/modules/models/public-web/Package";
import { SessionModalComponent } from "../../modals/session-modal/session-modal.component";
import { PAGE_CONFIGURATION, SESSION_SELECTION_MODEL_TYPE, } from "../../models/enums";
import { IDateAndSession, IPBReducer, IPublicPackage, } from "../../../../../store/public-web/public-web-reducers";
import * as _ from "lodash";
import { AlertMessageComponent } from "src/modules/shared/alert-message/alert-message.component";
import { Booking } from "src/modules/models/regular-portal/booking/booking";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { PackageDurationModalComponent } from "../../../modals/package-duration-modal/package-duration-modal.component";
import { PublicPackageService } from "src/modules/services/public-web/public-package.service";
import { BaseParam } from "src/modules/models/public-web/filter";
import { PackageDate } from "src/modules/models/public-web/PackageSession";
import { BOOKING_CART } from "src/modules/models/public-web/enum";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { Utility } from "src/modules/utility";
import {
    AVAILABILITY_INDICATOR_OPTION,
    AvailabilityConfiguration,
    PublicBookingSettings
} from "src/modules/models/settings/public-booking/public-booking-setting";
import { PublicEventService } from "../../../../../services/public-web/public-event.service";

@Component({
    selector: "opt-package-selection-area-layout",
    templateUrl: "./package-selection-area-layout.component.html",
})
export class PackageSelectionAreaLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {
    parentRef: any;
    theme: Theme;
    @Input() layoutAttribute: PackageSelectionAreaLayout;
    @Input() isView: boolean;
    @Input() elementID;
    @Input() type: string;
    @Input() unique_key: any;
    @Input() isGrid = false;

    publicCart$: Observable<IPublicPackage>;
    publicPackage: IPublicPackage;
    packageQuantity;
    clientCategoryId;
    cart$: Observable<ICart>;
    PBReducer$: Observable<any>;
    pageConfig: any;
    selectedSessions: IDateAndSession[];
    booking: Booking;
    isExpandChecked: boolean = false;
    bookingMode: BOOKING_CART;
    AVAILABILITY_INDICATOR_OPTION = AVAILABILITY_INDICATOR_OPTION;
    eventID: number;
    backgroundColor = "black";
    redirectToBookingApplication = true;
    availabilityConfiguration: AvailabilityConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration;
    isContinueBooking: any;
    showTotal: boolean = false
    adultCount: number;
    private selectedSessionID: string;

    constructor(
        private store: Store<any>,
        private router: Router,
        private activeRouter: ActivatedRoute,
        public snackBar: MatSnackBar,
        private packageService: PublicPackageService,
        private publicEventService: PublicEventService,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
        super();
        this.PBReducer$ = this.store.select(PBSelector.selectUpsellState);
        this.cart$ = this.store.select("cart");
        this.packageQuantity = 0;
    }

    get selectionPanelName() {
        if (
            this.publicPackage &&
            // this.publicPackage.isNotMemberPackage &&
            this.publicPackage.clientCategories &&
            !this.publicPackage.clientCategories.find(
                (c) => c.id === this.clientCategoryId
            )
        ) {
            return "Login to Proceed";
        } else {
            return "Select";
        }
    }

    get totalPrice() {
        this.showTotal = false
        if (this.publicPackage) {
            if (
                this.publicPackage &&
                this.publicPackage.isHaveLinkPackage &&
                this.publicPackage.linkPackages &&
                this.publicPackage.linkPackages.length > 0
            ) {
                let amount = 0;
                this.publicPackage.linkPackages.forEach((x) => {
                    amount += x.variablePriceIncludingTax * x.quantity;
                    if (x.quantity > 0) {
                        this.showTotal = true
                    }
                });
                return amount;
            }
            if (this.publicPackage && this.publicPackage.quantity > 0) {
                this.showTotal = true
            }
            return this.layoutAttribute.unitPrice * this.publicPackage.quantity;
        }
        return this.layoutAttribute.unitPrice;
    }

    get packagePrice() {
        if (this.publicPackage?.isHaveLinkPackage && this.publicPackage?.linkPackages?.length > 0) {
            const minPrice = Math.min(...this.publicPackage.linkPackages.map(pk => pk.variablePriceIncludingTax))
            return minPrice
        } else {
            return this.layoutAttribute.unitPrice
        }
    }

    get disableSelectBtn() {
        if (
            this.publicPackage &&
            // this.publicPackage.isNotMemberPackage &&
            this.publicPackage.clientCategories &&
            !this.publicPackage.clientCategories.find(
                (c) => c.id === this.clientCategoryId
            )
        ) {
            return false;
        }
        return (
            this.publicPackage &&
            !this.publicPackage.isHaveLinkPackage &&
            (this.packageQuantity <= 0 ||
                this.packageQuantity > this.layoutAttribute.available)
        );
    }

    get GET_WIDTH() {
        let iconBtn = document.getElementById("material-icon-btn");
        let iconBtnSec = document.getElementById("secondary-btn-linked");
        if (iconBtn && iconBtnSec) {
            iconBtnSec.style.width = (iconBtn.offsetWidth + 15) + "px";
        }
        return true;
    }

    get isExpandButton() {
        if (this.publicPackage && this.publicPackage.isHaveLinkPackage) {
            return true;
        }
        return false;
    }

    get isMultiSelectionPackage() {
        if (this.pageConfig) {
            return this.pageConfig?.includes(PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION);
        } else {
            return false;
        }
    }

    get AVAILABILITY_TERM() {
        let term = "";
        if (this.publicPackage && this.layoutAttribute && this.layoutAttribute.available) {
            this.availabilityConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration;
            //Need to find the percentage of availablity
            const capacity = this.publicPackage.packageSessions.reduce((accumulator, obj) => {
                return accumulator + obj.capacity;
            }, 0);

            const available = this.publicPackage.packageSessions.reduce((accumulator, obj) => {
                return accumulator + obj.availability;
            }, 0);

            const availablePercentage = Math.ceil(available / capacity * 100);
            // const availablePercentage = (this.layoutAttribute.available / capacity * 100);

            const thresholds = this.availabilityConfiguration.availabilityThresholds;
            if (thresholds.length > 0) {
                thresholds.forEach(threshold => {
                    if (availablePercentage >= threshold.availabilityFrom && availablePercentage <= threshold.availabilityTo) {
                        this.backgroundColor = threshold.thresholdColor;
                        term = threshold.thresholdTerm;
                    }
                    if (availablePercentage > 100 && threshold.availabilityTo == 100) {
                        this.backgroundColor = threshold.thresholdColor;
                        term = threshold.thresholdTerm;
                    }
                })
            }
        } else {
            if (!this.layoutAttribute.available) {
                this.availabilityConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).availabilityConfiguration;
                const thresholds = this.availabilityConfiguration.availabilityThresholds;
                let index = thresholds?.findIndex(threshold => 0 == threshold.availabilityFrom && 0 == threshold.availabilityTo)
                if (index != -1) {
                    this.backgroundColor = thresholds[index].thresholdColor;
                    term = thresholds[index].thresholdTerm;
                }
            }
        }

        return term;
    }

    ngOnInit() {
        this.cart$.subscribe((state) => {
            if (state.contact && state.contact.client) {
                this.clientCategoryId =
                    state.contact.client.clientCategoryId.toString();
            }
        });
        this.route.params.safeSubscribe(this, (params) => {
            this.eventID = +params["eventId"];
        });
        this.activeRouter.queryParams.safeSubscribe(this, (params) => {
            this.selectedSessionID = params.sessionID;
            this.isContinueBooking = params.isContinueShopping;
        });
        this.PBReducer$.safeSubscribe(this, (d: IPBReducer) => {
            this.pageConfig = d.pageConfiguration;
            this.booking = d.booking as any;
            this.bookingMode = d.bookingMode;
            this.selectedSessions = _.cloneDeep(d.selectedSessions);
        });
        this.publicCart$ = this.store.select(PBSelector.selectedPublicPackageById(this.layoutAttribute.packageID));
        this.publicCart$.safeSubscribe(this, (pk) => {
            if (pk) {
                this.publicPackage = pk

              if (this.publicPackage && !this.isMultiSelectionPackage)
                this.publicPackage.quantity = this.packageQuantity;

              if ((this.pageConfig && !this.pageConfig.includes("OVERRIDE_BOOKING_APP"))) {
                this.redirectToBookingApplication = this.publicPackage.hasTicketing;
              } else {
                this.redirectToBookingApplication = false;
              }
            }
        });

    }

    ngAfterViewInit(): void {
        this.initializeAttributes();

    }

    initializeAttributes() {
        const contentElement = document.getElementById(this.elementID);

        if (contentElement) {
            d3.select(contentElement.closest(".package-select-Area-layout"))
                .data([this.layoutAttribute.gridColumn])
                .style("margin", 0 + "px");
            // .style('margin', this.theme.card.margin + 'px');
            d3.select(contentElement.parentElement).attr(
                "class",
                `col-md-${this.layoutAttribute.gridColumn}
                col-lg-${this.layoutAttribute.gridColumn}
                col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
            );
        }
    }

    onClickBuyNow() {

        if (this.redirectToBookingApplication && this.isContinueBooking) {
            if (this.publicPackage && this.publicPackage.packageSessions && this.publicPackage.packageSessions.filter(x => x.salesChannelId == 5).length == 1) {
                let packageSession = this.publicPackage.packageSessions.filter(x => x.salesChannelId == 5)[0];

                const prevSession = this.selectedSessions.find(x =>
                    x?.startTime === Utility.convertToISO(Utility.convertISOToDate(packageSession?.startTime)) &&
                    x?.endTime === Utility.convertToISO(Utility.convertISOToDate(packageSession?.endTime)) &&
                    !!x.bookingPackages && x.bookingPackages.find(y => y.packageId == this.publicPackage.id) !== undefined
                );
                let session;
                if (prevSession) {
                    session = _.cloneDeep(prevSession);
                    let packageIndex = session.packages.findIndex(x => x.id == this.publicPackage.id)
                    if (packageIndex != -1) {
                        if (
                            session.packages[packageIndex].isHaveLinkPackage &&
                            this.publicPackage.isHaveLinkPackage
                        ) {
                            session.packages[packageIndex].linkPackages.forEach((lp) => {
                                this.publicPackage.linkPackages.forEach((publicLinkedPackage) => {
                                    if (lp.id === publicLinkedPackage.id) {
                                        lp.quantity += publicLinkedPackage.quantity;
                                        lp.isBooked = false;
                                    }
                                });
                            });
                            session.packages[packageIndex].isBooked = false;
                        } else {
                            session.packages[packageIndex].quantity += this.publicPackage.quantity;
                            session.packages[packageIndex].isBooked = false;
                        }
                    }
                } else {
                    session = {
                        id: packageSession.id,
                        startTime: Utility.convertToISO(Utility.convertISOToDate(packageSession.startTime)),
                        endTime: Utility.convertToISO(Utility.convertISOToDate(packageSession.endTime)),
                        availability: packageSession.availability,
                        packages: [this.publicPackage],
                    };
                }

                this.store.dispatch(new SelectSession(session));
                this.router.navigate(["seating-view/" + this.eventID], {
                    queryParams: {
                        sessionId: packageSession.id,
                        packageId: this.publicPackage.id
                    },
                });
            }
        } else if (this.publicPackage.linkPackages) {
            this.linkPackageSelection()
        } else {
            this.packageSelection();
        }
    }

    decrement() {
        if (this.packageQuantity > 0) {
            this.packageQuantity--;
            this.updatePackage(this.packageQuantity);
        }
    }

    onCheckPackQuantity(value) {
        let quantity = +value;
        if (quantity > this.layoutAttribute.available) {
            this.packageQuantity = this.layoutAttribute.available;
            value = this.packageQuantity;
            this.openSnackBarError([
                `Only ${this.layoutAttribute.available} are available`,
            ]);
        } else if (quantity < 0) {
            this.packageQuantity = 0;
            value = 0;
        } else {
            this.packageQuantity = quantity;
        }

        this.updatePackage(this.packageQuantity);
    }

    increment() {
        if (this.packageQuantity < this.layoutAttribute.available) {
            this.packageQuantity++;
            this.updatePackage(this.packageQuantity);
        } else {
            return this.openSnackBarError([
                `Only ${this.layoutAttribute.available} are available`,
            ]);
        }
    }

    updatePackageQuantity() {
        if (this.packageQuantity == null) {
            this.packageQuantity = 0;
        }
        this.updatePackage(this.packageQuantity);
    }

    updatePackage(q) {
        if (this.publicPackage) {
            this.publicPackage.quantity = q;
            this.store.dispatch(new UpdatePackage(this.publicPackage));
        }
    }

    addPackageQ(pk: Package) {
        if (this.validatePackage(pk, pk.quantity + 1)) {
            pk.quantity += 1;
            pk.isBooked = false;
            this.store.dispatch(new UpdatePackage(this.publicPackage));
        }
    }

    reducePackageQ(pk: Package) {
        if (pk.quantity != 0 && this.validatePackage(pk, pk.quantity - 1)) {
            pk.quantity -= 1;
            pk.isBooked = false;
        }
        this.store.dispatch(new UpdatePackage(this.publicPackage));
    }

    validated(pk: Package) {
        const adultPackage = _.cloneDeep(
            this.publicPackage.linkPackages.find(
                (x) => x.linkedPackageType.id === "1"
            )
        );

        if (pk.linkedPackageType.id === "1") {
            if (pk.quantity == null) {
                pk.quantity = 0;
                //this.store.dispatch(new UpdatePackage(this.publicPackage));
            }
            this.adultCount = pk.quantity;
        }
        let maxChildCount = 0
        if (!!adultPackage) {
            // if (
            //   pk.linkedPackageType.id === "2" &&
            //   pk.linkedPackageType.maximumNumberOfChildren !== 0 &&
            //   pk.linkedPackageType.adultRequired
            // )
            if (pk.linkedPackageType.adultRequired
            ) {
                if ((pk.maxChildrenPerAdult == null || pk.maxChildrenPerAdult == undefined) && pk.linkedPackageType.maximumNumberOfChildren !== 0 && pk.linkedPackageType.adultRequired) {
                    maxChildCount = pk.linkedPackageType.maximumNumberOfChildren;
                } else if (pk.maxChildrenPerAdult !== 0 && (pk.maxChildrenPerAdult != null || pk.maxChildrenPerAdult != undefined)) {
                    maxChildCount = pk.maxChildrenPerAdult * adultPackage.quantity;
                }
                if (this.adultCount > 0) {
                    if (pk.quantity == null) {
                        pk.quantity = 0;
                        //  this.store.dispatch(new UpdatePackage(this.publicPackage));
                    }
                    if (pk.quantity > maxChildCount) {
                        pk.quantity = 0;
                        // this.store.dispatch(new UpdatePackage(this.publicPackage));
                        this.openSnackBarError([
                            "Child Package count should be less than or equal " +
                            maxChildCount,
                        ]);
                        return true;
                    }
                } else {
                    if (pk.quantity == null || pk.quantity > 0) {
                        pk.quantity = 0;
                        // this.store.dispatch(new UpdatePackage(this.publicPackage));
                        this.openSnackBarError(["Adult package should be select first"]);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    validatePackage(pk: Package, upQ) {
        const adultPackage = _.cloneDeep(
            this.publicPackage.linkPackages.find(
                (x) => x.linkedPackageType.id === "1"
            )
        );
        let maxChildCount = 0
        if (!!adultPackage) {

            if (
                pk.linkedPackageType.id === "2"
                &&
                // pk.linkedPackageType.maximumNumberOfChildren !== 0 &&
                pk.linkedPackageType.adultRequired
            ) {

                if ((pk.maxChildrenPerAdult == null || pk.maxChildrenPerAdult == undefined) && pk.linkedPackageType.maximumNumberOfChildren !== 0 && pk.linkedPackageType.adultRequired) {
                    maxChildCount = pk.linkedPackageType.maximumNumberOfChildren;
                } else if (pk.maxChildrenPerAdult !== 0 && (pk.maxChildrenPerAdult != null || pk.maxChildrenPerAdult != undefined)) {
                    maxChildCount = pk.maxChildrenPerAdult * adultPackage.quantity;
                }
                if (upQ > maxChildCount) {
                    this.openSnackBarError([
                        "Child Package count should be less than or equal " + maxChildCount,
                    ]);
                    return false;
                }
            } else if (pk.id === adultPackage.id) {
                for (const key in this.publicPackage.linkPackages) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            this.publicPackage.linkPackages,
                            key
                        )
                    ) {
                        if (
                            this.publicPackage.linkPackages[key].linkedPackageType.id ===
                            "2" &&
                            this.publicPackage.linkPackages[key].linkedPackageType
                                .maximumNumberOfChildren !== 0 &&
                            this.publicPackage.linkPackages[key].linkedPackageType
                                .adultRequired
                        ) {
                            const mxCount =
                                this.publicPackage.linkPackages[key].linkedPackageType
                                    .maximumNumberOfChildren * upQ;
                            if (this.publicPackage.linkPackages[key].quantity > mxCount) {
                                this.publicPackage.linkPackages[key].quantity = mxCount;
                            }
                        }
                    }
                }
            }
            return true;
        }
        return true;
    }

    packageSelection() {
        if (
            this.publicPackage &&
            // this.publicPackage.isNotMemberPackage &&
            this.publicPackage.clientCategories &&
            !this.publicPackage.clientCategories.find(
                (c) => c.id === this.clientCategoryId
            )
        ) {
            // * Redirect to Customer Login
            this.store.dispatch(new SignOut());
            localStorage.setItem("PublicWebRedirection", this.router.url);
            window.open(
                environment.ApiUrl + "#/client/login?DirectLoginFromPublicWeb=1&ReturnUrl=" + this.router.url,
                "_self"
            );
        } else {
            const isRedirectDateASession = this.layoutAttribute.redirectionURL
                .toLocaleLowerCase()
                .includes("date-and-session");
            this.store.dispatch(
                new SetRedirectionPoint(this.layoutAttribute.redirectionURL)
            );

            if (this.publicPackage && this.isMultiSelectionPackage) {
                this.store.dispatch(
                    new SetRedirectionPoint(this.layoutAttribute.redirectionURL)
                );
                // this.store.dispatch(new UpdatePackage(this.publicPackage));
                this.openAddSessionModel();
            } else if (isRedirectDateASession) {
                if (this.layoutAttribute.redirectionURL.split("?").length > 0) {
                    const url = this.layoutAttribute.redirectionURL.split("?")[0];
                    const queryParams = this.layoutAttribute.redirectionURL.split("?")[1];
                    const params = queryParams.split("&");
                    let pair = null,
                        data = [];
                    let paramString = "{";
                    params.forEach(function (d) {
                        pair = d.split("=");
                        paramString += `"${pair[0]}":${pair[1]}`;
                        data.push({ key: pair[0], value: pair[1] });
                    });
                    paramString += "}";
                    const paramObj = JSON.parse(paramString);
                    const session: IDateAndSession = {
                        id: "-1",
                        startTime: undefined,
                        endTime: undefined,
                        availability: undefined,
                        packages: [this.publicPackage],
                    };
                    this.store.dispatch(
                        new SetRedirectionPoint(this.layoutAttribute.redirectionURL)
                    );
                    this.store.dispatch(new SelectPackageSessions([session]));
                    this.router.navigate([url], { queryParams: paramObj });
                } else {
                    this.openSnackBarError(this.layoutAttribute.redirectionURL);
                }
            } else if (this.publicPackage && this.publicPackage.isHaveLinkPackage) {
                this.publicPackage.isLinkPackageExpand =
                    !this.publicPackage.isLinkPackageExpand;
                this.store.dispatch(new UpdatePackage(this.publicPackage));
            } else {
                this.selectPackage();
            }
        }
    }

    openPackageDurationModal() {
        const filter = new BaseParam();
        filter.packageID = +this.publicPackage.id;
        const refSer = this.packageService
            .getPublicPackage(filter, undefined)
            .subscribe((publicPackages) => {
                const publicPackage = publicPackages.find(
                    (p) => p.id === filter.packageID.toString()
                );
                if (
                    publicPackage &&
                    publicPackage.packageDurationRanges &&
                    publicPackage.packageDurationRanges.length > 0
                ) {
                    if (publicPackage.packageDurationRanges.length == 1) {
                        this.selectPackageDuration(publicPackage.packageDurationRanges[0], publicPackage, this.publicPackage)
                    } else {
                        const dialogRef = this.dialog.open(PackageDurationModalComponent, {
                            data: {
                                publicPackage: publicPackage,
                                quantity: this.packageQuantity,
                                packageInterfaceModal: this.publicPackage,
                                isExpand: this.isExpandChecked,
                            },
                            height: "auto",
                            width: "40%",
                            minWidth: "350px",
                        });
                        dialogRef.afterClosed().subscribe((res) => {
                        });
                    }
                } else {
                    return this.openSnackBarError(["Package duration range undefine"]);
                }
                if (refSer) {
                    refSer.unsubscribe();
                }

            });
    }

    selectPackageDuration(packageDurationRange: PackageDurationRange, publicPackage, packageInterfaceModal) {
        const selectedPackageSession = publicPackage.packageSessions.find(
            (ps) => {
                let isInclude = false;
                if (ps.packageDate) {
                    let pd = publicPackage.packageDates
                        .sort((x, y) => (x.eventDate.date > y.eventDate.date ? 1 : 0))
                        .find(
                            (pd) =>
                                pd.id == ps.packageDate.id &&
                                pd.packageDurationRange &&
                                pd.packageDurationRange.id == packageDurationRange.id
                        );
                    if (pd) {
                        isInclude = true;
                    }
                }
                return isInclude;
            }
        );
        //#region Session create and update
        const { startDate, endDate } = this.getDurationDates(
            publicPackage.packageDates.filter(
                (x) => x.packageDurationRange.id === packageDurationRange.id
            )
        );
        if (selectedPackageSession && startDate && endDate) {
            // const sessionIndex = this.selectedSessions.findIndex(
            //   (s) =>
            //     s.id === selectedPackageSession.id &&
            //     s.durationRange &&
            //     s.durationRange.id === packageDurationRange.id.toString()
            // );
            //find index by date
            const sessionIndex = this.selectedSessions.findIndex(
                (s) =>
                    Utility.convertToISO(Utility.convertISOToDate(s.startTime)) === Utility.convertToISO(Utility.convertISOToDate(startDate)) &&
                    Utility.convertToISO(Utility.convertISOToDate(s.endTime)) === Utility.convertToISO(Utility.convertISOToDate(endDate))
            );
            let session: IDateAndSession;
            if (sessionIndex !== -1) {
                session = this.selectedSessions[sessionIndex];
                if (selectedPackageSession && selectedPackageSession.packages && selectedPackageSession.packages.length > 0) {
                    //find the package in session
                    const previousPackage = selectedPackageSession.packages.find(x => x.id == packageInterfaceModal.id);
                    if (previousPackage && previousPackage.linkPackages && previousPackage.linkPackages.length > 0) {
                        previousPackage.linkPackages.forEach(pkg => {
                            //finding existing exact linked package from the link package
                            pkg.isBooked = false;
                            const previousLinkPackage = packageInterfaceModal.linkPackages.find(x => x.id == pkg.id);
                            if (previousLinkPackage) {
                                //increasing the quantity with the previous package from new pkg quantity
                                previousLinkPackage.quantity += pkg.quantity;
                            } else {
                                previousPackage.linkPackages.push(pkg);
                            }
                        });
                    } else {
                        // debugger;
                        packageInterfaceModal.quantity += previousPackage.quantity;
                        previousPackage.isBooked = false;
                        console.log("No package found!")
                    }
                }
            } else {
                session = {
                    packages: [],
                    id: selectedPackageSession.id.toString(),
                    startTime: startDate,
                    endTime: endDate,
                    durationRange: packageDurationRange,
                    availability: selectedPackageSession.availability,
                };
            }
            const pk = _.cloneDeep(packageInterfaceModal);
            const pkIndex = session.packages.findIndex((p) => p.id === pk.id);
            if (pkIndex !== -1) {
                session.packages[pkIndex] = pk;
                if (session.packages[pkIndex].quantity > 0) {
                    session.packages[pkIndex].isBooked = false;
                } else {
                    session.packages[pkIndex].isBooked = true;
                }
            } else {
                if (pk.quantity > 0) {
                    pk.isBooked = false;
                } else {
                    pk.isBooked = true;
                }
                session.packages.push(_.cloneDeep(pk));
            }

            if (sessionIndex !== -1) {
                this.selectedSessions[sessionIndex] = session;
            } else {
                this.selectedSessions.push(session);
            }
            const filteredSession = [];
            this.selectedSessions.forEach((s) => {
                let canAdd = false;
                for (const p of s.packages) {
                    if (p.isHaveLinkPackage && p.linkPackages.length > 0) {
                        for (const lp of p.linkPackages) {
                            if (lp.quantity > 0) {
                                canAdd = true;
                                lp.isBooked = false;
                                p.isBooked = false;
                            } else {
                                lp.isBooked = true;
                            }
                        }
                    } else if (p.quantity > 0) {
                        p.isBooked = false;
                        canAdd = true;
                        break;
                    } else {
                        if (p.addOns && p.addOns.length > 0) {
                            for (const a of p.addOns) {
                                if (a.qty > 0) {
                                    canAdd = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (canAdd) {
                    filteredSession.push(s);
                }
            });

            this.store.dispatch(new SelectSession(session));
            this.store.dispatch(new SelectPackageSessions(filteredSession));

            this.store.dispatch(new AddPackage(publicPackage.id));
        } else {
            console.error(
                "session Undefine",
                packageDurationRange,
                publicPackage
            );
        }
        //#endregion
    }

    getDurationDates(packageDates: PackageDate[]) {
        let startDate, endDate;
        const sorted = packageDates.sort((x, y) => {
            const sDate = new Date(x.eventDate.date).getTime();
            const eDate = new Date(y.eventDate.date).getTime();
            return sDate - eDate
        });
        if (sorted.length > 0) {
            startDate = sorted[0].eventDate.startTime;
            endDate = sorted[sorted.length - 1].eventDate.endTime;
        }
        return { startDate, endDate };
    }

    selectPackage() {
        if (!this.publicPackage.singleDayPackage) {
            // FOR MULTI DAY PACKAGE SELECTION
            this.openPackageDurationModal();
        } else if (
            this.selectedSessionID &&
            !!this.selectedSessions &&
            this.selectedSessions.length > 0
        ) {
            //After selecting the session this code will execute
            let session = _.cloneDeep(
                this.selectedSessions.find((x) => x.id === this.selectedSessionID)
            );
            if (!session)
                return this.openSnackBarError(["session is not selected for package"]);
            session.packages = session.packages === undefined ? [] : session.packages;
            let pkIndex = session.packages.findIndex(
                (p) => p.id === this.publicPackage.id
            );
            if (pkIndex != -1) {
                if (
                    session.packages[pkIndex].isHaveLinkPackage &&
                    this.publicPackage.isHaveLinkPackage
                ) {
                    session.packages[pkIndex].linkPackages.forEach((lp) => {
                        this.publicPackage.linkPackages.forEach((publicLinkedPackage) => {
                            if (lp.id === publicLinkedPackage.id) {
                                lp.quantity += publicLinkedPackage.quantity;
                                lp.isBooked = false;
                            }
                        });
                    });
                    session.packages[pkIndex].isBooked = false;
                } else {
                    session.packages[pkIndex].quantity += this.publicPackage.quantity;
                    session.packages[pkIndex].isBooked = false;
                }
            } else {
                this.publicPackage.isBooked = false;
                session.packages.push(this.publicPackage);
            }
            this.store.dispatch(new SelectPackageSessions([session]));
            this.store.dispatch(new AddPackage(this.publicPackage.id));
            const url = this.layoutAttribute.redirectionURL.split("?")[0];
            this.store.dispatch(
                new SetRedirectionPoint(`upsell/` + this.publicPackage.id)
            );
        } else {

            if (this.publicPackage && this.publicPackage.packageSessions && this.publicPackage.packageSessions.filter(x => x.salesChannelId == 5).length == 1) {
                const packageSession = this.publicPackage.packageSessions.filter(x => x.salesChannelId == 5)[0];
                // Check this package i already booked
                const prevSession = this.selectedSessions.find(x =>
                        x?.startTime === Utility.convertToISO(
                            Utility.convertISOToDate(packageSession?.startTime)
                        ) && x.endTime === Utility.convertToISO(
                            Utility.convertISOToDate(packageSession?.endTime)
                        ) && !!x.bookingPackages && x.bookingPackages.find(y => y.packageId == this.publicPackage.id) !== undefined
                );
                let session;
                if (prevSession) {
                    session = _.cloneDeep(prevSession);
                    let packageIndex = session.packages.findIndex(x => x.id == this.publicPackage.id)
                    if (packageIndex != -1) {
                        // session.packages[packageIndex].quantity += this.publicPackage.quantity;
                        if (
                            session.packages[packageIndex].isHaveLinkPackage &&
                            this.publicPackage.isHaveLinkPackage
                        ) {
                            session.packages[packageIndex].linkPackages.forEach((lp) => {
                                this.publicPackage.linkPackages.forEach((publicLinkedPackage) => {
                                    if (lp.id === publicLinkedPackage.id) {
                                        lp.quantity += publicLinkedPackage.quantity;
                                        lp.isBooked = false;
                                    }
                                });
                            });
                            session.packages[packageIndex].isBooked = false;
                        } else {
                            session.packages[packageIndex].quantity += this.publicPackage.quantity;
                            session.packages[packageIndex].isBooked = false;
                        }
                    }

                } else {
                    session = {
                        id: packageSession.id,
                        startTime: Utility.convertToISO(
                            Utility.convertISOToDate(packageSession.startTime)
                        ),
                        endTime: Utility.convertToISO(
                            Utility.convertISOToDate(packageSession.endTime)
                        ),
                        availability: packageSession.availability,
                        packages: [this.publicPackage],
                    };
                }


                this.store.dispatch(new SelectSession(session));
                if (!this.redirectToBookingApplication) {
                    this.store.dispatch(new SelectPackageSessions([session]));
                    this.store.dispatch(new AddPackage(this.publicPackage.id));
                    this.store.dispatch(
                        new SetRedirectionPoint(`upsell/` + this.publicPackage.id)
                    );
                } else {
                    this.router.navigate(["seating-view/" + this.eventID], {
                        queryParams: {
                            sessionId: packageSession.id,
                            packageId: this.publicPackage.id
                        },
                    });
                }

                //if there are more than one package session then opening the date and session page with new session
            } else {
                const session: IDateAndSession = {
                    id: "-1",
                    startTime: undefined,
                    endTime: undefined,
                    availability: undefined,
                    packages: [this.publicPackage],
                };
                this.store.dispatch(new SelectPackageSessions([session]));
                this.router.navigate(['date-and-session/' + this.eventID], { queryParams: { packageId: this.publicPackage.id } });
            }
        }
    }

    linkPackageSelection() {
        this.publicPackage.isLinkPackageExpand = false;
        this.selectPackage();
    }

    openAddSessionModel() {
        this.dialog.open(SessionModalComponent, {
            data: {
                packageID: this.publicPackage.id,
                type: SESSION_SELECTION_MODEL_TYPE.BOOKING_PACKAGE_SESSION,
            },
            panelClass: ["w50modal", "modal-width", "extrapop"],
            height: "auto",
        });
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: "top",
        });
    }

    onvalueChanges(value) {
        this.onCheckPackQuantity(value)
        this.packageQuantity = value;
        this.updatePackageQuantity();
    }
}

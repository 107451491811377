<div class="mmc-modal-wrapper delivery-method-edit-modal">
  <div class="mmc-modal-header mmc_flex--container">
    <div class="mmc_flex__item mmc_flex__item--vcenter pl-3">
      <span>{{'CHECKOUTINFO.' +modelHeaderName|optimotranslate}}</span>
    </div>
    <div class="close-btn delivery-close-btn" style="align-items:center;display:flex;padding-right:8px;justify-content: flex-end;">
      <mat-icon style="color: white;display: flex;align-items: center;cursor: pointer;justify-content: flex-end;margin-right: -4px;"(click)="close()">close</mat-icon>
    </div>
  </div>
  <div class="mmc-modal-body">
    <form [formGroup]="modalFormGroup">
      <div class="inner-container">
        <div class="row">
          <ng-container *ngFor="let field of formFields()">
            <div class="col-sm-{{field.col}} col-md-{{field.col}} col-lg-{{field.col}} col-xl-{{field.col}}"
              *ngIf="field.canUpdate">
              <mat-form-field appearance="outline">
                <mat-label [attr.for]="field.name">{{('CHECKOUTINFO.'+ field.label)|optimotranslate}} <span *ngIf="field.required">*</span>
                </mat-label>
                <ng-container *ngIf="field.name == 'country';else isNotCountry">
                  <input matInput [placeholder]="('CHECKOUTINFO.'+ field.placeHolder)|optimotranslate" [formControlName]="field.name"
                    [matAutocomplete]="auto" [type]="field.type" />
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='getCountrySelect($event.option.value)'>
                    <mat-option *ngFor="let option of filteredCountriesOptions | async" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                    <mat-option *ngIf="isNoResultFound">{{'CHECKOUTINFO.No results found'|optimotranslate}}</mat-option>
                  </mat-autocomplete>
                </ng-container>
                <ng-template #isNotCountry>
                  <input matInput [placeholder]="('CHECKOUTINFO.'+ field.placeHolder)|optimotranslate" [formControlName]="field.name" autocomplete="off"
                    [type]="field.type" />
                </ng-template>
                <mat-error style="margin-bottom: 5px"
                  *ngIf="getFormControl(field.name) && (getFormControl(field.name).dirty || getFormControl(field.name).touched) && getFormControl(field.name).errors?.required">
                  {{('CHECKOUTINFO.'+field.label) | optimotranslate}} {{'CHECKOUTINFO.is required' | optimotranslate}}
                </mat-error>
                <mat-error style="margin-bottom: 5px"
                  *ngIf="getFormControl(field.name) && (getFormControl(field.name).dirty || getFormControl(field.name).touched) && getFormControl(field.name).errors?.email">
                  {{'CHECKOUTINFO.Invalid email' | optimotranslate}}
                </mat-error>
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
  <div class="mmc-modal-footer py-2">
    <div class="mmc_flex--container mmc_just-content-end" style="padding-right:15px;">
      <button mat-button class="mmc-btn-primary" (click)="close()">
        {{'CHECKOUTINFO.Cancel'| optimotranslate}}
      </button>
      <button mat-button class="mmc-btn-secondary" (click)="onSubmit()" [disabled]="!modalFormGroup.valid" >
        {{'CHECKOUTINFO.Save'| optimotranslate}}
      </button>
    </div>
  </div>
  <div *ngIf="showLoader">
    <opt-mmc-loader></opt-mmc-loader>
  </div>
</div>

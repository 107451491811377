import { Component, Input, OnInit } from '@angular/core';
import { FooterLocationLayout } from "../../models/footerLocationLayout.model";
import { Theme } from "../../models/globalTheme";
import { SocialMediaLayout } from "../../models/socialMediaLayout.model";

@Component({
  selector: 'opt-social-media-layout',
  templateUrl: './social-media-layout.component.html',
  styleUrls: ['./social-media-layout.component.scss']
})
export class SocialMediaLayoutComponent implements OnInit {
  @Input() layoutAttribute: SocialMediaLayout;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID = 'demo';
  @Input() isView: boolean;

  parentRef: any;
  theme: Theme;
  constructor() { }

  ngOnInit() {
  }

  goto(Url: string) {
    console.log(Url);
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const pattern = new RegExp(regex);
    const goUrl = pattern.test(Url);
    if (goUrl) {
      window.open(Url, '_blank');
    } else {
      window.open('http://' + Url, '_blank');
    }
  }
}

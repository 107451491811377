import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { environment } from "src/environments/environment";
import { CurrencyFormat } from "src/modules/currency-format";
import { BookingPackages } from "src/modules/models/booking/booking-packages";
import { SystemOption } from "src/modules/models/public-web/SystemOption";
import { BOOKING_CART, PAGE_TYPE } from "src/modules/models/public-web/enum";
import { AppSetting, MenuSettings } from "src/modules/models/settings/casual-portal/app-setting";
import { PBUtility } from "src/modules/public-web/pb-utility";
import { CustomerService } from "src/modules/services/customer.service";
import { PublicBookingSelectService } from "src/modules/services/public-web/public-booking-select.service";
import { PublicBookingService } from "src/modules/services/public-web/public-booking.service";
import { SystemService } from "src/modules/services/system.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { OptimoTanslate } from "src/modules/shared/opt-translate/optimo-translate.pipe";
import { ICart, SignOut } from "src/modules/store";
import { ReSetConfigurationState, ResetBlock, ResetSeat } from "src/modules/store/booking-application/Configuration.action";
import {
  ResetPackages,
  ResetState,
  SetRedirectionPoint
} from "../../../../../store/public-web/public-web-actions";
import { IPBReducer, IPublicWebSession } from "../../../../../store/public-web/public-web-reducers";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';
import { ClientSearchModalComponent } from "../../../modals/client-search-modal/client-search-modal.component";
import { NavigationConfirmationComponent } from "../../../modals/navigation-confirmation/navigation-confirmation.component";
import { BookingCart } from "../../models/bookingCart.model";
import { Theme } from "../../models/globalTheme";
import { ILayout } from "../../models/interfaces";
import { NavBarLayout } from "../../models/main";
import { PublicBookingSettings } from "./../../../../../models/settings/public-booking/public-booking-setting";

@Component({
  selector: "opt-nav-bar-layout",
  templateUrl: "./nav-bar-layout.component.html",
})
export class NavBarLayoutComponent
  extends BaseComponent
  implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: NavBarLayout;
  @Input() isView: boolean;
  @Input() elementID;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() isGrid = false;
  @Input() isPendingChanges;
  @Input() isClearCart;
  @Input() bookingDisplayCart: BookingCart[] = [];
  cart$: Observable<ICart>;
  publicWebSession$: Observable<IPublicWebSession>;
  bookingSession: SystemOption;
  paymentSession: SystemOption;
  publicConfig$: Observable<IPBReducer>;
  isAllImmediateConfirmed: any;
  isLoggedIn: boolean;
  imgUrl: string;
  maxAmount: number = 0;
  conformationModel: string;
  isBooking: boolean = false;
  message: string;
  heading: string;
  yesText: string;
  noText: string;
  packageClassId: number;
  selectedClient: any;
  isScroll: boolean = false;
  appSetting: AppSetting;
  isHome: any = false;
  HomeBaseUrl: string;
  pageType: PAGE_TYPE;
  isPackageListPage: boolean = false;
  selectedEventCategory: number;
  isPackageListRoute: boolean = false;
  appData: PublicBookingSettings;
  isShowClientActionsButtons: boolean;
  bookingPackages: BookingPackages[];

  navigationMessageNewBooking = (environment.PublicBookingSetting as PublicBookingSettings).enableNavigationConfirmationMessageNewBooking;
  navigationMessageEditBooking = (environment.PublicBookingSetting as PublicBookingSettings).enableNavigationConfirmationMessageEditBooking;
  BOOKING_CART = BOOKING_CART;
  editBookingButtonConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).editBookingButtonConfiguration;

  get getConfirmationMessage() {
    let confirmationMessage = ''
    const message = PBUtility.getConfirmationMessage(this.bookingType);
    if (message) {
      if (message.name) {
        this.translate.get("NAVIGATIONCONFIRMATIONMESSAGE." + message.name).subscribe((res: string) => {
          if (res && res != null) {
            confirmationMessage = res;
            if (document.getElementById('confirmationMessages')) {
              document.getElementById('confirmationMessages').innerHTML = res;
            }
          }
        });
      } else if (message.value) {
        confirmationMessage = message.value
        if (document.getElementById('confirmationMessages')) {
          document.getElementById('confirmationMessages').innerHTML = confirmationMessage;
        }
      }
    }
    return confirmationMessage;
  }

  constructor(
    private systemService: SystemService,
    private router: Router,
    private store: Store<any>,
    private currencyPipe: CurrencyFormat,
    private dialog: MatDialog,
    private translate: TranslateService,
    private bookingService: PublicBookingService,
    private customerService: CustomerService,
    private optimoTanslate: OptimoTanslate,
    private _publicBookingSelectService: PublicBookingSelectService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    this.cart$ = this.store.select("cart");
    this.publicConfig$ = this.store.select("PBReducer");

    this.maxAmount = 0;
    this.appSetting = environment.AppSetting as AppSetting;
    this.HomeBaseUrl = environment.HomeBaseUrl;
    this.store.select("PBReducer").subscribe((d) => {
      this.pageType = d.pageType;
    });
    if (this.pageType === PAGE_TYPE.PACKAGE_LIST) {
      this.isPackageListPage = true;
    }
    this.appData = environment.PublicBookingSetting as PublicBookingSettings
  }

  parentRef: any;
  theme: Theme;
  bookingType: BOOKING_CART;
  initializeAttributes() { }

  ngAfterViewInit(): void { }

  ngOnInit() {
    this.getPopUpContent();
    this.cart$.subscribe((state) => {
      this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
      this.isLoggedIn =
        state.contact != null &&
          state.contact.firstName != null &&
          state.contact.firstName != ""
          ? true
          : false;
      this.imgUrl = state.contact.profilePicture;
      if (this.imgUrl == undefined || state.contact.profilePicture == "")
        this.imgUrl =
          "../dist/assets/images/user-icon.png";
    });

    this.publicConfig$.subscribe((config) => {
      this.maxAmount = 0;
      this.bookingType = config.bookingMode;
      this.selectedClient = config.selectedClient;
      if (this.isPendingChanges === undefined) {
        if (this.bookingType == this.BOOKING_CART.NEW_BOOKING) {
          this.isPendingChanges = true;
        } else if (this.bookingType == this.BOOKING_CART.EDIT_BOOKING && config.booking?.dueAmount != config.freezedBooking?.dueAmount) {
          this.isPendingChanges = true;
        } else {
          this.isPendingChanges = false;
        }
      }

      if (
        config.booking &&
        config.booking.bookingPackages &&
        config.booking.bookingPackages.length > 0
      ) {
        this.bookingPackages = config.booking.bookingPackages;
        this.maxAmount = config.booking.grossAmount;
        this.isBooking = true;
      } else {
        this.bookingPackages = [];
        this.bookingDisplayCart = [];
        this.isBooking = false;
      }

    });
    if((typeof this.isClearCart === 'undefined' ) && !((this.bookingType === BOOKING_CART.EDIT_BOOKING && this.editBookingButtonConfiguration.continueShoppingEditBooking.visible) ||
          (this.bookingType === BOOKING_CART.NEW_BOOKING && this.editBookingButtonConfiguration.continueShoppingNewBooking.visible)) && !(this.isBooking && this.isPendingChanges)){
            this.store.dispatch(new ResetPackages());
            if (this.bookingType === BOOKING_CART.EDIT_BOOKING){
              this.store.dispatch(new ResetState());
            }
            this.store.dispatch(new ResetSeat());
            this.store.dispatch(new ResetBlock());
      }

    this.publicWebSession$.subscribe((config) => {
      if (config.bookingSession) {
        this.bookingSession = config.bookingSession;
        // this.paymentSession = config.paymentSession;
        //get url of current page
        // if (!this.router.url.includes('make-payment')) {
        // //   if (!this.bookingSession.timerPaused) {
        // //     this.store.dispatch(new PauseBookingSession())
        // //     if (config && config.paymentSession && config.paymentSession.timerPaused) {
        // //       this.store.dispatch(new ResumePaymentSession())
        // //     }
        // //   }
        // // } else {
        //   // if(this.router.url.includes('booking-checkout') && !paymentSession.timerPaused && paymentSession.isSessionTimerStart && this.bookingSession.timerPaused ) {
        //   // }
        //   // if (this.bookingSession.timerPaused && this.bookingSession.sessionEndDate && !this.paymentSession.isSessionTimerStart) {
        //   //   this.store.dispatch(new ResumeBookingSession());
        //   //   this.store.dispatch(new PausePaymentSession());
        //   // }
        // }
      }
      if (config.paymentSession) {
        this.paymentSession = config.paymentSession;
      }
    });
    if (this.router.url.includes('package-list')) {
      this.isPackageListRoute = true;
    }
    this.isShowClientActionsButtons = this.appData?.showClientActionsButtons;
  }

  getPopUpContent() {
    if (this.bookingType === BOOKING_CART.EDIT_BOOKING) {
      this.message = this.optimoTanslate.transform('NAVMODEL.Are you sure you want to navigate from the booking cart page?')
      this.message += '<br>' + this.optimoTanslate.transform('NAVMODEL.If you click ');
      this.message += '<strong>"' + this.optimoTanslate.transform('NAVMODEL.Yes, Navigate') + '"</strong>';
      this.message += ' ' + this.optimoTanslate.transform('NAVMODEL.any changes you have made will be discarded.');

      this.heading = this.optimoTanslate.transform('NAVMODEL.Navigation Confirmation');
      this.yesText = this.optimoTanslate.transform('NAVMODEL.Yes, Navigate');
      this.noText = this.optimoTanslate.transform('NAVMODEL.Close');
    } else if (this.bookingType === BOOKING_CART.NEW_BOOKING) {
      this.message = this.optimoTanslate.transform('NAVIGATIONCONFIRMATIONMESSAGE.New Booking Confirmation Message');
      this.heading = this.optimoTanslate.transform('NAVMODEL.Navigation Confirmation');
      this.yesText = this.optimoTanslate.transform('NAVMODEL.Yes, Navigate');
      this.noText = this.optimoTanslate.transform('NAVMODEL.Close');
    }
  }
  gotoClientLogin() {
    this.store.dispatch(new SetRedirectionPoint(this.router.url));
    if (this.isLoggedIn) {
      if (this.isBooking && this.isPendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
        const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
          data: {
            heading: this.heading,
            message: this.message,
            yesText: this.yesText,
            noText: this.noText
          },
          panelClass: [],
          height: 'auto',
          width: 'auto',
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res == true) {
            if (this.getMenuVisibility('MyProfile')) {
              window.open(environment.ApiUrl + "#/my-profile", "_self");
            }
          }
        });
      } else {
        if (this.getMenuVisibility('MyProfile')) {
          window.open(environment.ApiUrl + "#/my-profile", "_self");
        }
      }
    }
    else {
      window.open(
        environment.ApiUrl + "#/client/login?DirectLoginFromPublicWeb=1&ReturnUrl=" + this.router.url,
        "_self"
      );
    }
  }

  clearSession() {
    // this.bookingSession.isSessionExpired = true;
    // this.bookingSession.isSessionTimerStart = false;
    this.store.dispatch(new ResetState());
    this.store.dispatch(new ResetPackages());
    this.store.dispatch(new ReSetConfigurationState());
    // this.store.dispatch(new AddBookingSession(this.bookingSession));
    this._publicBookingSelectService.reSetValue();
    // if (this.router.url.includes('booking-checkout') && this.paymentSession) {
    //   this.paymentSession.isSessionExpired = true;
    //   this.paymentSession.isSessionTimerStart = false;
    //   this.paymentSession.minutes = '00';
    //   this.paymentSession.seconds = '00';
    //   this.paymentSession.sessionEndTime = 0;
    //   this.store.dispatch(new AddPaymentSession(this.paymentSession));
    // }
  }

  myBooking() {
    if (this.isBooking && this.isPendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          var ul = environment.ApiUrl + "#/bookings";
          window.open(ul, "_self");
        }
      });
    } else {
      //this.store.dispatch(new ResetState());
      //this.clearSession();
      var ul = environment.ApiUrl + "#/bookings";
      window.open(ul, "_self");
    }
  }

  changePassword() {
    if (this.isBooking && this.isPendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          var ul = environment.ApiUrl + "#/change-password";
          window.open(ul, "_self");
        }
      });
    } else {
      var ul = environment.ApiUrl + "#/change-password";
      window.open(ul, "_self");
    }
  }

  signOut() {
    if (this.isBooking && this.isPendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.customerService.DeleteSession().subscribe(x => {
            if (x == true) {
              this.clearSession();
              this.store.dispatch(new SignOut());
              this.gotoAfterSignOut();
            }
          });
        }
      });
    } else {
      this.customerService.DeleteSession().subscribe(x => {
        if (x == true) {
          this.clearSession();
          this.store.dispatch(new SignOut());
          this.gotoAfterSignOut();
        }
      });
    }
  }
  gotoAfterSignOut() {
    if (this.appSetting.AnyPortalSignOutRedirectToSignIn) {
      var ul = environment.ApiUrl + "#/client/login";
      window.open(ul, "_self");
    }
    else {
      if(environment.HomeBaseUrl.trim()) {
        window.location.href = environment.HomeBaseUrl;;
      } else {
        this.router.navigate(["/event-category"]);
      }
    }
  }

  returnHome() {
    if (this.isBooking && this.isPendingChanges && ((this.navigationMessageNewBooking && this.bookingType === BOOKING_CART.NEW_BOOKING) || (this.navigationMessageEditBooking && this.bookingType === BOOKING_CART.EDIT_BOOKING))) {
      const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
        data: {
          heading: this.heading,
          message: this.message,
          yesText: this.yesText,
          noText: this.noText
        },
        panelClass: [],
        height: 'auto',
        width: 'auto',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == true) {
          this.clearSession();
          if (this.HomeBaseUrl.trim()) {
            window.location.href = this.HomeBaseUrl;
          } else {
            this.router.navigate(["/event-category"]);
          }
        }
      });
    } else {
      if (this.HomeBaseUrl.trim()) {
        window.location.href = this.HomeBaseUrl;
      } else if (this.router.url == '/event-category' || this.router.url == 'event-category') {
        this.isHome = true;
        return null;
      } else {
        this.router.navigate(["/event-category"]);
      }
    }
  }
  cartClick() {
    this.router.navigate(["/booking-cart"]);
  }
  openSearchClient() {
    const dialogRef = this.dialog.open(ClientSearchModalComponent, {
      panelClass: ["w30modal"],
      height: "auto",
      width: "90%",
      data: {
        title: "Client Search",
        URL: environment.PublicWebClientSearchUrl,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openAddClient() {
    const dialogRef = this.dialog.open(ClientSearchModalComponent, {
      panelClass: ["w30modal"],
      height: "auto",
      width: "90%",
      data: { title: "Client Add", URL: environment.PublicWebClientAddUrl },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      document.body.scrollTop > 18 ||
      document.documentElement.scrollTop > 18
    ) {
      this.isScroll = true;
    } else {
      this.isScroll = false;
    }
  }

  getMenuName(id, defaultValue) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;

    if (menu)
      if (menu.Value != null && menu.Value != '')
        return 'NAVBAR.' + menu.Value;
      else
        return 'NAVBAR.' + defaultValue;
    else
      return 'NAVBAR.' + defaultValue;
  }

  getMenuVisibility(id) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;
    if (menu)
      return !(menu.Disabled)
    else
      return true;
  }

  viewAllEvents() {
    this.store.select("PBReducer").subscribe((d) => {
      this.selectedEventCategory = d.selectedEventCategory;
    });
    if (this.isPackageListPage) {
      this.router.navigate(["/event-list", this.selectedEventCategory]);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'opt-payment-main',
  templateUrl: './payment-main.component.html',
  //styleUrls: ['./payment-main.component.scss']
})
export class PaymentMainComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
  
}

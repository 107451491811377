import { LayoutObject } from './layoutObject.model';

export class FooterLocationLayout extends LayoutObject {
  height: number;
  width: number;
  transform: string;
  latitude: number;
  longitude: number;

  constructor(data) {
    super(data);

    this.height = data.height;
    this.width = data.width;
    this.transform = data.transform;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }
}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FooterLocationLayout } from "../../models/footerLocationLayout.model";
import { ILayout } from '../../models/interfaces';
import { Theme } from '../../models/globalTheme';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'opt-location-layout',
  templateUrl: './location-layout.component.html',
  styleUrls: ['./location-layout.component.scss']
})
export class LocationLayoutComponent implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: FooterLocationLayout;
  @Input() type: string;
  @Input() unique_key: any;
  @Input() elementID = 'demo';
  @Input() isView: boolean;

  parentRef: any;
  theme: Theme;

  constructor() { }

  ngOnInit() {
  }

  initializeAttributes() {
  }

  ngAfterViewInit(): void {
  }
}

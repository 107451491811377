import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { SuccessMessageComponent } from "src/modules/shared/success-message/success-message.component";
import { Store } from "src/modules/store";
import { SetSecondaryClient } from "../../../../store/public-web/public-web-actions";
import { environment } from "src/environments/environment";
import { PbCustomer } from "src/modules/models/public-web/customer/pb-customer"

@Component({
  selector: "opt-client-search-modal",
  templateUrl: "./client-search-modal.component.html",
  styleUrls: ["./client-search-modal.component.scss"],
})
export class ClientSearchModalComponent implements OnInit {
  showLoader: boolean = false;
  isInternalUser: boolean = environment.IsInternalUser;
  customerDetails: PbCustomer;
  url;
  constructor(
    public dialogRef: MatDialogRef<ClientSearchModalComponent>,
    public snackBar: MatSnackBar,
    private dom: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    private ngZone: NgZone
  ) {
    console.log(ngZone);
    window["angularComponentRef"] = { component: this, zone: ngZone };

    this.url = this.dom.bypassSecurityTrustResourceUrl(data.URL);
  }
  ngOnInit(): void {}

  onCancelClick() {
    this.openSnackBarSuccess([
      "Your request has been forwarded and we will respond shortly.",
    ]);
    setTimeout(() => {
      location.reload();
    }, 2000);
  }

  clientSearchAngularFunctionCalled(client) {
    if(client) {
      const pbCustomer = new PbCustomer();
      pbCustomer.clientId = +client.clientId;
      pbCustomer.title = client.title;
      pbCustomer.name = client.name;
      pbCustomer.tel = client.communicationMethods[0].value;
      pbCustomer.address = client.address;
      pbCustomer.email = client.email;
      pbCustomer.contactId = +client.contactId;
      pbCustomer.client = client.client;
      this.customerDetails = pbCustomer;
      if(this.customerDetails) {
        this.store.dispatch(new SetSecondaryClient(this.customerDetails));
        this.dialogRef.close(this.customerDetails);
      }
    }
  }

  clientAddAngularFunctionCalled(client) {
    if(client) {
      const addedCustomerDetails = new PbCustomer();
          addedCustomerDetails.tel = client.clientDetails.communicationMethods[0].value;
          addedCustomerDetails.title = client.clientDetails.title ? client.clientDetails.title : '';
          addedCustomerDetails.name = client.clientDetails.clientName;
          addedCustomerDetails.email = client.contactDetails.attributes.invoiceEmailAddress;
          addedCustomerDetails.clientId = client.savedClientId;
          addedCustomerDetails.contactId = client.contactDetails.id;
          addedCustomerDetails.client = client.clientDetails;
          this.customerDetails = addedCustomerDetails;
          if(this.customerDetails) {
            this.store.dispatch(new SetSecondaryClient(this.customerDetails));
            this.dialogRef.close(this.customerDetails);
          }
    }
  }

  openSnackBarSuccess(message) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: "top",
    });
  }

  // openSnackBarError(message) {
  //   this.snackBar.openFromComponent(AlertMessageComponent, {
  //     data: message,
  //     duration: 4000,
  //     verticalPosition: 'top'
  //   });
}

export class PublicEventTimeslot {
    primaryEventId: number;
    primaryEvent: string;
    shared: boolean;
    capacity: number;
    allocatedQuantity: number;
    availableQuantity: number;
    startTime: Date;
    endTime: Date;
    reserved: boolean;
    id: string;

    inilialize(d) {
        this.id = d.id;
        this.primaryEventId = d.primaryEventId;
        this.primaryEvent = d.primaryEvent;
        this.shared = d.shared;
        this.capacity = d.capacity;
        this.allocatedQuantity = d.allocatedQuantity;
        this.startTime = new Date(d.startTime);
        this.endTime = new Date(d.endTime);
        this.reserved = d.reserved;
        return this;
     }

}
import { HEIGHT_SIZE } from './enums';
import { LocalImage } from './image.model';
import { LayoutObject } from './layoutObject.model';

export class LogoLayout extends LayoutObject {
  transform: string;
  image: LocalImage;
  size: HEIGHT_SIZE;
  opacity: number;
  height: number;
  width: number;

  constructor(data) {
    super(data);

    this.height = data.height;
    this.width = data.width;
    this.transform = data.transform;
    this.opacity = data.opacity;
    this.size = data.size;
    this.image = new LocalImage(data.image);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicBookingSelectService {

  constructor() { }

  displayedBookingPackages$ = new BehaviorSubject<any[]>([]);
  displayedFreezedBookingPackages$ = new BehaviorSubject<any[]>([]);
  venueMapDisplayedInternalBookingPackageId$ = new BehaviorSubject<string>("");

  setValue(packages: any[]) {
    this.displayedBookingPackages$.next(packages);
  }

  getValue() {
    return this.displayedBookingPackages$.asObservable();
  }

  setdisplayedFreezedBookingPackages(packages: any[]) {
    this.displayedFreezedBookingPackages$.next(packages);
  }

  getdisplayedFreezedBookingPackages() {
    return this.displayedFreezedBookingPackages$.asObservable();
  }

  setVenueMapDisplayedInternalBookingPackageId(id : string) {
    this.venueMapDisplayedInternalBookingPackageId$.next(id);
  }

  getVenueMapDisplayedInternalBookingPackageId() {
    return this.venueMapDisplayedInternalBookingPackageId$.asObservable();
  }

  reSetValue() {
    this.displayedBookingPackages$.next([]);
    this.displayedFreezedBookingPackages$.next([]);
    this.venueMapDisplayedInternalBookingPackageId$.next("");
  }
}

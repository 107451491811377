<div class="card-layout">
  <div [id]="elementID" class="container-wrapper c-card-container" [ngClass]="layoutAttribute.componentClass">
    <div class="c-card" [style.height.px]="layoutAttribute.height" [ngClass]="layoutAttribute.componentClass">
      <div class="c-card-body">
        <ng-template #cardViewContainer></ng-template>
      </div>
      <div *ngIf="!layoutAttribute.availableToBook" class="card-disable p-2">
        <!-- <div class="body-container">
          <span class="material-icons" matTooltip="Package Not Available"> info </span>
        </div> -->
        <div class="go-corner" href="#">
          <div class="go-arrow">
            <mat-icon matTooltip="{{'Package Not Available' | optimotranslate }}">block</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { ThemeService } from 'src/modules/services/public-web/theme.service';
import { BaseComponent } from 'src/modules/shared/base.component';
import { Theme } from '../../models/globalTheme';
import { ImageLayout } from '../../models/image.model';
import { ILayout } from '../../models/interfaces';

@Component({
  selector: 'opt-image-layout',
  templateUrl: './image-layout.component.html',
  styleUrls: ['./image-layout.component.scss']
})
export class ImageLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: ImageLayout;
  @Input() unique_key: any;
  @Input() elementID: string;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() gridColumn: number;

  theme: Theme = new Theme();
  parentRef: any;

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private translate: TranslateService
  ) { 
    super();
  }

  ngOnInit(): void {
    this.themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });

    this.translate.onLangChange.safeSubscribe(this, (event: LangChangeEvent) => {
      this.translateLanguage();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAttributes();
      this.translateLanguage();
    });
  }

  translateLanguage() {    
    const matchedElements = Array.from(document.querySelectorAll('[id^=Translate_]'));

    matchedElements.forEach(ele => {
      this.translate.get("DynamicContent." + ele.id).safeSubscribe(this, (translated: string) => {
        ele.innerHTML = translated;
      });
    });
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);

    if (contentElement) {
      d3.select(contentElement.closest('.image-layout'))
        .data([this.layoutAttribute.gridColumn])
        .style('margin', 0 + 'px');
      // .style('margin', this.theme.card.margin + 'px');
      if (!!this.layoutAttribute.content && this.layoutAttribute.content.length > 0) {
        d3.select(contentElement).select('.image-layout-content').html(this.layoutAttribute.content)
      }
      d3.select(contentElement.parentElement)
        .attr('class',
          `col-md-${this.layoutAttribute.gridColumn}
                col-lg-${this.layoutAttribute.gridColumn}
                col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        ).style(`width`, '100%');
    }
  }

  navigate() {
    if (this.layoutAttribute.navigationUrl) {
      this.router.navigate(["/" + this.layoutAttribute.navigationUrl]);
    }
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VenueTypeService {

  constructor(private _base: BaseService) {
  }

  getVenueType() {
    let url = `api/public/venueTypes`;
    return this._base.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data
    }));
  }

  getAllVenues() {
    let url = `api/public/venues`;
    return this._base.Internal_WebAPI_GET(url).pipe(map((b: any) => {
      return b.body.data
    }));
  }
}

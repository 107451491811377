import { Injectable } from '@angular/core';
import { Theme } from '../../public-web/components/layout/models/globalTheme';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  private theme = new Theme();
  theme$ = new BehaviorSubject<Theme>(this.theme);

  constructor() {
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'opt-secure-pay',
  templateUrl: './secure-pay.component.html'
  
})
export class SecurePayComponent implements OnInit {
    paymentUrl: SafeResourceUrl;
    amount: "";
    bookingId: "";
    bookingTypeId:string = "";
    billerCode:string= "";
    immediate:string = "";
    isFromMyBooking:string = "";
	private sub: any;

    constructor(private sanitizer: DomSanitizer, private router: ActivatedRoute) {
		 this.sub = this.router.queryParams.subscribe((params) => {
             this.amount = params['amount'];		  
             this.bookingId = params['bookingId'];		 
             this.billerCode = params['billerCode'];
             this.bookingTypeId = params['bookingTypeId'];
             this.immediate = params['immediate'];
             this.isFromMyBooking= params['IsFromMyBooking'];
             console.log("params ",params)	  
		     console.log(this.amount);
		});
    
        var url = environment.ApiUrl + ((environment.ApiUrl.endsWith('/')) ? "":"/")+"Payment/Pay?InvoiceRef=" + window["InvoiceRef"]
        if (this.amount != undefined && this.amount.length>0)
            var url = url + "&amount=" + this.amount;
        if (this.bookingId != undefined && this.bookingId != "") {
            url = url + "&bookingId=" + this.bookingId;
        }
        if (this.billerCode != undefined && this.billerCode != "") {
            url = url + "&billerCode=" + this.billerCode;
        }
        if (this.bookingTypeId != undefined && this.bookingTypeId != "") {
            url = url + "&bookingTypeId=" + this.bookingTypeId;
        }
        if (this.immediate != undefined && this.immediate != "") {
            url = url + "&immediate=" + this.immediate;
        }
        if (this.isFromMyBooking != undefined && this.isFromMyBooking != "") {
            url = url + "&IsFromMyBooking=" + this.isFromMyBooking;
        }
            this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
           // this.sanitizer.bypassSecurityTrustResourceUrl();
    }


   ngOnInit() 
   {
   }
   ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

<div class="description-layout" 
  [id] = "elementID" 
  [ngClass] = "descriptionStyle.fontCase"
  [style.height.px] = "layoutAttribute.height"
  >
  {{layoutAttribute.content}}
</div>
<!-- [style.background-color] = "descriptionStyle.backgroundColour"
[style.font-size.px] = "descriptionStyle.fontSize"
[style.color] = "descriptionStyle.fontColor"
[style.font-weight] = "descriptionStyle.isBold ? 'bolder':''"
[style.font-style] = "descriptionStyle.isItalic ? 'italic':''"
[style.height.px] = "layoutAttribute.height" -->
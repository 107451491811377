import { NgModule, ModuleWithProviders, Provider } from "@angular/core";
import {
  CalendarCommonModule,
  CalendarModuleConfig,
  CalendarEventTitleFormatter,
  CalendarDateFormatter,
  CalendarA11y,
} from "./common/calendar-common.module";
import { CalendarMonthModule } from "./month/calendar-month.module";
import { CalendarWeekModule } from "./week/calendar-week.module";
// import { CalendarDayModule } from './day/calendar-day.module';
import { CalendarUtils } from "./common/calendar-utils.provider";
import { CalendarDayModule } from "./day/calendar-day.module";
import { AdditionalEventsComponent } from "./modals/additional-events/additional-events.component";
import { OptCommonModules } from "src/modules/shared/common.modules";
import { OptFormModules } from "src/modules/shared/form.modules";
import { PbCommonModule } from "../../pb-common.module";

export * from "./common/calendar-common.module";
export * from "./month/calendar-month.module";
export * from "./week/calendar-week.module";
@NgModule({
  imports: [
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    PbCommonModule
  ],
  exports: [
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
  ],
  declarations: [AdditionalEventsComponent],
  entryComponents: [AdditionalEventsComponent],
})
export class CalendarModule {
  static forRoot(
    dateAdapter: Provider,
    config: CalendarModuleConfig = {}
  ): ModuleWithProviders<CalendarModule> {
    return {
      ngModule: CalendarModule,
      providers: [
        dateAdapter,
        config.eventTitleFormatter || CalendarEventTitleFormatter,
        config.dateFormatter || CalendarDateFormatter,
        config.utils || CalendarUtils,
        config.a11y || CalendarA11y,
      ],
    };
  }
}

import {
  AfterViewInit,
  Component,
  DoCheck,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { ILayout } from "../../models/interfaces";
import * as d3 from "d3";
import {
  SearchBoxLayout,
  SearchPanel,
  SearchPanelWidth,
} from "../../models/searchboxLayout.model";
import { Theme } from "../../models/globalTheme";
import { ThemeService } from "../../../../../services/public-web/theme.service";
import { CardLayoutService } from "../../../../../services/public-web/card-layout.service";
import { PAGE_TYPE } from "src/modules/models/public-web/enum";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchBaseFilter } from "src/modules/models/public-web/filter";
import { VenueService } from "src/modules/services/public-web/venue.service";
import { BaseComponent } from "src/modules/shared/base.component";
import { Observable } from "rxjs/Observable";
import { map, startWith } from "rxjs/operators";
import { FormBuilder, FormControl } from "@angular/forms";
import moment, { duration } from "moment";
import { MatDialog } from "@angular/material/dialog";
import { Utility } from "src/modules/utility";
import { Store } from "src/modules/store";
import { IPBReducer } from "../../../../../store/public-web/public-web-reducers";
import { PAGE_CONFIGURATION } from "../../models/enums";
import { VenueTypeService } from "../../../../../services/public-web/venue-types.service";
import { forkJoin } from "rxjs";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import { PublicEventService } from "../../../../../services/public-web/public-event.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { parseInt } from "lodash";
import { Region } from "src/modules/public-web/regional-setting";
import { environment } from "src/environments/environment";
import { PublicBookingSettings } from "src/modules/models/settings/public-booking/public-booking-setting";
import * as PBSelector from '../../../../../store/public-web/public-web-selectors';

@Component({
  selector: "opt-searchbox-layout",
  templateUrl: "./search-box-layout.component.html",
})
export class SearchBoxLayoutComponent extends BaseComponent implements OnInit, ILayout, AfterViewInit {
  @Input() layoutAttribute: SearchBoxLayout;
  @Input() unique_key: string;
  @Input() isView: boolean;
  @Input() type: string;
  @Input() elementID = "";
  @Input() canLoad = false;

  searchPanelData: SearchPanel;
  theme: Theme;
  toppings = new FormControl();
  searchPanelWidth = new SearchPanelWidth();
  filter = new SearchBaseFilter();
  PBReducer$: Observable<any>;

  filteredOptions: Observable<any[]>;
  filteredOptionsBusiArea: Observable<any[]>;

  parentRef: any;

  venues: any[];
  salesCategories: any[];
  businessAreas: any[];

  alwaysShowCalendars: boolean;
  regionList = [];
  eventCategoryID: number;
  pageConfig: string[];

  isNoResultFound: boolean = false;
  isNoResultFoundBusiArea: boolean = false;

  selected: any;
  classValue = "col-md-3 col-sm-4 col-lg-3 col-xs-4 col-xl-3";
  countOfFilters = 0;
  datechooser = true;
  dropdownSettings = {};
  dropdownList: any[];
  selectedItems: any[];
  countDays;

  selectedVenue;
  selectedBusinessArea;

  displayRegion;
  selectedValue = "";
  selectedRegionEvent;
  displayDateRange;
  venuesList;
  sortedVenueList = [];
  searchFb;
  eventGroups: any[];

  eventGropsFilteredOptions: any;
  selectedEventGroup: any;
  isNoEventGropResultFound: boolean = false;

  eventByPriceRange: any[];
  minValue: number = 0;
  maxValue: number = 100;
  // set min and max price range

  //ngx-slider options for price range
  options: Options = {
    floor: 0,
    ceil: 0,
    enforceStep: false,
    step: 5,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
          );
        case LabelType.High:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
          );
        default:
          return (
            (environment.PublicBookingSetting as PublicBookingSettings)
              .regionalSetting.defaultCurrencySymbol + value
          );
      }
    },
  };
  ageGroupIds = "";
  disabled = false;
  ShowFilter = false;
  limitSelection = false;

  eventSalesCategories: any[];
  selectedEventSalesCategory: any;
  eventSalesCategoryFilteredOptions: any;
  isNoEventSalesCategoryResultFound: boolean = false;
  PriceValueChanged: boolean = false;
  invalidDates: moment.Moment[] = [
    // moment().add(2, "days"),
    // moment().add(3, "days"),
    // moment().add(5, "days"),
  ];
  ranges: any = {
    Today: [moment(), moment()],
    "Current Week": [
      moment().startOf("isoWeek").subtract(1, "days").startOf("day"),
      moment().endOf("isoWeek").subtract(1, "days").startOf("day"),
    ],
    "Current WeekEnd": [
      moment().endOf("isoWeek").subtract(1, "days"),
      moment().endOf("isoWeek"),
    ],
    "Next Week": [
      moment().add(1, "weeks").startOf("isoWeek").subtract(1, "days"),
      moment().add(1, "weeks").endOf("isoWeek").subtract(1, "days"),
    ],
    "This Month": [
      moment().clone().startOf("month"),
      moment().clone().endOf("month"),
    ],
    "Next Month": [
      moment().add(1, "month").startOf("month"),
      moment().add(1, "month").endOf("month"),
    ]
  };
  priceRange: any;
  get isMultiSelectionPackage() {
    return this.pageConfig.includes(
      PAGE_CONFIGURATION.CONFIG_CAN_SELECT_MULTI_SESSION
    );
  }
  get datePickerLocale() {
    return {
      firstDay: Region.StartDayOfWeek(
        (environment.PublicBookingSetting as PublicBookingSettings)
          .regionalSetting.startDayOfWeek
      ),
      format: (environment.PublicBookingSetting as PublicBookingSettings)
        .regionalSetting.defaultDateFormat,
    };
  }

  //Performance Issue on calendar
  // get datePickerRange() {
  //   return {
  //     Today: [moment(), moment()],
  //     "Current Week": [
  //       moment().startOf("isoWeek").subtract(1, "days").startOf("day"),
  //       moment().endOf("isoWeek").subtract(1, "days").startOf("day"),
  //     ],
  //     "Current WeekEnd": [
  //       moment().endOf("isoWeek").subtract(1, "days"),
  //       moment().endOf("isoWeek"),
  //     ],
  //     "Next Week": [
  //       moment().add(1, "weeks").startOf("isoWeek").subtract(1, "days"),
  //       moment().add(1, "weeks").endOf("isoWeek").subtract(1, "days"),
  //     ],
  //     "This Month": [
  //       moment().clone().startOf("month"),
  //       moment().clone().endOf("month"),
  //     ],
  //     "Next Month": [
  //       moment().add(1, "month").startOf("month"),
  //       moment().add(1, "month").endOf("month"),
  //     ]
  //   };
  // }
  constructor(
    public dialog: MatDialog,
    private themeService: ThemeService,
    private cardService: CardLayoutService,
    private route: Router,
    private actRoute: ActivatedRoute,
    private venueService: VenueService,
    private venueTypeService: VenueTypeService,
    private eventService: PublicEventService,
    private fb: FormBuilder,
    private store: Store<any>,
    private aRoute: ActivatedRoute
  ) {
    super();
    this.venues = [];
    this.eventGroups = [];
    this.alwaysShowCalendars = true;
    this.PBReducer$ = this.store.select(PBSelector.selectPageConfiguration);
    this.searchFb = this.fb.group({
      venueName: [""],
      eventName: [""],
      businessArea: [""],
      eventGroup: [""],
      isAddon: [""],
      addOnSearch: [""],
      days: [""],
      access: [""],
      accommodation: [""],
      parkSearch: [""],
      selectedValueRange: [""],
      selectedValue: [""],
      parkEvents: [""],
      toppings: this.toppings,
      availability: [0],
      // fromDate:[''],
      // toDate:['']
      eventByPriceRange: [""],
      ageGroupId:
        this.selectedItems != undefined && this.selectedItems.length > 0
          ? this.selectedItems
          : "",
      eventSalesCategory: [""],
    });
    this.setCalenderStartWeek();
    this.eventSalesCategories = [];
    this.searchFb.controls["eventByPriceRange"].disable();
  }
  setCalenderStartWeek() {}

  ngOnInit(): void {
    this.themeService.theme$.safeSubscribe(this, (theme) => {
      this.theme = theme;
    });
    this.aRoute.params.safeSubscribe(this, (params) => {
      if (params["categoryID"]) this.eventCategoryID = +params["categoryID"];
    });

    Object.values(this.layoutAttribute.searchPanelData).forEach((prop) => {
      if (prop) {
        this.countOfFilters++;
      }
    });
    this.PBReducer$.safeSubscribe(this, (d: IPBReducer) => {
      this.pageConfig = d.pageConfiguration || [];
    });

    this.actRoute.queryParams.safeSubscribe(this, (params) => {
      this.filter.eventName = params.eventName && params.eventName;
      // this.filter.venue = params.venue && params.venue;
      this.filter.businessArea = params.businessArea && params.businessArea;
      this.filter.sessionFromDate =
        params.sessionFromDate && params.sessionFromDate;
      this.filter.sessionToDate = params.sessionToDate && params.sessionToDate;
      this.filter.fromDate = params.fromDate && params.fromDate;
      this.filter.toDate = params.toDate && params.toDate;
      this.filter.isAddon = params.isAddon && params.isAddon;
      this.filter.addOnSearch = params.addOnSearch && params.addOnSearch;
      this.filter.days = params.days && params.days;
      this.filter.access = params.access && params.access;
      this.filter.accommodation = params.accommodation && params.accommodation;
      this.filter.venueType = params.venueType && params.venueType;
      this.filter.venue = params.venue && params.venue;
      this.filter.parkSearch = params.parkSearch && params.parkSearch;
      this.filter.availability = params.availability && params.availability;
      this.filter.eventGroup = params.eventGroup && params.eventGroup;
      this.filter.minPrice = params.minPrice && params.minPrice;
      this.filter.maxPrice = params.maxPrice && params.maxPrice;
      this.filter.ageGroupId = params.ageGroupId && params.ageGroupId;
      this.filter.eventSalesCategory =
        params.eventSalesCategory && params.eventSalesCategory;
      this.setValueIntoFB();
    });
    if (this.layoutAttribute.searchPanelData.IsPriceRange) {
      let filter = new SearchBaseFilter();
      if (this.eventCategoryID) {
        filter.eventCategoryID = this.eventCategoryID.toString();
      }
      this.eventService
        .getEventByPriceRange(filter)
        .safeSubscribe(this, (data) => {
          this.priceRange = data;
          if (isNaN(parseInt(this.filter.minPrice))) {
            this.minValue = parseInt(data.minPackagePrice);
          } else {
            this.minValue = parseInt(this.filter.minPrice);
          }
          if (isNaN(parseFloat(this.filter.maxPrice))) {
            this.maxValue = parseInt(data.maxPackagePrice);
          } else {
            this.maxValue = parseInt(this.filter.maxPrice);
          }
          this.assignPriceRangeValue(
            this.minValue,
            this.maxValue,
            parseInt(data.minPackagePrice),
            parseInt(data.maxPackagePrice)
          );
        });
    }
    this.dropdownList = [];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter,
      maxHeight: 197,
    };
    (this.filter.minPrice != undefined || this.filter.minPrice != null) &&
    (this.filter.maxPrice != undefined || this.filter.maxPrice != null)
      ? this.searchFb.controls["eventByPriceRange"].enable()
      : this.searchFb.controls["eventByPriceRange"].disable();
    this.filter.ageGroupId != undefined
      ? this.searchFb.controls["ageGroupId"].enable()
      : this.searchFb.controls["ageGroupId"].disable();
  }

  clearFields() {
    this.selectedValue = "";
    this.selected = undefined;
    this.isNoResultFound = false;
    this.selectedVenue = undefined;
    this.searchFb.reset();
    this.filter.sessionFromDate = null;
    this.filter.sessionToDate = null;
    this.filter.fromDate = null;
    this.filter.toDate = null;
    this.selectedEventGroup = null;
    this.isNoEventGropResultFound = false;
    this.selectedBusinessArea = null;
    this.isNoResultFoundBusiArea = false;
    this.selectedItems = null;
    this.selectedEventSalesCategory = null;
    this.isNoEventSalesCategoryResultFound = false;
    this.clearPriceRangeValue();
    this.getValues();
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, "day"));
  };

  private _filter(value: any, filtertype: string): any[] {
    if (!!value) {
      const filterValue = value.toLowerCase();
      if (filtertype === "venueName") {
        let venue = this.venues.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoResultFound = venue.length == 0;
        if (
          venue.length === 1 &&
          venue[0].name.toLowerCase() === value.toLowerCase()
        ) {
          this.selectedVenue = venue[0];
        } else {
          this.selectedVenue = undefined;
        }
        return venue;
      } else if (filtertype === "businessArea") {
        let businessArea = this.businessAreas.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoResultFoundBusiArea = businessArea.length == 0;
        if (
          businessArea.length === 1 &&
          businessArea[0].name.toLowerCase() === value.toLowerCase()
        ) {
          this.selectedBusinessArea = businessArea[0];
        } else {
          this.selectedBusinessArea = undefined;
        }
        return businessArea;
      } else if (filtertype === "eventGroup") {
        let eventGroup = this.eventGroups.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoEventGropResultFound = eventGroup.length == 0;
        if (
          eventGroup.length === 1 &&
          eventGroup[0].name.toLowerCase() === value.toLowerCase()
        ) {
          this.selectedEventGroup = eventGroup[0];
        } else {
          this.selectedEventGroup = undefined;
        }
        return eventGroup;
      } else if (filtertype === "eventSalesCategory") {
        let eventSalesCategory = this.eventSalesCategories.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        this.isNoEventGropResultFound = eventSalesCategory.length == 0;
        if (
          eventSalesCategory.length === 1 &&
          eventSalesCategory[0].name.toLowerCase() === value.toLowerCase()
        ) {
          this.selectedEventSalesCategory = eventSalesCategory[0];
        } else {
          this.selectedEventSalesCategory = undefined;
        }
        return eventSalesCategory;
      }
    }

    if (filtertype === "venueName") {
      this.selectedVenue = undefined;
      this.isNoResultFound = !(this.venues.length > 0);
      return this.venues;
    } else if (filtertype === "businessArea") {
      this.selectedBusinessArea = undefined;
      this.isNoResultFoundBusiArea = !(this.businessAreas.length > 0);
      return this.businessAreas;
    } else if (filtertype === "eventGroup") {
      this.selectedEventGroup = undefined;
      this.isNoEventGropResultFound = !(this.eventGroups.length > 0);
      return this.eventGroups;
    } else if (filtertype === "eventSalesCategory") {
      this.selectedEventSalesCategory = undefined;
      this.isNoEventSalesCategoryResultFound = !(
        this.eventSalesCategories.length > 0
      );
      return this.eventSalesCategories;
    }
  }

  ngAfterViewInit(): void {
    this.initializeAttributes();
    let filter = new SearchBaseFilter();
    if (this.eventCategoryID) {
      filter.eventCategoryID = this.eventCategoryID.toString();
    }
    if (this.layoutAttribute.searchPanelData.IsVenueSearch) {
      this.venueService
        .getVenue(filter)
        .pipe(map((c: any) => c.map((d) => ({ id: d.id, name: d.name }))))
        .safeSubscribe(this, (data) => {
          this.venues = data;
          this.filteredOptions = this.searchFb
            .get("venueName")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                return this._filter(value, "venueName");
              })
            );
        });
    }
    if (this.layoutAttribute.searchPanelData.IsBusinessArea) {
      this.eventService
        .getBusinessArea(filter)
        .pipe(map((c: any) => c.map((d) => ({ id: d.id, name: d.name }))))
        .safeSubscribe(this, (data) => {
          this.businessAreas = data;
          this.filteredOptionsBusiArea = this.searchFb
            .get("businessArea")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                return this._filter(value, "businessArea");
              })
            );
        });
    }

    if (this.layoutAttribute.searchPanelData.IsEventGroup) {
      let filter = new SearchBaseFilter();
      if (this.eventCategoryID) {
        filter.eventCategoryID = this.eventCategoryID.toString();
      }
      this.eventService
        .getEventGroup(filter)
        .pipe(map((c: any) => c.map((d) => ({ id: d.id, name: d.name }))))
        .safeSubscribe(this, (data) => {
          this.eventGroups = data;
          this.eventGropsFilteredOptions = this.searchFb
            .get("eventGroup")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                return this._filter(value, "eventGroup");
              })
            );
        });
    }
    if (this.layoutAttribute.searchPanelData.IsAgeGroup) {
      let filter = new SearchBaseFilter();
      if (this.eventCategoryID) {
        filter.eventCategoryID = this.eventCategoryID.toString();
      }
      this.eventService.getAgeGroup(filter).safeSubscribe(this, (data) => {
        this.createDropdownList(data.data);
        this.createSelectedDropDownFromUrlParam();
      });
    }

    if (this.layoutAttribute.searchPanelData.IsSalesCategory) {
      let filter = new SearchBaseFilter();
      if (this.eventCategoryID) {
        filter.eventCategoryID = this.eventCategoryID.toString();
      }
      this.eventService
        .getEventSalesCategory(filter)
        .pipe(map((c: any) => c.map((d) => ({ id: d.id, name: d.name }))))
        .safeSubscribe(this, (data) => {
          this.eventSalesCategories = data;
          this.eventSalesCategoryFilteredOptions = this.searchFb
            .get("eventSalesCategory")
            .valueChanges.pipe(
              startWith(""),
              map((value) => {
                return this._filter(value, "eventSalesCategory");
              })
            );
        });
    }
    const getVenueType = this.venueTypeService.getVenueType();
    const getAllVenues = this.venueTypeService.getAllVenues();

    forkJoin([getAllVenues, getVenueType]).safeSubscribe(this, (result) => {
      this.venuesList = result[0]; //get All venues
      let regionList: any[] = result[1] as any[]; //VenueTypes Means Regions
      regionList.map((r) => {
        this.regionList.push(r);
      });
      this.venuesList.map((v) => {
        this.sortedVenueList.push(v);
      });
      this.isMultiSelectionPackage ? this.getValues() : "";
      this.setValueIntoFB();
    });
    this.displayRegion =
      this.layoutAttribute.searchPanelData.IsRegionSearch &&
      this.layoutAttribute.searchPanelData.IsParkSearch
        ? true
        : false;
    this.displayDateRange =
      this.layoutAttribute.searchPanelData.IsDate &&
      this.layoutAttribute.searchPanelData.IsDays
        ? true
        : false;
  }
  getValues() {
    this.toppings.value != null ? (this.sortedVenueList = []) : "";
    let length;
    let e = document.getElementById("matLabelRegion");
    if (e != undefined && e != null) {
      try {
        length = this.toppings.value.length;
        if (length > 0) {
          this.toppings.value.forEach((topping) => {
            this.venuesList.forEach((element) => {
              if (length > 0 && topping == element.venueType) {
                this.sortedVenueList.push(element);
              }
            });
          });
        } else {
          this.venuesList.forEach((element) => {
            this.sortedVenueList.push(element);
          });
        }
      } catch (error) {}
      if (this.filter.parkSearch && this.venuesList != undefined) {
        this.venuesList.forEach((venue) => {
          if (venue.id == this.filter.parkSearch) {
            this.filter.parkSearch = venue.name;
          }
        });
        this.sortedVenueList.push();
      }
      length == undefined || length == null
        ? e.setAttribute("style", "display:block")
        : length > 0
        ? e.setAttribute("style", "display:none")
        : e.setAttribute("style", "display:block");

      //  this.filter.venueType ? e.setAttribute("style", "display:none") : e.setAttribute("style", "display:block");
    }
  }

  getSelectedValue(r) {
    this.toppings.setValue([r.venueType]);
    this.getValues();
  }

  daysChange(event) {
    const value = event.target.value;
    this.filter.sessionFromDate ? "" : "";
    this.filter.sessionToDate ? "" : "";

    if (
      this.filter.sessionFromDate == null &&
      this.filter.sessionToDate == null
    ) {
      this.filter.sessionFromDate = new Date().toString();
      this.filter.sessionToDate = new Date().toString();
    }
    let tempSelected = {
      startDate: moment(),
      endDate: moment(),
    };
    if (this.isMultiSelectionPackage) {
      if (
        this.filter.sessionFromDate &&
        !this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (
        this.filter.sessionFromDate &&
        this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate);
        tempSelected.endDate = moment(this.filter.sessionToDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.sessionToDate).add(
          newValue,
          "days"
        );
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }

      if (
        this.filter.sessionFromDate &&
        !this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (
        this.filter.sessionFromDate &&
        this.filter.sessionToDate &&
        this.datechooser
      ) {
        tempSelected.startDate = moment(this.filter.sessionFromDate);
        tempSelected.endDate = moment(this.filter.sessionToDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.sessionToDate).add(
          newValue,
          "days"
        );
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }
    } else {
      if (this.filter.fromDate && !this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (this.filter.fromDate && this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate);
        tempSelected.endDate = moment(this.filter.toDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.toDate).add(newValue, "days");
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }

      if (this.filter.fromDate && !this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate).add(
          parseInt(value) - 1,
          "days"
        );
        tempSelected.endDate = tempSelected.startDate;
        this.dateRange(tempSelected);
      }
      if (this.filter.fromDate && this.filter.toDate && this.datechooser) {
        tempSelected.startDate = moment(this.filter.fromDate);
        tempSelected.endDate = moment(this.filter.toDate);
        let diff =
          moment
            .duration(tempSelected.endDate.diff(tempSelected.startDate))
            .asDays() + 1;
        let newValue = value - diff + 1;
        tempSelected.endDate = moment(this.filter.toDate).add(newValue, "days");
        this.dateRange(tempSelected);
      } else {
        this.selected.endDate = moment(this.selected.startDate).add(
          parseInt(value) - 1,
          "days"
        );
        this.dateRange(this.selected);
      }
    }

    this.searchFb.patchValue({
      selectedValue: this.selectedValue,
    });
  }

  setValueIntoFB() {
    if (this.filter.venueType && this.filter.venueType.length > 0) {
      for (let index = 0; index < this.filter.venueType.length; index++) {
        const element = this.filter.venueType[index];
        this.regionList.forEach((vt) => {
          if (vt.id == element) {
            this.toppings.value == null
              ? this.toppings.setValue([vt.name])
              : this.toppings.value.push(vt.name);
          }
        });
      }
    }
    let venueValue = "";
    if (
      this.filter.venue &&
      this.sortedVenueList &&
      this.sortedVenueList.length > 0
    ) {
      this.sortedVenueList.forEach((element) => {
        if (element.id == this.filter.venue) {
          venueValue = element.name;
        }
      });
    }
    this.isMultiSelectionPackage ? this.getValues() : "";
    this.selectedVenue = this.venues.find((x) => x.id == this.filter.venue);
    let fromDate;
    let toDate;
    let dateCount;
    this.filter.sessionFromDate
      ? (fromDate = moment(this.filter.sessionFromDate, "YYYY-MM-DD"))
      : this.filter.fromDate
      ? (fromDate = moment(this.filter.fromDate, "YYYY-MM-DD"))
      : "";

    this.filter.sessionToDate
      ? (toDate = moment(this.filter.sessionToDate, "YYYY-MM-DD"))
      : this.filter.toDate
      ? (toDate = moment(this.filter.toDate, "YYYY-MM-DD"))
      : "";

    fromDate && toDate
      ? (dateCount = moment.duration(toDate.diff(fromDate)).asDays() + 1)
      : fromDate && toDate == undefined
      ? (dateCount = 1)
      : (dateCount = "");

    this.filter.sessionFromDate
      ? (this.selectedValue = moment(this.filter.sessionFromDate).format(
          "YYYY-MM-DD"
        ))
      : this.filter.fromDate
      ? (this.selectedValue = moment(this.filter.fromDate).format("YYYY-MM-DD"))
      : "";

    this.filter.sessionFromDate && this.filter.sessionToDate
      ? (this.selectedValue =
          moment(this.filter.sessionFromDate).format("YYYY-MM-DD") +
          " - " +
          moment(this.filter.sessionToDate).format("YYYY-MM-DD"))
      : this.filter.fromDate && this.filter.toDate
      ? (this.selectedValue =
          moment(this.filter.fromDate).format("YYYY-MM-DD") +
          " - " +
          moment(this.filter.toDate).format("YYYY-MM-DD"))
      : "";

    this.selectedEventGroup = this.eventGroups.find(
      (x) => x.id == this.filter.eventGroup
    )?.name;
    this.selectedEventSalesCategory = this.eventSalesCategories.find(
      (x) => x.id == this.filter.eventSalesCategory
    )?.name;
    this.businessAreas != undefined && this.businessAreas.length > 0
      ? (this.selectedBusinessArea = this.businessAreas.find(
          (x) => x.id == this.filter.businessArea
        )?.name)
      : "";

    this.searchFb.patchValue({
      venueName: this.selectedVenue ? this.selectedVenue.name : "",
      businessArea: this.selectedBusinessArea
        ? this.selectedBusinessArea.name
        : "",

      eventName: this.filter.eventName ? this.filter.eventName : "",

      addOnSearch: this.filter.addOnSearch ? this.filter.addOnSearch : "",
      days: dateCount,
      parkEvents: this.filter.parkSearch ? this.filter.parkSearch : "",
      selectedValue: this.selectedValue,
      eventByPriceRange:
        this.filter.minPrice != undefined && this.filter.maxPrice != undefined
          ? [this.filter.minPrice, this.filter.maxPrice]
          : "",
      availability: this.filter.availability ? this.filter.availability : "",
      eventGroup: this.selectedEventGroup ? this.selectedEventGroup : "",
      eventSalesCategory: this.selectedEventSalesCategory
        ? this.selectedEventSalesCategory
        : "",
      ageGroupId:
        this.selectedItems != undefined && this.selectedItems.length > 0
          ? this.selectedItems
          : "",
    });
    console.log("setValueIntoFB()", this.toppings, "this.toppings");
  }

  onSubmit() {
    if (this.selected) {
      if (this.isMultiSelectionPackage) {
        this.filter.sessionFromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        );
        this.filter.sessionToDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        );
      } else {
        this.filter.fromDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.startDate.toDate())
        );
        this.filter.toDate = Utility.convertToISO(
          Utility.convertISOToDate(this.selected.endDate.toDate())
        );
      }
    }
    if (this.toppings.value != null) {
      let venueTypeID = [];
      this.toppings.value.forEach((element) => {
        this.regionList.forEach((vt) => {
          if (element == vt.name) {
            venueTypeID.push(vt.id);
          }
        });
      });
      this.filter.venueType = venueTypeID;
    }

    if (this.searchFb.get("parkEvents").value) {
      let value = this.searchFb.get("parkEvents").value;
      if (this.sortedVenueList && this.sortedVenueList.length > 0) {
        this.sortedVenueList.forEach((element) => {
          if (element.name == value) {
            this.filter.parkSearch = element.id;
          }
        });
        // this.filter.venue = this.searchFb.get("parkEvents").value;
      }
    }
    this.filter.eventName = this.searchFb.get("eventName").value
      ? this.searchFb.get("eventName").value
      : undefined;
    this.filter.eventGroup = this.selectedEventGroup
      ? this.selectedEventGroup.id
      : undefined;
    this.filter.minPrice = this.PriceValueChanged
      ? this.searchFb.get("eventByPriceRange").value[0]
      : this.filter.minPrice != undefined && this.filter.minPrice
      ? this.filter.minPrice
      : undefined;
    this.filter.maxPrice = this.PriceValueChanged
      ? this.searchFb.get("eventByPriceRange").value[1]
      : this.filter.maxPrice != undefined && this.filter.maxPrice
      ? this.filter.maxPrice
      : undefined;
    this.filter.ageGroupId = this.concadinateAgeGroupIds();
    this.filter.eventSalesCategory = this.selectedEventSalesCategory
      ? this.selectedEventSalesCategory.id
      : undefined;
    if (this.searchFb.get("availability").value !== 0) {
      this.filter.availability = this.searchFb.get("availability").value
        ? this.searchFb.get("availability").value
        : undefined;
    }
    this.filter.venue = this.selectedVenue ? this.selectedVenue.id : undefined;
    this.filter.businessArea = this.selectedBusinessArea
      ? this.selectedBusinessArea.id
      : undefined;
    this.filter.clientCategoryId = this.filter.clientCategoryId
      ? this.filter.clientCategoryId
      : undefined;
    this.filter.clientID = this.filter.clientID
      ? this.filter.clientID
      : undefined;
    this.filter.itemName = this.filter.itemName
      ? this.filter.itemName
      : undefined;

    if (this.cardService.getPageType === PAGE_TYPE.EVENT_CATEGORY) {
      this.route.navigate(["/event-list"], { queryParams: this.filter });
    } else if (this.cardService.getPageType === PAGE_TYPE.EVENT_LIST) {
      this.route
        .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
        .then(() => {
          this.eventCategoryID
            ? this.route.navigate(["/event-list", this.eventCategoryID], {
                queryParams: this.filter,
              })
            : this.route.navigate(["/event-list"], {
                queryParams: this.filter,
              });
        });
    }
  }

  initializeAttributes() {
    const contentElement = document.getElementById(this.elementID);
    if (contentElement) {
      const mainContainer = contentElement.closest(".main-layout-container");
      if (this.layoutAttribute.isGrid) {
        d3.select(contentElement.parentElement).attr(
          "class",
          `col-md-${this.layoutAttribute.gridColumn}
                    col-lg-${this.layoutAttribute.gridColumn}
                    col-xl-${this.layoutAttribute.gridColumn} p-0 column-e`
        );
      }
      if (mainContainer) {
        d3.select(mainContainer)
          .style(
            "margin-top",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          )
          .style(
            "margin-bottom",
            this.theme.global.margin ? this.theme.global.margin + "px" : "0"
          )
          .selectAll(".s-p-btn")
          .style("background-color", this.theme.global.buttonColor);
      }
    }

    switch (this.countOfFilters) {
      case 1:
        this.classValue = "col-md-12 col-sm-12 col-lg-12 col-xs-12 col-xl-12";
        break;
      case 2:
        this.classValue = "col-md-6 col-sm-6 col-lg-6 col-xs-6 col-xl-6";
        break;
      case 3:
        this.classValue = "col-md-4 col-sm-4 col-lg-4 col-xs-4 col-xl-4";
        break;
      default:
        this.classValue = "col-md-3 col-sm-4 col-lg-3 col-xs-4 col-xl-3";
    }
  }

  setMaxWidth() {
    let dateRangeContainer = document.getElementsByClassName(
      "mat-dialog-container"
    );
    dateRangeContainer[0].removeAttribute("style");
    let inLine = document.getElementsByClassName("inline");
    inLine[0].removeAttribute("style");
  }

  dateRange(event) {
    this.selected = {
      startDate: "",
      endDate: "",
    };
    this.countDays =
      moment.duration(event.endDate.diff(event.startDate)).asDays() + 1;
    this.selected.startDate = moment(event.startDate);
    this.selected.endDate = moment(event.endDate);
    if (parseInt(this.countDays) < 2) {
      this.selectedValue = moment(event.startDate).format("YYYY-MM-DD");
      // this.selectedValue = moment(event.startDate).format("YYYY-MM-DD")+ " - " +  moment(event.endDate).format("YYYY-MM-DD");;
      //this.filter.fromDate = moment(event.startDate).format();
      this.isMultiSelectionPackage
        ? (this.filter.sessionFromDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.startDate.toDate())
          ))
        : (this.filter.fromDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.startDate.toDate())
          ));

      this.filter.sessionToDate = null;
    } else {
      this.selectedValue =
        moment(event.startDate).format("YYYY-MM-DD") +
        " - " +
        moment(event.endDate).format("YYYY-MM-DD");
      this.isMultiSelectionPackage
        ? (this.filter.sessionFromDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.startDate.toDate())
          ))
        : (this.filter.fromDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.startDate.toDate())
          ));

      this.isMultiSelectionPackage
        ? (this.filter.sessionToDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.endDate.toDate())
          ))
        : (this.filter.toDate = Utility.convertToISO(
            Utility.convertISOToDate(this.selected.endDate.toDate())
          ));
    }
    this.searchFb.patchValue({
      selectedValue: this.selectedValue,
    });
  }

  choosedDateTime(event) {
    this.selectedValue =
      moment(event.startDate).format("YYYY-MM-DD") +
      " - " +
      moment(event.endDate).format("YYYY-MM-DD");
    this.selected = event;
    if (this.isMultiSelectionPackage) {
      this.filter.sessionFromDate = moment(event.startDate).format();
      this.filter.sessionToDate = moment(event.endDate).format();
    } else {
      this.filter.fromDate = moment(event.startDate).format();
      this.filter.toDate = moment(event.endDate).format();
    }
    this.datechooser = false;
    this.selected = event;
    this.dateRange(event);
    this.layoutAttribute.searchPanelData.IsDays ? "" : (this.countDays = "");
    this.searchFb.patchValue({
      selectedValueRange: this.selectedValue,
      days: parseInt(this.countDays),
      // formControlName2: myValue2 (can be omitted)
    });
    this.dialog.closeAll();
    (<HTMLInputElement>document.getElementById("dateRangePicker")).value =
      this.selectedValue;
  }

  openDialog(templateRef) {
    let dialogRef = this.dialog.open(templateRef, {
      height: "max-content",
      width: "max-content",
    });
  }

  setWidth(venue, date, search) {
    this.searchPanelWidth.venue = venue;
    this.searchPanelWidth.date = date;
    this.searchPanelWidth.search = search;
  }

  setStyles(className) {
    if (className == "cdk-panel") {
      let cls = document.getElementById("cdk-overlay-0");
      cls.style.overflow = "hidden";
      cls.style.transform = "translateX(-12px) translateY(18px)";
      cls.style.minWidth = "325px";
    }
  }

  onNoDelBtnVisible(searchFbValues: {
    venueName: "";
    eventName: "";
    businessArea: "";
    isAddon: "";
    addOnSearch: "";
    days: "";
    access: "";
    accommodation: "";
    parkSearch: "";
    selectedValueRange: "";
    selectedValue: undefined | ""; //noted
    parkEvents: "";
    toppings: any[];
    availability: 0;
    eventGroup: "";
    ageGroupId: "";
    eventSalesCategory: "";
  }) {
    for (const value in searchFbValues) {
      if (value === "toppings") {
        if (searchFbValues[value] !== null) {
          if (searchFbValues[value].length !== 0) {
            return false;
          }
        }
      }
      if (value !== "toppings" && !!searchFbValues[value]) {
        return false;
      }
    }
    return true;
  }

  onInputDateRangeText(event: KeyboardEvent) {
    const textAtDatePicker = (event.target as HTMLInputElement).value;
    if (textAtDatePicker === "") {
      this.searchFb.patchValue({
        selectedValueRange: null,
        selectedValue: null,
        days: "",
      });

      this.selected = undefined;
      this.filter.sessionFromDate = null;
      this.filter.sessionToDate = null;
      this.filter.fromDate = null;
      this.filter.toDate = null;
      this.selectedValue = "";
    }
  }

  resetOnFalseVenue() {
    if (!this.selectedVenue) {
      this.searchFb.get("venueName").reset();
      console.log("reset venue");
    }
  }
  assignPriceRangeValue(
    minValue: number,
    maxValue: number,
    floor: number,
    ceil: number
  ) {
    this.minValue = minValue;
    this.maxValue = maxValue;

    //change ngx-slider min and max value
    this.options = {
      floor: parseFloat(floor.toFixed(2)),
      ceil: parseFloat(ceil.toFixed(2)),
      enforceStep: false,
      step: 5,
      disabled: this.minValue == this.maxValue ? true : false,
      translate: (value: number, label: LabelType): string => {
        //if not min & max are same
        if (this.minValue != this.maxValue) {
          switch (label) {
            case LabelType.Low:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
            case LabelType.High:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
            default:
              return (
                (environment.PublicBookingSetting as PublicBookingSettings)
                  .regionalSetting.defaultCurrencySymbol + value.toFixed(2)
              );
          }
        } else {
          switch (label) {
            case LabelType.Low:
              return "";
            case LabelType.High:
              return "";
            default:
              return "";
          }
        }
      },
    };
    setTimeout(() => {
      if (
        parseFloat(this.priceRange.minPackagePrice) ==
        parseFloat(this.priceRange.maxPackagePrice)
      ) {
        this.options = Object.assign({}, this.options, { disabled: true });
        document
          .getElementById("ngx-price-range-slider")
          .getElementsByClassName("ngx-slider-model-value")[0]
          .remove();
        document
          .getElementById("ngx-price-range-slider")
          .getElementsByClassName("ngx-slider-model-high")[0]
          .remove();
      }
    }, 0);
  }
  concadinateAgeGroupIds() {
    if (
      this.searchFb.get("ageGroupId").value != null &&
      this.searchFb.get("ageGroupId").value.length > 0
    ) {
      let ageGroupId = [];
      for (
        let index = 0;
        index < this.searchFb.get("ageGroupId").value.length;
        index++
      ) {
        ageGroupId.push(this.searchFb.get("ageGroupId").value[index].id);
      }
      return ageGroupId;
    }
  }

  createDropdownList(item: any) {
    let tmp = [];
    if (item) {
      for (let index = 0; index < item.length; index++) {
        var name = "";
        if (item[index].minimum == 0) {
          name = "Less than " + item[index].maximum + " Years";
        } else if (item[index].maximum == 0) {
          name = "Greater than " + item[index].minimum + " Years";
        } else {
          name = item[index].minimum + "-" + item[index].maximum + " Years";
        }
        tmp.push({ id: item[index].id.toString(), name: name });
      }
    }
    this.dropdownList = tmp;
  }
  onItemSelect($event) {
    this.searchFb.controls["ageGroupId"].enable();
  }

  resetOnFalseDate(event) {
    let dateRange = event.target.value;

    if (!this.selected?.endDate && !this.selected?.startDate) {
      if (!moment(dateRange, "DD-MMM-YYYY HH:mm a").isValid()) {
        this.selectedValue = "";
        event.target.value = this.selectedValue;
        console.log(dateRange, "in dateRange");
      }
    }
    console.log(this.selected, "selected");
  }
  createSelectedDropDownFromUrlParam() {
    if (this.dropdownList.length > 0) {
      if (
        this.filter.ageGroupId != undefined &&
        this.filter.ageGroupId.length > 0
      ) {
        let selectedIds = this.filter.ageGroupId;
        let selectedItems = this.dropdownList;
        let temp = [];
        for (let index = 0; index < selectedIds.length; index++) {
          temp.push(selectedItems[parseInt(selectedIds[index]) - 1]);
        }
        this.selectedItems = temp;
      }
    }
  }
  valueChanges() {
    this.PriceValueChanged = true;
    this.searchFb.controls["eventByPriceRange"].enable();
  }
  @HostListener("window:scroll", ["$event"])
  scrollHandler() {
    if (document.querySelector(".custom-style")) {
      var domRect = document
        .querySelector(".custom-style")
        .getBoundingClientRect();
      var spaceBelow = window.innerHeight - domRect.bottom;
      if (spaceBelow < this.dropdownSettings["maxHeight"]) {
        document.getElementsByClassName("dropdown-list")[0]["style"][
          "marginTop"
        ] = "-284px";
      } else {
        document.getElementsByClassName("dropdown-list")[0]["style"][
          "marginTop"
        ] = "0px";
      }
    }
  }
  dropdownOpenBasedOnSpace() {
    this.scrollHandler();
  }

  clearPriceRangeValue() {
    var min = parseFloat(this.priceRange.minPackagePrice);
    var max = parseFloat(this.priceRange.maxPackagePrice);
    setTimeout(() => {
      this.assignPriceRangeValue(min, max, min, max);
    }, 0);
    this.filter.minPrice = "";
    this.filter.maxPrice = "";
    this.PriceValueChanged = false;
    this.searchFb.controls["eventByPriceRange"].disable();
  }
  onItemDeSelect($event) {
    if (this.searchFb.get("ageGroupId").value == 0) {
      this.searchFb.controls["ageGroupId"].disable();
    }
  }
  selectallItems($event) {
    this.searchFb.controls["ageGroupId"].enable();
  }
  unselectallItems($event) {
    this.searchFb.controls["ageGroupId"].disable();
  }
}
